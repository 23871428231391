import React, { useState } from 'react';
import {Button, DatePicker, Dialog, Tabs, DialogActions} from "components/Mui";
import CustomAppBar from "components/CustomAppBar/CustomAppBar";
import AttendanceReportAllRecords from "./AttendanceReportAllRecords/AttendanceReportAllRecords";
import AttendanceReportMonthlyBasis from "./AttendanceReportMonthlyBasis/AttendanceReportMonthlyBasis";
import {defaultDateOptions} from "utility/constants/data";
import {CUSTOM_DATE_MODAL, TIME_OFF_REQUEST_MODAL,} from "utility/modals/constants";
import {FULL_DATE_MONTH_LETTERS_FORMAT} from "utility/helpers/metaData";
import WithModals from "hoc/withModals/withModals";
import moment from "moment";
import {useForm} from "react-hook-form";
import {getDateOptions} from "utility/helpers/utils";

import "./AttendanceReport.scss";

const getAttendanceReportTabs = (employeeId, date, setDate, message,colorTheme) => {
    return [
        {
            label: "All Records",
            component: <AttendanceReportAllRecords employeeId={employeeId} date={date} message={message} colorTheme={colorTheme} />
        },
        {
            label: "Monthly Basis",
            component: <AttendanceReportMonthlyBasis employeeId={employeeId} date={date} message={message}/>
        }
    ];
};

const AttendanceReport = (props) => {
    const { employeeId, message, title, colorTheme, handleCloseButton, modals, toggleCustomDateModal, toggleTimeOffEditModal } = props;
    const [date, setDate] = useState(undefined);
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const [dateOptions, setDateOptions] = useState(defaultDateOptions);
    const [from, setFrom] = useState(moment());
    const [to, setTo] = useState(moment);
    const [specificDate, setSpecificDate] = useState(moment());
    const [years, setYears] = useState(getDateOptions());
    const attendanceReportTabs = getAttendanceReportTabs(employeeId, date, setDate, message, colorTheme);
    const { handleSubmit, register } = useForm({ mode: 'onSubmit' });

    const handleDefaultDateSelection = (value) => {
        const yearData = dateOptions.find((item) => item.value === (value?.value || value));
        if (yearData?.value === "customDate") {
            toggleCustomDateModal();
        }else{
            setDate(yearData);
        }
    }
    const handleMonthlyDateSelection = (value) => {
        const yearData = dateOptions.find((item) => item.value === (value?.value || value));
        if (yearData?.value === "customDate") {
            toggleTimeOffEditModal();
        }else{
            setDate(yearData);
        }
    }
    const applyCustomDateRange = async (data) => {
        const { toDate, fromDate } = data;
        const customDateName =
            moment(fromDate).format(FULL_DATE_MONTH_LETTERS_FORMAT) +
            " - " +
            moment(toDate).format(FULL_DATE_MONTH_LETTERS_FORMAT);
        const customDateOption = {
            id: customDateName,
            label: customDateName,
            value: customDateName,
            fromDate: moment(fromDate).valueOf(),
            toDate: moment(toDate).valueOf(),
        };
        const updatedDateOptions = [
            ...defaultDateOptions.filter(option => option.value !== "customDate"),
            customDateOption,
            {
                label: "Custom Date",
                id: "customDate",
                value: "customDate",
            }
        ];
        setDateOptions(updatedDateOptions);
        setDate(customDateOption);
        toggleCustomDateModal();
    };


    const addCustomYearOption = () => {
        if (!specificDate) return;

        const newYearValue = specificDate.format("yyyy");

        if (!years.some((year) => year.value === newYearValue)) {
            const updatedYears = [...years];
            const lastElement = updatedYears.pop();
            updatedYears.push(
                { label: newYearValue, value: newYearValue },
                lastElement
            );
            setDateOptions(updatedYears);
        }
        setDate(newYearValue);
        toggleTimeOffEditModal();
    };


    const updateTabAndDateOptions = (index) => {
        if(index === 1){
            const dateOptions = getDateOptions();
            setDateOptions(dateOptions);
            const result = dateOptions.find(option => option.value === moment().format("YYYY"));
            setDate(result?.value || dateOptions[0]);
        }else{
            setDateOptions(defaultDateOptions);
            setDate(defaultDateOptions[0]);
        }
        setCurrentTabIndex(index);
    }
    return (
        <div id="attendance-report" className="c-attendance-report px-4 m-3">
            <CustomAppBar title={title} handleCloseButton={handleCloseButton} showSecondaryButton dateOptions={dateOptions} date={date} handleDateChange={currentTabIndex === 0 ? handleDefaultDateSelection : handleMonthlyDateSelection} addCustomDate message={message} />
            {!!attendanceReportTabs.length &&
                <Tabs currentTabIndex={currentTabIndex} items={attendanceReportTabs} handleTabOnChange={updateTabAndDateOptions} />
            }
            <Dialog className='time-off-request-dialog' open={modals[CUSTOM_DATE_MODAL]} toggle={toggleCustomDateModal}>
                <form onSubmit={handleSubmit(applyCustomDateRange)}>
                    <div className="m-4">
                        <div className="column">
                            <DatePicker
                                name="fromDate"
                                label="From Date"
                                className="mx-1"
                                defaultValue={from}
                                onChange={(event)=>setFrom(event)}
                                inputRef={register({
                                    required: "This field is required"
                                })}
                            />
                            <DatePicker
                                name="toDate"
                                label="To Date"
                                className="mx-1"
                                defaultValue={to}
                                minDate={from}
                                onChange={(event)=>setTo(event)}
                                inputRef={register({
                                    required: "This field is required"
                                })}
                            />
                        </div>
                    </div>
                    <div>
                        <DialogActions className="p-3 mt-3">
                            <Button className="px-4 me-3" variant="outlined" onClick={toggleCustomDateModal}>Cancel</Button>
                            <Button className="px-4" variant="contained" type="submit">OK</Button>
                        </DialogActions>
                    </div>
                </form>
            </Dialog>
            <Dialog className='time-off-request-dialog' open={modals[TIME_OFF_REQUEST_MODAL]} toggle={toggleTimeOffEditModal}>
                <div className="m-4">
                    <h6 className="mb-4" style={{color: colorTheme}}>Custom Date</h6>
                    <div className="column">
                        <DatePicker
                            name="specificDate"
                            label="Specific Date"
                            className="mx-1"
                            value={specificDate}
                            onChange={(newValue) => setSpecificDate(newValue)}
                            views={['year']}
                            openTo="year"
                        />
                    </div>
                </div>
                <div>
                    <DialogActions className="p-3 mt-3">
                        <Button className="px-4 me-3" variant="outlined" onClick={toggleTimeOffEditModal}>Cancel</Button>
                        <Button className="px-4" variant="contained" type="submit" onClick={addCustomYearOption}>Save</Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
};
const modalsProps = {
    toggleCustomDateModal: CUSTOM_DATE_MODAL,
    toggleTimeOffEditModal: TIME_OFF_REQUEST_MODAL
};
export default WithModals(AttendanceReport, modalsProps);
