import React  from "react";
import { useTranslation } from 'react-i18next';
import { Dialog, DialogActions, Button, useTheme } from "components/Mui";

const Confirm = (props) =>{
    const { t } = useTranslation();
    const {confirmMessage, actionLabel, onActionClick, onCancel, open} = props;
    const selectedTheme = useTheme();
    const colorTheme = selectedTheme?.palette?.primary?.main;
    const onCancelClick = () =>{
        if(onCancel){
            onCancel();
        }
    }

    const onAction = ()=>{
        if(onActionClick){
            onActionClick();
        }
    }

    return (
        <>
            <Dialog className='confirmation-dialog' open={open}>
                <div>
                    <div className="m-4" style={{color: colorTheme}}>
                        {confirmMessage}
                    </div>
                    <div>
                        <DialogActions className="p-3 mt-3">
                            <Button className="px-4 me-3" variant="outlined" onClick={onCancelClick}>{t('cancel')}</Button>
                            <Button className="px-4 bg-danger" color="secondary" variant="outlined" onClick={onAction}>{actionLabel}</Button>
                        </DialogActions>
                    </div>
                </div>
            </Dialog>
        </>
    );
}
export default Confirm;
