import React, {useEffect, useState} from 'react';
import {Button, DataGridTable, Select, useTheme} from "components/Mui";
import {getUserInfo} from "utility/helpers/authHelper";
import {getSlackToken, getUserList} from "utility/apis/SettingService";
import {TABLES_NAMES} from "utility/constants/constants";
import SlackButton from "assets/images/slack-2.png";
import CustomAppBar from "components/CustomAppBar/CustomAppBar";

import './SlackSetting.scss'

const SlackSetting = (props) => {
    const {title, handleCloseButton, tenantId} = props;
    const [toffyAccount, setToffyAccount] = useState()
    const [dataTable, setDataTable] = useState();
    const [filterStatus, setFilterStatus] = useState('');
    const selectedTheme = useTheme();
    const colorTheme = selectedTheme?.palette?.primary?.main;
    const getSlackTokenData = async ()=>{
        try {
            const response = await getSlackToken(tenantId);
            setToffyAccount(response.data);
        }catch (e){
            console.log(e)
        }
    }
    const getSlackUserList = async (slackToken)=>{
        try {
            const response = await getUserList(slackToken);
            setDataTable(response.data);
        }catch (e){
            console.log(e)
        }
    }
    useEffect(() => {
        getSlackTokenData();
    },[title]);
    useEffect(() => {
        if(toffyAccount) {
            getSlackUserList(toffyAccount?.slack_token);
        };
    }, [toffyAccount]);

    const handleFilterChange = (event) => {
        setFilterStatus(event.target.value);
    };

    const filteredData = dataTable?.members?.filter((member) => {
        if (!filterStatus) return true;
        switch (filterStatus) {
            case 'accepted':
                return member?.slackInvitationStatus === 'Accepted';
            case 'pending':
                return member?.slackInvitationStatus === 'Pending';
            case 'notInvited':
                return member?.slackInvitationStatus === 'Not Invited';
            default:
                return true;
        }
    });

    const columnDefs = [
        {
            headerName: 'Employee Email',
            field: 'profile',
            renderCell: (params) => (
                <div>{params?.value?.email}</div>
            ),
            flex: 2,
        },
        {
            headerName: 'Slack Invitation Status',
            field: 'slackInvitationStatus',
            renderCell: () => (
                <div className="text-primary">Joined</div>
            ),
            flex: 2,

        }
    ];

    return (
        <div id="SlackSetting" className="c-slack-setting px-4 mt-4">
            <CustomAppBar
                title={title}
                showSecondaryButton
                textSecondary="Close"
                handleCloseButton={handleCloseButton}
            />
            {!toffyAccount ?
                <div className="d-flex justify-content-between">
                    <span>Connect with Toffy, your HR virtual assistant</span>
                    <Button variant="outlined" href={JSON.parse(getUserInfo()).client_id} target="_blank"
                            startIcon={<img src={SlackButton} alt="add to slack"/>}> Add To Slack</Button>
                </div>
            :
                <div className="d-flex justify-content-around">
                    <span><img src={SlackButton} alt="add to slack" className="mx-3"/>Your Toffy account is now connected to <b style={{color : colorTheme}}>{toffyAccount.workspace_name}</b></span>
                </div>
            }
            <hr/>
            <div className="w-100 d-flex justify-content-end">
                <Select
                    label="Show"
                    options={[
                        { value: 'all', label: 'All' },
                        { value: 'accepted', label: 'Accepted' },
                        { value: 'pending', label: 'Pending' },
                        { value: 'notInvited', label: 'Not Invited' },
                    ]}
                    value={filterStatus}
                    onChange={handleFilterChange}
                />
            </div>
            <div style={{height: "calc(100vh - 293px)"}} className="row w-100">
                <DataGridTable
                    className="col-12"
                    key={TABLES_NAMES.SLACK_LIST}
                    columns={columnDefs}
                    dataTable={filteredData}
                />
            </div>
        </div>
    );
};

export default SlackSetting;