import React, {createContext, useState} from 'react';
import {Route, Redirect, useLocation} from 'react-router-dom';
import classNames from "classnames";
import { ThemeProvider } from "components/Mui";
import Header from 'containers/Header/Header';
import Footer from 'containers/Footer/Footer';
import {getIsLoggedIn, getIsManager} from 'utility/helpers/authHelper'
import {getCurrentTenant, getTenantId} from "utility/helpers/utils";
import {colorList} from "utility/constants/data";
import {customTheme} from "theme";
import BackgroundImage from 'assets/images/background.png';

import './ManagerLayout.scss';

export const ManagerThemeColorContext = createContext();
const ManagerLayout = ({ component: Component, className='logged-in', ...rest }) => {
    const isLoggedIn = getIsLoggedIn();
    const tenantId = getTenantId(useLocation(), "tenantId");
    const tenantInfo = JSON.parse(getCurrentTenant());
    const [tenantTheme] = useState( colorList.find((item)=> item.name === tenantInfo?.themeColor) || colorList[0] );
    const [selectedTheme, setSelectedTheme] = useState(tenantTheme);
    const isManager = getIsManager();
    return (
        <Route
            {...rest}
            render={(matchProps) => {
                if (!isLoggedIn) {
                    // not logged in so redirect to login page with the return url
                    return (
                        <Redirect
                            to={{ pathname: '/login', state: { from: matchProps.location } }}
                        />
                    );
                } else if (!isManager) {
                    return (
                        <Redirect
                            to={{ pathname: '/employees' }}
                        />
                    );
                } else if (!tenantId) {
                    return (
                        <Redirect
                            to={{ pathname: '/tenants' }}
                        />
                    );
                }
                // authorised so return component
                return (
                    <ManagerThemeColorContext.Provider value={{ setSelectedTheme }}>
                        <ThemeProvider theme={customTheme(selectedTheme)}>
                            <div className={classNames("c-layout", className)}>
                                <Header/>
                                <main className="c-manager-layout c-layout_main container-fluid"
                                      style={{backgroundColor: selectedTheme?.lighter, backgroundImage: `url(${BackgroundImage})`, backgroundRepeat: 'no-repeat'}}>
                                    <Component {...rest} {...matchProps} />
                                </main>
                                <Footer/>
                            </div>
                        </ThemeProvider>
                    </ManagerThemeColorContext.Provider>
                );
            }}
        />
    );
};

export default ManagerLayout;
