import React, {useContext, useState} from 'react';
import {useEffect} from "react";
import {useForm} from "react-hook-form";
import {Button, Grid, CircularProgress} from "components/Mui";
import Loading from "components/Loading/Loading";
import {getTenantId, isObjectEmpty} from "utility/helpers/utils";
import WFHPolicyCustomization from "./WFHPolicyCostumization/WFHPolicyCustomization";
import Conditions from "./Conditions/Conditions";
import {getPolicy} from "utility/apis/SetupGuideService";
import {ProfileContext} from "containers/ProfileDetails/ProfileDetails";
import {useLocation} from "react-router-dom";


const WFHCustomizationPolicyListForm = (props) => {
    const { wfhPolicyDataFromServer, setSaveWfhPolicyData, handleSaveWfhPolicy, withSaveButton, employeeId, profileWfhPolicy, setProfileWfhPolicy } = props;
    const [defaultWfhPolicyData, setDefaultWfhPolicyData] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const profileContext = useContext(ProfileContext);
    const { register, control, handleSubmit, reset, formState: { errors }, getValues, trigger } = useForm({
        defaultValues: (profileWfhPolicy && !employeeId) ? profileWfhPolicy : {},
        mode: "all"
    });

    const tenantId = getTenantId(useLocation(),"tenantId");
    const resetToDefault = async () => {
        if (isSubmitting) return;
        setIsSubmitting(true);
        const response = await getPolicy(tenantId);
        if (employeeId) {
            handleSaveWfhPolicy(response.data.workFromHomePolicy);
        } else {
            setDefaultWfhPolicyData(response.data.workFromHomePolicy);
        }
    };
    const handleProfileCustomizeTimeOffSaveStep = async (event) => {
        const {saveProfile, setSaveProfile} = profileContext;
        event && event.preventDefault();
        try {
            const isValid = await trigger();
            if (isValid) {
                setProfileWfhPolicy(getValues())
                setSaveProfile(!saveProfile)
            }
        } catch(e){

        }

    }

    useEffect(() => {
        if (defaultWfhPolicyData) {
            reset(defaultWfhPolicyData);
            setIsSubmitting(false)
        }
    }, [defaultWfhPolicyData, reset]);

    useEffect(() => {
        if (withSaveButton || employeeId) {
            reset(wfhPolicyDataFromServer);
            setIsSubmitting(false)
        }
    }, [wfhPolicyDataFromServer, reset]);

    const handleChangedForm = () => {
        const currentValues = getValues();
        setSaveWfhPolicyData(currentValues)
        setProfileWfhPolicy && setProfileWfhPolicy(currentValues)
    }

    return (
        <>
            {isObjectEmpty(wfhPolicyDataFromServer) && profileWfhPolicy === undefined ? (
                <Loading></Loading>
            ) : (
                <div id="WfhPolicy" className="c-wfh-monthly-basis ps-4">
                    <form id="Wfh-policy" onChange={handleChangedForm}  onClick={handleChangedForm} onBlur={handleChangedForm} onSubmit={(employeeId || withSaveButton) ? handleSubmit(handleSaveWfhPolicy) : (event) => handleProfileCustomizeTimeOffSaveStep(event)}>
                        <Grid container spacing={{md: 1, xs: 0}}>
                            <WFHPolicyCustomization
                                control={control}
                                errors={errors}
                                register={register}
                                wfhPolicyDataFromServer={
                                    (wfhPolicyDataFromServer && Object.keys(wfhPolicyDataFromServer).length > 0)
                                        ? wfhPolicyDataFromServer
                                        : (defaultWfhPolicyData && Object.keys(defaultWfhPolicyData).length > 0
                                            ? defaultWfhPolicyData
                                            : profileWfhPolicy)
                                }
                            />
                            <Conditions
                                errors={errors}
                                register={register}
                                control={control}
                                wfhPolicyDataFromServer={
                                    (wfhPolicyDataFromServer && Object.keys(wfhPolicyDataFromServer).length > 0)
                                        ? wfhPolicyDataFromServer
                                        : (defaultWfhPolicyData && Object.keys(defaultWfhPolicyData).length > 0
                                            ? defaultWfhPolicyData
                                            : profileWfhPolicy)
                                }
                            />
                        </Grid>
                        {withSaveButton &&
                            <Button type="submit" variant="contained" className='float-end my-3'>
                                Save
                            </Button>
                        }
                        <Button
                            type="button"
                            className="float-start my-3 bg-danger" color="secondary" variant="outlined"
                            onClick={() => {
                                resetToDefault()
                            }}
                        >

                            {isSubmitting ? (
                                    <CircularProgress size={24} color="inherit" />
                                ) :
                                "Reset to default"
                            }
                        </Button>
                    </form>

                </div>
            )}
        </>
    );
};

export default WFHCustomizationPolicyListForm;
