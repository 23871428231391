import React, { useState } from 'react';
import {Controller, useForm} from "react-hook-form";
import { get } from "lodash";
import moment from "moment";
import {
    updateEmployeeDetails
} from "utility/apis/EmployeeService";
import {DATE_FORMAT, FULL_DATE_MONTH_LETTERS_FORMAT} from "utility/helpers/metaData";
import {
    BadgeIcon, BusinessCenterIcon, ContactsIcon, HouseOutlinedIcon,
    LocationOnIcon,
    MailOutlineIcon, PersonOffIcon,
    PhoneIcon,
    RedeemIcon,
    SmartphoneIcon,
    WcIcon,
} from "components/Mui/Icons";
import {
    DatePicker,
    Select,
    TextField,
    useTheme,
} from "components/Mui";
import CheckboxesTags from "components/CheckboxesTags/CheckboxesTags";
import CustomAppBar from "components/CustomAppBar/CustomAppBar";
import {getErrorMessage} from "utility/helpers/utils";
import {toast} from "react-toastify";
import {getIsManager} from "utility/helpers/authHelper";

import "./PersonalInformation.scss";


const PersonalInformation = (props) => {
    const { title, employeeId, handleCloseButton, message, employee, setEmployeeUpdated, profilePersonalInfo, setProfilePersonalInfo, setCurrentTabIndex, currentTabIndex } = props;
    const { register, handleSubmit, control, errors, trigger, getValues } = useForm({ mode: employeeId ?'onSubmit' : 'onChange', defaultValues: profilePersonalInfo });
    const [isEditing, setIsEditing] = useState(false);
    const [deletionDate, setDeletionDate] = useState(employee?.deletionDate ? moment(employee?.deletionDate, DATE_FORMAT) : profilePersonalInfo?.deletionDate ? moment(profilePersonalInfo?.deletionDate, DATE_FORMAT) : null)
    const [birthDate, setBirthDate] = useState(employee ? moment(employee?.dob) : profilePersonalInfo.dob ? moment(profilePersonalInfo?.dob) : null);
    const [hireDateState, setHireDateState] = useState(employee ? moment(employee?.hireDate) : profilePersonalInfo.hireDate ? moment(profilePersonalInfo?.hireDate) : null);
    const selectedTheme = useTheme();
    const colorTheme = selectedTheme?.palette?.primary?.main;
    const isEditView = getIsManager();
    const [isInProgress, setIsInProgress] = useState(false);
    const teamWithApprovers = employee?.teamWithApprovers
     const teamsString = teamWithApprovers?.length > 1 ? `${teamWithApprovers[0].team} (+${teamWithApprovers.length - 1} Teams)` : ((teamWithApprovers && teamWithApprovers[0]?.team) || "Unassigned");

    let dobYears =0 , dobMonths =0 , dobDays=0 ;
    const dob = employee ? moment(employee?.dob).format(FULL_DATE_MONTH_LETTERS_FORMAT) : profilePersonalInfo.dob ? moment(profilePersonalInfo?.dob).format(FULL_DATE_MONTH_LETTERS_FORMAT) : null;
    if(dob){
        dobYears = moment().diff(dob, 'years');
        const adjustedDob = moment(dob).add(dobYears, 'years');
        dobMonths = moment().diff(adjustedDob, 'months');
        const adjustedMonths = adjustedDob.add(dobMonths, 'months');
        dobDays = moment().diff(adjustedMonths, 'days');
    }

    const hireDate = employee ? moment(employee?.hireDate).format(FULL_DATE_MONTH_LETTERS_FORMAT) : profilePersonalInfo.hireDate ? moment(profilePersonalInfo?.hireDate).format(FULL_DATE_MONTH_LETTERS_FORMAT) : null;
    let hireYears =0 , hireMonths =0 , hireDays=0 ;
    if(hireDate){
        hireYears = moment().diff(hireDate, 'years');
        const adjustedHireDate = moment(hireDate).add(hireYears, 'years');
        hireMonths = moment().diff(adjustedHireDate, 'months');
        const adjustedMonths = adjustedHireDate.add(hireMonths, 'months');
        hireDays = moment().diff(adjustedMonths, 'days');
    }

    const handleSaveDetailsButton = async (data) => {
        setIsInProgress(true);
        try {
            await updateEmployeeDetails(employeeId, {
                personalInfo: {
                    ...data,
                    functionalRole: employee?.functionalRole,
                    attendanceWhiteListed: employee?.attendanceWhiteListed,
                    eligibleForEmergencyWFH: employee?.eligibleForEmergencyWFH,
                    officeCountry: employee?.officeCountry,
                    officeCity: employee?.officeCity,
                    officeBuilding: employee?.officeBuilding,
                    deletionDate: deletionDate,
                },
                updatedTab: "PERSONALINFO"
            });
            toast.success("Updated Successfully");
            setEmployeeUpdated(true)
        } catch (e) {
            toast.error(getErrorMessage(e));
        }finally {
            setTimeout(() => {
                setIsInProgress(false);
            }, 2000);
        }
    };

    const handleProfilePersonalInfoStep = async (event) => {
        event.preventDefault()
        try {
            const isValid = await trigger();
            if (isValid) {
              // Handle successful validation
              setProfilePersonalInfo({...profilePersonalInfo, ...getValues()})
              setCurrentTabIndex(currentTabIndex + 1)
            }
        } catch(e){
            toast.error(getErrorMessage(e));
        }

    }
    const handleEdit = async () => {
        setIsEditing(!isEditing);
    }
    return (
        <div id="personal-info" className="c-personal-info mx-4">
            <div className="container-fluid">
                <form id="personal_information"
                      onSubmit={employeeId ? handleSubmit(!isEditing? handleEdit : handleSaveDetailsButton) : (event) => handleProfilePersonalInfoStep(event)}>
                    <CustomAppBar title={title} showPrimaryButton textPrimary={!employeeId && "Next" || (!isEditing && "Edit")}
                                  showSecondaryButton textSecondary={!employeeId && "Cancel"}
                                  handleCloseButton={handleCloseButton} message={message} submitting={isInProgress}/>
                    {(isEditing || !employeeId) ? (
                        <div className="container m-0 mt-5">
                            <div className="row">
                                <div className="col-12 col-md-3">
                                    <TextField
                                        id="name"
                                        name="name"
                                        label="Name (First and Last)"
                                        type="text"
                                        defaultValue={get(employee, 'name', '')}
                                        inputRef={register({
                                            required: "This field is required"
                                        })}
                                        errors={errors}
                                    />
                                </div>
                                <div className="col-12 col-md-3">
                                    <TextField
                                        id="fullName"
                                        name="fullName"
                                        label="Full Name (First and Mid and Last)"
                                        type="text"
                                        defaultValue={get(employee, 'fullName', '')}
                                        inputRef={register({
                                            required: "This field is required"
                                        })}
                                        errors={errors}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-3">
                                    <TextField
                                        id="email"
                                        name="email"
                                        label="Employee E-Mail"
                                        type="email"
                                        disabled={!!employee}
                                        defaultValue={get(employee, 'email', '')}
                                        inputRef={register({
                                            required: "This field is required",
                                            pattern: {
                                                value: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/i,
                                                message: "Enter a valid e-mail address",
                                            }
                                        })}
                                        errors={errors}
                                    />
                                </div>
                                <div className="col-12 col-md-3">
                                    <TextField
                                        id="adUsername"
                                        name="adUsername"
                                        label="AD Username"
                                        type="text"
                                        defaultValue={get(employee, 'adUsername', '')}
                                        inputRef={register}
                                        errors={errors}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-3">
                                    <TextField
                                        id="city"
                                        name="city"
                                        label="City"
                                        type="text"
                                        defaultValue={get(employee, 'city', '')}
                                        inputRef={register({
                                            required: "This field is required"
                                        })}
                                        errors={errors}
                                    />
                                </div>
                                <div className="col-12 col-md-3">
                                    <TextField
                                        id="country"
                                        name="country"
                                        label="Country"
                                        type="text"
                                        defaultValue={get(employee, 'country', '')}
                                        inputRef={register({
                                            required: "This field is required"
                                        })}
                                        errors={errors}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-3">
                                    <Controller
                                        as={<Select label="Gender" options={[{value: 'male', label: 'Male'}, {
                                            value: 'female',
                                            label: 'Female'
                                        }]}/>}
                                        id="gender"
                                        name="gender"
                                        label="Gender"
                                        control={control}
                                        mode="onBlur"
                                        defaultValue={get(employee, 'gender', '')}
                                        rules={{ required: "This field is required" }}
                                        errors={errors}
                                    />
                                </div>
                                <div className="col-12 col-md-3">
                                    <TextField
                                        id="idNumber"
                                        name="idNumber"
                                        label="Identity Number"
                                        type="text"
                                        defaultValue={get(employee, 'idNumber')}
                                        inputRef={register({
                                            required: "This field is required"
                                        })}
                                        errors={errors}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-3">
                                    <TextField
                                        id="mobileNumber"
                                        name="mobileNumber"
                                        label="Mobile Number"
                                        type="text"
                                        defaultValue={get(employee, 'mobileNumber', '')}
                                        inputRef={register({
                                            required: "This field is required",
                                            pattern: {
                                                value: /^(\+\d{1,3}[- ]?)?\d{10}$/,
                                                message: "Enter a valid Mobile Number",
                                            }
                                        })}
                                        errors={errors}
                                    />
                                </div>
                                <div className="col-12 col-md-3">
                                    <TextField
                                        id="homePhoneNumber"
                                        name="homePhoneNumber"
                                        label="Home Number"
                                        type="text"
                                        defaultValue={get(employee, 'homePhoneNumber', '')}
                                        inputRef={register}
                                        errors={errors}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-3">
                                    <DatePicker
                                        name="dob"
                                        label="Date of birth"
                                        defaultValue={employee ? moment(employee?.dob, DATE_FORMAT) : profilePersonalInfo.dob ? moment(profilePersonalInfo?.dob, DATE_FORMAT) : null}
                                        inputRef={register({
                                            required: "This field is required"
                                        })}
                                        maxDate={moment().subtract(18, "years")}
                                        onChange={(event) => setBirthDate(event && moment(event))}
                                        disableFuture
                                        errors={errors}
                                    />
                                </div>
                                <div className="col-12 col-md-3">
                                    <DatePicker
                                        name="hireDate"
                                        label="Hire date"
                                        disabled={employee && !isEditView}
                                        defaultValue={employee ? moment(employee?.hireDate, DATE_FORMAT) : profilePersonalInfo.hireDate ? moment(profilePersonalInfo?.hireDate, DATE_FORMAT) : null}
                                        inputRef={register({
                                            required: "This field is required"
                                        })}
                                        onChange={(event)=> setHireDateState(event && moment(event))}
                                        minDate={moment(birthDate)}
                                        errors={errors}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-3">
                                    <TextField
                                        id="residence"
                                        name="residence"
                                        label="Residence"
                                        type="text"
                                        defaultValue={get(employee, 'residence', '')}
                                        inputRef={register}
                                        errors={errors}
                                    />
                                </div>
                                {employee &&
                                    <div className="col-12 col-md-3">
                                        <DatePicker
                                            name="deletionDate"
                                            label="Last Working Date"
                                            defaultValue={deletionDate}
                                            onChange={(event)=>setDeletionDate(event && moment(event).format(DATE_FORMAT))}
                                            slotProps={{
                                                field: {
                                                    clearable: true,
                                                    onClear: () => setDeletionDate(moment().format(DATE_FORMAT))
                                                },
                                            }}
                                            minDate={hireDateState}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    ) : (
                        <>
                            <h2 style={{color: colorTheme}}>{get(employee, 'name', '')}</h2>
                            <div>{get(employee, 'fullName', '')}</div>
                            <div className='fw-semibold fs-5 my-1'>
                                {teamsString}
                            </div>
                            <div className=" row fs-6 mt-4">
                                <div className="col-2 u-color-gray"><MailOutlineIcon className='icon-style'/> Employee
                                    email
                                </div>
                                <div className="col-10">
                                    {get(employee, 'email', '')}
                                </div>
                            </div>
                            <div className=" row fs-6 mt-2">
                                <div className="col-2 u-color-gray"><LocationOnIcon className='icon-style'/> Location</div>                                
                                <div className="col-10">
                                    {get(employee, 'city', '')}, {get(employee, 'country', '')}
                                </div>
                            </div>
                            <div className=" row fs-6 mt-2">
                                <div className="col-2 u-color-gray"><BadgeIcon className='icon-style'/> Employee ID</div>
                                <div className="col-10">
                                    {get(employee, 'id', '')}
                                </div>
                            </div>
                            <div className=" row fs-6 mt-2">
                                <div className="col-2 u-color-gray"><WcIcon className='icon-style'/> Gender</div>
                                <div className="col-10">
                                    {get(employee, 'gender', '')}
                                </div>
                            </div>
                            <div className=" row fs-6 mt-2">
                                <div className="col-2 u-color-gray"><SmartphoneIcon className='icon-style'/> Mobile Number</div>
                                <div className="col-10">
                                    {get(employee, 'mobileNumber', '')}
                                </div>
                            </div>
                            <div className=" row fs-6 mt-2">
                                <div className="col-2 u-color-gray"><PhoneIcon className='icon-style'/> Home Number</div>
                                <div className="col-10">
                                    {get(employee, 'homePhoneNumber', '')}
                                </div>
                            </div>
                            <div className=" row fs-6 mt-2">
                                <div className="col-2 u-color-gray"><BadgeIcon className='icon-style'/> Identity Number</div>
                                <div className="col-10">
                                    {get(employee, 'idNumber', '')}
                                </div>
                            </div>
                            <div className=" row fs-6 mt-2">
                                <div className="col-2 u-color-gray"><RedeemIcon className='icon-style'/> Date of birth</div>
                                <div className="col-10">
                                    {dob} <span className="ms-3" style={{color: colorTheme}}>( {`${dobYears} years, ${dobMonths} months, and ${dobDays} days`} )</span>
                                </div>
                            </div>
                            <div className=" row fs-6 mt-2">
                                <div className="col-2 u-color-gray"><BusinessCenterIcon className='icon-style'/>  Hire date</div>
                                <div className="col-10">
                                    {hireDate} <span className="ms-3" style={{color: colorTheme}}>( {`${hireYears} years, ${hireMonths} months, and ${hireDays} days`} )</span>
                                </div>
                            </div>
                            <div className=" row fs-6 mt-2">
                                <div className="col-2 u-color-gray"><ContactsIcon className='icon-style'/> AD Username</div>
                                <div className="col-10">
                                    {get(employee, 'adUsername', '')}
                                </div>
                            </div>
                            <div className=" row fs-6 mt-2">
                                <div className="col-2 u-color-gray"><HouseOutlinedIcon className='icon-style'/> Residence</div>
                                <div className="col-10">
                                    {get(employee, 'residence', '') || '--'}
                                </div>
                            </div>
                            <div className=" row fs-6 mt-2">
                                <div className="col-2 u-color-gray"><PersonOffIcon className='icon-style'/> Last Working Date </div>
                                <div className="col-10">
                                    {get(employee, 'deletionDate', '') ?
                                        moment(get(employee, 'deletionDate', '')).format(DATE_FORMAT)
                                        : '--'}
                                </div>
                            </div>
                        </>
                    )}
                </form>
            </div>
        </div>
    );
};

export default PersonalInformation;
