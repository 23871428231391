import React, {useState} from 'react';
import { useForm } from "react-hook-form";
import { hardDeleteProfile } from "utility/apis/EmployeeService";
import CustomAppBar from "components/CustomAppBar/CustomAppBar";
import {NotesIcon} from "components/Mui/Icons";
import {toast} from "react-toastify";
import {getErrorMessage} from "utility/helpers/utils";

import "./ProfileActions.scss";

const DeleteProfile = (props) => {
    const { title, employeeId, handleCloseButton, message, employee, colorTheme,  handelRefreshEmployeeList } = props;
    const { handleSubmit} = useForm({ mode: 'onSubmit' });
    const [submitting, setSubmitting] = useState(false);


    const handleDeleteProfileButton = async (data) => {
        try {
            setSubmitting(true);
            await hardDeleteProfile(employeeId);
            toast.success("Deleted Successfully");
            handleCloseButton();
            handelRefreshEmployeeList();
        } catch (e) {
            toast.error(getErrorMessage(e));
        }finally {
            setTimeout(() => {
                setSubmitting(false);
            }, 2000);
        }
    };

    return (
        <div id="delete-profile" className="c-delete-profile mx-4 mt-5">
            <div className="container-fluid">
                <form onSubmit={handleSubmit(handleDeleteProfileButton)}>
                    <CustomAppBar title={title} showPrimaryButton showSecondaryButton handleCloseButton={handleCloseButton} submitting={submitting} textPrimary="Delete" message={message}/>
                    <div className="fw-bold fs-5 fst-italic mb-1">
                            <div>
                                <div className='text-danger'>Warning!</div>
                                <span><NotesIcon style={{color: colorTheme}}/></span> Once you
                                clicked on <span style={{color: colorTheme}}>DELETE</span> , we will completely delete the user <span style={{color:  colorTheme}}>({employee.name})</span> record and history from the system.
                            </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default DeleteProfile;
