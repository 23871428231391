import React, {useEffect, useState} from 'react';
import {map} from "lodash";
import { API_URL } from "utility/constants/APIConst";
import { TABLES_NAMES } from "utility/constants/constants";
import { DataGridTable } from "components/Mui";
import CustomAppBar from "components/CustomAppBar/CustomAppBar";
import {getTeams} from "utility/apis/TeamsService";
import Loading from "components/Loading/Loading";
import {useLocation} from "react-router-dom";
import {getTenantId} from "utility/helpers/utils";

import "./Benefits.scss";

const Benefits = (props) => {
    const { title, employeeId, employee, handleCloseButton, message } = props;
    const benefitsListUrl = API_URL.BENEFITS_LIST(employeeId);
    const tenantId = getTenantId(useLocation(), "tenantId");
    const [allTeams, setAllTeams] = useState([]);

    useEffect(() => {
        fetchTeams();
    }, []);
    const fetchTeams = async () => {
        try {
            const response = await getTeams(tenantId);
            setAllTeams(response?.data);
        } catch (error) {
            console.error("Error fetching teams:", error);
        }
    };
    const formatDataTable = (data) => {
        return (map(data, ({fromDate, toDate, benefit}, index) => {
            let beneficiaryName = benefit?.beneficiary;
            if (benefit?.beneficiary === "TEAM" && benefit?.beneficiaryId) {
                beneficiaryName = allTeams?.find(item => item.id === benefit?.beneficiaryId)?.name;
            } else if (benefit?.beneficiary === "EMPLOYEE" && benefit?.beneficiaryId) {
                beneficiaryName = employee?.name;
            }

            return {
                id: index,
                name: benefit?.name,
                date: `${fromDate} - ${toDate}`,
                beneficiary: beneficiaryName
            };
        }));
    };

    const columnDefs = [
        {
            headerName: 'Name',
            field: 'name',
            flex: 1
        },
        {
            headerName: 'Date',
            field: 'date',
            flex: 1.2
        },
        {
            headerName: 'Beneficiary',
            field: 'beneficiary',
            flex: 1
        }
    ];

    return (
        <div id="benefits" className="c-benefits px-4">
            <div className="container-fluid">
                <CustomAppBar title={title} showSecondaryButton handleCloseButton={handleCloseButton} message={message} />
                {!(allTeams?.length > 0)?
                        <Loading></Loading>
                    :
                        <div style={{height: `calc(100vh - ${message ? 120 : 90}px)`}} className="row">
                        <DataGridTable
                            className="col-12"
                            key={TABLES_NAMES.BENEFITS_LIST}
                            columns={columnDefs}
                            URL={benefitsListUrl}
                            formatDataTable={formatDataTable}
                        />
                    </div>
                }
            </div>
        </div>
    );
};

export default Benefits;
