import React from 'react';
import classNames from "classnames";
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment, LocalizationProvider } from 'components/Mui';
import { DATE_FORMAT } from "utility/helpers/metaData";

import './DatePicker.scss';
import {ErrorMessage} from "@hookform/error-message";

function DatePicker(props) {
    const { className, errors, name, dateFormat=DATE_FORMAT, ...rest } = props;
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <MuiDatePicker
                format={dateFormat}
                className={classNames('date-text-size', className)}
                name={name} {...rest}
            />
            {!!errors &&
                <ErrorMessage className="error-message" errors={errors} name={name} as="p"/>
            }
        </LocalizationProvider>
    );
}

export default DatePicker;
