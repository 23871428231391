import API from './ApiBase';
import { API_URL } from "utility/constants/APIConst";

export const contactUS = (payload) => {
  return API.post('/api/v1/toffy/contact', payload);
};

export const getDataTable = async (url) => {
  return await API.get(url);
};

export const getTemplates = async (tenantId, year) => {
  if (year) {
    return await API.get(`/api/v1/w-hours/tenant/${tenantId}?year=${year}`);
  } else {
    return await API.get(`/api/v1/w-hours/tenant/${tenantId}`);
  }
};

export const getSpecialHolidays = async (tenantId) => {
  return await API.get(`/api/v1/holidays/type/${tenantId}?special=true`);
};
export const getSpecialHolidaysForEmployee = async (employeeId,tenantId) => {
  return await API.get(`/api/v1/holidays/${employeeId}/${tenantId}`);
};

export const getTeams = async (tenantId) => {
  return await API.get(`/api/v1/team/tenantTeams/${tenantId}`);
};

export const getTeamsForEmployeeInfo = async (tenantId) => {
  return await API.get(`/api/v1/team/all/${tenantId}`);
};

export const getHireDates = async (tenantId) => {
  return await API.get(`/api/v1/employee/hire-dates/${tenantId}`);
};

export const getRoles = async (tenantId) => {
  return await API.get(`/api/v1/employee/roles?forFilter=true`);
  // const roles = {
  //   data: [
  //       {
  //         id: 4,
  //         fixed: true,
  //         name: "Employee",
  //         authorities: {
  //           id: 6,
  //           leftOver: null,
  //           holidays: null,
  //           templates: null,
  //           roles: null,
  //           tenantLocation: null,
  //           vacationOnBehalfOfEmployees: false,
  //           addExtraVacation: false,
  //           viewEmployeeStats: false,
  //           viewEmployeeProfile: true,
  //           viewEmployeeVacationHistory: false,
  //           viewHireApplicants: false,
  //           employees: "CreateEditDelete"
  //         },
  //         details: []
  //       },
  //       {
  //         id: 1,
  //         name: "Account Adminstrator",
  //         authorities: {
  //           id: 4,
  //           leftOver: "Edit",
  //           holidays: "CreateEditDelete",
  //           templates: "CreateEditDelete",
  //           roles: "Edit",
  //           tenantLocation: null,
  //           vacationOnBehalfOfEmployees: true,
  //           addExtraVacation: true,
  //           viewEmployeeStats: true,
  //           viewEmployeeProfile: true,
  //           viewEmployeeVacationHistory: true,
  //           viewHireApplicants: false,
  //           employees: "CreateEditDelete"
  //         },
  //         details: ["Manage Toffy Account", "Manage Slack accounts and integration"]
  //       },
  //       {
  //         id: 2,
  //         name: "HR Manager",
  //         authorities: {
  //           id: 1,
  //           leftOver: "Edit",
  //           holidays: "CreateEditDelete",
  //           templates: "CreateEditDelete",
  //           roles: "Edit",
  //           tenantLocation: null,
  //           vacationOnBehalfOfEmployees: true,
  //           addExtraVacation: true,
  //           viewEmployeeStats: true,
  //           viewEmployeeProfile: true,
  //           viewEmployeeVacationHistory: true,
  //           viewHireApplicants: false,
  //           employees: "CreateEditDelete"
  //         },
  //         details: ["Manage team members", "Manage official holidays", "Manage Work schedule templates", "Manage permissions", "Manage reports"]
  //       },
  //       {
  //         id: 3,
  //         name: "HR Assistant",
  //         authorities: {
  //           id: 2,
  //           leftOver: "Edit",
  //           holidays: "CreateEditDelete",
  //           templates: "CreateEditDelete",
  //           roles: "Edit",
  //           tenantLocation: null,
  //           vacationOnBehalfOfEmployees: true,
  //           addExtraVacation: true,
  //           viewEmployeeStats: true,
  //           viewEmployeeProfile: true,
  //           viewEmployeeVacationHistory: true,
  //           viewHireApplicants: false,
  //           employees: "CreateEditDelete"
  //         },
  //         details: ["Manage team members", "Manage official holidays", "Manage Work schedule templates", "Manage reports", "Manage Sick leave reports"]
  //       },
  //       {
  //         id: 6,
  //         name: "Interviewer",
  //         authorities: {
  //           id: 5,
  //           leftOver: null,
  //           holidays: null,
  //           templates: null,
  //           roles: null,
  //           tenantLocation: null,
  //           vacationOnBehalfOfEmployees: false,
  //           addExtraVacation: false,
  //           viewEmployeeStats: false,
  //           viewEmployeeProfile: false,
  //           viewEmployeeVacationHistory: false,
  //           viewHireApplicants: false,
  //           employees: null
  //         },
  //         details: []
  //       },
  //       {
  //         id: 7,
  //         name: "Assistant",
  //         authorities: {
  //           id: 6,
  //           leftOver: null,
  //           holidays: null,
  //           templates: null,
  //           roles: null,
  //           tenantLocation: null,
  //           vacationOnBehalfOfEmployees: false,
  //           addExtraVacation: false,
  //           viewEmployeeStats: false,
  //           viewEmployeeProfile: true,
  //           viewEmployeeVacationHistory: false,
  //           viewHireApplicants: false,
  //           employees: "CreateEditDelete"
  //         },
  //         details: []
  //       }
  //     ]
  // };
  // return roles;
};

export const getTimeOff = async (employeeId, year, type, toDate) => {
  return await API.get(API_URL.TIME_OFF_LIST(employeeId, year, type, toDate));
};


export const getWfhMonthlyBasis = async (tenantId, employeeId, year) => {
  return await API.get(API_URL.WFH_MONTHLY_BASIS_LIST(tenantId, employeeId, year));
};


export const getTimeOffPolicy = async (tenantId, employeeId) => {
  return await API.get(API_URL.TIME_OFF_POLICY_LIST(employeeId,tenantId));
};

export const getWfhPolicyList = async (employeeId, tenantId) => {
  return await API.get(API_URL.WFH_POLICY_LIST(employeeId, tenantId));
}
