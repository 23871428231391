import React, { useEffect, useState } from "react";
import { filter, includes, find } from "lodash";
import {LeftTabsDrawer, useTheme} from "components/Mui";
import Loading from "components/Loading/Loading";

import PersonalInformation from "components/EmployeeDetailsForms/PersonalInformation/PersonalInformation";
import WorkScheduleTemplates from "containers/EmployeeDetails/WorkScheduleTemplates/WorkScheduleTemplates";
import Teams from "containers/EmployeeDetails/Teams/Teams";
import Roles from "components/EmployeeDetailsForms/Roles/Roles";
import CustomizeTimeOff from "components/EmployeeDetailsForms/CustomizeTimeOff/CustomizeTimeOff";

import Benefits from "./Benefits/Benefits";
import AttendanceReport from "./AttendanceReport/AttendanceReport";
import Wfh from "./Wfh/Wfh";
import TimeOff from "./TimeOff/TimeOff";
import DeactivateProfile from "./ProfileActions/DeactivateProfile";
import ReactivateProfile from "./ProfileActions/ReactivateProfile";
import DeleteProfile from "./ProfileActions/DeleteProfile";

import { formatDate } from "utility/helpers/utils";

import {
    EventNoteOutlinedIcon,
    HouseOutlinedIcon,
    InfoOutlinedIcon,
    ManageAccountsOutlinedIcon,
    PeopleOutlineIcon,
    PoolOutlinedIcon,
    RedeemOutlinedIcon,
    AccessAlarmOutlinedIcon,
    PersonOffSharpIcon,
    PersonSharpIcon,
    PersonRemoveSharpIcon,
} from "components/Mui/Icons";
import { getEmployeeDetails } from "utility/apis/EmployeeService";

import "./EmployeeDetails.scss";
import {getIsManager} from "utility/helpers/authHelper";

const getEmployeeDrawerList = (
    employeeId,
    handleCloseButton,
    employee,
    managersInfo,
    setEmployeeUpdated,
    handleTabOnChange,
    handelRefreshEmployeeList,
    profileTabs,
    colorTheme,
    balanceTab
) => {
    const isManager = getIsManager();
    let teams = [], holidays = [], isApproval = false, roles = [];
    if(employeeId){
        teams = employee?.employeeTeams.map(({ id }) => id.team);
        holidays = employee?.holidays.map(({ id }) => id);
        roles = employee?.roles;
        isApproval = !!find(roles, ({ id }) => (id === 1 || id === 2 || id === 5));
    }
    let message;
    if (!employee.activated || employee?.deletionDate) {
        message = {
            word: "deactivated-profile-note",
            data: { date: formatDate(employee.deletionDate) },
        };
    }
    let tabs = [
        {
            id: "personal_info",
            title: "Personal Info",
            component: (
                <PersonalInformation
                    setEmployeeUpdated={setEmployeeUpdated}
                    title="Personal Info"
                    employeeId={employeeId}
                    handleCloseButton={handleCloseButton}
                    message={message}
                    employee={employee}
                    newProfile
                />
            ),
            icon: <InfoOutlinedIcon />,
        },
        {
            id: "roles",
            title: "Work-related Information",
            component: (
                <Roles
                    title="Work-related Information"
                    employeeId={employeeId}
                    handleCloseButton={handleCloseButton}
                    roles={roles}
                    employee={employee}
                    setEmployeeUpdated={setEmployeeUpdated}
                    message={message}
                />
            ),
            icon: <ManageAccountsOutlinedIcon />,
        },
        {
            id: "work_schedule_templates",
            title: "Work Schedule Templates",
            component: (
                <WorkScheduleTemplates
                    title="Work Schedule Templates"
                    employeeId={employeeId}
                    employee={employee}
                    handleCloseButton={handleCloseButton}
                    defaultHolidays={holidays}
                    message={message}
                    colorTheme={colorTheme}
                />
            ),
            icon: <EventNoteOutlinedIcon />,
        },
    ];
    if (isManager) {
        tabs = [
            ...tabs,
            {
            id: "benefits",
            title: "Benefits",
            component: (
                <Benefits
                    title="Benefits"
                    employeeId={employeeId}
                    employee={employee}
                    handleCloseButton={handleCloseButton}
                    message={message}
                />
            ),
            icon: <RedeemOutlinedIcon/>,
        },
            {
                id: "teams",
                title: "Teams",
                component: (
                    <Teams
                        title="Teams"
                        employeeId={employeeId}
                        handleCloseButton={handleCloseButton}
                        teams={teams}
                        isApproval={isApproval}
                        message={message}
                        employee={employee}
                        handelRefreshEmployeeList={handelRefreshEmployeeList}
                        colorTheme={colorTheme}
                        setEmployeeUpdated={setEmployeeUpdated}
                    />
                ),
                icon: <PeopleOutlineIcon/>,
            },
            {
                id: "time_off",
                title: "Time off",
                component: (
                    <TimeOff
                        title="Time off"
                        employeeId={employeeId}
                        handleCloseButton={handleCloseButton}
                        message={message}
                        colorTheme={colorTheme}
                        balanceTab={balanceTab}
                    />
                ),
                icon: <PoolOutlinedIcon/>,
            },
            {
                id: "wfh",
                title: "WFH",
                component: (
                    <Wfh
                        title="WFH"
                        employeeId={employeeId}
                        handleCloseButton={handleCloseButton}
                        message={message}
                        colorTheme={colorTheme}
                    />
                ),
                icon: <HouseOutlinedIcon/>,
            },
            {
                id: "customize_time_off_policy",
                title: "Customize Policies",
                component: (
                    <CustomizeTimeOff
                        title="Customize Policies"
                        setEmployeeUpdated={setEmployeeUpdated}
                        employeeId={employeeId}
                        handleCloseButton={handleCloseButton}
                    />
                ),
                icon: <PoolOutlinedIcon/>,
            },
            {
                id: "attendance_report",
                title: "Attendance Report",
                component: (
                    <AttendanceReport
                        title="Attendance Report"
                        employeeId={employeeId}
                        handleCloseButton={handleCloseButton}
                        message={message}
                        colorTheme={colorTheme}
                    />
                ),
                icon: <AccessAlarmOutlinedIcon/>,
            },
        ];
    }
    tabs = filter(tabs, (el) => includes(profileTabs, el.id))

    if (employee.activated) {
        tabs.push({
            id: "deactivate_profile",
            title: "Deactivate Profile",
            component: (
                <DeactivateProfile
                    title="Deactivate Profile"
                    employeeId={employeeId}
                    setEmployeeUpdated={setEmployeeUpdated}
                    handleCloseButton={handleCloseButton}
                    message={message}
                    employee={employee}
                    handleTabOnChange={handleTabOnChange}
                    handelRefreshEmployeeList={handelRefreshEmployeeList}
                    colorTheme={colorTheme}
                />
            ),
            icon: <PersonOffSharpIcon />,
            className: "text-danger",
        });
    } else {
        tabs.push(
            {
                id: "delete_profile",
                title: "Delete Profile",
                component: (
                    <DeleteProfile
                        title="Delete Profile"
                        employeeId={employeeId}
                        setEmployeeUpdated={setEmployeeUpdated}
                        handleCloseButton={handleCloseButton}
                        message={message}
                        employee={employee}
                        handelRefreshEmployeeList={handelRefreshEmployeeList}
                        colorTheme={colorTheme}
                    />
                ),
                icon: <PersonRemoveSharpIcon />,
                className: "text-danger",
            },
            {
                id: "reactivate_profile",
                title: "Reactivate Profile",
                component: (
                    <ReactivateProfile
                        title="Reactivate Profile"
                        employeeId={employeeId}
                        setEmployeeUpdated={setEmployeeUpdated}
                        handleCloseButton={handleCloseButton}
                        handleTabOnChange={handleTabOnChange}
                        message={message}
                        employee={employee}
                        handelRefreshEmployeeList={handelRefreshEmployeeList}
                        colorTheme={colorTheme}
                    />
                ),
                icon: <PersonSharpIcon />,
                className: "text-success",
            }
        );
    }

    return tabs;
};

function EmployeeDetails(props) {
    const { employeeId, handleCloseButton, handelRefreshEmployeeList, profileTabs, tabIndex, balanceTab } = props;
    const isManager = getIsManager();
    const [employeeUpdated, setEmployeeUpdated] = useState(false);
    const [inProgress, setInProgress] = useState(true);
    const [currentTabIndex, setCurrentTabIndex] = useState(isManager? tabIndex || 0 : 0);
    const [profilePicture, setProfilePicture] = useState(undefined);
    const [employeeDrawerList, setEmployeeDrawerList] = useState([]);
    const selectedTheme = useTheme();
    const colorTheme = selectedTheme?.palette?.primary?.main;
    const handleTabOnChange = (newTabIndex) => {
        setCurrentTabIndex(newTabIndex);
    };

    const setProfileDrawerList = (basicInformationWithManager, employee) => {
        setEmployeeDrawerList(
            getEmployeeDrawerList(
                employeeId,
                handleCloseButton,
                employee,
                basicInformationWithManager,
                setEmployeeUpdated,
                handleTabOnChange,
                handelRefreshEmployeeList,
                profileTabs,
                colorTheme,
                balanceTab
            )
        );
    }
    const fetchData = async () => {
        setInProgress(true);
        try {
            let response = await getEmployeeDetails(employeeId);
            const { basicInformationWithManager, employee } = response.data;
            setProfilePicture(employee.profilePicture);
            setProfileDrawerList(basicInformationWithManager, employee);
        } catch (e) {
            //setPaginationData({...paginationData, next: false });
        }
        setInProgress(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (employeeUpdated) {
            fetchData();
            setEmployeeUpdated(false)

        }
    }, [employeeUpdated]);

    return (
        <div className="c-employee-details">
            {inProgress ? (
                <Loading></Loading>
            ) : (
                <>
                    {!!employeeDrawerList.length && (
                        <LeftTabsDrawer
                            currentTabIndex={currentTabIndex}
                            handleTabOnChange={handleTabOnChange}
                            drawerList={employeeDrawerList}
                            orientation="vertical"
                            profilePicture={profilePicture}
                            employeeId={employeeId}
                            handelRefreshEmployeeList={handelRefreshEmployeeList}
                        ></LeftTabsDrawer>
                    )}
                </>
            )}
        </div>
    );
}

export default EmployeeDetails;
