const TEAMS_LIST = (id) => `/api/v1/employee/employee-teams-history/${id}`;
const ROLES_LIST = (id) => `/api/v1/employee/employee-roles/${id}`;
const BENEFITS_LIST = (id) => `/api/v1/benefit-tracking/employee/${id}`;
const TEMPLATES_LIST = (tenantId, employeeId) => `/api/v2/employee/get-templates/tenant/${tenantId}/${employeeId}`;
const ATTENDANCE_REPORT_LIST = (employeeId, fromDate, toDate) => `/api/v1/employee/attendance-records/${employeeId}?fromDate=${fromDate}&toDate=${toDate}`;
const ATTENDANCE_REPORT_MONTHLY_SUMMARY = (employeeId, date) => `/api/v1/clock/monthly-summary/${date}/${employeeId}`;
const TIME_OFF_LIST = (employeeId, year, type, toDate) => {
    let url = `/api/v2/employee/${employeeId}/vacationsByTypeWithMetrics/${year}?type=${type}`;
    if (toDate) {
        url += `&toDate=${toDate}`;
    }
    return url;
};
const WFH_MONTHLY_BASIS_LIST = (tenantId, employeeId, year) => `/api/v1/vacation/wfhHistory/${tenantId}/${employeeId}/${year}`;
const COMPENSATIONS_TIME_LIST = (employeeId, year) => `/api/v1/employee/${employeeId}/balance/${year}`;
const BALANCE_DEDUCTION_LIST = (employeeId, year) => `/api/v1/balance-deduction/${employeeId}?year=${year}`;
const BALANCE_LIST = (employeeId, year) => `/api/v1/employee/${employeeId}/balance/${year}`;
const ACCRUED_BALANCE_LIST = (employeeId, year) => `/api/v1/employee/${employeeId}/accruedBalance/${year}`;
const BALANCE_ONLY_LIST = (employeeId, year) => `/api/v1/employee/balanceonly/${employeeId}/${year}`;
const SALARY_DEDUCTION_LIST = (employeeId, year) => `/api/v1/employee/salaryDeduction/${employeeId}/${year}`;
const TIME_OFF_POLICY_LIST = (employeeId, tenantId) => `/api/v1/policy/timeoffpolicy/${tenantId}?empId=${employeeId}`;
const WFH_POLICY_LIST = (employeeId, tenantId) => `/api/v1/policy/getWFHPolicy/?tId=${tenantId}&empId=${employeeId}`;
const PENDING_LIST = (employeeId) => `/api/v1/employee/${employeeId}/pending-vacations?type=all`;
const SYSTEM_CONFIGURATION_LIST = (tenantId) => `/api/v1/system-configuration/${tenantId}`;
const ADD_NEW_SYSTEM_CONFIGURATION_LIST = () => `/api/v1/system-configuration/`;
export const DELETE_SYSTEM_CONFIGURATION = (tenantId) => `/api/v1/system-configuration/${tenantId}`
export const TENANT_URL = '/api/v1/tenant';
export const STATISTICS_URL = '/api/v1/statistics';
export const TENANT_LOGS = '/api/v1/logs/tenant-bot-logs';
export const AUDIT_LOGS = '/api/v1/logs/groupedLogs';
export const SETTLEMENT_VACATION_URL = '/api/v1/vacation/take-settlement-action';
export const SETTLEMENT_URL = '/api/v1/settlement';
export const TENANT_TEAMS_URL = (tenantId)=> `/api/v1/team/tenantTeams/${tenantId}`;
export const VACATION_DETAILS_URL = `/api/v2/employee/vacation-details`;
export const BALANCE_REPORT_URL = (year, tenantId) => `/api/v1/employee/balance-reports/${year}/${tenantId}`;
export const ATTENDANCE_LOCATIONS_URL = (tenantId) => `/api/v1/clock/attendance-locations/${tenantId}`;
export const ATTENDANCE_REPORT_URL = (tenantId) => `/api/v2/clock/attendance-report?tenant=${tenantId}`;
export const VACATION_BALANCE_URL = (year, tenantId) => `/api/v1/employee/sick-report/${year}/${tenantId}`;

export const GET_SLACK_TOKEN = (tenantId) => `/api/v1/wrapper/wrap?apiUrl=/workspace/getSlackToken?tenantId=${tenantId}`;
export const GET_USER_LIST = (slackToken) => `/api/v1/wrapper/wrap?apiUrl=https://slack.com/api/users.list?token=${slackToken}`;
export const GET_BENEFITS = (tenantId, date) => `/api/v1/benefit/tenant/${tenantId}/${date}`;
export const DELETE_BENEFIT = (id) => `/api/v1/benefit/${id}`;
export const UPDATE_BENEFIT = '/api/v1/benefit';
export const GET_SCHEDULE_TASKS = (tenantId) => `/api/v1/scheduled-tasks/${tenantId}`;
export const UPDATE_SCHEDULE_TASKS = '/api/v1/scheduled-tasks';
export const ADD_SCHEDULE_TASKS = (tenantId) => `/api/v1/scheduled-tasks/${tenantId}`;
export const DELETE_SCHEDULE_TASKS = (tenantId, id) => `/api/v1/scheduled-tasks/${tenantId}/${id}`;



export const GET_FUNCTIONAL_ROLE = (tenantId) => `/api/v1/functional-role?tenantId=${tenantId}`;
export const UPDATE_FUNCTIONAL_ROLE = (tenantId, id) => `/api/v1/functional-role/${id}?tenantId=${tenantId}`;
export const ADD_FUNCTIONAL_ROLE = (tenantId) => `/api/v1/functional-role?tenantId=${tenantId}`;
export const DELETE_FUNCTIONAL_ROLE = (tenantId, id) => `/api/v1/functional-role/${id}?tenantId=${tenantId}`;


export const UPDATE_THEME_COLOR = (tenantId) => `/api/v1/tenant/${tenantId}`;
export const UPDATE_DAYLIGHT_TIME_SAVING = (tenantId, summerTime, winterTime, dst) => `/api/v1/tenant/${tenantId}/${summerTime}/${winterTime}?dst=${dst}`;
export const GET_DAYLIGHT_TIME_SAVING = (tenantId) => `/api/v1/tenant/${tenantId}`;

export const API_URL = {
    TEAMS_LIST,
    BENEFITS_LIST,
    TEMPLATES_LIST,
    ROLES_LIST,
    ATTENDANCE_REPORT_LIST,
    ATTENDANCE_REPORT_MONTHLY_SUMMARY,
    TIME_OFF_LIST,
    WFH_MONTHLY_BASIS_LIST,
    COMPENSATIONS_TIME_LIST,
    BALANCE_DEDUCTION_LIST,
    BALANCE_LIST,
    ACCRUED_BALANCE_LIST,
    BALANCE_ONLY_LIST,
    SALARY_DEDUCTION_LIST,
    TIME_OFF_POLICY_LIST,
    SYSTEM_CONFIGURATION_LIST,
    ADD_NEW_SYSTEM_CONFIGURATION_LIST,
    DELETE_SYSTEM_CONFIGURATION,
    WFH_POLICY_LIST,
    PENDING_LIST
}
