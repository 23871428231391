import React, {useState} from 'react';
import { useForm } from "react-hook-form";
import { deactivateProfile } from "utility/apis/EmployeeService";
import CustomAppBar from "components/CustomAppBar/CustomAppBar";
import {NotesIcon} from "components/Mui/Icons";

import "./ProfileActions.scss";
import {toast} from "react-toastify";
import {getErrorMessage} from "utility/helpers/utils";

const DeactivateProfile = (props) => {
    const { title, employeeId, handleCloseButton, message, employee, setEmployeeUpdated, colorTheme, handleTabOnChange, handelRefreshEmployeeList } = props;
    const { handleSubmit} = useForm({ mode: 'onSubmit' });
    const [submitting, setSubmitting] = useState(false);


    const handleDeactivateProfileButton = async (data) => {
        try {
            setSubmitting(true);
            await deactivateProfile(employeeId);
            toast.success("Deactivated Successfully");
            setEmployeeUpdated(true);
            handelRefreshEmployeeList();
            handleTabOnChange(0);
        } catch (e) {
            toast.error(getErrorMessage(e));
        }finally {
            setTimeout(() => {
                setSubmitting(false);
            }, 2000);
        }
    };

    return (
        <div id="deactivate-profile" className="c-deactivate-profile mx-4 mt-5">
            <div className="container-fluid">
                <form onSubmit={handleSubmit(handleDeactivateProfileButton)}>
                    <CustomAppBar title={title} showPrimaryButton showSecondaryButton handleCloseButton={handleCloseButton} textPrimary="Deactivate" message={message} submitting={submitting}/>
                    <div className="fw-bold fs-5 fst-italic mb-1">
                        <div>
                            <span><NotesIcon style={{color: colorTheme}}/></span> Once you
                            clicked on <span style={{color: colorTheme}}>DEACTIVATE</span> , we will deactivate <span style={{color:  colorTheme}}>{employee.name}</span> account.

                            Please note that you can still re-activate this account from <span className='text-danger'>Deactivated</span> using <span style={{color:  colorTheme}}>Employee Filter</span>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default DeactivateProfile;
