import React, {useState} from "react";
import {Button, DatePicker, Dialog, Tabs, DialogActions} from "components/Mui";
import {TIME_OFF_REQUEST_MODAL} from "utility/modals/constants";
import WithModals from "hoc/withModals/withModals";

import WfhAllRecords from "./WfhAllRecords/WfhAllRecords";
import WfhMonthlyBasis from "./WfhMonthlyBasis/WfhMonthlyBasis";
import CustomAppBar from "components/CustomAppBar/CustomAppBar";
import {getDateOptions} from "utility/helpers/utils";

const getWfhTabs = (employeeId, date, message, colorTheme) => {
    return [
        {
            label: "All Records",
            component: <WfhAllRecords employeeId={employeeId} date={date} message={message} colorTheme={colorTheme}/>
        },
        {
            label: "Monthly Basis",
            component: <WfhMonthlyBasis employeeId={employeeId} date={date} message={message}/>
        }
    ];
};

function Wfh(props) {
    const { employeeId, message, handleCloseButton, title, colorTheme, modals, toggleTimeOffEditModal } = props;
    const [ date, setDate ] = useState(undefined);
    const wfhTabs = getWfhTabs(employeeId, date, message, colorTheme);
    const [specificDate, setSpecificDate] = useState();
    const [years, setYears] = useState(getDateOptions());

    const handleDateChange = (year) => {
        if (year === "customDate") {
            toggleTimeOffEditModal();
        } else {
            const yearData = years.find((item) => item.value === (year.value || year) );
            if (yearData) {
                setDate(yearData);
            }
        }
    };

    const handleCustomDate = () => {
        if (!specificDate) return;

        const newYearValue = specificDate.format("yyyy");

        if (!years.some((year) => year.value === newYearValue)) {
            setYears((prevYears) => {
                const lastElement = prevYears[prevYears.length - 1];
                const updatedYears = [
                    ...prevYears.slice(0, -1),
                    { label: newYearValue, value: newYearValue },
                    lastElement
                ];
                return updatedYears;
            });
        }
        setDate(specificDate);
        toggleTimeOffEditModal();
    };

    return (
        <div className="c-wfh_tabs px-4 mt-2">
            <CustomAppBar title={title} showSecondaryButton handleCloseButton={handleCloseButton} date={date} dateOptions={years} handleDateChange={handleDateChange} addCustomDate message={message}/>

            {!!wfhTabs.length &&
                <Tabs currentTabIndex={0} items={wfhTabs}/>
            }
            <Dialog className='time-off-request-dialog' open={modals[TIME_OFF_REQUEST_MODAL]} toggle={toggleTimeOffEditModal}>
                <div className="m-4">
                    <h6 className="mb-4" style={{color: colorTheme}}>Custom Date</h6>
                    <div className="column">
                        <DatePicker
                            name="specificDate"
                            label="Specific Date"
                            className="mx-1"
                            value={specificDate}
                            onChange={(newValue) => setSpecificDate(newValue)}
                            views={['year']}
                            openTo="year"
                        />
                    </div>
                </div>
                <div>
                    <DialogActions className="p-3 mt-3">
                        <Button className="px-4 me-3" variant="outlined" onClick={toggleTimeOffEditModal}>Cancel</Button>
                        <Button className="px-4" variant="contained" type="submit" onClick={handleCustomDate}>Save</Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}

const modalsProps = {
    toggleTimeOffEditModal: TIME_OFF_REQUEST_MODAL,
};
export default WithModals(Wfh, modalsProps);
