import React from 'react';
import { Radio as MuiRadio } from "@mui/material";
import classNames from "classnames";
import './Radio.scss';

function Radio(props) {
    const { className, ...rest } = props;
    return (
        <MuiRadio className={classNames(className)} size="small" {...rest} />
    );
}

export default Radio;