import React, { useCallback, useEffect, useState } from 'react';
import {DataGrid as MuiDataGrid, GridToolbarContainer, GridToolbarExport, TablePagination} from 'components/Mui';
import { getDataTable } from "utility/apis/ConfigService";

import "./DataGridTable.scss";

const ITEM_PER_PAGE_OPTIONS = [
    {label:"20", value:'20'},
    {label:"50", value:'50'},
    {label:"100", value:'100'}
];

function DataGridTable(props) {
    // const [queryOptions, setQueryOptions] = useState({});
    const { pageNumber, setPageNumber, isExport, isLoading, URL, formatDataTable, onDataTableChanged, setIsLoading, data, dataTable, reload, addPagenation,itemPerPageOption, className='', reloadWithoutURL=false , ...rest } = props;
    const [ inProgress, setInProgress ] = useState(false);
    const [ rows, setRows ] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [count, setCount] = useState(0);

    const onFilterChange = useCallback((filterModel) => {
        // Here you save the data you need from the filter model
        // setQueryOptions({ filterModel: { ...filterModel } });
    }, []);

    useEffect(() => {
        if (URL) {
            fetchData();
        } else {
            if (reloadWithoutURL)
                setInProgress(true);
            else setInProgress(false);
        }
    }, [URL, reload, page, pageNumber, pageSize,reloadWithoutURL]);

    useEffect(() => {
        if (setIsLoading) {
            setIsLoading(inProgress);
        }
    }, [inProgress]);

    useEffect(() => {
        if (dataTable) {
            setRows(dataTable);
        }
    }, [dataTable]);

    const fetchData = async () => {
        setInProgress(true);
        try {
            if (URL) {
                try {
                    const url = addPagenation&&pageSize!=='all'? `${URL}&page=${pageNumber? pageNumber-1 : page-1}&size=${pageSize}`:URL;
                    const response = await getDataTable(url);
                    let  { data }= response;

                    let tableData = data.content??data;
                    if (onDataTableChanged) {
                        onDataTableChanged(tableData);
                    }
                    if(addPagenation){
                        setCount(data?.totalElements);
                    }

                    if (formatDataTable) {
                        tableData = formatDataTable(tableData);
                    }
                    setRows(tableData);
                } catch (e) {
                }
            }
        } catch (e) {
        }
        setInProgress(false);
    }

    const onPageSizeChange = (pageSize, details) => {
    }

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    const onPageChange= (event, value)=>{
        setPage(value+1);
        setPageNumber? setPageNumber(value+1) : null;
    }

    const onRowsPerPageChange= (event)=>{
        setPageSize(event.target.value)
        setPage(1);
        setPageNumber? setPageNumber(1) : null;
    }
    return (
        <>
            <div className={`c-data-grid ${className}`}>
                <MuiDataGrid
                    // filterMode="server"
                    onFilterModelChange={onFilterChange}
                    loading={inProgress}
                    slots={{
                        toolbar: isExport ? CustomToolbar : undefined
                    }}
                    rows={rows}
                    onPaginationModelChange={onPageSizeChange}
                    autoPageSize={true}
                    {...rest}
                />
            </div>
            
            {addPagenation && count > 1 &&
                <div className='pagination-container'>
                    <TablePagination component="div" count={count} rowsPerPage={pageSize} onRowsPerPageChange={onRowsPerPageChange} rowsPerPageOptions={itemPerPageOption??ITEM_PER_PAGE_OPTIONS} variant='outlined' page={pageNumber? pageNumber-1 : page-1} onPageChange={onPageChange}/>
                </div>
            }
        </>
    );
}

export default DataGridTable;
