import React, {useState, useEffect} from 'react';
import {useForm} from "react-hook-form";
import {useLocation} from "react-router-dom";
import moment from "moment";
import {filter, find, includes, map} from "lodash";
import {EditIcon, KeyboardArrowDownIcon, KeyboardArrowUpIcon, MoreVertIcon} from "components/Mui/Icons";
import {getTenantId, getDurationText, formatDate, getErrorMessage} from "utility/helpers/utils";
import {API_URL} from "utility/constants/APIConst";
import {TABLES_NAMES} from "utility/constants/constants";
import {updateEmployeeDetails, updateJoinedTeamDate} from "utility/apis/EmployeeService";
import {
    Tooltip,
    Button,
    Collapse,
    DataGridTable,
    Dialog,
    Grid,
    DatePicker,
    DialogActions,
    GridActionsCellItem
} from "components/Mui";
import CheckboxesTags from "components/CheckboxesTags/CheckboxesTags";
import {getTeams} from "utility/apis/ConfigService";
import CustomAppBar from "components/CustomAppBar/CustomAppBar";
import {TEAMS_INFO_MODAL, TIME_OFF_REQUEST_MODAL} from "utility/modals/constants";
import WithModals from "hoc/withModals/withModals";
import profilePicture from "assets/images/profilePicture.png";
import EmployeeCircularImage from "components/EmployeeCircularImage/EmployeeCircularImage";
import Loading from "components/Loading/Loading";
import {toast} from "react-toastify";
import {DATE_FORMAT} from "utility/helpers/metaData";

import "./Teams.scss";

const Teams = (props) => {
    const tenantId = getTenantId(useLocation(), "tenantId");
    const {
        employeeId,
        employee,
        message,
        title,
        teams,
        isApproval,
        handleCloseButton,
        colorTheme,
        modals,
        toggleTeamsInfoModal,
        toggleTimeOffEditModal,
        handelRefreshEmployeeList,
        setEmployeeUpdated
    } = props;
    const teamsListUrl = API_URL.TEAMS_LIST(employeeId);
    const {register, handleSubmit, errors} = useForm({mode: 'onSubmit'});
    const [open, setOpen] = useState(true);
    const [teamsOptions, setTeamsOptions] = useState([]);
    const [teamsValue, setTeamsValue] = useState([]);
    const [inProgress, setInProgress] = useState(true);
    const defaultTeams = isApproval ? filter(teamsOptions, (el) => includes(teams, el.id)) : find(teamsOptions, (el) => includes(teams, el.id));
    const [infoData, setInfoData] = useState({});
    const [newJoinedDate, setNewJoinedDate] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [reload, setReload] = useState(false);
    const [isInProgress, setIsInProgress] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [hideSaveButtonForApprovers, setHideSaveButtonForApprovers] = useState(false);

    const fetchData = async () => {
        setInProgress(true);
        try {
            const response = await getTeams(tenantId);
            setTeamsOptions(response?.data?.map(item => ({
                ...item,
                name: item?.name,
                id: item?.id,
                membersAmount: `${item?.employees?.length || 0} members`
            })));
            employee?.roles.map(role => {
                if (role.name.includes('APPROVER')) {
                    setHideSaveButtonForApprovers(true);
                    console.log(hideSaveButtonForApprovers, 'HideSaveButtonForApprovers');
                }
            })
        } catch (e) {
        }
        setInProgress(false);
    }

    useEffect(() => {
        fetchData();
        setTeamsValue(teams);
    }, []);

    const formatDataTable = (data) => {
        return map(data?.fullEmployeeTeamHistory, ({from, to, team: {name}}, index) => {
            const now = moment().valueOf();
            if (!to || to <= 0 || moment(to).diff(moment(from)) < 0 || moment(to).format(DATE_FORMAT) === "1899-12-31") {
                to = now;
            }
            const involvement = getDurationText(to - from);
            return {
                id: index,
                data: data?.fullEmployeeTeamHistory[index],
                from: from ? formatDate(from) : '',
                to: to === now ? 'Present' : formatDate(to),
                name,
                involvement
            }
        });
    }
    const columnDefs = [
        {
            headerName: 'Name',
            field: 'name',
            flex: 1
        },
        {
            headerName: 'Joined',
            field: 'from',
            flex: 1.2,
        },
        {
            headerName: 'To',
            field: 'to',
            flex: 1,
        },
        {
            headerName: 'Involvement',
            field: 'involvement',
            flex: 1,
            renderCell: (params) => (
                <Tooltip title={calculateInvolvement(params.row?.from, params.row?.to)}>
                    <div className="ellipsis">
                        {calculateInvolvement(params.row?.from, params.row?.to)}
                    </div>
                </Tooltip>
            ),
        },
        {
            headerName: 'Actions',
            field: 'actions',
            type: 'actions',
            flex: 0.5,
            getActions: (params) => [
                (
                    // !(params?.row?.data?.team?.customApproverList?.length && params?.row?.data?.team?.customApprovers?.length) ?
                    !(params?.row?.data?.team?.customApprovers.some(customApprover => (customApprover?.employee?.id ? customApprover?.employee?.id : customApprover?.employee) === employeeId)) ?
                        <GridActionsCellItem showInMenu icon={<EditIcon/>}
                                             onClick={(event) => handleEditClick(event, params)} label="Edit"/>
                        :
                        <Tooltip title={'No actions available; the employee is a custom approver on this team.'}
                                 disableInteractive>
                            <div style={{display: "inline-block"}}>
                                <Button disabled>
                                    <MoreVertIcon/>
                                </Button>
                            </div>
                        </Tooltip>
                ),
            ]
        }
    ];

    const handleEditClick = (event, params) => {
        setSelectedRow(params.row);
        toggleTimeOffEditModal();
    }
    const handleNewJoinedDate = async () => {
        setIsInProgress(true);
        try {
            const payload = {
                employee: employeeId,
                team: selectedRow?.data?.team?.id,
                fromDate: moment(selectedRow?.data?.from).valueOf(),
                toDate: moment(selectedRow?.data?.to).valueOf(),
                newFromDate: moment(`${newJoinedDate?.format(DATE_FORMAT)}T00:00:00.000Z`).valueOf() || moment(selectedRow?.data?.from).valueOf(),
            };
            await updateJoinedTeamDate(employeeId, selectedRow?.data?.team?.id, payload);
            toggleTimeOffEditModal();
            setReload(!reload)
            toast.success("Updated Successfully");
        } catch (e) {
            toast.error(getErrorMessage(e));
        } finally {
            setTimeout(() => {
                setIsInProgress(false);
            }, 2000);
        }
    }
    const handleTeamsChange = (event, value) => {
        if (isApproval) {
            setTeamsValue(map(value, 'id'));
        } else if (value?.id) {
            setTeamsValue([value.id]);
        } else {
            setTeamsValue([]);
        }
    };

    const handleSaveDetailsButton = async () => {
        setSubmitting(true);
        try {
            await updateEmployeeDetails(employeeId, {
                teams: teamsValue,
                updatedTab: "TEAMS"
            });
            toast.success("Updated Successfully");
            setEmployeeUpdated(true);
        } catch (e) {
            toast.error(getErrorMessage(e));
        } finally {
            setTimeout(() => {
                setSubmitting(false);
            }, 2000);
        }
    };

    const calculateInvolvement = (fromDate, toDate) => {
        const from = moment(fromDate);
        const to = toDate === 'Present' ? moment() : moment(toDate);

        if (from.isValid() && to.isValid()) {
            const duration = moment.duration(to.diff(from));
            const years = Math.floor(duration.asYears());
            const months = Math.floor(duration.asMonths() % 12);
            const days = Math.floor(duration.asDays() % 30);

            return `${years > 0 ? `${years} year${years !== 1 ? 's' : ''}, ` : ''}` +
                `${months > 0 ? `${months} month${months !== 1 ? 's' : ''}, ` : ''}` +
                `${days > 0 ? `${days} day${days !== 1 ? 's' : ''}` : ''}`.replace(/,\s*$/, '');
        } else {
            return '';
        }
    };

    const handleRowClick = (event) => {
        const fromDate = event.row?.from;
        const toDate = event.row?.to;
        const involvement = calculateInvolvement(fromDate, toDate);
        setInfoData({...event.row, involvement});
        toggleTeamsInfoModal();
    };

    return (
        <div id="teams" className="c-teams px-4 mt-5">
            {inProgress ?
                <Loading></Loading>
                :
                <div className="container-fluid">
                    <form onSubmit={handleSubmit(handleSaveDetailsButton)}>
                        <CustomAppBar title={title} showPrimaryButton={!hideSaveButtonForApprovers} showSecondaryButton
                                      handleCloseButton={handleCloseButton} message={message} submitting={submitting}/>
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <CheckboxesTags
                                    disableCloseOnSelect={isApproval}
                                    label="Current Team"
                                    name="teams"
                                    onChange={handleTeamsChange}
                                    labelField='name'
                                    fullWidth={true}
                                    options={teamsOptions}
                                    limitTags={2}
                                    defaultValue={defaultTeams}
                                    id="teams"
                                    multiple={isApproval}
                                    inputRef={isApproval ? register : register({
                                        required: "This field is required"
                                    })}
                                    errors={errors}
                                />
                            </div>
                        </div>
                        <div>
                            <Button onClick={() => setOpen(!open)}>
                                <span>Teams History</span>
                                {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                            </Button>
                        </div>
                        <Collapse in={open} timeout="auto">
                            <div style={{height: `calc(100vh - ${message ? 230 : 205}px)`}} className="row">
                                <DataGridTable
                                    className="col-12"
                                    key={TABLES_NAMES.TEAMS_LIST}
                                    formatDataTable={formatDataTable}
                                    columns={columnDefs}
                                    URL={teamsListUrl}
                                    reload={reload}
                                    isExport
                                    onRowClick={(event) => handleRowClick(event)}
                                />
                            </div>
                        </Collapse>
                    </form>
                </div>
            }
            <Dialog className='confirmation-dialog' open={modals[TEAMS_INFO_MODAL]} toggle={toggleTeamsInfoModal}
                    mainTitle={infoData?.data?.team?.name}>
                {infoData && (
                    <div className="m-2" style={{width: "500px"}}>
                        {infoData?.from && (
                        <div>
                            {employee?.name} Joined on : {infoData?.from} to {infoData?.to}
                            <br/>
                            Involvement : {infoData?.involvement}
                            <hr/>
                        </div>
                        )}
                        {infoData?.to === "Present" && (
                            <>
                                <div>
                                    <div style={{color: colorTheme}}>
                                        {infoData?.data?.team?.approvers?.length + infoData?.data?.team?.customApproverList?.length + infoData?.data?.team?.observers?.length} APPROVERS
                                    </div>
                                    <Grid container>
                                        {infoData?.data?.team?.customApproverList.map((item) => (
                                            <Grid item xs={4}>
                                                <EmployeeCircularImage
                                                    customApprover
                                                    imageUrl={item.profilePicture || profilePicture}
                                                    name={item.name || "undefined"}
                                                    id={item.id || "undefined"}
                                                    color={colorTheme}
                                                    handelRefreshEmployeeList={handelRefreshEmployeeList}
                                                />
                                            </Grid>
                                        ))}
                                        {infoData?.data?.team?.observers.map((item) => (
                                            <Grid item xs={4}>
                                                <EmployeeCircularImage
                                                    isObserver
                                                    imageUrl={item.profilePicture || profilePicture}
                                                    name={item.name || "undefined"}
                                                    id={item.id || "undefined"}
                                                    color={colorTheme}
                                                    handelRefreshEmployeeList={handelRefreshEmployeeList}
                                                />
                                            </Grid>
                                        ))}
                                        {infoData?.data?.team?.approvers.map((item) => (
                                            <Grid item xs={4}>
                                                <EmployeeCircularImage
                                                    rank={item.rank}
                                                    imageUrl={item.profilePicture || profilePicture}
                                                    name={item.name || "undefined"}
                                                    id={item.id || "undefined"}
                                                    color={colorTheme}
                                                    handelRefreshEmployeeList={handelRefreshEmployeeList}
                                                />
                                            </Grid>
                                        ))}
                                        {/*{infoData?.data?.team?.customApprovers.map((item) => (*/}
                                        {/*    <Grid item xs={4}>*/}
                                        {/*        <EmployeeCircularImage*/}
                                        {/*            imageUrl={item.profilePicture || profilePicture}*/}
                                        {/*            name={item.name || "undefined"}*/}
                                        {/*            id={item.id || "undefined"}*/}
                                        {/*            color={colorTheme}*/}
                                        {/*            handelRefreshEmployeeList={handelRefreshEmployeeList}*/}
                                        {/*        />*/}
                                        {/*    </Grid>*/}
                                        {/*))}*/}
                                    </Grid>
                                </div>
                                <hr/>
                                <div>
                                    <div style={{color: colorTheme}}>
                                        {infoData?.data?.team?.employees.length} TEAM MEMBERS
                                    </div>
                                    <Grid container className="custom-scrollbar">
                                        {infoData?.data?.team?.employees.map((item) => (
                                            <Grid item xs={4}>
                                                <EmployeeCircularImage
                                                    imageUrl={item.profilePicture || profilePicture}
                                                    name={item.name || "undefined"}
                                                    id={item.id || "undefined"}
                                                    handelRefreshEmployeeList={handelRefreshEmployeeList}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </div>
                            </>
                        )}

                    </div>
                )}
            </Dialog>
            <Dialog
                className="time-off-request-dialog"
                open={modals[TIME_OFF_REQUEST_MODAL]}
                toggle={toggleTimeOffEditModal}
            >
                <div className="m-4">
                    <h6 className="mb-4" style={{color: colorTheme}}>{selectedRow?.name}</h6>
                    <div className="column">
                        <DatePicker
                            name="joined"
                            label="Joined"
                            className="mx-1"
                            value={moment(selectedRow?.from)}
                            maxDate={moment(selectedRow?.to)}
                            onChange={setNewJoinedDate}
                        />
                    </div>
                </div>
                <DialogActions className="p-3 mt-3">
                    <Button className="px-4 me-3" variant="outlined" onClick={toggleTimeOffEditModal}>
                        Cancel
                    </Button>
                    <Button className="px-4" variant="contained" onClick={handleNewJoinedDate} disabled={isInProgress}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
const modalsProps = {
    toggleTeamsInfoModal: TEAMS_INFO_MODAL,
    toggleTimeOffEditModal: TIME_OFF_REQUEST_MODAL
};
export default WithModals(Teams, modalsProps);
