import React, { useState, useEffect } from 'react';
import {useForm} from "react-hook-form";
import {useLocation} from "react-router-dom";
import moment from "moment";
import { EditIcon, DeleteIcon } from "components/Mui/Icons";
import {formatDate, getErrorMessage, getTenantId} from "utility/helpers/utils";
import { getBalanceDeduction, updateBalanceDeduction, deleteBalanceDeduction } from "utility/apis/EmployeeService";
import { BALANCE_DEDUCTION_MODAL, CONFIRMATION_MODAL } from "utility/modals/constants";
import WithModals from "hoc/withModals/withModals";
import { TABLES_NAMES } from "utility/constants/constants";

import {
    Button,
    DataGridTable,
    DialogActions,
    GridActionsCellItem,
    Dialog,
    TextField,
    DatePicker,
    Tooltip
} from "components/Mui";
import Loading from "components/Loading/Loading";
import {DATE_FORMAT, YEAR_FORMAT} from "utility/helpers/metaData";
import "./BalanceDeduction.scss";
import {toast} from "react-toastify";

const BalanceDeduction = (props) => {
    const tenantId = getTenantId(useLocation(),"tenantId");
    const { employeeId, date, colorTheme, modals, toggleBalanceDeductionModal, toggleConfirmationModal, message } = props;

    const [ inProgress, setInProgress ] = useState(false);
    const [selectedBalanceDeduction, setSelectedBalanceDeduction] = useState({});
    const { register, handleSubmit, errors, trigger } = useForm({ mode: 'onSubmit' });
    const [dataTable, setDataTable] = useState([]);
    const [isInProgress, setIsInProgress] = useState(false);

    const fetchData = async () => {
        setInProgress(true);
        try {
            const response = await getBalanceDeduction(employeeId, moment.isMoment(date)? date.format("YYYY") : moment.isMoment(date.value)? moment(date.value).format("YYYY") : date?.value);
            setDataTable(response?.data);
        } catch (e) {
        }
        setInProgress(false);
    }

    const refreshData = () => {
        fetchData() ;
    }

    useEffect(() => {
        if (date) {
            fetchData();
        }
    }, [date]);

    const handleEditClick = (event, params) => {
        setSelectedBalanceDeduction(params.row);
        toggleBalanceDeductionModal();
    }

    const handleDeleteClick = (event, params) => {
        setSelectedBalanceDeduction(params.row);
        toggleConfirmationModal();
    }

    const handleBalanceDeductionUpdate   = async (data) => {
        setIsInProgress(true);
        try {
            const isValid = await trigger();
            if (isValid) {
                const { value, comment, date } = data;
                const payload = {
                    ...selectedBalanceDeduction,
                    value: parseFloat(value),
                    date: moment(`${moment(date, 'YYYY-MM-DD').format(DATE_FORMAT)}T00:00:00.000Z`).valueOf(),
                    comment
                };
                await updateBalanceDeduction(tenantId, selectedBalanceDeduction.id, payload);
                refreshData();
                toggleBalanceDeductionModal();
                toast.success("Updated Successfully");
            }
        } catch (e) {
            toast.error(getErrorMessage(e));
        }finally {
            setTimeout(() => {
                setIsInProgress(false);
            }, 2000);
        }
    }

    const handleBalanceDeductionDelete = async () => {
        setIsInProgress(true);
        try {
            await deleteBalanceDeduction(selectedBalanceDeduction.id);
            refreshData();
            toggleConfirmationModal();
            toast.success("Deleted Successfully");
        } catch (e) {
            toast.error(getErrorMessage(e));
        }finally {
            setTimeout(() => {
                setIsInProgress(false);
            }, 2000);
        }
    }

    const columnDefs = [
        {
            headerName: 'Month',
            field: 'date',
            renderCell: (params) => (
                <Tooltip title={formatDate(params.value)}>
                    <div className="ellipsis">
                        {formatDate(params.value)}
                    </div>
                </Tooltip>
            ),
            flex: 1
        },
        {
            headerName: 'Number of hours',
            field: 'value',
            renderCell: (params) => (
                <Tooltip title={`${params.value} ${params.value > 1 ? 'hours' : 'hour'}`}>
                    <div className="ellipsis">
                        {parseFloat(params.value).toFixed(params.value % 1 === 0 ? 0 : 2)} {params.value > 1 ? 'hours' : 'hour'}
                    </div>
                </Tooltip>
            ),
            flex: 1.5
        },
        {
            headerName: 'Comment',
            field: 'comment',
            flex: 2
        },
        {
            headerName: 'Created',
            field: 'created',
            renderCell: (params) => (
                <Tooltip title={formatDate(params.value)}>
                    <div className="ellipsis">
                        {formatDate(params.value)}
                    </div>
                </Tooltip>
            ),
            flex: 1
        },
        {
            headerName: 'Submitted By',
            field: 'byWhomName',
            flex: 1
        },
        {
            headerName: 'Actions',
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params) => [
                <GridActionsCellItem showInMenu icon={<EditIcon />} onClick={(event) => handleEditClick(event, params)} label="Edit" />,
                <GridActionsCellItem showInMenu icon={<DeleteIcon />} onClick={(event) => handleDeleteClick(event, params)} label="Delete" className="text-danger" />,
            ]
        }
    ];

    return (
        <div id="balance-deduction" className="c-balance-deduction">
            {inProgress ?
                <Loading></Loading>
                :
                <div className="container-fluid">
                <div style={{height: `calc(100vh - ${message ? 200 : 165}px)`}} className="row">
                        <DataGridTable
                            className="col-12"
                            key={TABLES_NAMES.BALANCE_DEDUCTION_LIST}
                            columns={columnDefs}
                            dataTable={dataTable}
                            isExport={false}
                        />
                    </div>
                </div>
            }
            <Dialog className='balance-deduction-dialog' open={modals[BALANCE_DEDUCTION_MODAL]} toggle={toggleBalanceDeductionModal}>
                <form onSubmit={handleSubmit(handleBalanceDeductionUpdate)}>
                    <div className="m-4">
                        <h6 className="mb-4" style={{color: colorTheme}}>Edit balance deduction</h6>
                        <div className="row">
                            <TextField defaultValue={selectedBalanceDeduction?.value}
                                       inputRef={register({
                                           required: "This field is required",
                                           pattern: {
                                               value: /^(0|[1-9]\d*)(\.\d+)?$/,
                                               message: "Enter a valid number",
                                           }
                                       })}
                                       errors={errors}
                                       className="col-12" id="hours" name="value" label="Number of Hours"
                                       type="text"/>
                            <DatePicker
                                name="date"
                                label="Date"
                                className="col-12"
                                defaultValue={moment.utc(selectedBalanceDeduction?.date)}
                                openTo="month"
                                views={['month']}
                                inputRef={register({
                                    required: "Date is required",
                                    validate: (value) =>
                                        moment(value)?.format(YEAR_FORMAT) === (moment.isMoment(date) ? date.format("YYYY") : moment.isMoment(date.value) ? moment(date.value).format("YYYY") : date?.value) ||
                                        "Invalid date",
                                })}
                            />
                            {errors?.date && <div className="error-message">{errors?.date?.message}</div>}
                            <TextField defaultValue={selectedBalanceDeduction?.comment}
                                       inputRef={register}
                                       className="col-12" id="comment" name="comment"
                                       label="Comment" type="text"/>
                        </div>
                    </div>
                    <div>
                        <DialogActions className="p-3 mt-3">
                            <Button className="px-4 me-3" variant="outlined" onClick={toggleBalanceDeductionModal}>Cancel</Button>
                            <Button className="px-4" variant="contained" type="submit" disabled={isInProgress}>Save</Button>
                        </DialogActions>
                    </div>
                </form>
            </Dialog>
            <Dialog className='confirmation-dialog' open={modals[CONFIRMATION_MODAL]} toggle={toggleConfirmationModal}>
                <div>
                    <div className="m-4" style={{color: colorTheme}}>
                        Are you sure you want to delete this ?
                    </div>
                    <div>
                    <DialogActions className="p-3 mt-3">
                        <Button className="px-4 me-3" variant="outlined" onClick={toggleConfirmationModal}>Cancel</Button>
                        <Button className="px-4 bg-danger" color="secondary" variant="outlined" disabled={isInProgress} onClick={handleBalanceDeductionDelete}>Delete</Button>
                    </DialogActions>
                </div>
                </div>
            </Dialog>
        </div>
    );
}
const modalsProps = {
    toggleBalanceDeductionModal: BALANCE_DEDUCTION_MODAL,
    toggleConfirmationModal: CONFIRMATION_MODAL
};

export default WithModals(BalanceDeduction, modalsProps)
