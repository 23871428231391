import React, { useEffect, useState, useRef, createContext } from "react";
import { LeftTabsDrawer } from "components/Mui";
import Loading from "components/Loading/Loading";
import moment from "moment";
import PersonalInformation from "components/EmployeeDetailsForms/PersonalInformation/PersonalInformation";
import TeamsAndWorkScheduleTemplates from "components/EmployeeDetailsForms/TeamsAndWorkScheduleTemplates/TeamsAndWorkScheduleTemplates";
import Roles from "components/EmployeeDetailsForms/Roles/Roles";
import CustomizeTimeOff from "components/EmployeeDetailsForms/CustomizeTimeOff/CustomizeTimeOff";
import {getErrorMessage, getTenantId} from "utility/helpers/utils";
import { useLocation } from "react-router-dom";
import {createProfile, getPolicy, getTimeOffPolicy} from "utility/apis/SetupGuideService";
import {getSpecialHolidays, getTeams} from "utility/apis/ConfigService";
import { getWorkScheduleTemplates } from "utility/apis/WorkScheduleTemplateService";
import {
    EventNoteOutlinedIcon,
    InfoOutlinedIcon,
    ManageAccountsOutlinedIcon,
    PoolOutlinedIcon,
} from "components/Mui/Icons";

import classNames from "classnames";
import "./ProfileDetails.scss";
import {toast} from "react-toastify";
import {getIsManager} from "../../utility/helpers/authHelper";

export const ProfileContext = createContext();

const getProfileDrawerList = (handleCloseButton, setProfileHolidays, setProfileTemplate, setProfileRoles, setProfileTeams, setProfilePersonalInfo, setProfileCustomizeTimeOff, setCurrentTabIndex, currentTabIndex, profilePersonalInfo, profileHolidays, profileRoles, profileTeams, profileTemplate, profileCustomizeTimeOff, profileWfhPolicy, setProfileWfhPolicy, templateOptions, holidayOptions, teamsOptions, setSaveProfile) => {
    const isManager = getIsManager();
    let tabs = [
        {
            id: "personal_info",
            title: "Personal Info",
            component: (
                <PersonalInformation title="Personal Info" profilePersonalInfo={profilePersonalInfo} setProfilePersonalInfo={setProfilePersonalInfo} setCurrentTabIndex={setCurrentTabIndex} currentTabIndex={currentTabIndex} handleCloseButton={handleCloseButton}/>
            ),
            icon: <InfoOutlinedIcon />,
        },
        {
            id: "teams_and_work_schedule_templates",
            title: "Work Schedule Templates",
            component: (
                <TeamsAndWorkScheduleTemplates title="Work schedule templates" templateOptions={templateOptions}  holidayOptions={holidayOptions} teamsOptions={teamsOptions} teams={profileTeams} setProfileTeams={setProfileTeams} defaultHolidays={profileHolidays} profileTemplate={profileTemplate} setProfileHolidays={setProfileHolidays} setProfileTemplate={setProfileTemplate} setCurrentTabIndex={setCurrentTabIndex} currentTabIndex={currentTabIndex}/>
            ),
            icon: <EventNoteOutlinedIcon />,
        },
        {
            id: "roles",
            title: "Roles",
            component: (
                <Roles title="Roles" roles={profileRoles} setProfileRoles={setProfileRoles} profilePersonalInfo={profilePersonalInfo} setProfilePersonalInfo={setProfilePersonalInfo} setCurrentTabIndex={setCurrentTabIndex} currentTabIndex={currentTabIndex} setSaveProfile={setSaveProfile}/>
            ),
            icon: <ManageAccountsOutlinedIcon />,
        },
    ];

    if(isManager) {
        tabs.push({
            id: "customize_time_off_policy",
            title: "Customize Policies",
            component: (
                <CustomizeTimeOff  title="Customize Policies" profileCustomizeTimeOff={profileCustomizeTimeOff} profileWfhPolicy={profileWfhPolicy} setProfileCustomizeTimeOff={setProfileCustomizeTimeOff} setProfileWfhPolicy={setProfileWfhPolicy} setParentTabIndex={setCurrentTabIndex} parentTabIndex={currentTabIndex} handleCloseButton={handleCloseButton}/>
            ),
            icon: <PoolOutlinedIcon />,
        })
    }

    return tabs;
};

function ProfileDetails(props) {
    const { handleCloseButton, handelRefreshEmployeeList } = props
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const [profileDrawerList, setProfileDrawerList] = useState([]);
    const [profileHolidays, setProfileHolidays] = useState([]);
    const [profileTemplate, setProfileTemplate] = useState(undefined);
    const [profileRoles, setProfileRoles] = useState([]);
    const [profileTeams, setProfileTeams] = useState([]);
    const [profilePersonalInfo, setProfilePersonalInfo] = useState({});
    const [profileCustomizeTimeOff, setProfileCustomizeTimeOff] = useState({});
    const [profileWfhPolicy, setProfileWfhPolicy] = useState({});
    const [templateOptions, setTemplateOptions] = useState([]);
    const [holidayOptions, setHolidayOptions] = useState([]);
    const [teamsOptions, setTeamsOptions] = useState([]);
    const [saveProfile, setSaveProfile] = useState(false);
    const [profileImage, setProfileImage] = useState();
    const [inProgress, setInProgress] = useState(false);
    const tenantId = getTenantId(useLocation(), "tenantId");
    const hasRendered = useRef(false);

    const handleTabOnChange = (newTabIndex) => {
        setCurrentTabIndex(newTabIndex);
    };

    const getWorkSecduleTemplatesAndTeamsData = async () => {
        try {
            const response = await getWorkScheduleTemplates(tenantId);
            setTemplateOptions(response.data);
            const response2 = await getSpecialHolidays(tenantId);
            setHolidayOptions(response2.data);
            const response3 = await getTeams(tenantId);
            setTeamsOptions(response3.data);
        } catch (e) {
        }
    }

    const getTimeOffPolicyData = async () => {
        try {
            const response = await getTimeOffPolicy(tenantId);
            setProfileCustomizeTimeOff(response.data);
        } catch (e) {
            console.log(e);
        }
    }

    const getWfhPolicyData = async () => {
        try {
            const response = await getPolicy(tenantId);
            setProfileWfhPolicy(response.data.workFromHomePolicy);
        } catch (e) {
            console.log(e);
        }
    }

    const isManager = getIsManager();

    useEffect(() => {
        getWorkSecduleTemplatesAndTeamsData();
        getTimeOffPolicyData();
        getWfhPolicyData();
    }, [])

    useEffect(() => {
        if (hasRendered.current) {
            if (saveProfile) {
                handleCreateProfile();
            }
          } else {
            hasRendered.current = true;
          }
    }, [saveProfile])

    const handleCreateProfile = async () => {
        try {
            setInProgress(true)
            // const useHRWeb = profileRoles.some( roleId => [1,2,3,7].includes(roleId)); // [1,2,3,7] role ids for the employee can use the HR app web

            const formData = new FormData();
            formData.append('empPic', profileImage);
            const employeeRequest = isManager ? JSON.stringify({
                holidays: profileHolidays,
                personalInfo: profilePersonalInfo,
                templates: [{ templateId: profileTemplate?.id}],
                roles: profileRoles?.map((v) => (`${v}`)),
                teams: profileTeams?.map((v) => (`${v}`)),
                timeOffPolicyDto: profileCustomizeTimeOff,
                workFromHomePolicy: profileWfhPolicy,
                sendSlackInvitation: false, //TODO send true when the backend resolve this parameter
                sendUserNamePasswordInvitation: false, //TODO send useHRWeb when the backend resolve this parameter
                updatedTab: "ALL"
            }) : JSON.stringify({
                holidays: profileHolidays,
                personalInfo: profilePersonalInfo,
                templates: [{ templateId: profileTemplate?.id}],
                roles: profileRoles?.map((v) => (`${v}`)),
                teams: profileTeams?.map((v) => (`${v}`)),
                sendSlackInvitation: false, //TODO send true when the backend resolve this parameter
                sendUserNamePasswordInvitation: false, //TODO send useHRWeb when the backend resolve this parameter
                updatedTab: "ALL"
            });
            formData.append('employeeRequest', new Blob([employeeRequest], { type: 'application/json' }));

            await createProfile(tenantId, formData);
            toast.success("Profile Created Successfully");
            setInProgress(false)
            handelRefreshEmployeeList?.()
            handleCloseButton()
        } catch (e) {
            setInProgress(false)
            toast.error(getErrorMessage(e));
        }
    };

    useEffect(() => {
        setProfileDrawerList(
            getProfileDrawerList(
                handleCloseButton,
                setProfileHolidays, 
                setProfileTemplate,
                setProfileRoles,
                setProfileTeams,
                setProfilePersonalInfo,
                setProfileCustomizeTimeOff,
                setCurrentTabIndex,
                currentTabIndex,
                profilePersonalInfo,
                profileHolidays,
                profileRoles,
                profileTeams,
                profileTemplate,
                profileCustomizeTimeOff,
                profileWfhPolicy,
                setProfileWfhPolicy,
                templateOptions,
                holidayOptions,
                teamsOptions,
                setSaveProfile
            )
        );
    }, [currentTabIndex, profilePersonalInfo, profileHolidays, profileRoles, profileTeams, profileTemplate, profileCustomizeTimeOff, profileWfhPolicy])

    return (
        <ProfileContext.Provider value={{ saveProfile, setSaveProfile, profilePersonalInfo, setProfileImage }}>
            <div className="c-profile-details"> 
                <>
                    <div className={classNames("centered-loading", inProgress ? "d-block" : "d-none")}>
                        {inProgress && <Loading></Loading>}
                    </div>
                   
                    {!!profileDrawerList.length && (
                        <LeftTabsDrawer
                            currentTabIndex={currentTabIndex}
                            handleTabOnChange={handleTabOnChange}
                            drawerList={profileDrawerList}
                            orientation="vertical"
                            wizardForm
                            UploadNewProfileImage
                        >
                        </LeftTabsDrawer>
                    )}
                </>
            </div>
        </ProfileContext.Provider>
    );
}

export default ProfileDetails;
