import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Tabs } from "components/Mui";
import CustomAppBar from "components/CustomAppBar/CustomAppBar";
import TimeOffPolicyEmployeeForm from "./TimeOffPolicyEmployeeForm/TimeOffPolicyEmployeeForm";
import WFHCustomizationPolicyListForm from "./WFHCustomizationPolicyListForm/WFHCustomizationPolicyListForm";
import {getTimeOffPolicy, getWfhPolicyList} from "utility/apis/ConfigService";
import {getErrorMessage, getTenantId, isObjectEmpty} from "utility/helpers/utils";
import {updateTimeOffPolicyDetails, updateWfhPolicyDetails} from "utility/apis/EmployeeService";
import Loading from "components/Loading/Loading";
import { toast } from "react-toastify";

const getTimeOffPolicyTabs = (
    employeeId,
    timeOffDataFromServer,
    setSaveTimeOffData,
    wfhPolicyDataFromServer,
    setSaveWfhPolicyData,
    timeOffPolicyTabsProps,
    profileCustomizeTimeOff,
    setProfileCustomizeTimeOff,
    currentTabIndex,
    setCurrentTabIndex,
    backClicked,
    wfhPolicyProps,
    profileWfhPolicy,
    setProfileWfhPolicy
) => {
    return [
        {
            label: "Time Off Policy Employee",
            component: (
                <TimeOffPolicyEmployeeForm
                    dataFromServer={timeOffDataFromServer}
                    setSaveTimeOffData={setSaveTimeOffData}
                    employeeId={employeeId}
                    handleSave={timeOffPolicyTabsProps}
                    setProfileCustomizeTimeOff={setProfileCustomizeTimeOff}
                    profileCustomizeTimeOff={profileCustomizeTimeOff}
                    backClicked={backClicked}
                    currentTabIndex={currentTabIndex}
                    setCurrentTabIndex={setCurrentTabIndex}
                />
            ),
        },
        {
            label: "WFH Customization Policy List",
            component: (
                <WFHCustomizationPolicyListForm
                    wfhPolicyDataFromServer={wfhPolicyDataFromServer}
                    setSaveWfhPolicyData={setSaveWfhPolicyData}
                    employeeId={employeeId}
                    handleSaveWfhPolicy={wfhPolicyProps}
                    setProfileWfhPolicy={setProfileWfhPolicy}
                    profileWfhPolicy={profileWfhPolicy}
                    backClicked={backClicked}
                    currentTabIndex={currentTabIndex}
                    setCurrentTabIndex={setCurrentTabIndex}
                />
            ),
        },
    ];
};

function CustomizeTimeOff(props) {
    const {
        employeeId,
        handleCloseButton,
        title,
        setEmployeeUpdated,
        profileCustomizeTimeOff,
        setProfileCustomizeTimeOff,
        profileWfhPolicy,
        setProfileWfhPolicy,
        setParentTabIndex,
        parentTabIndex
    } = props;
    const tenantId = getTenantId(useLocation(), "tenantId");

    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const [inProgress, setInProgress] = useState(false);
    const [timeOffDataFromServer, setTimeOffDataFromServer] = useState({});
    const [wfhPolicyDataFromServer, setWfhPolicyDataFromServer] = useState({});
    const [saveTimeOffData, setSaveTimeOffData] = useState({});
    const [saveWfhPolicyData, setSaveWfhPolicyData] = useState({});
    const [wfhTabs, setWfhTabs] = useState({});
    const [submitting, setSubmitting] = useState(false);
    const [backClicked, setBackClicked] = useState(false);
    const [wfhPolicyId, setWfhPolicyId] = useState(undefined);
    const fetchWfhCustomizationPolicyList = async () => {
        setInProgress(true);
        try {
            const response = await getWfhPolicyList(employeeId, tenantId);
            const data = response?.data;
            setWfhPolicyId(response?.data?.id);
            setWfhPolicyDataFromServer((wfhPolicyDataFromServer) => ({
                ...wfhPolicyDataFromServer,
                ...data,
            }));
        } catch (e) {
            toast.error(getErrorMessage(e));
        }
        setInProgress(false);
    }
    const fetchData = async () => {
        setInProgress(true);
        try {
            const response = await getTimeOffPolicy(tenantId, employeeId);
            const data = response?.data;
            setTimeOffDataFromServer((timeOffDataFromServer) => ({
                ...timeOffDataFromServer,
                ...data,
            }));
        } catch (e) {
            toast.error(getErrorMessage(e));
        }
        setInProgress(false);
    };

    useEffect(() => {
        employeeId && fetchData() && fetchWfhCustomizationPolicyList();
    }, []);

    useEffect(() => {
        if (!isObjectEmpty(timeOffDataFromServer || wfhPolicyDataFromServer) || !employeeId) { // (!employeeId) : this condition for new profile
            const timeOffPolicyTabsProps = handleSave;
            const wfhPolicyProps = handleSaveWfhPolicy;
            const wfhTabs = getTimeOffPolicyTabs(
                employeeId,
                timeOffDataFromServer,
                setSaveTimeOffData,
                wfhPolicyDataFromServer,
                setSaveWfhPolicyData,
                timeOffPolicyTabsProps,
                profileCustomizeTimeOff,
                setProfileCustomizeTimeOff,
                currentTabIndex,
                setCurrentTabIndex,
                backClicked,
                wfhPolicyProps,
                profileWfhPolicy,
                setProfileWfhPolicy,
            );
            setWfhTabs(wfhTabs);
        }
    }, [timeOffDataFromServer, wfhPolicyDataFromServer, backClicked]);

    const handleSave = async (data) => {
        try {
            timeOffDataFromServer.increaseSet.forEach((item, index) => {
                if (data.increaseSet[index]) {
                    data.increaseSet[index].id = item.id;
                }
            });
            setSubmitting(true);
            await updateTimeOffPolicyDetails(employeeId, data);
            toast.success("Updated Successfully");
            setEmployeeUpdated(true)
        } catch (e) {
            toast.error(getErrorMessage(e));
        }finally {
            setTimeout(() => {
                setSubmitting(false);
            }, 2000);
        }
    };

    const handleProfileRolesStep = (event, step) => {
        event.preventDefault()
        setParentTabIndex(parentTabIndex +  (step === "next"? 1 : -1))
    }

    const handleSaveWfhPolicy = async (...data) => {
        try {
            setSubmitting(true);
            await updateWfhPolicyDetails(employeeId, wfhPolicyId, ...data);
            toast.success("Updated Successfully");
            setEmployeeUpdated(true)
        } catch (e) {
            toast.error(getErrorMessage(e));
        }finally {
            setTimeout(() => {
                setSubmitting(false);
            }, 2000);
        }
    };
    const handleTabChange = (newIndex) => {
        if (saveTimeOffData && Object.keys(saveTimeOffData).length > 0) {
            setTimeOffDataFromServer(saveTimeOffData)
        }
        if (saveWfhPolicyData && Object.keys(saveWfhPolicyData).length > 0) {
            setWfhPolicyDataFromServer(saveWfhPolicyData)
        }
        setCurrentTabIndex(newIndex);
    };

    return (
        <div className="c-policy_tabs px-4 mt-3">
            {inProgress && isObjectEmpty(timeOffDataFromServer || wfhPolicyDataFromServer) ? (
                <Loading></Loading>
            ) : (
                <>
                    <CustomAppBar
                        title={title}
                        showPrimaryButton
                        textPrimary={!employeeId && "Save & Create Profile"}
                        showSecondaryButton={employeeId}
                        showBackButton={!employeeId}
                        handleBackButton={(event) => handleProfileRolesStep(event, "back")}
                        buttonType="submit"
                        formId={currentTabIndex === 0 ? "time-off-policy" : "Wfh-policy"}
                        submitting={submitting}
                        handleCloseButton={handleCloseButton}
                    />

                    {wfhTabs && wfhTabs.length > 0 && (
                        <Tabs
                            currentTabIndex={currentTabIndex}
                            items={wfhTabs}
                            handleTabOnChange={handleTabChange}
                        />
                    )}
                </>
            )}
        </div>
    );
}

export default CustomizeTimeOff;
