import {
  attendance_white_listed_options,
  how_it_works_carousel,
  features,
  clients,
  review_of_customers,
  pending_options,
  social_links,
  yesNoOptions,
  activateOptions
} from 'utility/constants/data';

export const ATTENDANCE_WHITE_LISTED_OPTIONS = attendance_white_listed_options;
export const HOW_IT_WORKS_CAROUSEL = how_it_works_carousel;
export const FEATURES = features;
export const CLIENTS = clients;
export const REVIEW_OF_CUSTOMERS = review_of_customers;
export const PENDING_OPTIONS = pending_options;
export const SOCIAL_LINKS = social_links;
export const YES_NO_OPTIONS = yesNoOptions;
export const ACTIVATE_OPTIONS = activateOptions;

export const TABLES_NAMES = {
  BENEFITS_LIST: 'BenefitsList',
  TEMPLATES_LIST: 'TemplatesList',
  TEAMS_LIST: 'TeamsList',
  ROLES_LIST: 'RolesList',
  ATTENDANCE_REPORT_LIST: 'AttendanceReportList',
  TIME_OFF_LIST: 'TimeOffList',
  WFH_LIST: 'WfhList',
  WFH_MONTHLY_BASIS_LIST: 'wfhMonthlyBasisList',
  SICK_LIST: 'SickList',
  OTHER_LIST: 'OTHER_LIST',
  COMPENSATIONS_TIME_LIST: 'CompensationsTimeList',
  BALANCE_DEDUCTION_LIST: 'BalanceDeductionList',
  SALARY_DEDUCTION_LIST: 'SalaryDeductionList',
  PENDING_LIST: 'PendingList',
  ATTENDANCE_REPORT_MONTHLY_BASIS: 'AttendanceReportMonthlyBasis',
  ATTENDANCE_CANCELLATION_DETAILS_LIST: 'AttendanceCancellationDetailsList',
  SYSTEM_CONFIGURATION_LIST: 'SystemConfigurationList',
  PENDING_ACTION_DETAILS_LIST: 'PendingActionDetailsList',
  SLACK_LIST: 'SlackList',
  CONFLICT_LIST: 'conflictList',
  UPDATED_ATTENDANCE_LIST: 'updatedAttendanceList',
}

export const EMPLOYEE_VACATIONS_TYPE = {
  PERSONAL_AND_OTHERS_VACATION_TYPE:["PersonalVacation","LeaveWithoutPayVacation","Haj", "Marriage", "UnPaid", "DeathGrievingVacation"],
  MATERNITY:"MaternityVacation",
  PATERNITY:"PaternityVacation",
  SICK:"SickVacation",
}