import React, {useEffect, useState} from 'react';
import {Button, Checkbox, FormControlLabel, TextField, Dialog, DialogActions, TimePicker, CircularProgress} from "components/Mui";
import {Controller, useForm} from "react-hook-form";
import {enablePolicies, updateAttendancePolicy} from "utility/apis/SetupGuideService";
import {DISABLE_POLICY_MODAL} from "utility/modals/constants";
import withModals from "hoc/withModals/withModals";
import {getErrorMessage} from "utility/helpers/utils";
import {toast} from "react-toastify";
import {TIME_FORMAT} from "utility/helpers/metaData";
import moment from "moment";

const AttendancePolicy = (props) => {
    const {attendancePolicy, tenantId, setInProgress, refreshPolicyData, colorTheme, modals, toggleDisablePolicyModal} = props;
    const [startRecordingCheckin, setStartRecordingCheckin] = useState(attendancePolicy?.checkInStartsFrom? moment(attendancePolicy?.checkInStartsFrom, TIME_FORMAT): null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { register, control, reset, handleSubmit, errors } = useForm({
        defaultValues: {},
    });
    useEffect(() => {
        reset(attendancePolicy);
    }, [attendancePolicy]);
    const handleSave = async (data) => {
        setInProgress(true);
        data.id = attendancePolicy?.id;
        data.enabled = attendancePolicy?.enabled;
        data.checkInStartsFrom = startRecordingCheckin?.format(TIME_FORMAT);
        try {
            await updateAttendancePolicy( tenantId, data);
            refreshPolicyData();
            toast.success("Updated Successfully");
        } catch (e) {
            toast.error(getErrorMessage(e));
            setInProgress(false);
        }
    };
    const handleDisable = async () => {
        if (isSubmitting) return;
        setIsSubmitting(true);
        const payload = [{
            policyType: attendancePolicy?.type,
            enable: false
        }];
        try {
            await enablePolicies(tenantId, payload);
            refreshPolicyData();
            toggleDisablePolicyModal();
            toast.success("Disabled Successfully");
        } catch (e) {
            toast.error(getErrorMessage(e));
            setIsSubmitting(false);
        }
    };
    return (
        <div id="AttendancePolicy" className="c-attendance-policy px-4 mt-3">
            <form id="AttendancePolicy" className="row d-flex flex-column" onSubmit={handleSubmit(handleSave)}>
                <div className="col-6">
                    <Controller
                        as={
                            <TextField
                                label="Max Allowance Hours In Day"
                                InputLabelProps={{shrink: true}}
                                type="number"
                                inputRef={register({
                                    validate: (value) => (value < 0 ? "This field is required" : true),
                                })}
                                inputProps={{
                                    min: 0,
                                    step: "any"
                                }}
                            />
                        }
                        name="allowanceHoursInDay"
                        id="allowanceHoursInDay"
                        defaultValue={attendancePolicy?.allowanceHoursInDay}
                        control={control}
                    />
                </div>
                <div className="col-6">
                    <Controller
                        as={
                            <TextField
                                label="Max Allowance Hours In Month"
                                InputLabelProps={{shrink: true}}
                                type="number"
                                inputRef={register({
                                    validate: (value) => (value < 0 ? "This field is required" : true),
                                })}
                                inputProps={{
                                    min: 0,
                                    step: "any"
                                }}
                            />
                        }
                        name="allowanceHoursInMonth"
                        id="allowanceHoursInMonth"
                        className="col-7"
                        defaultValue={attendancePolicy?.allowanceHoursInMonth}
                        control={control}
                    />
                </div>
                <div className="col-6">
                    <Controller
                        as={
                            <TextField
                                label="Restrict the attendance hours to a max of"
                                InputLabelProps={{shrink: true}}
                                type="number"
                                inputRef={register({
                                    validate: (value) => (value < 0 ? "This field is required" : true),
                                })}
                                inputProps={{
                                    min: 0,
                                    step: "any"
                                }}
                            />
                        }
                        name="attendancePeriodRestrictionValue"
                        id="attendancePeriodRestrictionValue"
                        className="col-7"
                        defaultValue={attendancePolicy?.attendancePeriodRestrictionValue}
                        control={control}
                    />
                </div>
                <div className="col-6">
                    <TimePicker
                        label="Start recording check-ins from"
                        value={startRecordingCheckin}
                        onChange={(newValue) => setStartRecordingCheckin(newValue)}
                        slotProps={{
                            field: {clearable: true, onClear: () => setStartRecordingCheckin(undefined)},
                        }}
                    />
                </div>
                <FormControlLabel
                    control={
                        <Checkbox
                            inputRef={register}
                            name="excludeEligibleForEmergencyWFH"
                            defaultChecked={attendancePolicy?.excludeEligibleForEmergencyWFH}
                        />
                    }
                    className="check-box"
                    label="Exclude Eligible WFH"
                />
                <div className="col-12">
                    <Button type="submit" variant="contained" className='float-end my-3'>
                        Save
                    </Button>
                    <Button className="bg-danger float-end my-3 mx-3" color="secondary" variant="outlined"
                            onClick={toggleDisablePolicyModal}>
                        Disable Policy
                    </Button>
                </div>
            </form>
            <Dialog
                className="details-dialog"
                open={modals[DISABLE_POLICY_MODAL]}
                toggle={toggleDisablePolicyModal}
            >
                <div>
                    <div className="m-4" style={{color: colorTheme}}>
                        Are you sure you want to disable ( Attendance ) Policy?
                    </div>
                    <div>
                        <DialogActions className="p-3 mt-3">
                            <Button
                                className="px-4 me-3"
                                variant="outlined"
                                onClick={toggleDisablePolicyModal}
                            >
                                Cancel
                            </Button>
                            <Button
                                className="px-4 bg-danger"
                                color="secondary"
                                variant="outlined"
                                onClick={handleDisable}
                            >
                                {isSubmitting ? (
                                        <CircularProgress size={24} color="inherit" />
                                    ) :
                                    "Disable"
                                }
                            </Button>
                        </DialogActions>
                    </div>
                </div>
            </Dialog>
        </div>
);
};
const modalsProps = {
    toggleDisablePolicyModal: DISABLE_POLICY_MODAL,
};
export default withModals(AttendancePolicy, modalsProps);
