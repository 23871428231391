import axios from 'axios';
import { ENVIRONMENT } from '../config';
import { clearAuth, getToken, getRefreshToken, setAuthData } from "../helpers/authHelper";

export const API_URL = ENVIRONMENT.ENV_IP;

const handleErrors = async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/api/v1/employee/login-username-password" && err.response) {
        const refreshToken = getRefreshToken();

        if (err.response.status === 401 && !originalConfig._retry && refreshToken) {
            // Access Token was expired
            originalConfig._retry = true;

            try {
                const response = await axiosInstance.post('/api/v1/employee/login-username-password', {refresh_token: refreshToken})
                setAuthData(response.data);
                return axiosInstance(originalConfig);
            } catch (_error) {
                clearAuth();
                return Promise.reject(_error);
            }
        } else if (err.response.status === 401) {
          clearAuth();
        }
    }

    return Promise.reject(err);
};

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: `${API_URL}`,
  timeout: 600000,
});

axiosInstance.interceptors.request.use(
  (config) => {
      const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (err) => Promise.reject(err)
);

axiosInstance.interceptors.response.use(
  (response) =>
    // Do something with response data
    response,
  handleErrors
);

export default axiosInstance;



