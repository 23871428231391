import React, {useState} from 'react';
import { useForm } from "react-hook-form";
import { reactivateProfile} from "utility/apis/EmployeeService";
import CustomAppBar from "components/CustomAppBar/CustomAppBar";
import {NotesIcon} from "components/Mui/Icons";
import {toast} from "react-toastify";
import {getErrorMessage} from "utility/helpers/utils";


import "./ProfileActions.scss";
const ReactivateProfile = (props) => {
    const { title, employeeId, handleCloseButton, message, employee, setEmployeeUpdated, colorTheme, handleTabOnChange, handelRefreshEmployeeList } = props;
    const { handleSubmit} = useForm({ mode: 'onSubmit' });
    const [submitting, setSubmitting] = useState(false);


    const handleReactivateButton = async () => {
        try {
            setSubmitting(true);
            await reactivateProfile(employeeId)
            toast.success("Reactivated Successfully");
            setEmployeeUpdated(true)
            handleTabOnChange(0);
            handelRefreshEmployeeList();
        } catch (e) {
            toast.error(getErrorMessage(e));
        }finally {
            setTimeout(() => {
                setSubmitting(false);
            }, 2000);
        }
    };

    return (
        <div id="reactivate-profile" className="c-reactivate-profile mx-4 mt-5">
            <div className="container-fluid">
                <form onSubmit={handleSubmit(handleReactivateButton)}>
                    <CustomAppBar title={title} showPrimaryButton showSecondaryButton handleCloseButton={handleCloseButton} submitting={submitting} textPrimary="Reactivate" message={message}/>
                    <div className="fw-bold fs-5 fst-italic mb-1">
                        <div>
                            Once you clicked on <span style={{color:  colorTheme}}>REACTIVATE</span> , we will Reactivate <span style={{color:  colorTheme}}>{employee.name}</span> account.
                            <br />
                            <br />
                            <span><NotesIcon style={{color: colorTheme}}/></span> The
                            calculation will continue and the period from last deletion will be calculated. You can create new account to avoid calculation.
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ReactivateProfile;
