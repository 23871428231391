import React, {useEffect, useState} from "react";
import {AppBar, Button, Toolbar, CircularProgress, Select} from "components/Mui";
import moment from "moment";
import { find } from "lodash";
import { getDateOptions } from "utility/helpers/utils";
import { useTranslation } from "react-i18next";
import {useTheme} from "../Mui";

import "./CustomAppBar.scss";

const CustomAppBar = ({
  title,
  textPrimary,
  textSecondary,
  handleCloseButton,
  handlePrimaryButton,
  handleBackButton,
  showPrimaryButton,
  showSecondaryButton,
  showBackButton,
  date,
  keepFormat,
  handleDateChange,
  dateOptions: dateOptionsProp,
  submitting,
  formId,
  message,
  children,
  className='',
  addCustomDate
}) => {
  const [dateOptions, setDateOptions] = useState(dateOptionsProp || getDateOptions() || []);
  const { t } = useTranslation();
  const selectedTheme = useTheme();
  const colorTheme = selectedTheme?.palette?.primary?.main;

  useEffect(() => {
    if (handleDateChange) {
      const dateOptions = addCustomDate? dateOptionsProp?  dateOptionsProp : getDateOptions() : null;
      let selectedDateOption;
      if(date?.fromDate && date?.toDate){
        selectedDateOption = date?.value;
      }else if(keepFormat && date){
        selectedDateOption = {value: date};
      }else{
        selectedDateOption = find(dateOptions, {
          value: moment.isMoment(date)
              ? date.format("YYYY")
              : date
                  ? moment(date).format("YYYY")
                  : moment().format("YYYY")
        }) || dateOptions[0];
      }
      handleDateChange(selectedDateOption);
      setDateOptions(dateOptions);
    }
  }, [dateOptionsProp, addCustomDate]);

  return (
    <>
      <AppBar className={`col-12 custom-app-bar ${className}`} color="inherit">
        <Toolbar>
          {title && 
            <h5 className="col my-2 ellipsis">{title}</h5>
          }
          {addCustomDate && (
              <>
                <Select
                    value={moment.isMoment(date)? moment(date).format("YYYY") : (date?.value || date?.id) || ""}
                    onChange={(event) => handleDateChange(event.target.value)}
                    options={dateOptions}
                    label="Date"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        width: "150px",
                        height: "30px",
                        marginRight: '10px',
                        marginTop: '4px',
                        "& fieldset": { borderColor: colorTheme },
                        "&:hover fieldset": { borderColor: colorTheme },
                        "&.Mui-focused fieldset": { borderColor: colorTheme },
                      },
                      "& .MuiSelect-select": {
                        color: colorTheme,
                      },
                      "& .MuiSvgIcon-root": {
                        color: colorTheme,
                      },
                      "& .MuiInputLabel-root": {
                        color: colorTheme,
                      },

                    }}
                />
              </>
          )}
          {showBackButton &&
            <Button
              className="px-4 me-3"
              onClick={handleBackButton}
              variant="contained"
            >
              Back 
            </Button>
          }
          {showSecondaryButton &&
            <Button
              className="px-4 me-3"
              onClick={handleCloseButton}
              variant="outlined"
            >
              {textSecondary ? (
                textSecondary
                ) : (
                  "Close"
                )}
            </Button>
          }
          {showPrimaryButton && (
            <Button
              type="submit"
              disabled={submitting}
              form={formId}
              className="px-4 ms-0"
              variant="contained"
              onClick={handlePrimaryButton}
            >
              {submitting ? (
                <CircularProgress size={24} color="inherit" />
              ) : textPrimary ? (
                textPrimary
              ) : (
                "Save"
              )}
            </Button>
          )}
          
          {children}
        </Toolbar>
      </AppBar>
      {message && (
        <div className="error-message top-note">
          {t(message.word, message.data)}
        </div>
      )}
    </>
  );
};
export default CustomAppBar;
