import API from "./ApiBase";
import {
    ADD_SCHEDULE_TASKS,
    API_URL,
    DELETE_BENEFIT,
    DELETE_SCHEDULE_TASKS,
    GET_BENEFITS,
    GET_DAYLIGHT_TIME_SAVING,
    GET_SCHEDULE_TASKS,
    GET_SLACK_TOKEN,
    GET_USER_LIST,
    UPDATE_BENEFIT,
    UPDATE_DAYLIGHT_TIME_SAVING,
    UPDATE_SCHEDULE_TASKS,
    GET_FUNCTIONAL_ROLE,
    ADD_FUNCTIONAL_ROLE,
    UPDATE_FUNCTIONAL_ROLE,
    DELETE_FUNCTIONAL_ROLE,
    UPDATE_THEME_COLOR
} from "../constants/APIConst";

export const getSlackToken = (tenantId) => {
    return API.get(GET_SLACK_TOKEN(tenantId));
};
export const getUserList = (slackToken) => {
    return API.get(GET_USER_LIST(slackToken));
};
export const getBenefits = (tenantId, date) => {
    return API.get(GET_BENEFITS(tenantId,date));
};
export const deleteBenefit = (id) => {
    return API.delete(DELETE_BENEFIT(id));
};
export const addBenefit = (payload) => {
    return API.post(UPDATE_BENEFIT, payload);
};
export const updateBenefit = (payload) => {
    return API.put(UPDATE_BENEFIT, payload);
};
export const getScheduleTasks = (tenantId) => {
    return API.get(GET_SCHEDULE_TASKS(tenantId));
};
export const updateScheduleTasks = (payload) => {
    return API.put(UPDATE_SCHEDULE_TASKS, payload);
};
export const addScheduleTasks = (payload,tenantId) => {
    return API.post(ADD_SCHEDULE_TASKS(tenantId), payload);
};
export const deleteScheduleTasks = (tenantId, id) => {
    return API.delete(DELETE_SCHEDULE_TASKS(tenantId, id));
};

export const getFunctionalRole = (tenantId) => {
    return API.get(GET_FUNCTIONAL_ROLE(tenantId));
};
export const updateFunctionalRole = (payload, tenantId, id) => {
    return API.put(UPDATE_FUNCTIONAL_ROLE(tenantId, id), payload);
};
export const addFunctionalRole = (payload, tenantId) => {
    return API.post(ADD_FUNCTIONAL_ROLE(tenantId), payload);
};
export const deleteFunctionalRole = (tenantId, id) => {
    return API.delete(DELETE_FUNCTIONAL_ROLE(tenantId, id));
};
export const updateThemeColor = (tenantId, payload) => {
    return API.put(UPDATE_THEME_COLOR(tenantId), payload);
};
export const updateDaylightTimeSaving = (tenantId, summerTime, winterTime, dst) => {
    return API.put(UPDATE_DAYLIGHT_TIME_SAVING(tenantId, summerTime, winterTime, dst), {})
}
export const getDaylightTimeSaving = (tenantId) => {
    return API.get(GET_DAYLIGHT_TIME_SAVING(tenantId));
}
export const getSystemConfiguration = async (tenantId) => {
    return await API.get(API_URL.SYSTEM_CONFIGURATION_LIST(tenantId));
};
