import React, {useEffect, useState} from 'react';
import WithModals from "hoc/withModals/withModals";
import {Collapse, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Button, Dialog, Menu, MenuItem} from 'components/Mui';
import {KeyboardArrowDownIcon, KeyboardArrowRightIcon, MoreVertIcon} from 'components/Mui/Icons';
import TeamAvatar from "containers/Teams/TeamsAvatar/TeamAvatar";
import {directDescendants} from "utility/apis/TeamsService";
import {EMPLOYEE_DETAILS_MODAL} from "utility/modals/constants";
import EmployeeDetails from "containers/EmployeeDetails/EmployeeDetails";

import "./CollapsibleTable.scss";


function DynamicTreeLeaves({ detailsRow, tableColumns, getTeamName, index, openProfile }) {
    const [isChildOpen, setIsChildOpen] = useState(false);
    const [descendantsData, setDescendantsData] = useState();
    const [position] = useState(index+1);
    const [anchorEl, setAnchorEl] = useState(null);
    const isMenuOpen = Boolean(anchorEl);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const childToggleOpen = (id) => () => {
        setIsChildOpen(prevState => prevState === id ? false : id);
    };

    const directDescendantsData = async (employeeId) => {
        try {
            const response = await directDescendants(employeeId);
            const mappedData = response.data.map(item => ({
                id: item?.id,
                name: item?.name,
                profilePicture: item?.profilePicture,
                reporting: item?.approverEmployeeCount,
                phone: item?.phoneNumber,
                officeLocation: 'Ramallah',
                teamName: getTeamName(item?.id),
                approverEmployeeCount: item?.approverEmployeeCount
            }));

            setDescendantsData(mappedData);
        }catch (e){
            console.log(e)
        }
    }
    useEffect(() => {
        if (isChildOpen === detailsRow?.id) {
            directDescendantsData(isChildOpen);
        }
    }, [isChildOpen]);


    return (
        <>
            <TableRow sx={position % 2 === 0 ? { backgroundColor: "#f5f5f5" } : null}>
                {Object.keys(tableColumns).map((key, index) => (
                    (key === 'name' ?
                            (detailsRow?.approverEmployeeCount >= 1 ?
                                    <TableCell sx={{ width: "20%" }} key={detailsRow[key]} onClick={detailsRow?.approverEmployeeCount >= 1 ? childToggleOpen(detailsRow?.id) : null}>
                                        <div className='d-flex align-items-center'>
                                            <div style={{ paddingLeft: `${position * 15}px` }}></div>
                                            <IconButton
                                                aria-label="expand row"
                                                size="small"
                                            >
                                                {isChildOpen === detailsRow?.id ?
                                                    <KeyboardArrowDownIcon /> :
                                                    <KeyboardArrowRightIcon />}
                                            </IconButton>
                                            <TeamAvatar
                                                profilePicture={detailsRow?.profilePicture} />
                                            <div
                                                className='employee-name'>{detailsRow[key]}</div>
                                        </div>
                                    </TableCell>
                                    :
                                    <TableCell key={detailsRow[key]}  sx={{ width: "20%" }}>
                                        <div className='d-flex align-items-center'>
                                            <div style={{ paddingLeft: `${position * 15}px` }}></div>
                                            <TeamAvatar
                                                profilePicture={detailsRow?.profilePicture}
                                                sx={{ marginLeft: '33px' }} />
                                            <div
                                                className='employee-name'>{detailsRow[key]}</div>
                                        </div>
                                    </TableCell>
                            )
                            :
                            (
                                (key === 'setting' ? (
                                    <TableCell sx={{ width: "20%" }} key={detailsRow[key]}>
                                        <Button
                                            size="small"
                                            onClick={handleMenuOpen}
                                        >
                                            <MoreVertIcon />
                                        </Button>
                                        <Menu
                                            anchorEl={anchorEl}
                                            open={isMenuOpen}
                                            onClose={handleMenuClose}
                                        >
                                            <MenuItem onClick={(event) => {openProfile(detailsRow?.id); handleMenuClose()}}>View Profile</MenuItem>
                                            <MenuItem onClick={handleMenuClose}>Move</MenuItem>
                                        </Menu>
                                    </TableCell>
                                ) : (
                                    <TableCell sx={{ width: "20%" }} key={detailsRow[key]}>{detailsRow[key]}</TableCell>
                                ))
                            )
                    )
                ))}
            </TableRow>
            {isChildOpen === detailsRow?.id &&
                <TableRow>
                    <TableCell colSpan={Object.keys(tableColumns).length + 1}>
                        <Collapse in={isChildOpen} timeout="auto">
                            <Table className={"child-table"}>
                                <TableBody>
                                    {descendantsData?.map((childDetailsRow, index) => (
                                        <DynamicTreeLeaves
                                            key={index}
                                            detailsRow={childDetailsRow}
                                            tableColumns={tableColumns}
                                            getTeamName={getTeamName}
                                            index={position}
                                            openProfile={openProfile}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </Collapse>
                    </TableCell>
                </TableRow>
            }
        </>
    );
}

function Row(props) {
    const { data, tableColumns, isOpen, toggleOpen, getTeamName, openProfile } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const isMenuOpen = Boolean(anchorEl);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <TableRow sx={{backgroundColor: "#f5f5f5"}}>
                {Object.keys(tableColumns).map((key, index) => (
                    <TableCell key={data[key]}>
                        {key === 'name' ? (
                            <div style={{ display: 'flex', alignItems: 'center' }}  onClick={toggleOpen}>
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                >
                                    {isOpen ? <KeyboardArrowDownIcon/> : <KeyboardArrowRightIcon/>}
                                </IconButton>
                                <TeamAvatar profilePicture={data?.profilePicture}/>
                                <div className='employee-name'>{data[key]}</div>
                            </div>
                        ) : (
                            (key === 'setting' ? (
                                <>
                                    <Button
                                        size="small"
                                        className="d-flex justify-content-end"
                                        onClick={handleMenuOpen}
                                    >
                                        <MoreVertIcon />
                                    </Button>
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={isMenuOpen}
                                        onClose={handleMenuClose}
                                    >
                                        <MenuItem onClick={(event) => {openProfile(data?.id); handleMenuClose()}}>View Profile</MenuItem>
                                        <MenuItem onClick={handleMenuClose}>Move</MenuItem>
                                    </Menu>
                                </>
                            ) : (
                                data[key]
                            ))
                        )}
                    </TableCell>
                ))}
            </TableRow>
            <TableRow  className={"child-table"}>
                <TableCell colSpan={Object.keys(tableColumns).length + 1}>
                    <Collapse in={isOpen} timeout="auto">
                        <Table className={"child-table"}>
                            <TableBody>
                                {data?.details?.map((detailsRow, index) => (
                                    <DynamicTreeLeaves
                                        key={index}
                                        detailsRow={detailsRow}
                                        tableColumns={tableColumns}
                                        getTeamName={getTeamName}
                                        index={0}
                                        openProfile={openProfile}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

function CollapsibleTable(props) {
    const { rows, tableColumns ,getTeamName, toggleEmployeeProfileDetails, modals } = props;
    const [isOpen, setIsOpen] = useState({0: true});
    const [selectedEmployeeId, setSelectedEmployeeId] = useState();
    const profileTabs = ["personal_info", "work_schedule_templates", "benefits", "teams", "roles", "time_off", "wfh", "customize_time_off_policy", "attendance_report"];
    const openProfile = (employeeId)=> {
        setSelectedEmployeeId(employeeId);
        toggleEmployeeProfileDetails();
    }
    const toggleOpen = (index) => {
        setIsOpen(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow style={{margin: '10px'}}>
                        {Object.keys(tableColumns).map((key, index) => (
                            <TableCell sx={{width: "20%"}} key={key}>{tableColumns[key]}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!!rows?.length && rows.map((row, index) => (
                        <React.Fragment key={index}>
                            <Row
                                tableColumns={tableColumns}
                                data={row}
                                isOpen={isOpen[index]}
                                toggleOpen={() => toggleOpen(index)}
                                getTeamName={getTeamName}
                                openProfile={openProfile}
                            />
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
            <Dialog className='details-dialog' fullScreen open={modals[EMPLOYEE_DETAILS_MODAL]} toggle={toggleEmployeeProfileDetails}>
                <EmployeeDetails handleCloseButton={toggleEmployeeProfileDetails} employeeId={selectedEmployeeId} profileTabs={profileTabs}></EmployeeDetails>
            </Dialog>
        </>
    );

}
const modalsProps = {
    toggleEmployeeProfileDetails: EMPLOYEE_DETAILS_MODAL
};

export default WithModals(CollapsibleTable, modalsProps);
