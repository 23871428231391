import React, {useState} from "react";
import { FileUploader as DragDropFiles } from "react-drag-drop-files";
import { CloudUploadIcon } from "components/Mui/Icons";
import { Attachment as AttachmentIcon } from "@mui/icons-material";
import {Button} from "components/Mui";

import "./FileUploader.scss";

function FileUploader(props) {
    const { link, label, handleChange,className, ...rest } = props;
    const [ file, setFile ] = useState();

    const handleFileChange = (files) => {
        setFile(files);
        handleChange(files);
    }
    const children = (
        <>
            {link?
                 <Button><AttachmentIcon className='attachment-icon'/>Upload Attachment</Button>
            :
                <div className="file-uploader_children">
                    {!!file ?
                    <img className='logo' src={URL.createObjectURL(file)} alt={file.name} />
                    :
                    <div>
                        <CloudUploadIcon />
                        <div>
                            {label}
                        </div>
                    </div>
                    }
                </div>
            }
        </>
    );

    return (
        <>
            {link ?
                <div className={`${className ?? ''}`}>
                    <DragDropFiles
                        name="file"
                        children={children}
                        handleChange={handleFileChange}
                        {...rest}
                    />
                </div>
                :
                <div className={`file-uploader_container ${className ?? ''}`}>
                    <DragDropFiles
                        name="file"
                        children={children}
                        handleChange={handleFileChange}
                        {...rest}
                    />
                </div>
            }
        </>
    );
}

export default FileUploader;
