import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import moment from "moment/moment";
import WithModals from "hoc/withModals/withModals";
import {addScheduleTasks, deleteScheduleTasks, updateScheduleTasks} from "utility/apis/SettingService";
import {Button, Checkbox, Dialog, DialogActions, CircularProgress, FormControlLabel, TimePicker, Grid} from "components/Mui";
import { dayOptions, daysOfMonthOptions, monthOptions, periodUnitOptions, scheduleTasksTypes, timeZoneOptions } from "utility/constants/data";
import {TIME_24_HOUR_FORMAT, TIME_FORMAT} from "utility/helpers/metaData";
import {CONFIRMATION_MODAL} from "utility/modals/constants";
import CheckboxesTags from "components/CheckboxesTags/CheckboxesTags";
import {getErrorMessage} from "utility/helpers/utils";

import "./ScheduleForm.scss"
import {toast} from "react-toastify";

const ScheduleForm = (props) => {
    const {task, tenantId, getSchedule, colorTheme, toggleScheduleFormModal, modals, toggleConfirmationModal} = props;
    const [period, setPeriod] = useState(task?.every || periodUnitOptions[0]);
    const [type, setType] = useState(task?.type || scheduleTasksTypes[0]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit'
    });

    const handleSave = async (payload)=> {
        if (isSubmitting) return;
        setIsSubmitting(true);

        const [hour, minute] = moment(payload.time, TIME_FORMAT).format(TIME_24_HOUR_FORMAT).split(':');
        payload['hour'] = hour;
        payload['minute'] = minute;
        payload['tenantId'] =  tenantId;
        payload.month = payload?.month? monthOptions.find(item => item.label === payload?.month).value : undefined;
        payload.dayMonth = payload?.dayMonth? daysOfMonthOptions.find(item => item.label === payload?.dayMonth).value : undefined;
        payload.dayWeek = payload?.dayWeek? dayOptions.find(item => item.label === payload?.dayWeek).value : undefined;
        payload['type'] = type.label && type.value ? type?.value : type;
        payload['id'] = task?.id;
        payload['disabled'] = !payload?.disabled;
        payload['every'] = period.label && period.value ? period.value : period;
        try {

            task? await updateScheduleTasks(payload) : await addScheduleTasks(payload, tenantId);
            if (!task) {
                toggleScheduleFormModal();
            }
            await getSchedule();
            toast.success(task ? 'Updated Successfully' : 'Saved Successfully');
        } catch (e) {
            toast.error(getErrorMessage(e));
            setIsSubmitting(false);
        }
    };
    const handleDelete = async ()=> {
        if (isSubmitting) return;
        setIsSubmitting(true);
        try {
            await deleteScheduleTasks(tenantId, task?.id);
            await getSchedule();
            toggleConfirmationModal();
            toast.success("Deleted Successfully");
        } catch (e) {
            toast.error(getErrorMessage(e));
            setIsSubmitting(false);
        }
    };
    return (
        <div className="c-schedule-form">
            <form className="m-3">
                <Grid container spacing={{md: 1, xs: 0}}>
                    <Grid item md={5}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    inputRef={register}
                                    name="disabled"
                                    defaultChecked={task ? !task.disabled : true}
                                />
                            }
                            style={{ paddingBottom: 12, paddingLeft: 0 }}
                            label="Enable Task"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={{md: 1, xs: 0}}>
                    {!task &&
                        <Grid item md={12}>
                            <CheckboxesTags
                                id="type"
                                label="Scheduled Task Type"
                                name="type"
                                labelField='label'
                                fullWidth={true}
                                options={scheduleTasksTypes}
                                onChange={(e, value) => setType(value?.value)}
                                defaultValue={scheduleTasksTypes[0]}
                                inputRef={register({
                                    required: "This field is required"
                                })}
                                errors={errors}
                            />
                        </Grid>
                    }
                </Grid>
                <Grid container spacing={{md: 1, xs: 1}}>
                    <Grid item md={!task? 12 : 5} xs={12}>
                        <CheckboxesTags
                            id="every"
                            label="Run Every"
                            name="every"
                            labelField='label'
                            fullWidth={true}
                            options={periodUnitOptions}
                            onChange={(e, value) => setPeriod(value?.value)}
                            defaultValue={task?.every ? {label: periodUnitOptions?.find((option) => option.value === task?.every)?.label} : periodUnitOptions[0]}
                            inputRef={register({
                                required: "This field is required"
                            })}
                            errors={errors}
                        />
                    </Grid>
                </Grid>
                {period &&
                    <Grid container spacing={{md: 1, xs: 1}}>
                        <Grid item md={!task? 12 : 3} xs={12}>
                            <TimePicker
                                name={`time`}
                                label="At Time"
                                inputRef={register({
                                    required: "This field is required"
                                })}
                                format={TIME_FORMAT}
                                defaultValue={task? moment({ hour: task?.hour, minute: task?.minute }) : moment()}
                                className="w-100"
                            />
                        </Grid>
                        {(period === "EVERY_YEAR") &&
                            <Grid item md={!task? 12 : 3} xs={12}>
                                <CheckboxesTags
                                    id="month"
                                    label="Month"
                                    name="month"
                                    labelField='label'
                                    fullWidth={true}
                                    options={monthOptions}
                                    defaultValue={task?.month ? {label: monthOptions.find(item => item.value === task?.month).label} : monthOptions[0]}
                                    inputRef={register({
                                        required: "This field is required"
                                    })}
                                    errors={errors}
                                />
                            </Grid>
                        }
                        {(period === "EVERY_YEAR" || period === "EVERY_MONTH") &&
                            <Grid item md={!task? 12 : 3} xs={12}>
                                <CheckboxesTags
                                    id="dayMonth"
                                    label="Day Of Month"
                                    name="dayMonth"
                                    labelField='label'
                                    fullWidth={true}
                                    options={daysOfMonthOptions}
                                    defaultValue={task?.dayMonth ? {label: daysOfMonthOptions.find(item => item.value === task?.dayMonth).label} : daysOfMonthOptions[0]}
                                    inputRef={register({
                                        required: "This field is required"
                                    })}
                                    errors={errors}
                                />
                            </Grid>
                        }
                        {(period === "EVERY_WEEK") &&
                            <Grid item md={!task? 12 : 3} xs={12}>
                                <CheckboxesTags
                                    label="Day Of Week"
                                    name="dayWeek"
                                    labelField='label'
                                    fullWidth={true}
                                    options={dayOptions}
                                    defaultValue={task?.dayWeek ? {label: dayOptions.find(item => item.value === task?.dayWeek).label} : dayOptions[0]}
                                    id="dayWeek"
                                    inputRef={register({
                                        required: "This field is required"
                                    })}
                                    errors={errors}
                                />
                            </Grid>
                        }
                        <Grid item md={!task? 12 : 3} xs={12}>
                            <CheckboxesTags
                                id="timeZone"
                                label="TimeZone"
                                name="timeZone"
                                labelField='value'
                                fullWidth={true}
                                options={timeZoneOptions}
                                defaultValue={task?.timeZone ? {value: task?.timeZone} : {value: "Asia/Jerusalem"}}
                                inputRef={register({
                                    required: "This field is required"
                                })}
                                errors={errors}
                            />
                        </Grid>
                    </Grid>
                }
                <Grid item xs={12} className="my-3">
                    <Button
                        type="button"
                        variant="contained"
                        className="float-end my-3"
                        onClick={handleSubmit(handleSave)}
                    >
                        {isSubmitting ? (
                            <CircularProgress size={24} color="inherit" />
                        ) :
                            "Save"
                        }
                    </Button>
                    {!task ?
                        <Button
                            className="float-end m-3"
                            variant="outlined"
                            onClick={() => {
                                toggleScheduleFormModal();
                            }}
                        >
                            Cancel
                        </Button>
                        :
                        <Button
                            className="float-end m-3 bg-danger"
                            color="secondary"
                            variant="outlined"
                            onClick={() => {
                                toggleConfirmationModal();
                            }}
                        >
                            Delete
                        </Button>
                    }
                </Grid>
                <Dialog className='confirmation-dialog' open={modals[CONFIRMATION_MODAL]} toggle={toggleConfirmationModal} >
                    <>
                        <div className="m-4" style={{color: colorTheme}}>
                            Are you sure you want to delete this ?
                        </div>
                        <div>
                            <DialogActions className="p-3 mt-3">
                                <Button className="px-4 me-3" variant="outlined"
                                        onClick={toggleConfirmationModal}>Cancel</Button>
                                <Button className="px-4 bg-danger" color="secondary" variant="outlined" onClick={() => handleDelete()}>
                                    {isSubmitting ? (
                                            <CircularProgress size={24} color="inherit" />
                                        ) :
                                        "Delete"
                                    }
                                </Button>
                            </DialogActions>
                        </div>
                    </>
                </Dialog>
            </form>
        </div>
    )
}
const modalsProps = {
    toggleConfirmationModal: CONFIRMATION_MODAL
};

export default WithModals(ScheduleForm, modalsProps);
