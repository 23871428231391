import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { styled } from '@mui/material/styles';
import {Button, useMediaQuery} from "components/Mui";
import MuiDrawer from '@mui/material/Drawer'
import { ArrowBackIcon, ArrowForwardIcon } from "components/Mui/Icons";

import "./LeftDrawer.scss";

const drawerWidth = 260;
const closeDrawerWidth = 72;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    width: closeDrawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden'
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: open ? drawerWidth : closeDrawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

function LeftDrawer({ children, onDrawerChange, keepOpen=false, className }) {
    const [open, setOpen] = useState(true);
    const isDesktop = useMediaQuery("(min-width: 768px)");

    useEffect(() => {
        if (!open && isDesktop) {
            handleDrawerChange();
        }
    }, [isDesktop]);

    const handleDrawerChange = () => {
        const toggleOpen = !open;
        if (onDrawerChange) {
            onDrawerChange(toggleOpen);
        }
        setOpen(toggleOpen);
    };

    return (
        <div className={classNames("mini-drawer-container")}>
            <Drawer className={classNames('c-drawer', className)} variant="permanent" open={open}>
                {children}
                {!keepOpen &&
                    <Button className="control-button" onClick={handleDrawerChange}>
                        {open ?
                            <ArrowBackIcon/>
                            :
                            <ArrowForwardIcon/>
                        }
                    </Button>
                }
            </Drawer>
        </div>
    );
}
export default LeftDrawer;