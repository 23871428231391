import React, {Suspense} from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import PublicLayout from "layouts/PublicLayout/PublicLayout";
import PrivateLayout from "layouts/PrivateLayout/PrivateLayout";
import ManagerLayout from "layouts/ManagerLayout/ManagerLayout";
import HRAssistantLayout from "layouts/HRAssistantLayout/HRAssistantLayout";
import AdminLayout from "layouts/AdminLayout/AdminLayout";
import ReportsLayout from "./layouts/ReportsLayout/ReportsLayout";
import Loading from "components/Loading/Loading";

const NotFound = React.lazy(() => import("./containers/NotFound/NotFound"));
const ComingSoon = React.lazy(() =>
  import("./containers/ComingSoon/ComingSoon")
);
const Login = React.lazy(() => import("./containers/Login/Login"));
const SignUp = React.lazy(() => import("./containers/SignUp/SignUp"));

const Home = React.lazy(() => import("./containers/Home/Home"));
const Holidays = React.lazy(() => import("./containers/Holidays/Holidays"));
const Teams = React.lazy(() => import("./containers/Teams/Teams"));
const WorkScheduleTemplates = React.lazy(() =>
  import("./containers/WorkScheduleTemplate/WorkScheduleTemplates")
);
const Pending = React.lazy(() => import("./containers/Pending/Pending"));
const Employees = React.lazy(() => import("./containers/Employees/Employees"));
// const Admin = React.lazy(() => import("./containers/Admin/Admin"));
const Customers = React.lazy(() => import("./containers/Admin/Customers/Customers"));
const SystemLogs = React.lazy(() => import("./containers/Admin/SystemLogs/SystemLogs"));
const Tenants = React.lazy(() => import("./containers/Tenants/Tenants"));
const EmployeeClockRecords = React.lazy(() => import("./containers/EmployeeClockRecords/EmployeeClockRecords"));
const Reports = React.lazy(() => import("./containers/Reports/Reports"));

const ResetPassword = React.lazy(() => import('./containers/ResetPassword/ResetPassword'));

import { ToastContainer } from 'react-toastify';

const App = () => {

  return (
    <Suspense fallback={<Loading />}>
      <BrowserRouter>
        <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover
            theme="colored"
        />
        <Switch>
          <PublicLayout
            enableRedirect={true}
            path="/login"
            component={Login}
          ></PublicLayout>
          <PublicLayout
            exact
            enableRedirect={true}
            path="/sign-up"
            component={SignUp}
          ></PublicLayout>
          <PublicLayout exact path="/reset-password" hideAllNav={true} component={ResetPassword}></PublicLayout>
          <PublicLayout exact path="/" component={Home} />
          <PublicLayout exact path="/home" component={Home} />
          <AdminLayout exact path="/customers" component={Customers} />
          <AdminLayout exact path="/system-logs" component={SystemLogs} />
          <ManagerLayout exact path="/pending" component={Pending}/>
          <ManagerLayout exact path="/reports" component={Reports} />
          <HRAssistantLayout exact path="/templates" component={WorkScheduleTemplates} />
          <HRAssistantLayout exact path="/holidays" component={Holidays}/>
          <HRAssistantLayout exact path="/teams" component={Teams} />
          <PrivateLayout exact path="/employees" component={Employees}/>
          <PrivateLayout exact path="/tenants" hideNav={true} component={Tenants} />


          <PublicLayout exact path="/coming-soon" component={ComingSoon} />
          <ReportsLayout exact path="/employee_clock_records" component={EmployeeClockRecords}/>
          <PublicLayout className="logged-in" component={NotFound} />
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
};

export default App;
