import React, {useEffect, useState} from "react";
import { Grid, Select } from "components/Mui";
import { Controller } from "react-hook-form";
import { workingDaysOption } from "utility/constants/data";

const WorkingDays = (props) => {
  const { dataFromServer, typeSelected, control, disableAllField, setValue } = props;

    const [updatedWorkingDays, setUpdatedWorkingDays] = useState(workingDaysOption);

    useEffect(() => {
        const newWorkingDays = workingDaysOption.map((day) => {
            if (typeSelected === 'Custom') {
                return day.value === "Custom"
                    ? { ...day, disabled: false }
                    : { ...day, disabled: true };
            } else {
                return day.value !== "Custom"
                    ? { ...day, disabled: false }
                    : { ...day, disabled: true };
            }
        });
        typeSelected === 'Custom'? setValue( "workingDays",updatedWorkingDays[3]?.value) : setValue( "workingDays", workingDaysOption?.find(
            (item) => dataFromServer? dataFromServer?.workingDays === 'Custom'? null : dataFromServer?.workingDays === item?.ServerLabel : updatedWorkingDays[0]?.value
        )?.value);

        setUpdatedWorkingDays(newWorkingDays);
    }, [typeSelected]);



  return (
    <>
      <Grid className="mx-1" container spacing={{ md: 1, xs: 0 }}>
        <Grid item xs={12} md={3}>
          <Controller
            as={
              <Select label="Working days" options={updatedWorkingDays} />
            }
            id="workingDays"
            name="workingDays"
            className="w-100"
            disabled={disableAllField}
            control={control}
            defaultValue={updatedWorkingDays[0]?.value}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default WorkingDays;
