import React, { useState, useEffect } from 'react';
import {map} from "lodash";
import {getDurationText, formatTime, formatDate, mapVacationType, getErrorMessage} from "utility/helpers/utils";
import { API_URL } from "utility/constants/APIConst";
import { TABLES_NAMES } from "utility/constants/constants";
import {Button, DataGridTable, DialogActions, GridActionsCellItem, Dialog, TimePicker, Tooltip} from "components/Mui";
import {ArrowUpwardIcon, ArrowDownwardIcon, EditIcon} from 'components/Mui/Icons';
import {CONFLICTS_EDIT_RECORD_MODAL, TIME_OFF_REQUEST_MODAL} from "utility/modals/constants";
import WithModals from "hoc/withModals/withModals";
import {DATE_FORMAT, FULL_DATE_FORMAT, FULL_DATE_ISO_FORMAT, TIME_FORMAT} from "utility/helpers/metaData";
import moment from "moment";
import {checkConflicts, updateAttendanceRecord} from "utility/apis/EmployeeService";
import {toast} from "react-toastify";

import "./AttendanceReportAllRecords.scss";

const AttendanceReportAllRecords = (props) => {
    const { attendanceReportData, employeeId, date, message, fromReportsLayout, colorTheme, modals, toggleTimeOffEditModal, toggleConflictsEditRecordModal} = props;
    const [ attendanceReportListUrl, setAttendanceReportListUrl ] = useState(undefined);
    const [ selectedEditRow, setSelectedEditRow ] = useState();
    const [ refetchData, setRefetchData ] = useState(false);
    const [checkIn, setCheckIn] = useState(moment(selectedEditRow?.checkIn, TIME_FORMAT));
    const [checkOut, setCheckOut] = useState(moment(selectedEditRow?.checkOut, TIME_FORMAT));
    const [conflictList, setConflictList] = useState();
    const [updatedAttendanceList, setUpdatedAttendanceList] = useState();
    const [haveConflicts, setHaveConflicts] = useState(false);
    const [haveDeductions, setHaveDeductions] = useState(false);
    const [updateAttendancePayload, setUpdateAttendancePayload] = useState();
    const [isSaveDisabled, setIsSaveDisabled] = useState(false);
    const [isInProgress, setIsInProgress] = useState(false);

    useEffect(() => {
        if (date && !attendanceReportData){
            const offset = moment().utcOffset() * 60 * 1000;
            const toDate = date?.toDate + offset;
            const fromDate = date.fromDate + offset;
            setAttendanceReportListUrl(API_URL.ATTENDANCE_REPORT_LIST(employeeId,  fromDate, toDate));
        }
    }, [date]);

    const formatAdjustedDate = (date) => moment(date).subtract(4, "hours").format(FULL_DATE_FORMAT);

    const formatDataTable = (data) => {
        return map(data, ({ date, checkInMargin, checkOutMargin, workingours, totalMargin, attendance, vacations }, index) => {
            let checkIn;
            let checkOut;
            if (attendance?.length) {
                checkIn= formatTime(attendance[0].checkIn);
                checkOut= formatTime(attendance[0].checkOut);
            }

            return {
                id: attendance[0]?.id || index,
                date: formatDate(date),
                checkIn,
                checkOut,
                workingHours: getDurationText(workingours * 60 * 60 * 1000, true),
                totalMargin: getDurationText(totalMargin, true),
                checkInMargin: getDurationText(checkInMargin, true),
                totalMarginStatus: totalMargin,
                checkInMarginStatus: checkInMargin,
                checkOutMarginStatus: checkOutMargin,
                checkOutMargin: getDurationText(checkOutMargin, true),
                notes: vacations?.map((el) =>
                    `${mapVacationType(el.type)} (${el?.days > 1 ? `${el?.days.toFixed(2)} days` : `${parseFloat(el?.hours.toFixed(3)) +
                    (parseFloat(el?.hours.toFixed(3)) > 1 ? " hours" : " hour")}`}
                     from ${moment.utc(el?.fromDate)?.format(FULL_DATE_FORMAT)} to ${moment.utc(el?.toDate)?.format(FULL_DATE_FORMAT)})`
                )
            }
        });
    }

    const columnDefs = [
        {
            headerName: 'Day/Date',
            field: 'date',
            flex: 1
        },
        {
            headerName: 'Check In',
            field: 'checkIn',
            sortable: false,
            flex: 1.8,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <div className="ellipsis">
                        <span style={{minWidth: "60px"}}>{params.value}</span>
                        <span className={`ms-2 ${params.row.checkInMarginStatus > 0 ? "text-danger" : "text-success"}`}>
                            {params.row.checkInMargin && params.row.checkInMarginStatus > 0 &&
                                <ArrowDownwardIcon fontSize="small" className="text-danger" />
                            }
                            {params.row.checkInMargin && params.row.checkInMarginStatus < 0 &&
                                <ArrowUpwardIcon fontSize="small" className="text-success" />
                            }
                            {params.row.checkInMargin}
                        </span>
                    </div>
                </Tooltip>
            ),
        },
        {
            headerName: 'Check Out',
            field: 'checkOut',
            flex: 1.8,
            sortable: false,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <div className="ellipsis">
                        <span style={{minWidth: "60px"}}>{params.value}</span>
                        <span className={`ms-2 ${params.row.checkOutMarginStatus >= 0 ? "text-success" : "text-danger"}`}>
                            {params.row.checkOutMargin && params.row.checkOutMarginStatus > 0 &&
                                <ArrowUpwardIcon className="text-success" />
                            }
                            {params.row.checkOutMargin && params.row.checkOutMarginStatus < 0 &&
                                <ArrowDownwardIcon className="text-danger" />
                            }
                            {params.row.checkOutMargin}
                        </span>
                    </div>
                </Tooltip>
            ),
        },
        {
            headerName: 'Work hours',
            field: 'workingHours',
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <div className="ellipsis">
                        <span style={{minWidth: "85px",display: "inline-block"}}>{params.value? params.value : '--'}</span>
                        <span className={`ms-2 ${params.row.totalMarginStatus >= 0 ? "text-success" : "text-danger"}`}>
                            {params.row.totalMargin && params.row.totalMarginStatus > 0 &&
                                <ArrowUpwardIcon fontSize="small" className="text-success" />
                            }
                            {params.row.totalMargin && params.row.totalMarginStatus < 0 &&
                                <ArrowDownwardIcon fontSize="small" className="text-danger" />
                            }
                            {params.row.totalMargin}
                        </span>
                    </div>
                </Tooltip>
            ),
            flex: 2,
        },
        {
            headerName: 'Notes',
            field: 'notes',
            flex: 2,
            renderCell: (params) => (
                <div className="ellipsis">
                    {params.value?.map((text) => (
                        <Tooltip title={text}>
                            <div className="ellipsis">{text}</div>
                        </Tooltip>
                    ))}
                </div>
            ),
        },
        employeeId && {
            headerName: 'Actions',
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params) => [
                <GridActionsCellItem showInMenu icon={<EditIcon />} onClick={(event) => handleEditClick(event, params)} label="Edit" />,
            ]
        }
    ];
    const conflictColumnDefs = [

        {
            headerName: 'Name',
            field: 'conflictName',
            flex: 1,
        },
        {
            headerName: 'From Date',
            field: 'conflictFromDate',
            renderCell: (params) => (
                <div className="ellipsis">
                    <Tooltip title={moment(params.value).format(FULL_DATE_FORMAT)}>
                        {moment.utc(params.value).format(FULL_DATE_FORMAT)}
                    </Tooltip>
                </div>
            ),
            flex: 1,
        },
        {
            headerName: 'To Date',
            field: 'conflictToDate',
            renderCell: (params) => (
                <div className="ellipsis">
                    <Tooltip title={moment(params.value).format(FULL_DATE_FORMAT)}>
                        {moment.utc(params.value).format(FULL_DATE_FORMAT)}
                    </Tooltip>
                </div>
            ),
            flex: 1,
        },
        {
            headerName: 'Type',
            field: 'type',
            flex: 1,
        },
    ];
    const updateAttendanceDefs = [

        {
            headerName: 'Date',
            field: 'utccheckIn',
            flex: 1,
            renderCell: (params) => (
                <div className="ellipsis">
                    <Tooltip title={moment(params.value).format(DATE_FORMAT)}>
                        {moment(params.value).format(DATE_FORMAT)}
                    </Tooltip>
                </div>
            ),
        },
        {
            headerName: 'Clock In',
            field: 'checkIn',
            flex: 1,
            renderCell: () => (
                <div className="ellipsis">
                    <Tooltip title={checkIn?.format(TIME_FORMAT)}>
                        {checkIn?.format(TIME_FORMAT)}
                    </Tooltip>
                </div>
            ),
        },
        {
            headerName: 'Clock Out',
            field: 'checkOut',
            flex: 1,
            renderCell: () => (
                <div className="ellipsis">
                    <Tooltip title={checkOut?.format(TIME_FORMAT)}>
                        {checkOut?.format(TIME_FORMAT)}
                    </Tooltip>
                </div>
            ),
        },
        {
            headerName: 'Work hours',
            field: 'period',
            renderCell: (params) => (
                <div className="ellipsis">
                    {formattedPeriod(params?.row?.attendancePeriod)}
                </div>
            ),
            flex: 1,
        },
    ];
    const formattedPeriod = (attendancePeriod) => {
        const attendancePeriodInHours = attendancePeriod;
        const hours = Math.floor(attendancePeriodInHours);
        const minutes = Math.round((attendancePeriodInHours % 1) * 60);
        let formattedPeriod = "--";
        if (hours !== 0 && minutes !== 0) {
            formattedPeriod = (hours === 1 ? hours + " hr " : hours + " hrs ") + (minutes >= 1 ? minutes + " min" : "");
        } else if (hours !== 0 && minutes === 0) {
            formattedPeriod = (hours === 1 ? hours + " hr" : hours + " hrs");
        } else if (hours === 0 && minutes !== 0) {
            formattedPeriod = minutes + " min";
        }
        return formattedPeriod;
    };


    const handleEditClick = (event, params) => {
        setSelectedEditRow(params.row);
        toggleTimeOffEditModal();
    }
    const handleTimeUpdate = async (event, data) => {
        setIsInProgress(true);
        event.preventDefault();
        try {
            const {  checkIn, checkOut } = data;
            const formattedCheckIn = checkIn.format(FULL_DATE_ISO_FORMAT) + "Z";
            const formattedCheckOut = checkOut.format(FULL_DATE_ISO_FORMAT) + "Z";

            const payload = {
                clockInDate : formattedCheckIn,
                clockOutDate : formattedCheckOut,
                onlyEditClockIn : false
            };
            setUpdateAttendancePayload(payload)
            const response = await checkConflicts(selectedEditRow?.id, payload);
            const hasDeduction = response?.data?.some(item => item.type === "DEDUCTION");
            setHaveDeductions(hasDeduction)
            setConflictList(response?.data);
            if (response?.data?.length === 0){
                payload['dryRun']= true;
                const response = await updateAttendanceRecord(selectedEditRow?.id, payload);
                setUpdatedAttendanceList(response?.data?.updatedAttendance);
                setHaveConflicts(false);
                toggleConflictsEditRecordModal();

            }else{
                setHaveConflicts(true);
                toggleConflictsEditRecordModal();
            }
        } catch (e) {
            toast.error(getErrorMessage(e));
        }finally {
            setTimeout(() => {
                setIsInProgress(false);
            }, 2000);
        }
    }

    const handleUpdateAttendance = async (event) => {
        event.preventDefault()
        setIsSaveDisabled(true);
        setRefetchData(true);
        try {
            await updateAttendanceRecord(selectedEditRow?.id, {...updateAttendancePayload, dryRun: false});
            toggleConflictsEditRecordModal();
            toggleTimeOffEditModal();
            toast.success("Updated Successfully");
        } catch (e) {
            toast.error(getErrorMessage(e));
        }finally {
            setRefetchData(false);
            setTimeout(() => {
                setIsSaveDisabled(false);
            }, 2000);
        }
    }

    useEffect(() => {
        if (selectedEditRow) {
            const checkInDateTime = `${selectedEditRow?.date} ${selectedEditRow?.checkIn}`;
            const checkOutDateTime = `${selectedEditRow?.date} ${selectedEditRow?.checkOut}`;

            setCheckIn(moment(checkInDateTime));
            setCheckOut(moment(checkOutDateTime));
        }
    }, [selectedEditRow]);


    return (
        <div id="all-records" className={!fromReportsLayout? "c-attendance-report-all-record" : null}>
            <div className="container-fluid">
                <div style={{height: `calc(100vh - ${message ? 155 : 120}px)`}} className="row">
                    {attendanceReportData?
                        <DataGridTable
                            className="col-12"
                            key={TABLES_NAMES.ATTENDANCE_REPORT_LIST}
                            formatDataTable={formatDataTable}
                            columns={columnDefs}
                            dataTable={attendanceReportData}
                            isExport={!fromReportsLayout}
                        />
                    :
                        <DataGridTable
                            className="col-12"
                            key={TABLES_NAMES.ATTENDANCE_REPORT_LIST}
                            formatDataTable={formatDataTable}
                            columns={columnDefs}
                            reload={refetchData}
                            URL={attendanceReportListUrl}
                            isExport={!fromReportsLayout}
                        />
                    }
                </div>
            </div>
            <Dialog className='time-off-request-dialog' open={modals[TIME_OFF_REQUEST_MODAL]} toggle={toggleTimeOffEditModal}>
                <form onSubmit={(event) => handleTimeUpdate(event, { checkIn, checkOut })}>
                    <div className="m-4">
                        <h6 className="mb-4" style={{color: colorTheme}}>Edit Record Time</h6>
                        <div className="column">
                            <TimePicker
                                name={`checkIn`}
                                label="Check In"
                                format={TIME_FORMAT}
                                className="mx-1"
                                value={checkIn}
                                onChange={(newValue) => setCheckIn(newValue)}
                            />
                            <TimePicker
                                name={`checkOut`}
                                label="Check Out"
                                format={TIME_FORMAT}
                                value={checkOut}
                                onChange={(newValue) => {
                                    const updatedCheckOut = moment(newValue).set({
                                        year: moment(checkIn).year(),
                                        month: moment(checkIn).month(),
                                        date: moment(checkIn).date(),
                                    });
                                    setCheckOut(updatedCheckOut);
                                }}
                                minTime={checkIn}
                            />
                        </div>
                    </div>
                    <div>
                        <DialogActions className="p-3 mt-3">
                            <Button className="px-4 me-3" variant="outlined" onClick={toggleTimeOffEditModal} disabled={isInProgress}>Cancel</Button>
                            <Button className="px-4" variant="contained" type="submit" disabled={isInProgress}>Next</Button>
                        </DialogActions>
                    </div>
                </form>
            </Dialog>
            <Dialog
                className='conflict-table'
                open={modals[CONFLICTS_EDIT_RECORD_MODAL]}
                toggle={toggleConflictsEditRecordModal}
                sx={{
                '& .MuiPaper-root': {
                    width: '1000px',
                    maxWidth: '1000px',
                },}}
            >
                <form>
                    <div className="m-4">
                        {haveConflicts? <h6 className="mb-4 text-danger">Please address the following conflicts:</h6> : <></>}
                    </div>
                    {haveConflicts?
                        <DataGridTable
                            key={TABLES_NAMES.CONFLICT_LIST}
                            columns={conflictColumnDefs}
                            dataTable={conflictList}
                            sx={{
                                width: '975px',
                                height: '400px',
                                margin: '10px',
                            }}
                        />
                    :
                        <DataGridTable
                            key={TABLES_NAMES.UPDATED_ATTENDANCE_LIST}
                            columns={updateAttendanceDefs}
                            dataTable={updatedAttendanceList ? [updatedAttendanceList] : []}
                            reloadWithoutURL={refetchData}
                            sx={{
                                width: '975px',
                                height: '400px',
                                margin: '10px',
                            }}
                        />
                    }
                    <div>
                        <DialogActions className="p-3 mt-3">
                            <Button className="px-4 me-3" variant="text" onClick={toggleConflictsEditRecordModal} disabled={isSaveDisabled}>Cancel</Button>
                            <Button className="px-4 me-3" variant="outlined" onClick={toggleConflictsEditRecordModal} disabled={isSaveDisabled}>Back</Button>
                            <Button
                                className="px-4"
                                variant="contained"
                                type="submit"
                                disabled={haveDeductions || isSaveDisabled}
                                onClick={(event) => handleUpdateAttendance(event)}
                            >Save</Button>
                        </DialogActions>
                    </div>
                </form>
            </Dialog>
        </div>
    );
};
const modalsProps = {
    toggleTimeOffEditModal: TIME_OFF_REQUEST_MODAL,
    toggleConflictsEditRecordModal: CONFLICTS_EDIT_RECORD_MODAL
};
export default WithModals(AttendanceReportAllRecords, modalsProps);
