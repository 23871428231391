import React, {useEffect, useState} from 'react';
import {Controller, useFieldArray, useForm} from "react-hook-form";
import {DISABLE_POLICY_MODAL} from "utility/modals/constants";
import withModals from "hoc/withModals/withModals";
import {Button, Grid, TextField, Dialog, DialogActions, Divider, Select, Link, CircularProgress} from "components/Mui";
import {enablePolicies, updatePolicy} from "utility/apis/SetupGuideService";
import {AddIcon, ClearIcon, DoneIcon, EditIcon} from "components/Mui/Icons";
import {bereavementRelationOptions, daysPerYearArr, logoFileTypes} from "utility/constants/data";
import FileUploader from "components/FileUploader/FileUploader";
import {getErrorMessage} from "utility/helpers/utils";

import "./BereavementLeavePolicy.scss"
import {toast} from "react-toastify";

const BereavementLeavePolicy = (props) => {
    const {bereavementAndFamilyDeathPolicy, tenantId, setInProgress,  refreshPolicyData, colorTheme, modals, toggleDisablePolicyModal} = props;
    const [link, setLink] = useState(bereavementAndFamilyDeathPolicy?.attachment);
    const [editLink, setEditLink] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { register, control, reset, handleSubmit, errors } = useForm({
        defaultValues: {},
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "periodList",
    });
    useEffect(() => {
        reset(bereavementAndFamilyDeathPolicy);
    }, [bereavementAndFamilyDeathPolicy]);
    const handleSave = async (data) => {
        setInProgress(true);
        data['attachment'] = link.name || link;
        try {
            await updatePolicy("bereavementAndFamilyDeathPolicy", bereavementAndFamilyDeathPolicy?.id, tenantId, data);
            refreshPolicyData();
            toast.success("Updated Successfully");
        } catch (e) {
            toast.error(getErrorMessage(e));
            setInProgress(false);
        }
    };
    const handleDisable = async () => {
        if (isSubmitting) return;
        setIsSubmitting(true);
        const payload = [{
            policyType: bereavementAndFamilyDeathPolicy?.type,
            enable: false
        }];
        try {
            await enablePolicies(tenantId, payload);
            refreshPolicyData();
            toggleDisablePolicyModal();
            toast.success("Disabled Successfully");
        } catch (e) {
            toast.error(getErrorMessage(e));
            setIsSubmitting(false);
        }
    };
    return (
        <div id="BereavementLeavePolicy" className="c-bereavement-leave-policy px-4 mt-3">
            <form id="time-off-policy" onSubmit={handleSubmit(handleSave)}>
                <Grid item xs={12}>
                    <div className="fs-5 fw-normal mb-3">Bereavement amount</div>
                </Grid>
                {fields?.map((item, index) => (
                    <Grid container spacing={{ md: 1, xs: 0 }} key={item.id}>
                        <Grid item xs={2}>
                            <Controller
                                as={
                                    <TextField
                                        errors={errors}
                                        label="Consider"
                                        type="number"
                                        inputRef={register({
                                            required: "This field is required",
                                            validate: (value) =>
                                                value >= 0,
                                        })}
                                        inputProps={{
                                            min: 0,
                                            step: "any"
                                        }}
                                    />
                                }
                                id="value"
                                control={control}
                                mode="onBlur"
                                name={`periodList[${index}].value`}
                                defaultValue={item.value}
                            />
                        </Grid>
                        <Grid item md={3} xs={8}>
                            <Controller
                                as={
                                    <Select options={daysPerYearArr} />
                                }
                                id="valueUnit"
                                control={control}
                                mode="onBlur"
                                name={`periodList[${index}].valueUnit`}
                                defaultValue={item.valueUnit}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <p className="fs-6 from-to-lbl">for</p>
                        </Grid>
                        <Grid item md={3} xs={8}>
                            <Controller
                                as={
                                    <Select options={bereavementRelationOptions} />
                                }
                                id="relation"
                                control={control}
                                mode="onBlur"
                                name={`periodList[${index}].relation`}
                                defaultValue={item.relation}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            {index !== 0 && (
                                <Button
                                    className="delete-btn"
                                    type="button"
                                    onClick={() => {
                                        remove(index);
                                    }}
                                    variant="contained"
                                >
                                    <ClearIcon />
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                ))}
                <Grid item xs={12} className="mb-3">
                    <Button
                        type="button"
                        onClick={() => {
                            append({
                                value: 0,
                                valueUnit: "WorkingDay",
                                relation: "ImmediateFamilyMembers",
                            });
                        }}
                        variant="contained"
                    >
                        <AddIcon />
                    </Button>
                </Grid>
                <Grid item xs={12} spacing={3}>
                    <Divider className="form-divider"/>
                </Grid>
                <Grid item xs={12} className='mt-3'>
                    <div className="fs-5 fw-normal">Bereavement leave details</div>
                </Grid>
                <Grid item xs={12}>
                    <FileUploader link multiple={false} handleChange={setLink} name="logo" types={logoFileTypes} className='file-uploader'></FileUploader>
                </Grid>
                <Grid container xs={12}>
                    {!editLink?
                        <Grid item xs={12}>
                            <span className="fs-6">Link:</span>
                            <Link href={link?.name || link} target="_blank" rel="noopener noreferrer">
                                {link?.name || link}
                            </Link>
                            <Button
                                    onClick={() => {
                                        setEditLink(!editLink);
                                    }}
                            >
                                <EditIcon className="fs-6"/>
                            </Button>
                        </Grid>
                    :
                        <Grid item xs={12} className="d-flex align-items-start">
                            <TextField
                                label="Link"
                                type="text"
                                id="attachment"
                                name="attachment"
                                value={link.name || link}
                                className='mw-100 w-100'
                                onChange={(e) => setLink(e.target.value)}
                            />
                            <Button
                                onClick={() => {
                                    setEditLink(!editLink);
                                }}
                            >
                                <DoneIcon className="fs-6"/> Done
                            </Button>
                        </Grid>
                    }
                </Grid>
                <Grid item xs={12}>
                    <Button type="submit" variant="contained" className='float-end my-3' >
                        Save
                    </Button>
                    <Button className="bg-danger float-end my-3 mx-3" color="secondary" variant="outlined" onClick={toggleDisablePolicyModal} >
                        Disable Policy
                    </Button>
                </Grid>
            </form>
            <Dialog
                className="details-dialog"
                open={modals[DISABLE_POLICY_MODAL]}
                toggle={toggleDisablePolicyModal}
            >
                <div>
                    <div className="m-4" style={{color: colorTheme}}>
                        Are you sure you want to disable ( Bereavement Leaves ) Policy?
                    </div>
                    <div>
                        <DialogActions className="p-3 mt-3">
                            <Button
                                className="px-4 me-3"
                                variant="outlined"
                                onClick={toggleDisablePolicyModal}
                            >
                                Cancel
                            </Button>
                            <Button
                                className="px-4 bg-danger"
                                color="secondary"
                                variant="outlined"
                                onClick={handleDisable}
                            >
                                {isSubmitting ? (
                                        <CircularProgress size={24} color="inherit" />
                                    ) :
                                    "Disable"
                                }
                            </Button>
                        </DialogActions>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};
const modalsProps = {
    toggleDisablePolicyModal: DISABLE_POLICY_MODAL,
};

export default withModals(BereavementLeavePolicy, modalsProps);
