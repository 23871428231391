import React, { useState, useEffect } from 'react';
import {map, startCase} from "lodash";
import {formatFullDate, getErrorMessage} from "utility/helpers/utils";
import { getTimeOff } from "utility/apis/ConfigService";
import { TABLES_NAMES } from "utility/constants/constants";
import {Button, DataGridTable, DateTimePicker, Dialog, Select, Tooltip, DialogActions, GridActionsCellItem} from "components/Mui";
import Loading from "components/Loading/Loading";
import StatusCard from "components/StatusCard/StatusCard";
import {CancelIcon, DeleteIcon, EditIcon, InsertLinkIcon, RedoIcon} from "components/Mui/Icons";
import moment from "moment/moment";
import {
    CONFIRMATION_MODAL, SICK_ADD_REPORT,
    TIME_OFF_CANCEL_REQUEST_MODAL,
    TIME_OFF_CONVERT_REQUEST_MODAL,
    TIME_OFF_REQUEST_MODAL
} from "utility/modals/constants";
import WithModals from "hoc/withModals/withModals";
import {DATE_FORMAT, DAY_MONTH_FORMAT, FULL_DATE_ISO_FORMAT, TIME_FORMAT} from "utility/helpers/metaData";
import {Controller, useForm} from "react-hook-form";
import {sickOptions} from "utility/constants/data";
import {
    cancelTimeOffRequest,
    convertTimeOffRequest, deleteTimeOffRequest, reportTimeOffRequest,
    updateTimeOffRequest
} from "utility/apis/EmployeeService";
import FileUploader from "components/FileUploader/FileUploader";

import "./Sick.scss";
import {toast} from "react-toastify";
import {getSystemConfiguration} from "../../../../utility/apis/SettingService";

const Sick = (props) => {
    const { employeeId, date, message, colorTheme, modals, toggleTimeOffEditModal, toggleConfirmationModal, toggleTimeOffCancelModal, toggleTimeOffConvertModal, toggleSickAddReport, tenantId } = props;

    const [ inProgress, setInProgress ] = useState(false);
    const [ statusCard, setStatusCard ] = useState({status: {}, label: ''});
    const [dataTable, setDataTable] = useState([]);
    const [selectedTimeOffRequest, setSelectedTimeOffRequest] = useState({});
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [report, setReport] = useState(null);
    const [isInProgress, setIsInProgress] = useState(false);
    const fileTypes = ["GIF" ,"JFIF" ,"PJPEG" ,"JPEG" ,"PJP" ,"JPG", "PNG"];
    const {  control, handleSubmit } = useForm({defaultValues: {}});

    const fetchData = async () => {
        setInProgress(true);
        try {
            const response = await getTimeOff(employeeId, moment.isMoment(date)? date.format("YYYY") : moment.isMoment(date.value)? moment(date.value).format("YYYY") : date?.value, 'sick', moment.isMoment(date)? date.format(DATE_FORMAT): moment.isMoment(date.value)? moment(date.value).format(DATE_FORMAT) : `${date?.value}-12-31`);
            const data = formatDataTable(response?.data?.mergedVacations);
            const systemConfig = await getSystemConfiguration(tenantId);
            let weeksSummaryEnabled;
            systemConfig?.data.forEach((row) => {
                if(row.name === "Weeks-Summary" && row.enabled) weeksSummaryEnabled = true;
            });
            if(weeksSummaryEnabled) {
                setStatusCard({
                    status: {
                        'Used': response?.data?.usedSick / response?.data?.workingDaysPerWeek,
                        'Available': (response?.data?.accruedSickTimeOff - response?.data?.usedSick) / response?.data?.workingDaysPerWeek,
                        'Converted To Time Off': response?.data?.sickConvertedToTimeOff / response?.data?.workingDaysPerWeek,
                        'Rejected': response?.data?.rejectedSickTimeOff / response?.data?.workingDaysPerWeek,
                    },
                    label: 'weeks'
                });
            } else {
                setStatusCard({
                    status: {
                        'Used': response?.data?.usedSick,
                        'Available': response?.data?.accruedSickTimeOff - response?.data?.usedSick,
                        'Converted To Time Off': response?.data?.sickConvertedToTimeOff,
                        'Rejected': response?.data?.rejectedSickTimeOff,
                    },
                    label: 'days'
                });
            }
            setDataTable(data);
        } catch (e) {
        }
        setInProgress(false);
    }

    useEffect(() => {
        if (date) {
            fetchData();
        }
    }, [date]);

    const formatDataTable = (data) => {
        return map(data, ({ fromDate, toDate, hours, value, minutes, mergedVacation, vacationLengthLabel }, index) => {
            const newMergedVacations = {
                state: [],
                approver: [],
                comments: [],
            };
            map(mergedVacation, ({ vacationState, attendanceState, teamManagerApprovals, comments }) =>  {
                newMergedVacations.state.push(attendanceState || vacationState);
                newMergedVacations.comments.push(comments);
                if (teamManagerApprovals) {
                    teamManagerApprovals.forEach((teamManagerApproval) => {
                        newMergedVacations.approver.unshift(...map(teamManagerApproval?.managerApprovalSet, ({ state, managerName, type, comments }) => ({
                            managerName,
                            state: type === 'HR' && state.indexOf('Rejected') > -1 ? 'Rejected sick report' : state,
                            comments
                        })));
                    });
                }
            });
            return {
                id: index,
                fromDate: formatFullDate(fromDate),
                toDate: formatFullDate(toDate),
                details: vacationLengthLabel,
                state: newMergedVacations.state,
                approver: newMergedVacations.approver,
                comments: newMergedVacations.comments,
                mergedVacation
            }
        });
    }

    const columnDefs = [
        {
            headerName: 'Details',
            field: 'details',
            flex: 1
        },
        {
            headerName: 'From Date',
            field: 'fromDate',
            flex: 1.8,
        },
        {
            headerName: 'To Date',
            field: 'toDate',
            flex: 1.8,
        },
        {
            headerName: 'Approver',
            field: 'approver',
            renderCell: (params) => (
                <div className="ellipsis">
                    {params.value?.map(({ state, managerName }) => (
                        <Tooltip title={`${startCase(managerName)}: ${startCase(state)}`}>
                            <div className="ellipsis">
                                <span>{startCase(managerName)}: </span>
                                <span className={state.indexOf('Rejected') > -1 ? "text-danger" : "text-info"}>{startCase(state)}</span>
                            </div>
                        </Tooltip>
                    ))}
                    {/*<span className={`ms-2 ${params.row.approver >= 0 ? "text-success" : "text-danger"}`}>*/}
                    {/*    {params.row.approver}*/}
                    {/*</span>*/}
                </div>
            ),
            flex: 2,
        },
        {
            headerName: ' Final State',
            field: 'state',
            flex: 1.5,
            renderCell: (params) => (
                <div className="ellipsis">
                    {params.value?.map((text) => (
                        <Tooltip title={startCase(text)}>
                            <div className={`ellipsis ${text.indexOf('Rejected') > -1 ? "text-danger" : "text-info"}`}>{startCase(text)}</div>
                        </Tooltip>
                    ))}
                </div>
            ),
        },
        {
            headerName: 'Attachments',
            field: 'attachments',
            renderCell: (params) => (
                <div className="ellipsis">
                    {params?.row?.mergedVacation?.map((vacation) => (
                        vacation?.attachments?.map(({ id, reference }, attachmentIndex) => (
                            <div>
                                <Tooltip
                                    key={id}
                                    title={
                                        <img
                                            src={reference}
                                            alt={`Report ${attachmentIndex}`}
                                            style={{ width: '200px', height: '250px' }}
                                        />
                                    }
                                >
                                    <a
                                        href={reference}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-info"
                                    >
                                        {`Report ${attachmentIndex}`}
                                    </a>
                                </Tooltip>
                            </div>
                        ))
                    ))}
                </div>
            ),
            flex: 2,
        },
        {
            headerName: 'Comments',
            field: 'comments',
            flex: 1.5,
            renderCell: (params) => (
                <div className="ellipsis">
                    {params.value?.map((text) => (
                        <>
                            {text &&
                                <Tooltip  title={startCase(text)}>
                                    <div className="ellipsis">{startCase(text)}</div>
                                </Tooltip>
                            }
                        </>
                    ))}
                </div>
            ),
        },
        {
            headerName: 'Actions',
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params) => {
                const actions = [];
                actions.push(
                    <GridActionsCellItem showInMenu icon={<EditIcon />} onClick={(event) => handleEditClick(event, params)} label="Edit" />,
                    <GridActionsCellItem showInMenu icon={<RedoIcon />} onClick={(event) => handleConvertClick(event, params)} label="Convert vacation" />,
                    <GridActionsCellItem sx={!(params?.row?.state[0] === "Approved_with_sick_report" || params?.row?.state[0] === "Pending_Sick_Report_Submission")? {'color': '#cdcdcd', 'cursor': 'default'} : null}
                                         showInMenu icon={<InsertLinkIcon sx={!(params?.row?.state[0] === "Approved_with_sick_report" || params?.row?.state[0] === "Pending_Sick_Report_Submission")?
                        {'color': '#cdcdcd', 'cursor': 'default'} : null}/>}
                        onClick={(event) => {
                            if (!(params?.row?.state[0] === "Approved_with_sick_report" || params?.row?.state[0] === "Pending_Sick_Report_Submission")) {
                                event.stopPropagation();
                                return;
                            }
                            handleAddReport(event, params);
                        }}
                    label="Add report" />,
                    <GridActionsCellItem sx={Number(date?.value) !== moment().year()? {'color': '#cdcdcd', 'cursor': 'default'} : null} showInMenu icon={<CancelIcon sx={Number(date?.value) !== moment().year()? {'color': '#cdcdcd', 'cursor': 'default'} : null}/>}
                         onClick={(event) => {
                             if (Number(date?.value) !== moment().year()) {
                                 event.stopPropagation();
                                 return;
                             }
                             handleCancelClick(event, params);
                         }}
                    label="Cancel request" />,
                    <GridActionsCellItem sx={Number(date?.value) !== moment().year()? {'color': '#cdcdcd !important', 'cursor': 'default'} : null} showInMenu icon={<DeleteIcon sx={Number(date?.value) !== moment().year()? {'color': '#cdcdcd !important', 'cursor': 'default'} : null}/>}
                         onClick={(event) => {
                             if (Number(date?.value) !== moment().year()) {
                                 event.stopPropagation();
                                 return;
                             }
                             handleDeleteClick(event, params);
                         }}
                    label="Delete" className="text-danger" />
                );
                return actions;
            },
        }
    ];

    const handleEditClick = (event, params) => {
        setSelectedTimeOffRequest(params.row);
        setFromDate(moment(params.row.fromDate));
        setToDate(moment(params.row.toDate));
        toggleTimeOffEditModal();
    }

    const handleConvertClick = (event, params) => {
        setSelectedTimeOffRequest(params.row);
        toggleTimeOffConvertModal();
    }

    const handleAddReport = (event, params) => {
        setSelectedTimeOffRequest(params.row);
        toggleSickAddReport();
    }

    const handleCancelClick = (event, params) => {
        setSelectedTimeOffRequest(params.row);
        toggleTimeOffCancelModal();
    }
    const handleDeleteClick = (event, params) => {
        setSelectedTimeOffRequest(params.row);
        toggleConfirmationModal();
    }

    const handleTimeOffRequestUpdate = async (event, data) => {
        setIsInProgress(true);
        event.preventDefault();
        try {
            const {  toDate, fromDate } = data;
            const formattedFormDate = fromDate.format(FULL_DATE_ISO_FORMAT) + "Z";
            const formattedToDate = toDate.format(FULL_DATE_ISO_FORMAT) + "Z";

            const payload = {
                from: formattedFormDate,
                to: formattedToDate,
                employee_id: employeeId,
                type: selectedTimeOffRequest?.mergedVacation[0]?.type
            };
            await updateTimeOffRequest(selectedTimeOffRequest?.mergedVacation[0]?.id, payload);
            toast.success("Updated Successfully");
            refreshData();
            toggleTimeOffEditModal();
        } catch (e) {
            toast.error(getErrorMessage(e));
        }finally {
            setTimeout(() => {
                setIsInProgress(false);
            }, 2000);
        }
    }
    const handleTimeOffRequestConvert = async (data) => {
        setIsInProgress(true);
        try {
            await convertTimeOffRequest(selectedTimeOffRequest?.mergedVacation[0]?.id, data.timeOffOption);
            toast.success("Converted Successfully");
            refreshData();
            toggleTimeOffConvertModal();
        } catch (e) {
            toast.error(getErrorMessage(e));
        }finally {
            setTimeout(() => {
                setIsInProgress(false);
            }, 2000);
        }
    }
    const handleSickReport = async () => {
        setIsInProgress(true);
        const formData = new FormData();
        formData.append('uploadfile', report);
        try {
            await reportTimeOffRequest(selectedTimeOffRequest?.mergedVacation[0]?.id, formData);
            toast.success("Converted Successfully");
            refreshData();
            toggleSickAddReport();
        } catch (e) {
            toast.error(getErrorMessage(e));
        }finally {
            setTimeout(() => {
                setIsInProgress(false);
            }, 2000);
        }
    }
    const handleTimeOffRequestCancel = async () => {
        setIsInProgress(true);
        try {
            await cancelTimeOffRequest(selectedTimeOffRequest?.mergedVacation[0]?.id);
            toast.success("Canceled Successfully");
            refreshData();
            toggleTimeOffCancelModal();
        } catch (e) {
            toast.error(getErrorMessage(e));
        }finally {
            setTimeout(() => {
                setIsInProgress(false);
            }, 2000);
        }
    }
    const handleTimeOffRequestDelete = async () => {
        setIsInProgress(true);
        try {
            await deleteTimeOffRequest(selectedTimeOffRequest?.mergedVacation[0]?.id);
            toast.success("Deleted Successfully");
            refreshData();
            toggleConfirmationModal();
        } catch (e) {
            toast.error(getErrorMessage(e));
        }finally {
            setTimeout(() => {
                setIsInProgress(false);
            }, 2000);
        }
    }
    const handleFileChange = (file) => {
        setReport(file);
    }

    const refreshData = () => {
        fetchData() ;
    }

    return (
        <div id="sick" className="c-sick">
            {inProgress ?
                <Loading></Loading>
                :
                <div className="container-fluid">
                    {!!statusCard &&
                        <div className="mt-4">
                            <StatusCard status={statusCard.status} valueLabel={statusCard.label}/>
                        </div>
                    }
                    <div style={{height: `calc(100vh - ${message ? 315 : 265}px)`}} className="row">
                        <DataGridTable
                            className="col-12"
                            key={TABLES_NAMES.SICK_LIST}
                            columns={columnDefs}
                            dataTable={dataTable}
                            isExport={false}
                        />
                    </div>
                </div>
            }
            <Dialog className='time-off-request-dialog' open={modals[TIME_OFF_REQUEST_MODAL]} toggle={toggleTimeOffEditModal}>
                <form onSubmit={(event) => handleTimeOffRequestUpdate(event, { toDate, fromDate })}>
                    <div className="m-4">
                        <h6 className="mb-4" style={{color: colorTheme}}>Edit Time Off Request</h6>
                        <div className="column">
                            <DateTimePicker
                                name="fromDateTime"
                                label="From Date"
                                className="mx-1"
                                value={fromDate}
                                onChange={(newValue) => setFromDate(newValue)}
                            />
                            <DateTimePicker
                                name="toDateTime"
                                label="To Date"
                                className="mx-1"
                                value={toDate}
                                minDateTime={fromDate}
                                onChange={(newValue) => setToDate(newValue)}
                            />
                        </div>
                    </div>
                    <div>
                        <DialogActions className="p-3 mt-3">
                            <Button className="px-4 me-3" variant="outlined" onClick={toggleTimeOffEditModal}>Cancel</Button>
                            <Button className="px-4" variant="contained" type="submit" disabled={isInProgress}>Save</Button>
                        </DialogActions>
                    </div>
                </form>
            </Dialog>
            <Dialog className='confirmation-dialog' open={modals[TIME_OFF_CONVERT_REQUEST_MODAL]} toggle={toggleTimeOffConvertModal}>
                <form onSubmit={handleSubmit(handleTimeOffRequestConvert)}>
                    <div className="m-4" style={{color: colorTheme}}>
                        {selectedTimeOffRequest.details} leave on {moment(selectedTimeOffRequest.fromDate).format(DAY_MONTH_FORMAT)} ({moment(selectedTimeOffRequest.fromDate).format(TIME_FORMAT)} to {moment(selectedTimeOffRequest.toDate).format(TIME_FORMAT)})
                    </div>
                    <div className="m-4">
                        <Controller
                            as={
                                <Select options={sickOptions} />
                            }
                            id="timeOffOption"
                            name="timeOffOption"
                            control = {control}
                            defaultValue={sickOptions[0].value}
                        />
                    </div>
                    <div>
                        <DialogActions className="p-3 mt-3">
                            <Button className="px-4 me-3" variant="outlined" onClick={toggleTimeOffConvertModal}>Cancel</Button>
                            <Button className="px-4" variant="contained" type="submit" disabled={isInProgress}>Confirm</Button>
                        </DialogActions>
                    </div>
                </form>
            </Dialog>
            <Dialog className='add-report-dialog' open={modals[SICK_ADD_REPORT]} toggle={toggleSickAddReport}>
                <form onSubmit={handleSubmit(handleSickReport)}>
                    <div className="m-4" style={{color: colorTheme}}>
                        {selectedTimeOffRequest.details} leave on {moment(selectedTimeOffRequest.fromDate).format(DAY_MONTH_FORMAT)} ({moment(selectedTimeOffRequest.fromDate).format(TIME_FORMAT)} to {moment(selectedTimeOffRequest.toDate).format(TIME_FORMAT)})
                    </div>
                    <div className="col-12 px-3 mb-4 overflow-hidden">
                        <FileUploader
                            multiple={false}
                            handleChange={handleFileChange}
                            name="file"
                            types={fileTypes}
                            label="Upload Logo"
                        />
                    </div>
                    <div>
                        <DialogActions className="p-3 mt-3">
                            <Button className="px-4 me-3" variant="outlined"
                                    onClick={toggleSickAddReport}>Cancel</Button>
                            <Button className="px-4" variant="contained" type="submit" disabled={isInProgress}>Submit Sick Report</Button>
                        </DialogActions>
                    </div>
                </form>
            </Dialog>
            <Dialog className='confirmation-dialog' open={modals[TIME_OFF_CANCEL_REQUEST_MODAL]} toggle={toggleTimeOffCancelModal}>
                <div>
                    <div className="m-4">
                        Are you sure you want to cancel{' '}
                        <span style={{color: colorTheme}}>
                            {selectedTimeOffRequest.details} leave on {moment(selectedTimeOffRequest.fromDate).format(DAY_MONTH_FORMAT)} ({moment(selectedTimeOffRequest.fromDate).format(TIME_FORMAT)} to {moment(selectedTimeOffRequest.toDate).format(TIME_FORMAT)})
                        </span>
                        ?
                    </div>
                    <div className="m-4 fw-light">
                        Note: Cancellation requests require approval from managers
                    </div>
                    <div>
                        <DialogActions className="p-3 mt-3">
                            <Button className="px-4 me-3" variant="outlined"
                                    onClick={toggleTimeOffCancelModal}>Cancel</Button>
                            <Button className="px-4" variant="contained" disabled={isInProgress}
                                    onClick={handleTimeOffRequestCancel}>Confirm</Button>
                        </DialogActions>
                    </div>
                </div>
            </Dialog>
            <Dialog className='confirmation-dialog' open={modals[CONFIRMATION_MODAL]} toggle={toggleConfirmationModal}>
                <div>
                    <div className="m-4" style={{color: colorTheme}}>
                        Are you sure you want to delete this ?
                    </div>
                    <div>
                        <DialogActions className="p-3 mt-3">
                            <Button className="px-4 me-3" variant="outlined"
                                    onClick={toggleConfirmationModal}>Cancel</Button>
                            <Button className="px-4 bg-danger" color="secondary" variant="outlined"
                                    onClick={handleTimeOffRequestDelete} disabled={isInProgress}>Delete</Button>
                        </DialogActions>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

const modalsProps = {
    toggleTimeOffEditModal: TIME_OFF_REQUEST_MODAL,
    toggleTimeOffConvertModal: TIME_OFF_CONVERT_REQUEST_MODAL,
    toggleTimeOffCancelModal: TIME_OFF_CANCEL_REQUEST_MODAL,
    toggleConfirmationModal: CONFIRMATION_MODAL,
    toggleSickAddReport: SICK_ADD_REPORT
};
export default WithModals(Sick, modalsProps);
