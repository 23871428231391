import React, {useState} from "react";
import {Button, DatePicker, Dialog, Tabs, DialogActions} from "components/Mui";
import { TIME_OFF_REQUEST_MODAL } from "utility/modals/constants";
import WithModals from "hoc/withModals/withModals";
import {DATE_FORMAT} from "utility/helpers/metaData";
import moment from "moment";
import BalanceDeduction from "containers/EmployeeDetails/TimeOff/BalanceDeduction/BalanceDeduction";
import CompensationsTime from "containers/EmployeeDetails/TimeOff/CompensationsTime/CompensationsTime";
import Sick from "containers/EmployeeDetails/TimeOff/Sick/Sick";
import TimeOffRequests from "containers/EmployeeDetails/TimeOff/TimeOffRequests/TimeOffRequests";
import CustomAppBar from "components/CustomAppBar/CustomAppBar";
import Other from "./Other/Other";
import Balance from "./Balance/Balance";
import SalaryDeduction from "./SalaryDeduction/SalaryDeduction";
import {getDateOptions, getTenantId} from "../../../utility/helpers/utils";
import {useLocation} from "react-router-dom";

const getTimeOffTabs = (employeeId, date, message, colorTheme, tenantId) => {
    return [
        {
            label: "time off requests",
            component: <TimeOffRequests employeeId={employeeId} date={date} message={message} colorTheme={colorTheme} tenantId={tenantId}/>
        },
        {
            label: "sick",
            component: <Sick employeeId={employeeId} date={date} message={message} colorTheme={colorTheme} tenantId={tenantId}/>
        },
        {
            label: "compensations time",
            component: <CompensationsTime employeeId={employeeId} date={date} message={message} colorTheme={colorTheme}/>
        },
        {
            label: "salary deduction",
            component: <SalaryDeduction employeeId={employeeId} date={date} message={message} colorTheme={colorTheme}/>
        },
        {
            label: "balance deduction",
            component: <BalanceDeduction employeeId={employeeId} date={date} message={message} colorTheme={colorTheme}/>
        },
        {
            label: "other",
            component: <Other employeeId={employeeId} date={date} message={message} colorTheme={colorTheme} tenantId={tenantId}/>
        },
        {
            label: "balance",
            component: <Balance employeeId={employeeId} date={date} message={message} tenantId={tenantId}/>
        }
    ];
};

function TimeOff(props) {
    const tenantId = getTenantId(useLocation(), "tenantId");
    const { employeeId, message, handleCloseButton, title, colorTheme, modals, toggleTimeOffEditModal, balanceTab } = props;
    const [ date, setDate ] = useState(undefined);
    const timeOffTabs = getTimeOffTabs(employeeId, date, message, colorTheme, tenantId);
    const [specificDate, setSpecificDate] = useState();
    const [years, setYears] = useState(getDateOptions());

    const handleDateChange = (year) => {
        if (year === "customDate") {
            toggleTimeOffEditModal();
        } else {
            const yearData = years.find((item) => item.value === (year.value || year));
            if (yearData) {
                setDate(yearData);
            }
        }
    };

    const handleCustomDate = () => {
        if (!specificDate) return;


        setYears((prevYears) => {
            const lastElement = prevYears[prevYears.length - 1];
            const updatedYears = [
                ...prevYears.slice(0, -1),
                { label: specificDate?.format(DATE_FORMAT), value: specificDate },
                lastElement
            ];
            return updatedYears;
        });

        setDate(specificDate);
        toggleTimeOffEditModal();
    };

    return (
        <div className="c-time-off_tabs px-4 mt-3">
            <CustomAppBar title={title} showSecondaryButton handleCloseButton={handleCloseButton} date={date} keepFormat={true} dateOptions={years} handleDateChange={handleDateChange} addCustomDate message={message}/>

            {!!timeOffTabs.length &&
                <Tabs currentTabIndex={balanceTab? 6 : 0} items={timeOffTabs} />
           }
            <Dialog className='time-off-request-dialog' open={modals[TIME_OFF_REQUEST_MODAL]} toggle={toggleTimeOffEditModal}>
                    <div className="m-4">
                        <h6 className="mb-4" style={{color: colorTheme}}>Custom Date</h6>
                        <div className="column">
                            <DatePicker
                                name="specificDate"
                                label="Specific Date"
                                className="mx-1"
                                value={specificDate}
                                onChange={(newValue) => setSpecificDate(newValue)}
                            />
                        </div>
                    </div>
                    <div>
                        <DialogActions className="p-3 mt-3">
                            <Button className="px-4 me-3" variant="outlined" onClick={toggleTimeOffEditModal}>Cancel</Button>
                            <Button className="px-4" variant="contained" type="submit" onClick={handleCustomDate}>Save</Button>
                        </DialogActions>
                    </div>
            </Dialog>

        </div>
    );
}


const modalsProps = {
    toggleTimeOffEditModal: TIME_OFF_REQUEST_MODAL,
};
export default WithModals(TimeOff, modalsProps);