import React from 'react';
import { RadioGroup as MuiRadioGroup } from "@mui/material";
import { ErrorMessage } from "@hookform/error-message";
import classNames from "classnames";
import './RadioGroup.scss';

function RadioGroup(props) {
    const { name, children, className, errors, ...rest } = props;
    return (
        <>
            {!!errors &&
                <ErrorMessage className="error-message my-2" errors={errors} name={name} as="p"/>
            }
            <MuiRadioGroup className={classNames(className)} name={name} size="small" {...rest}>
                {children}
            </MuiRadioGroup>
        </>
    );
}

export default RadioGroup;