import React, { useState } from "react";
import CustomAppBar from "components/CustomAppBar/CustomAppBar";
import WithModals from "hoc/withModals/withModals";
import { Dialog, useTheme } from "components/Mui";
import ProfileDetails from "containers/ProfileDetails/ProfileDetails";
import { PROFILE_DETAILS_MODAL } from "utility/modals/constants";
import {NotesIcon} from "../../../../components/Mui/Icons";

const CreateProfile = (props) => {
  const { title, handleCloseButton,  modals, toggleProfileDetailsModal } = props;
    const selectedTheme = useTheme();
    const colorTheme = selectedTheme?.palette?.primary?.main;
  const OpenCreateProfilePanel = () => {
    toggleProfileDetailsModal();
  };
  return (
    <div id="create-profile" className="c-create-profile mx-3 mt-3">
     <div className="container-fluid">
        <CustomAppBar title={title} textPrimary="Create Profile" showSecondaryButton textSecondary="Close" showPrimaryButton handleCloseButton={handleCloseButton} handlePrimaryButton={OpenCreateProfilePanel}/>
        <div className='fs-4'>
          <div>
              <div className='fw-bold fs-6 fst-italic mb-3' style={{color: colorTheme}}>Start adding profiles for your team and assign roles.</div>
              <div className="fw-bold fs-6 fst-italic"><span><NotesIcon style={{color: colorTheme}} /></span> Your profile has got HR Manager and HR Officer roles. You can manage roles as soon as you have created profiles</div>
          </div>
        </div>
     </div>
     <Dialog className='profile-details-dialog' fullScreen open={modals[PROFILE_DETAILS_MODAL]} toggle={toggleProfileDetailsModal}>
        <ProfileDetails handleCloseButton={toggleProfileDetailsModal}></ProfileDetails>
      </Dialog>
    </div>
  );
};

const modalsProps = {
  toggleProfileDetailsModal: PROFILE_DETAILS_MODAL
};

export default WithModals(CreateProfile, modalsProps)
