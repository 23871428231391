import React, { useContext } from 'react';
import { useForm } from "react-hook-form";
import {filter, find, includes, map} from "lodash";
import {useTheme} from "components/Mui";
import CheckboxesTags from "components/CheckboxesTags/CheckboxesTags";
import CustomAppBar from "components/CustomAppBar/CustomAppBar";
import { ProfileContext } from "containers/ProfileDetails/ProfileDetails";

import "./TeamsAndWorkScheduleTemplates.scss";

const TeamsAndWorkScheduleTemplates = (props) => {
    const { message, employeeId, handleCloseButton, title, teams, setProfileTeams, defaultHolidays, setProfileHolidays, profileTemplate, setProfileTemplate, setCurrentTabIndex, currentTabIndex, templateOptions, holidayOptions, teamsOptions } = props;
    const { register, errors, trigger } = useForm({ mode:'onChange' });
    const isApproval = false;
    const defaultTeams = find(teamsOptions, (el) => includes(teams, el.id));
    const selectedTheme = useTheme();
    const colorTheme = selectedTheme?.palette?.primary?.main;


    const handleHolidaysChange = (event, value) => {
         setProfileHolidays(map(value, 'id'));
    };
 
    const handleTemplatesChange = (event, value) => {
        setProfileTemplate(find(templateOptions, (el) => el?.id === value?.id ));
    };

    const handleTeamsChange = (event, value) => {
        if (isApproval) {
            setProfileTeams(map(value, 'id'))
        } else {
            setProfileTeams(value.id? [value.id] : [])
        }
        
    };

    const handleProfileWSTNextStep = async (event) => {
        event.preventDefault();
        try {
            const isValid = await trigger();
            if (isValid) {
              // Handle successful validation
              setCurrentTabIndex(currentTabIndex + 1);
            } 
        } catch(e){}
    } 

    const handleProfileWSTBackStep = (event) => {
        event.preventDefault();
        setCurrentTabIndex(currentTabIndex - 1);
    } 

    return (
        <div id="work-schedule-templates" className="c-work-schedule-templates ps-4">
            <div className="container-fluid">
                <form onSubmit={(event) => handleProfileWSTNextStep(event)}>
                    <CustomAppBar title={title} showPrimaryButton textPrimary="Next" showBackButton={!employeeId} handleBackButton={(event) => handleProfileWSTBackStep(event)} showSecondaryButton={employeeId} textSecondary="Cancel" handleCloseButton={handleCloseButton}  message={message}/>
                    <div className='fs-4  my-3'>
                        <div className='fs-5' style={{color: colorTheme}}>To change work schedule templates, please update the following fields: </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-7">
                            <CheckboxesTags
                                label="Active Employee Template"
                                name="activeTemplate"
                                onChange={handleTemplatesChange}
                                labelField='templateName'
                                fullWidth={true}
                                options={templateOptions}
                                defaultValue={profileTemplate}
                                id="activeTemplate"
                                inputRef={register({
                                    required: "This field is required"
                                })}
                                errors={errors}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-7">
                            <CheckboxesTags
                                disableCloseOnSelect
                                label="Special Holidays"
                                name="holidays"
                                onChange={handleHolidaysChange}
                                labelField='name'
                                fullWidth={true}
                                options={holidayOptions}
                                limitTags={2}
                                defaultValue={filter(holidayOptions, (el) => includes(defaultHolidays, el.id))}
                                id="holidays"
                                multiple={true}
                                inputRef={register}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-7">
                            <CheckboxesTags
                                disableCloseOnSelect={isApproval}
                                label="Active Employee Team"
                                name="teams"
                                onChange={handleTeamsChange}
                                labelField='name'
                                fullWidth={true}
                                options={teamsOptions}
                                limitTags={2}
                                defaultValue={defaultTeams}
                                id="teams"
                                multiple={isApproval}
                                inputRef={register}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default TeamsAndWorkScheduleTemplates;
