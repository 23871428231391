import React, {useEffect, useState} from 'react';
import CustomAppBar from "components/CustomAppBar/CustomAppBar";
import {getScheduleTasks} from "utility/apis/SettingService";
import {Button, Dialog} from "components/Mui";
import Loading from "components/Loading/Loading";
import {AddCircleIcon, ExpandMoreIcon} from "components/Mui/Icons";
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "components/Mui";
import WithModals from "hoc/withModals/withModals";
import {SCHEDULE_FORM_MODAL} from "utility/modals/constants";
import ScheduleForm from "./ScheduleForm/ScheduleForm";

import "./ScheduleSetting.scss"
const ScheduleSetting = (props) => {
    const {title, handleCloseButton, tenantId, colorTheme, modals, toggleScheduleFormModal, toggleConfirmationModal} = props;
    const [scheduleTasks, setScheduleTasks] = useState();
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const getSchedule = async () => {
        try {
            let response = await getScheduleTasks(tenantId);
            setExpanded(false);
            setScheduleTasks(response.data);
        } catch (e) {
            console.log(e);
        }
    }
    useEffect( () => {
        getSchedule();
    }, []);

    const taskTypeMap = {
        AUTO_CHECKOUT_AND_DEDUCT: 'AUTO CHECKOUT AND DEDUCT',
        SEND_ATTENDANCE_DEDUCTION_REPORT_TO_MANAGERS: "SEND ATTENDANCE DEDUCTION REPORT TO MANAGERS",
        NOTIFY_MANAGERS_ABOUT_NON_ATTENDANTS: "NOTIFY MANAGERS ABOUT NON ATTENDANTS",
        REMINDER_TO_NON_ATTENDANTS: "REMINDER TO NON ATTENDANTS"
    }

    return (
        <div id="ScheduleSetting" className="c-schedule-setting px-4 mt-4">
            <CustomAppBar
                title={title}
                showSecondaryButton
                textSecondary="Close"
                handleCloseButton={handleCloseButton}
            />
            {scheduleTasks?
                <>
                    <div className="d-flex">
                        <div className="message-frame">
                            Create a new schedule task
                        </div>
                        <Button className="add-salary-deduction-button" onClick={toggleScheduleFormModal}>
                            <AddCircleIcon/>
                        </Button>
                    </div>
                    {scheduleTasks?.map((item) => (
                        <Accordion
                            className="my-3"
                            expanded={expanded === item.id}
                            onChange={handleChange(item.id)}
                            key={item.id}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                className="accordion-summary-bg"
                            >
                                <Typography className="holiday-title">{taskTypeMap[item.type]}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ScheduleForm task={{ ...item, typeLabel: taskTypeMap[item.type] }}
                                              tenantId={tenantId} getSchedule={getSchedule} colorTheme={colorTheme}></ScheduleForm>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </>
            :
                <Loading></Loading>
            }
            <Dialog
                className="details-dialog"
                open={modals[SCHEDULE_FORM_MODAL]}
                toggle={toggleScheduleFormModal}
                mainTitle={'Create a New Scheduled Task'}
            >
                <div>
                    <ScheduleForm tenantId={tenantId} getSchedule={getSchedule} toggleScheduleFormModal={toggleScheduleFormModal} colorTheme={colorTheme}></ScheduleForm>
                </div>
            </Dialog>
        </div>
    );
};

const modalsProps = {
    toggleScheduleFormModal: SCHEDULE_FORM_MODAL,
};

export default WithModals(ScheduleSetting, modalsProps);
