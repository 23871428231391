import { Divider, Grid, Button } from "components/Mui";
import React, { useEffect, useContext, useRef } from "react";
import { useForm, useFieldArray } from "react-hook-form";

import AccrualRules from "./AccrualRules/AccrualRules";
import Rollover from "./Rollover/Rollover";
import Leaves from "./Leaves/Leaves.";

import { isObjectEmpty } from "utility/helpers/utils";
import { ProfileContext } from "containers/ProfileDetails/ProfileDetails";
import Loading from "components/Loading/Loading";

import "./TimeOffPolicyEmployeeForm.scss";

const TimeOffPolicyEmployeeForm = (props) => {
  const { dataFromServer, setSaveTimeOffData = () => {}, handleSave, withSaveButton, employeeId, profileCustomizeTimeOff, setProfileCustomizeTimeOff,  currentTabIndex, setCurrentTabIndex, backClicked } = props;
  const profileContext = useContext(ProfileContext);
  const hasRendered = useRef(false);

  const { register, control, handleSubmit, reset, errors, getValues, trigger } = useForm({
    defaultValues: {
      ...profileCustomizeTimeOff,
      increaseSet: profileCustomizeTimeOff?.increaseSet || dataFromServer?.increaseSet || [],
    },
  });
  
  const { fields, append, remove } = useFieldArray({
    control,
    name: "increaseSet",
  });

  useEffect(() => {
    if (hasRendered.current && !withSaveButton) {
      // Code to run after the first render
      setCurrentTabIndex(currentTabIndex);
      handleProfileCustomizeTimeOffBackStep()
    } else {
      hasRendered.current = true;
    }
  }, [backClicked])


  const handleProfileCustomizeTimeOffSaveStep = async (event) => {
    const {saveProfile, setSaveProfile} = profileContext;
    event && event.preventDefault();
    try {
      const isValid = await trigger();
      if (isValid) {
        // Handle successful validation
        setProfileCustomizeTimeOff(getValues())
        setSaveProfile(!saveProfile)
      } 
    } catch(e){

    }
   
  }

  const handleProfileCustomizeTimeOffBackStep = () => {
    try {
        setProfileCustomizeTimeOff(getValues())
        setCurrentTabIndex(currentTabIndex - 1);
    } catch(e){

    }
   
  }

  useEffect(() => {
    if (dataFromServer || profileCustomizeTimeOff) {
      reset(profileCustomizeTimeOff || dataFromServer || {});
    }
  }, [dataFromServer, profileCustomizeTimeOff, reset]);

  const handleChangedForm = () =>{
    setTimeout(() => {
      const currentValues = { ...getValues() };

      setSaveTimeOffData(currentValues);
      setProfileCustomizeTimeOff && setProfileCustomizeTimeOff(currentValues);
    }, 0);
  }

  return (
    <>
      {isObjectEmpty(dataFromServer) && profileCustomizeTimeOff === undefined? (
        <Loading></Loading>
      ) : (
        <div id="WfhMonthlyBasis" className="c-time-off-policy-cont ps-4">
          <form id="time-off-policy" onChange={handleChangedForm} onClick={handleChangedForm} onBlur={handleChangedForm} onSubmit={(employeeId || withSaveButton) ? handleSubmit(handleSave) : (event) => handleProfileCustomizeTimeOffSaveStep(event)}>
            <Grid container spacing={{ md: 1, xs: 0 }}>
              {fields.length > 0 ? (
                  <AccrualRules
                      register={register}
                      fields={fields}
                      control={control}
                      append={append}
                      remove={remove}
                      errors={errors}
                      handleChangedForm={handleChangedForm}
                  />
              ) : (
                  <div className="loading-container">
                    <Loading />
                  </div>
              )}
              <Grid item xs={12} spacing={3}>
                <Divider
                  sx={{ bgcolor: "black", p: 0, mt: 3 }}
                  className="form-divider"
                />
              </Grid>
              <Rollover
                errors={errors}
                register={register}
                control={control}
                handleChangedForm={handleChangedForm}
                dataFromServer={dataFromServer && Object.keys(dataFromServer).length > 0 ? dataFromServer : profileCustomizeTimeOff}
              />
              <Grid item xs={12} spacing={3}>
                <Divider
                  sx={{ bgcolor: "black", p: 0 }}
                  className="form-divider"
                />
              </Grid>
              <Leaves
                errors={errors}
                register={register}
                control={control}
                dataFromServer={dataFromServer && Object.keys(dataFromServer).length > 0 ? dataFromServer : profileCustomizeTimeOff}
              />
            </Grid>
            {withSaveButton &&
                <Button type="submit" variant="contained" className='float-end my-3' >
                  Save
                </Button>
            }
          </form>
        </div>
      )}
    </>
  );
};

export default TimeOffPolicyEmployeeForm;
