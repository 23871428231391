import React, {useContext, useRef, useState} from 'react';
import { CameraAltIcon } from 'components/Mui/Icons';
import { uploadEmployeeImage } from "utility/apis/EmployeeService";
import { ReactComponent as AccountCircleOutlinedIcon } from 'assets/images/icons/user.svg';
import {ProfileContext} from "containers/ProfileDetails/ProfileDetails";
import profile from 'assets/images/profile.svg';
import './uploadEmployeeImage.scss';
import {getErrorMessage} from "utility/helpers/utils";
import {toast} from "react-toastify";

const UploadEmployeeImage = (props) => {
    const { employeeId, profilePicture, handelRefreshEmployeeList, UploadNewProfileImage } = props;
    const [image, setImage] = useState(profilePicture);
    const context = useContext(ProfileContext) ;
    const fileInputRef = useRef(null);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        handleImageUpload(selectedFile);
    };

    const handleImageDrop = (e) => {
        e.preventDefault();
        const selectedFile = e.dataTransfer.files[0];
        handleImageUpload(selectedFile);
    };

    const handleImageUpload = async (selectedFile) => {
        if (selectedFile) {
            const selectedFileURL = URL.createObjectURL(selectedFile);
            setImage(selectedFileURL);
            if(UploadNewProfileImage){
                const {setProfileImage} = context;
                setProfileImage(selectedFile);
            }
            else{
                try {
                    await uploadEmployeeImage(employeeId, { empPic: selectedFile });
                    toast.success("Uploaded Successfully");
                    handelRefreshEmployeeList();
                } catch (e) {
                    toast.error(getErrorMessage(e));
                }
            }

        }
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    return (
        <div
            className="c-upload-employee-image"
            onDrop={handleImageDrop}
            onDragOver={handleDragOver}
        >
            <div className="employee-image">
                {image ? (
                    <img className="image" alt="profile" src={image}  onError={(e) => e.target.src = profile}/>
                ) : (
                    <AccountCircleOutlinedIcon className="image" />
                )}
                <button className="transparent-btn" onClick={handleButtonClick}>
                    <div className="w-100">
                        <CameraAltIcon className="transparent-icon" />
                    </div>
                </button>
                <input
                    ref={fileInputRef}
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                />
            </div>
        </div>
    );
};

export default UploadEmployeeImage;
