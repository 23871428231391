import React from "react";
import { Grid, Select, TextField } from "components/Mui";
import { Controller } from "react-hook-form";
import { TemplateType } from "utility/constants/data";

const Template = (props) => {
  const { register, errors, control, dataFromServer, disableAllField } = props;
  return (
    <>
      {/*<Grid item xs={12} className="mt-3">*/}
      {/*  <h6>Template name</h6>*/}
      {/*</Grid>*/}
      <Grid className="mx-1" container spacing={{ md: 1, xs: 0 }}>
        <Grid item xs={12} md={3}>
          <Controller
            as={
              <TextField
                name="templateName"
                inputRef={register({
                  required: "required",
                })}
                errors={errors}
                label="Template Name"
              />
            }
            name="templateName"
            id="templateName"
            className="w-100"
            defaultValue={dataFromServer?.templateName}
            control={control}
            disabled={disableAllField}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <Controller
            as={
              <Select label="Type" options={TemplateType} />
            }
            id="templateType"
            className="w-100"
            disabled={disableAllField}
            name="templateType"
            control={control}
            defaultValue={dataFromServer?.type || TemplateType[0]?.value}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Template;
