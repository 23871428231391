import React, {useState} from "react";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import CustomAppBar from "../../CustomAppBar/CustomAppBar";
import {
  Grid,
  TextField,
  Select,
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  useTheme,
  DatePicker,
  Tooltip,
} from "components/Mui";
import {RemoveIcon, AddIcon} from "components/Mui/Icons";
import { DATE_FORMAT } from "utility/helpers/metaData";
import {getIsManager} from "utility/helpers/authHelper";
import "./HolidayAddEditForm.scss"

const HolidayAddEditForm = (props) => {
  const { dataFromServer, handleSave, submitting, handleDelete, holidayAddWithCustomBar, title , handleCloseButton } = props;
  const { register, control, handleSubmit, errors } = useForm({
    mode: "all",
  });
  const [addEndDate, setEndDate] = useState(dataFromServer?.toDate && dataFromServer?.toDate !== dataFromServer?.fromDate);
  const selectedTheme = useTheme();
  const colorTheme = selectedTheme?.palette?.primary?.main;
  const isEditView = getIsManager();
  const [from, setFrom] = useState(moment(dataFromServer?.fromDate));
  const [to, setTo] = useState(dataFromServer?.toDate && addEndDate? moment(dataFromServer?.toDate): null);

  return (
      <div>
        <form id={`holiday-form`} className="holiday-form" onSubmit={handleSubmit(handleSave)}>
          {holidayAddWithCustomBar &&
              <>
                <CustomAppBar title={title} showPrimaryButton showSecondaryButton textSecondary="Close" handleCloseButton={handleCloseButton}/>
                <div className='fs-4  my-3'>
                  <div className="fw-bold fs-6 fst-italic mb-3"  style={{color: colorTheme}}>Create official, floating and custom holidays</div>
                </div>
              </>
          }
          <Grid container spacing={0.5}>
            <Grid className={'holiday-form-item'} item xs={12} md={5}>
              <TextField
                errors={errors}
                label="Holiday Name"
                inputRef={register({
                    required: "this field is required",
                })}
                disabled={!isEditView}
                InputLabelProps={{ shrink: true }}
                name="name"
                id="name"
                className="w-100"
                defaultValue={dataFromServer?.name}
              />
            </Grid>
            <Grid className={'holiday-form-item'} item xs={9} md={3}>
              <DatePicker
                  className="w-100 withAuto"
                  inputRef={register({
                    required: "this field is required",
                  })}
                  onChange={(event)=>setFrom(event)}
                  size="small"
                  variant="outlined"
                  slotProps={{
                    textField: {
                      id: "fromDate",
                      name: "fromDate",
                      size: "small",
                      variant: "outlined",
                    },
                  }}
                  disabled={!isEditView}
                  label="Date"
                  format={DATE_FORMAT}
                  defaultValue={from}
              />
            </Grid>
            { addEndDate &&
                <Grid className={'holiday-form-item'} item xs={9} md={3}>
                  <DatePicker
                      className="w-100 withAuto"
                      inputRef={register({
                        required: "this field is required",
                      })}
                      minDate={from}
                      onChange={(event)=>setTo(event)}
                      size="small"
                      variant="outlined"
                      slotProps={{
                        textField: {
                          id: "toDate",
                          name: "toDate",
                          size: "small",
                          variant: "outlined",
                        },
                      }}
                      disabled={!isEditView}
                      label="End Date"
                      format={DATE_FORMAT}
                      defaultValue={to}
                  />
                </Grid>
            }
            {isEditView &&
                <Grid item xs={2} md={1} className="button-grid">
                  { addEndDate ?
                      <Grid item xs={2} md={1}>
                        <Tooltip title="Delete shift">
                          <Button
                            className="delete-btn"
                            type="button"
                            onClick={(e) => {
                              e.preventDefault();
                              setEndDate(false);
                            }}
                            variant="contained"
                            >
                              <RemoveIcon />
                            </Button>
                        </Tooltip>
                      </Grid>
                      :
                      <Grid item xs={2} md={1}>
                          <Tooltip title="Add shift">
                              <Button
                                className="add-btn"
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setEndDate(true);
                                }}
                                variant="contained"
                            >
                              <AddIcon />
                            </Button>
                          </Tooltip>
                      </Grid>
                  }
                </Grid>
            }
          </Grid>
          <Grid container spacing={0.5}>
              <Grid item xs={12} md={6}>
                    <Controller
                        as={
                            <Select className="w-100" label="Holiday Type" disabled={!isEditView}
                                    options={[{value: 'One time', label: 'One time'}, {value: 'Annual', label: 'Annual'}]}
                            />
                        }
                        control={control}
                        defaultValue={dataFromServer?.type || "Annual"}
                        id="type"
                        name="type"
                    />
                </Grid>
              <Grid item xs={12} md={6}>
                    <Controller
                        as={
                            <Select className="w-100" label="Applicability" disabled={!isEditView}
                                    options={[{value: '0', label: 'Applicable for all employees'}, {value: '1', label: 'Applicable for certain employees'}]} />
                        }
                        id="special"
                        name="special"
                        control={control}
                        required={true}
                        defaultValue={dataFromServer?.special ? "1" : "0"}
                    />
                </Grid>
              <Grid item md={12} className="w-100">
                    <Controller
                        as={
                            <TextField
                                errors={errors}
                                className="w-100"
                                label="Comments"
                                disabled={!isEditView}
                                InputLabelProps={{ shrink: true }}
                            />
                        }
                        defaultValue={dataFromServer?.comments}
                        id="comments"
                        name="comments"
                        className="mb-0 w-100"
                        control={control}
                    />
                </Grid>

              <Grid item md={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                inputRef={register}
                                name="isExcluded"
                                disabled={!isEditView}
                                defaultChecked={dataFromServer?.isExcluded ? true : false}
                            />
                        }
                        style={{ paddingBottom: 2, marginLeft: -9 }}
                        label="Exclude from holidays"
                    />
              </Grid>
              {!holidayAddWithCustomBar && isEditView &&
                <div className="d-flex justify-content-end w-100">
                  {dataFromServer?.id && (
                      <Button
                          className="px-4 my-2 ms-2 bg-danger"
                          color="secondary"
                          variant="outlined"
                          onClick={() => handleDelete(dataFromServer?.id)}
                      >
                        Delete
                      </Button>
                  )}
                  <Button
                      type="submit"
                      disabled={submitting}
                      className="px-4 my-2 ms-2"
                      variant="contained"
                  >
                    {submitting ? (
                        <CircularProgress size={24} color="inherit"/>
                    ) : (
                        "Save"
                    )}
                  </Button>
                </div>
              }
          </Grid>
        </form>
      </div>
  );
};
export default HolidayAddEditForm;
