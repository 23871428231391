import React from "react";
import CustomAppBar from "components/CustomAppBar/CustomAppBar";
import { Button, useTheme } from "components/Mui";
import SlackButton from 'assets/images/slack-2.png';
import { getUserInfo } from "utility/helpers/authHelper";
import {NotesIcon} from "components/Mui/Icons";


const AddToSlack = (props) => {
  const {  title, handleCloseButton } = props;
  const selectedTheme = useTheme();
  const colorTheme = selectedTheme?.palette?.primary?.main;
  return (
    <div id="add-to-slack" className="c-add-to-slack mx-3">
        <div className="container-fluid">
        <CustomAppBar title={title} showSecondaryButton textSecondary="Close" handleCloseButton={handleCloseButton} />
        <div className='fs-4'>
            <div>
                <div className="fw-bold fs-6 fst-italic mb-1" style={{color: colorTheme}}>This is a mandatory step in order to connect Toffy with your Slack Workspace account. <a href="/" className="fw-bold">Learn more</a></div>
                <div className="fw-bold fs-6 fst-italic"><span><NotesIcon style={{color: colorTheme}}/></span> Go to Settings > Slack Accounts tab if you want to do this step later </div>
            </div>
        </div>
        <br />
        <Button variant="outlined" href={JSON.parse(getUserInfo()).client_id}  target="_blank" startIcon={<img src={SlackButton} alt="add to slack" />}> Add To Slack</Button>
        </div>
   </div>
  );
};

export default AddToSlack;
