import React, { useEffect, useState } from "react";
import { LeftTabsDrawer, useTheme } from "components/Mui";

import CreateEditTemplate from "./CreateEditTemplate/CreateEditTemplate";
import CreateProfile from "./CreateProfile/CreateProfile";
import CreatePolicy from "./CreatePolicy/CreatePolicy";
import AddHoliday from "./AddHoliday/AddHoliday";
import AddToSlack from "./AddToSlack/AddToSlack";

import {
  GroupsSharpIcon,
  TagSharpIcon,
  UpdateSharpIcon,
  BeachAccessSharpIcon,
  AssignmentSharpIcon, StarsIcon,
} from "components/Mui/Icons";
import {getIsHRAssistant, getIsManager} from "utility/helpers/authHelper";
import {filter} from "lodash";

import "./SetupGuide.scss";

const getSetupGuideDrawerList = (handleCloseButton) => {
  const isManager = getIsManager();
  const isHRAssistant = getIsHRAssistant();

  let tabs = [
    {
      title: "Work Schedule Templates",
      isShow: isManager,
      component: (
        <CreateEditTemplate
          title="Work Schedule Templates"
          addWithCustomBar={true}
          handleCloseButton={handleCloseButton}
        />
      ),
      icon: <UpdateSharpIcon />,
    },
    {
      title: "Create Profiles",
      isShow: true,
      component: (
        <CreateProfile
          title="Create Profiles"
          handleCloseButton={handleCloseButton}
        />
      ),
      icon: <GroupsSharpIcon />,
    },
    {
      title: "Customize Work Policy",
      isShow: isManager,
      component: (
        <CreatePolicy
          title="Customize Work Policy"
          handleCloseButton={handleCloseButton}
        />
      ),
      icon: <AssignmentSharpIcon />,
    },
    {
      title: "Define your holidays",
      isShow: isManager,
      component: (
        <AddHoliday
          title="Define your holidays"
          handleCloseButton={handleCloseButton}
        />
      ),
      icon: <BeachAccessSharpIcon />,
    },
    {
      title: "Connect to Slack",
      isShow: true,
      component: (
        <AddToSlack
          title="Connect to Slack"
          handleCloseButton={handleCloseButton}
        />
      ),
      icon: <TagSharpIcon className="slack-icon" />,
    },
  ];

  return filter(tabs, el => el.isShow);
};

const SetupGuide = (props) => {
  const { handleCloseButton } = props;
  const [setupGuideDrawerList, setSetupGuideDrawerList] = useState([]);
  const selectedTheme = useTheme();
  const colorTheme = selectedTheme?.palette?.primary?.main;

  useEffect(() => {
    setSetupGuideDrawerList(getSetupGuideDrawerList(handleCloseButton));
  }, []);

  return (
    <div className="c-setup">
      {!!setupGuideDrawerList.length && (
          <LeftTabsDrawer
              drawerList={setupGuideDrawerList}
              orientation="vertical"
          >
            <div className="drawer-header ">
              <div className="employee-image">
                <StarsIcon className="image" style={{color: colorTheme}}/>
                <h3 style={{color: colorTheme}} className='d-label-none px-2'>Setup Guide</h3>
              </div>
            </div>
          </LeftTabsDrawer>
      )}
    </div>
  );
};

export default SetupGuide;
