import React from "react";
import { Card } from "components/Mui";

import "./StatusCard.scss";

const StatusCard = ({ status, className='', colClassName, oneCard, oneCardLabel, valueLabel='days', formatted=true }) => {

  return (
    <div className={`${className} row`}>
        {oneCard ?
            <div className="col-auto mx-auto">
                <Card className="c-status-card d-flex px-4">
                    {Object.keys(status).map((key, index) => (
                        <div key={key} className="c-status-card_container d-flex align-items-center">
                            {index % 2 === 1 &&
                                <div className="back-slash">/</div>
                            }
                            <div key={index}>
                                <div className="ellipsis">{key}</div>
                                <div className="fw-bold">{status[key]?.toFixed(2)} {oneCardLabel}</div>
                            </div>
                        </div>
                    ))}
                </Card>
            </div>
            :
            <>
              {Object.keys(status).map((key, index) => (
                  <div key={key} className={colClassName ? colClassName : "col-6 col-md-3"}>
                     <Card className="c-status-card">
                          <div className="c-status-card_container" key={index}>
                            <div className="border-bottom p-2 ellipsis fw-bold">{key}</div>
                            <div className="p-3">
                              {formatted?<span className="fw-bold">{parseFloat(status[key])?.toFixed(2)}</span>:<span className="fw-bold">{status[key]}</span>}
                              <span> {valueLabel}</span>
                            </div>
                          </div>
                     </Card>
                  </div>
              ))}
            </>
        }
    </div>
  );
};

export default StatusCard;
