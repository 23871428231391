import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import WithModals from "hoc/withModals/withModals";
import {addFunctionalRole, deleteFunctionalRole, updateFunctionalRole} from "utility/apis/SettingService";
import {Button, Dialog, DialogActions, Grid, TextField, CircularProgress} from "components/Mui";
import {CONFIRMATION_MODAL} from "utility/modals/constants";
import {getErrorMessage} from "utility/helpers/utils";

import "./FunctionalRoleForm.scss"
import {toast} from "react-toastify";

const FunctionalRoleForm = (props) => {
    const {role, tenantId, fetchData, colorTheme, toggleFunctionalRoleModal, modals, toggleConfirmationModal} = props;
    const { register, control, handleSubmit, errors, setError } = useForm({
        mode: 'onSubmit'
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const refreshData = () => {
        if (fetchData) {
            fetchData() ;
        }
    }

    const handleSave = async (payload)=> {
        if (isSubmitting) return;
        setIsSubmitting(true);
        // payload['tenantId'] =  tenantId;
        payload['value'] = payload.value? payload.value : role?.value;
        payload['description'] = payload.description? payload.description : role?.description;

        try {
            role ? await updateFunctionalRole(payload, tenantId, role.id) : await addFunctionalRole(payload, tenantId);
            toast.success(role ? 'Updated Successfully' : 'Saved Successfully');
            refreshData();
            if(toggleFunctionalRoleModal){
                toggleFunctionalRoleModal();
            }
        } catch (e) {
            const message = getErrorMessage(e);
            // setError("value", { message });
            toast.error(message);
            setIsSubmitting(false);
        }
    };
    const handleDelete = async ()=> {
        if (isSubmitting) return;
        setIsSubmitting(true);
        try {
            await deleteFunctionalRole(tenantId, role?.id);
            toast.success("Deleted Successfully");
            refreshData();
            toggleConfirmationModal();
        } catch (e) {
            toast.error(getErrorMessage(e));
            setIsSubmitting(false);
        }
    };

    return (
        <div className="c-functional-role-form">
            <form onSubmit={handleSubmit(handleSave)} className="m-3">
                <Grid container spacing={{md: 1, xs: 1}}>
                    <Grid item md={12} xs={12}>
                        <TextField
                            inputRef={register({
                                required: "required",
                            })}
                            errors={errors}
                            control={control}
                            label="Name"
                            mode="onBlur"
                            name="value"
                            defaultValue={role?.value}
                        />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TextField
                            minRows={3}
                            inputRef={register({
                                required: "required",
                            })}
                            label="Description"
                            errors={errors}
                            control={control}
                            mode="onBlur"
                            name="description"
                            defaultValue={role?.description}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} className="my-3">
                    <Button type="submit" variant="contained" className='float-end my-3'>
                        {isSubmitting ? (
                                <CircularProgress size={24} color="inherit" />
                            ) :
                            "Save"
                        }
                    </Button>
                    {toggleFunctionalRoleModal ?
                        <Button
                            className="float-end m-3"
                            variant="outlined"
                            onClick={toggleFunctionalRoleModal}
                        >
                            Cancel
                        </Button>
                        :
                        <Button
                            className="float-end m-3 bg-danger"
                            color="secondary"
                            variant="outlined"
                            onClick={() => {
                                toggleConfirmationModal();
                            }}
                        >
                            Delete
                        </Button>
                    }
                </Grid>
                <Dialog className='confirmation-dialog' open={modals[CONFIRMATION_MODAL]} toggle={toggleConfirmationModal} >
                    <>
                        <div className="m-4" style={{color: colorTheme}}>
                            Are you sure you want to delete this ?
                        </div>
                        <div>
                            <DialogActions className="p-3 mt-3">
                                <Button className="px-4 me-3" variant="outlined"
                                        onClick={toggleConfirmationModal}>Cancel</Button>
                                <Button className="px-4 bg-danger" color="secondary" variant="outlined"
                                        onClick={() => handleDelete()}>
                                    {isSubmitting ? (
                                            <CircularProgress size={24} color="inherit" />
                                        ) :
                                        "Delete"
                                    }
                                </Button>
                            </DialogActions>
                        </div>
                    </>
                </Dialog>
            </form>
        </div>
    )
}
const modalsProps = {
    toggleConfirmationModal: CONFIRMATION_MODAL
};

export default WithModals(FunctionalRoleForm, modalsProps);
