import React from 'react';
const withModals = (WrappedComponent, modalsToggleProps = {}, modalsStatusProps = {}) =>  {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.toggleModal = this.toggleModal.bind(this);
      this.state = {
        modals: {},
      };
    }

    toggleModal(identifier) {
      this.setState(prevState => ({
        modals: {...prevState.modals, [identifier]: !prevState.modals[identifier]}
      }));
    }

    render() {
      // ... and renders the wrapped component with the fresh data!
      // Notice that we pass through any additional props
      const modalsProps = {};
      Object.keys(modalsToggleProps).forEach((modalProp) => {
        modalsProps[modalProp] = () => this.toggleModal(modalsToggleProps[modalProp]);
      });
      Object.keys(modalsStatusProps).forEach((modalProp) => {
        modalsProps[modalProp] = this.state.modals[modalsStatusProps[modalProp]];
      });


      return <WrappedComponent modals={this.state.modals}
                               toggleModal={this.toggleModal} {...this.props} {...modalsProps} />;
    }
  };
};
export default withModals;
