import React, { useState, useEffect } from 'react';
import {useForm} from "react-hook-form";
import { EditIcon, DeleteIcon } from "components/Mui/Icons";
import {formatDate, getErrorMessage} from "utility/helpers/utils";
import { getCompensationsTime, updateCompensation, deleteCompensation } from "utility/apis/EmployeeService";
import { COMPENSATION_TIME_MODAL, CONFIRMATION_MODAL } from "utility/modals/constants";
import WithModals from "hoc/withModals/withModals";
import { TABLES_NAMES } from "utility/constants/constants";
import {
    Button,
    DataGridTable,
    DialogActions,
    GridActionsCellItem,
    Dialog,
    TextField,
    Tooltip
} from "components/Mui";
import Loading from "components/Loading/Loading";
import {toast} from "react-toastify";
import moment from "moment/moment";

import "./CompensationsTime.scss";


const CompensationsTime = (props) => {
    const { employeeId, date, colorTheme, modals, toggleCompensationTimeModal, toggleConfirmationModal, message } = props;

    const [ inProgress, setInProgress ] = useState(false);
    const [selectedCompensationTime, setSelectedCompensationTime] = useState({});
    const { handleSubmit, register, errors } = useForm({ mode: 'onSubmit' });
    const [dataTable, setDataTable] = useState([]);
    const [isInProgress, setIsInProgress] = useState(false);

    const fetchData = async () => {
        setInProgress(true);
        try {
            const response = await getCompensationsTime(employeeId, moment.isMoment(date)? date.format("YYYY") : moment.isMoment(date.value)? moment(date.value).format("YYYY") : date?.value);
            setDataTable(response?.data?.compensation);
        } catch (e) {
        }
        setInProgress(false);
    }

    const refreshData = () => {
        fetchData() ;
    }

    useEffect(() => {
        if (date) {
            fetchData();
        }
    }, [date]);

    const handleEditClick = (event, params) => {
        setSelectedCompensationTime(params.row);
        toggleCompensationTimeModal();
    }

    const handleDeleteClick = (event, params) => {
        setSelectedCompensationTime(params.row);
        toggleConfirmationModal();
    }

    const handleCompensationUpdate = async (data) => {
        setIsInProgress(true);
        try {
            const { value, comments } = data
            const payload = {
                ...selectedCompensationTime,
                value_days: parseFloat(value),
                value: parseFloat(value),
                comments
            }

            await updateCompensation(selectedCompensationTime.id, payload);
            refreshData();
            toggleCompensationTimeModal();
            toast.success("Updated Successfully");

        } catch (e) {
            toast.error(getErrorMessage(e));
        }finally {
            setTimeout(() => {
                setIsInProgress(false);
            }, 2000);
        }
    }

    const handleCompensationDelete = async () => {
        setIsInProgress(true);
        try {
            await deleteCompensation(selectedCompensationTime.id);
            refreshData();
            toggleConfirmationModal();
            toast.success("Deleted Successfully");
        } catch (e) {
            toast.error(getErrorMessage(e));
        }finally {
            setTimeout(() => {
                setIsInProgress(false);
            }, 2000);
        }
    }

    const columnDefs = [
        {
            headerName: 'Date',
            field: 'created',
            renderCell: (params) => (
                <Tooltip title={formatDate(params.value)}>
                    <div className="ellipsis">
                        {formatDate(params.value)}
                    </div>
                </Tooltip>
            ),
            flex: 1
        },
        {
            headerName: 'Duration',
            field: 'value',
            renderCell: (params) => (
                <Tooltip title={`${params.value} ${params.value > 1 ? 'days' : 'day'}`}>
                    <div className="ellipsis">
                        {parseFloat(params.value).toFixed(params.value % 1 === 0 ? 0 : 2)} {params.value > 1 ? 'days' : 'day'}
                    </div>
                </Tooltip>
            ),
            flex: 1
        },
        {
            headerName: 'Comment',
            field: 'comments',
            flex: 2
        },
        {
            headerName: 'Actions',
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params) => [
                <GridActionsCellItem showInMenu icon={<EditIcon />} onClick={(event) => handleEditClick(event, params)} label="Edit" />,
                <GridActionsCellItem showInMenu icon={<DeleteIcon />} onClick={(event) => handleDeleteClick(event, params)} label="Delete" className="text-danger" />,
            ]
        }
    ];

    return (
        <div id="compensations-time" className="c-compensations-time">
            {inProgress ?
                <Loading></Loading>
                :
                <div className="container-fluid">
                <div style={{height: `calc(100vh - ${message ? 200 : 165}px)`}} className="row">
                        <DataGridTable
                            className="col-12"
                            key={TABLES_NAMES.COMPENSATIONS_TIME_LIST}
                            columns={columnDefs}
                            dataTable={dataTable}
                            isExport={false}
                        />
                    </div>
                </div>
            }
            <Dialog className='compensations-time-dialog' open={modals[COMPENSATION_TIME_MODAL]} toggle={toggleCompensationTimeModal}>
                <form onSubmit={handleSubmit(handleCompensationUpdate)}>
                    <div className="m-4">
                        <h6 className="mb-4"  style={{color: colorTheme}}>Edit compensation created
                            in {formatDate(selectedCompensationTime?.created)}</h6>
                        <div className="row dialog-row">
                            <TextField defaultValue={selectedCompensationTime?.comments}
                                       inputRef={register}
                                       className="col-12" id="comments" name="comments" label="Comment"
                                       type="text"/>
                            <TextField defaultValue={selectedCompensationTime?.value}
                                       inputRef={register({
                                           required: "This field is required",
                                           pattern: {
                                               value: /^(0|[1-9]\d*)(\.\d+)?$/,
                                               message: "Enter a valid number",
                                           }
                                       })}
                                       errors={errors}
                                       className="col-12" id="value" name="value"
                                       label="Compensation Duration" type="number"
                                       inputProps={{
                                           step: "any"
                                       }}
                            />
                            {errors?.date && <div  className="error-message col-12">{errors?.date?.message}</div>}
                        </div>
                    </div>
                    <div>
                        <DialogActions className="p-3 mt-3">
                            <Button className="px-4 me-3" variant="outlined" onClick={toggleCompensationTimeModal}>Cancel</Button>
                            <Button className="px-4" variant="contained" type="submit" disabled={isInProgress}>Save</Button>
                        </DialogActions>
                    </div>
                </form>
            </Dialog>
            <Dialog className='confirmation-dialog' open={modals[CONFIRMATION_MODAL]} toggle={toggleConfirmationModal}>
                <div>
                    <div className="m-4" style={{color: colorTheme}}>
                        Are you sure you want to delete this compensation ?
                    </div>
                    <div>
                    <DialogActions className="p-3 mt-3">
                        <Button className="px-4 me-3" variant="outlined" onClick={toggleConfirmationModal}>Cancel</Button>
                        <Button className="px-4 bg-danger" color="secondary" variant="outlined" onClick={handleCompensationDelete} disabled={isInProgress}>Delete</Button>
                    </DialogActions>
                </div>
                </div>
            </Dialog>
        </div>
    );
}
const modalsProps = {
    toggleCompensationTimeModal: COMPENSATION_TIME_MODAL,
    toggleConfirmationModal: CONFIRMATION_MODAL
};

export default WithModals(CompensationsTime, modalsProps)
