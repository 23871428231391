import React from "react";
import { Checkbox, FormControlLabel, Grid, Select, TextField, Typography } from "components/Mui";
import { Controller } from "react-hook-form";
import { daysPerYearArr } from "utility/constants/data";


const Rollover = (props) => {
  const { errors, register, control, dataFromServer } = props;
  return (
    <Grid container spacing={{ md: 1, xs: 1 }}>
      <Grid item xs={12}>
        <div className="fs-5 fw-semibold mt-3">Rollover (carried over amount to next year)</div>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={{ md: 1, xs: 0 }}>
          <Grid item xs={12}>
            <Typography as="h6" style={{ paddingTop: "12px", paddingLeft: "0" }}>
              Carried over per year
            </Typography>
          </Grid>
          <Grid item xs={6} md={2}>
            <Controller
              as={
                <TextField
                  hiddenLabel
                  errors={errors}
                  inputRef={register({
                    required: "required",
                  })}
                />
              }
              id="carriedPerYear"
              type="number"
              inputRef={register({
                  required: "This field is required",
                  validate: (value) =>
                      value >= 0,
              })}
              inputProps={{
                  min: 0,
              }}
              control={control}
              mode="onBlur"
              defaultValue={dataFromServer?.carriedPerYear}
              name={`carriedPerYear`}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Controller
              as={
                  <Select hiddenLabel options={daysPerYearArr} />
              }
              sx={{ minWidth: "134px" }} // Set fixed width
              id="carriedPerYearUnit"
              control={control}
              mode="onBlur"
              name={`carriedPerYearUnit`}
              defaultValue={dataFromServer?.carriedPerYearUnit}
            />
          </Grid>
          <Grid className="allow-negative-time" item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  inputRef={register}
                  name="limited"
                  defaultChecked={dataFromServer?.limited}
                />
              }
              style={{ paddingBottom: 12, paddingLeft: 0 }}
              label="Allow negative time off"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={{ md: 1, xs: 0 }}>
          <Grid item xs={12} md={2}>
            <Controller
              as={
                <TextField
                  label="Max Amount"
                  InputLabelProps={{ shrink: true }}
                />
              }
              id="maxNigative"
              type="number"
              inputRef={register({
                  required: "This field is required",
                  validate: (value) =>
                      value >= 0,
              })}
              inputProps={{
                  min: 0,
              }}
              control={control}
              mode="onBlur"
              name={`maxNigative`}
              defaultValue={dataFromServer?.maxNigative}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Controller
              as={
                  <Select options={daysPerYearArr} />
              }
              sx={{ minWidth: "134px" }} // Set fixed width
              id="maxNigativeUnit"
              control={control}
              mode="onBlur"
              name={`maxNigativeUnit`}
              defaultValue={dataFromServer?.maxNigativeUnit}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Rollover;
