import React, {useEffect, useState} from 'react';
import CustomAppBar from "components/CustomAppBar/CustomAppBar";
import {AddCircleIcon, ExpandMoreIcon} from "components/Mui/Icons";
import WithModals from "hoc/withModals/withModals";
import {BENEFIT_FORM_MODAL, TIME_OFF_REQUEST_MODAL} from "utility/modals/constants";
import {Accordion, AccordionDetails, AccordionSummary, Button, DatePicker, Dialog, Typography, DialogActions} from "components/Mui";
import Loading from "components/Loading/Loading";
import BenefitForm from "./BenefitForm/BenefitForm";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {getBenefits} from "utility/apis/SettingService";
import {getEmployees} from "utility/apis/EmployeeService";
import {getTeams} from "utility/apis/ConfigService";
import {getDateOptions} from "utility/helpers/utils";
import moment from "moment";

 import "./BenefitSetting.scss"

const BenefitSetting = (props) => {
    const {title, handleCloseButton, tenantId, colorTheme, modals, toggleBenefitFormModal, toggleTimeOffEditModal } = props;
    const [expanded, setExpanded] = useState(false);
    const [ date, setDate ] = useState();
    const [benefits, setBenefits] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [teams, setTeams] = useState([]);
    const [ inProgress, setInProgress ] = useState(false);
    const [specificDate, setSpecificDate] = useState();
    const [years, setYears] = useState(getDateOptions());

    const handleDateChange = (year) => {
        if (year === "customDate") {
            toggleTimeOffEditModal();
        } else {
            const yearData = years.find((item) => item.value === (year.value || year) );
            if (yearData) {
                setDate(yearData);
            }
        }
    };

    const handleCustomDate = () => {
        if (!specificDate) return;

        const newYearValue = specificDate.format("yyyy");

        if (!years.some((year) => year.value === newYearValue)) {
            setYears((prevYears) => {
                const lastElement = prevYears[prevYears.length - 1];
                const updatedYears = [
                    ...prevYears.slice(0, -1),
                    { label: newYearValue, value: newYearValue },
                    lastElement
                ];
                return updatedYears;
            });
        }
        setDate(specificDate);
        toggleTimeOffEditModal();
    };

    useEffect(() => {
        fetchData();
    }, [date]);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const fetchData = async () => {
        if(!date) return;
        try {
            setInProgress(true);
            const response = await getBenefits(tenantId, moment.isMoment(date)? moment(date).format("YYYY") : date?.value);
            setBenefits(response.data);
        } catch (e) {
            console.log(e);
        }
        setInProgress(false);
    }

    const refreshData = () => {
        fetchData() ;
        setExpanded(false);
    }

    const getAllEmployees = async () => {
        try {
            const response = await getEmployees(tenantId, 0, { activated: true });
            const employeeList = response.data.content.map(item => ({ id: item.id, value: item.name, profilePicture : item.profilePicture }));
            setEmployees(employeeList);
        } catch (e) {
            console.log(e);
        }
    }
    const getAllTeams = async () => {
        try {
            let response = await getTeams(tenantId);
            const teamList = response.data.map(item => ({ id: item.id , value: item.name }));
            setTeams(teamList);
        } catch (e) {
            console.log(e);
        }
    }
    useEffect(() => {
        getAllEmployees();
        getAllTeams();
    }, []);

    return (
        <div id="BenefitSetting" className="c-benefit-setting px-4 mt-4">
            <CustomAppBar
                title={title}
                date={date}
                dateOptions={years}
                addCustomDate
                handleDateChange={handleDateChange}
                showSecondaryButton
                textSecondary="Close"
                handleCloseButton={handleCloseButton}
            />
            {inProgress?
                <Loading></Loading>
            :
                <>
                    <div className="d-flex">
                        <div className="message-frame">
                            Create a new benefit
                        </div>
                        <Button className="add-benefit-setting-button" onClick={toggleBenefitFormModal}>
                            <AddCircleIcon/>
                        </Button>
                    </div>
                    {benefits.map((item) => (
                        <Accordion
                            className="my-3"
                            expanded={expanded === item.id}
                            onChange={handleChange(item.id)}
                            key={item.id}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                className="accordion-summary-bg"
                            >
                                <div className="d-flex justify-content-between w-100">
                                    <Typography className="holiday-title">{item.name}</Typography>
                                    <Typography className="holiday-status">{item.active ? 'Active' : 'Not Active'}</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <BenefitForm employees={employees} teams={teams} benefit={item} refreshData={refreshData} date={date} colorTheme={colorTheme}/>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </>
            }

            <Dialog
                className="details-dialog"
                open={modals[BENEFIT_FORM_MODAL]}
                toggle={toggleBenefitFormModal}
                mainTitle={'Create a New Benefit'}
            >
                <div>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <BenefitForm employees={employees} teams={teams} tenantId={tenantId} refreshData={refreshData} date={date} toggleBenefitFormModal={toggleBenefitFormModal} colorTheme={colorTheme}/>
                    </LocalizationProvider>
                </div>
            </Dialog>
            <Dialog className='time-off-request-dialog' open={modals[TIME_OFF_REQUEST_MODAL]} toggle={toggleTimeOffEditModal}>
                <div className="m-4">
                    <h6 className="mb-4" style={{color: colorTheme}}>Custom Date</h6>
                    <div className="column">
                        <DatePicker
                            name="specificDate"
                            label="Specific Date"
                            className="mx-1"
                            value={specificDate}
                            onChange={(newValue) => setSpecificDate(newValue)}
                            views={['year']}
                            openTo="year"
                        />
                    </div>
                </div>
                <div>
                    <DialogActions className="p-3 mt-3">
                        <Button className="px-4 me-3" variant="outlined" onClick={toggleTimeOffEditModal}>Cancel</Button>
                        <Button className="px-4" variant="contained" type="submit" onClick={handleCustomDate}>Save</Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
};
const modalsProps = {
    toggleBenefitFormModal: BENEFIT_FORM_MODAL,
    toggleTimeOffEditModal: TIME_OFF_REQUEST_MODAL
};

export default WithModals(BenefitSetting, modalsProps)
