import React  from "react";
import { Button, Grid, Select, TextField } from "components/Mui";
import { Controller } from "react-hook-form";
import { daysPerYearArr, fromYearsArr, toYearsArr } from "utility/constants/data";
import { AddIcon, ClearIcon } from "components/Mui/Icons";

const AccrualRules = (props) => {
  const { fields, register, handleChangedForm, errors, control, remove, append} = props;

    const handleFieldChange = () => {
        handleChangedForm();
    };
  
  return (
    <>
      <Grid container md={12} className='my-3'>
        <div className="fs-5 fw-semibold">Accrual rules</div>
      </Grid>

      {fields?.map((item, index) => (
        <Grid container spacing={{ md: 1, xs: 1 }} key={item.id}>
          <Grid item xs={12} md={7}>
            <Grid container spacing={{ md: 0, xs: 1 }}>
              { index === 0 && (
                  <Grid item xs={12}>
                      <div className="mb-3" style={{fontSize : "14px",color:"rgba(0, 0, 0, 1)"}}>Years of Service</div>
                  </Grid>
              )}
              <Grid item xs={4} md={2}>
                <TextField
                  hiddenLabel
                  style={{ width: "80px" }}
                  inputRef={register({
                      required: "This field is required",
                      validate: (value) =>
                          value >= 0,
                  })}
                  inputProps={{
                      min: 0,
                      step: "any"
                  }}
                  errors={errors}
                  control={control}
                  mode="onBlur"
                  type="number"
                  name={`increaseSet[${index}].fromValue`}
                  defaultValue={item.fromValue}
                  onChange={handleFieldChange}
                />
              </Grid>
              <Grid item xs={8} md={3}>
                <Controller
                  as={
                      <Select hiddenLabel options={fromYearsArr} />
                  }
                  sx={{minWidth:"101px",paddingLeft:"8px"}} // Set fixed width
                  id="fromUnit"
                  control={control}
                  mode="onBlur"
                  name={`increaseSet[${index}].fromUnit`}
                  defaultValue={item.fromUnit}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                  <div className="fs-6 mb-3 from-to-lbl">To</div>
              </Grid>
              <Grid item md={2} xs={4}>
                <Controller
                  as={
                    <TextField
                      hiddenLabel
                      errors={errors}
                      style={{ width: "80px" }}
                    />
                  }
                  id="toValue"
                  type="number"
                  inputRef={register({
                      required: "This field is required",
                      validate: (value) =>
                          value >= 0,
                  })}
                  inputProps={{
                      min: 0,
                      step: "any"
                  }}
                  control={control}
                  mode="onBlur"
                  name={`increaseSet[${index}].toValue`}
                  defaultValue={item.toValue === 0? null : item.toValue}
                />
              </Grid>
              <Grid item md={3} xs={8}>
                <Controller
                  as={
                      <Select hiddenLabel options={toYearsArr} />
                  }
                  sx={{minWidth:"101px",paddingLeft:"8px"}} // Set fixed width
                  id="toUnit"
                  control={control}
                  mode="onBlur"
                  name={`increaseSet[${index}].toUnit`}
                  defaultValue={item.toUnit}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={5} xs={12} className="per-year-grid">
            <Grid container spacing={{ md: 0, xs: 1 }}>
              <Grid item xs={12}>
                { index === 0 && (
                    <Grid item xs={12}>
                        <div className="mb-3"  style={{fontSize : "14px",color:"rgba(0, 0, 0, 1)"}}>Days per year</div>
                    </Grid>
                )}
              </Grid>
              <Grid item xs={3}>
                <Controller
                  as={
                    <TextField
                      hiddenLabel
                      inputRef={register({
                        required: "required",
                      })}
                      errors={errors}
                      style={{ width: "80px" }}
                    />
                  }
                  id="value"
                  type="number"
                  inputRef={register({
                      required: "This field is required",
                      validate: (value) =>
                          value >= 0,
                  })}
                  inputProps={{
                      min: 0,
                      step: "any"
                  }}
                  control={control}
                  mode="onBlur"
                  name={`increaseSet[${index}].value`}
                  defaultValue={item.value}
                />
              </Grid>
              <Grid item xs={5}>
                <Controller
                  as={
                      <Select hiddenLabel options={daysPerYearArr} />
                  }
                  sx={{ minWidth: "142px" ,paddingLeft:"8px"}} // Set fixed width
                  id="valueUnit"
                  control={control}
                  mode="onBlur"
                  name={`increaseSet[${index}].valueUnit`}
                  defaultValue={item.valueUnit}
                />
              </Grid>
              <Grid item xs={2}>
                {index !== 0 && (
                  <Button
                    className="delete-btn"
                    type="button"
                    onClick={() => {
                      remove(index);
                        handleFieldChange();
                    }}
                    variant="contained"
                  >
                    <ClearIcon />
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Grid container spacing={{ md: 1, xs: 1 }}>
        <Grid item xs={12}>
        <Button
          type="button"
          onClick={() => {
            append({
              fromValue: 0,
              fromUnit: "Year",
              toValue: 0,
              toUnit: "Year",
              value: 0,
              valueUnit: "WorkingDay",
            });
          }}
          variant="contained"
        >
          <AddIcon />
        </Button>
      </Grid>
      </Grid>
    </>
  );
};

export default AccrualRules;
