import React, {useState} from 'react';
import {Controller, useForm} from "react-hook-form";
import moment from "moment/moment";
import {TextField, TimePicker} from "components/Mui";
import {Grid} from "components/Mui";
import {TIME_24_HOUR_FORMAT} from "utility/helpers/metaData";
import {logoFileTypes} from "utility/constants/data";
import FileUploader from "components/FileUploader/FileUploader";
import CustomAppBar from "components/CustomAppBar/CustomAppBar";

import "./BirthdaySetting.scss"


const BirthdaySetting = (props) => {
    const {handleCloseButton} = props;
    const [submitting, setSubmitting] = React.useState(false);
    const [image, setImage] = useState(null);

    const { register, control, handleSubmit, errors} = useForm({
        defaultValues: {},
    });
    const handleSave = async (data) => {
        //the backend side not ready
    };
    const handleFileChange = (file) => {
        setImage(file);
    }
    return (
        <div id="BirthdaySetting" className="c-birthday-setting ps-x mt-4">
            <form id="WorkFromHomePolicy" className="row d-flex flex-column" onSubmit={handleSubmit(handleSave)}>
                <CustomAppBar
                    title="Birthday Wishes"
                    showPrimaryButton
                    showSecondaryButton
                    textSecondary="Close"
                    handleCloseButton={handleCloseButton}
                    submitting={submitting}
                />
                <Grid container spacing={{ md: 1, xs: 0 }}>
                    <Grid item xs={10}>
                        <Controller
                            as={
                                <TextField
                                    errors={errors}
                                    label="Birthday Message"
                                    inputRef={register({
                                        required: "this field is required",
                                    })}
                                    InputLabelProps={{shrink: true}}
                                    rows={3}
                                    multiline
                                    type="string"
                                />
                            }
                            name="birthdayMessage"
                            id="birthdayMessage"
                            className='row'
                            control={control}
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <p>Birthday card image</p>
                    </Grid>
                    <Grid item xs={10}>
                        <FileUploader
                            multiple={false}
                            handleChange={handleFileChange}
                            name="file"
                            types={logoFileTypes}
                            label="Drag Image To Upload Or Tap To Select"
                            className="file-uploader-center"
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Controller
                            as={
                                <TimePicker defaultValue={moment("15:00", TIME_24_HOUR_FORMAT)} />
                            }
                            label="Set Time"
                            defaultValue={moment("15:00", TIME_24_HOUR_FORMAT)}
                            name="setTime"
                            control={control}
                            className='mt-3'
                        />
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};

export default BirthdaySetting;
