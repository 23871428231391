import React from 'react';
import TimeOffPolicyEmployeeForm from "components/EmployeeDetailsForms/CustomizeTimeOff/TimeOffPolicyEmployeeForm/TimeOffPolicyEmployeeForm";
import {updatePolicy} from "utility/apis/SetupGuideService";
import {toast} from "react-toastify";
import {getErrorMessage} from "../../../../../utility/helpers/utils";

const TimeOffPolicy = (props) => {
    const { setInProgress, refreshPolicyData, timeOffPolicyData, tenantId } = props;
    const handleSave = async (data) => {
        setInProgress(true);
        try {
            await updatePolicy("timeOffPolicy", timeOffPolicyData?.id, tenantId, data);
            if (refreshPolicyData) {
                refreshPolicyData();
            }
            toast.success("Updated Successfully");
        } catch (e) {
            toast.error(getErrorMessage(e));
            setInProgress(false);
        }
    };
    return (
        <div id="TimeOffPolicy" className="c-time-off-policy px-4">
            <TimeOffPolicyEmployeeForm
                dataFromServer={timeOffPolicyData || {}}
                handleSave={handleSave}
                withSaveButton
            />
        </div>
    );
};

export default TimeOffPolicy;
