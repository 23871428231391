import React  from 'react';
import { Route, Redirect } from 'react-router-dom';
import classNames from "classnames";
import AdminHeader from 'containers/Header/AdminHeader';
import {getIsLoggedIn, getIsAdmin, getUserInfo} from 'utility/helpers/authHelper'
import { ThemeProvider } from "components/Mui";
import {defaultTheme} from "theme";
import BackgroundImage from "assets/images/background.png";

import './AdminLayout.scss';

const AdminLayout = ({ component: Component, className='logged-in', ...rest }) => {
    const isLoggedIn = getIsLoggedIn();
    const userInfo = JSON.parse(getUserInfo());
    return (
        <Route
            {...rest}
            render={(matchProps) => {
                if (!isLoggedIn) {
                    return (
                        <Redirect
                            to={{ pathname: '/login', state: { from: matchProps.location } }}
                        />
                    );
                } else if (!userInfo?.showBotLogs) {
                    return (
                        <Redirect to={{ pathname: '/tenants' }} />
                    );
                } else if (userInfo?.showBotLogs) {
                    return (
                        <ThemeProvider theme={defaultTheme}>
                            <div className={classNames("c-layout", className)}>
                                <AdminHeader />
                                <main className="c-admin-layout c-layout_main container-fluid background-style px-0"
                                      style={{ backgroundImage: `url(${BackgroundImage})`, backgroundRepeat: 'no-repeat'}}>
                                    <Component {...rest} {...matchProps} />
                                </main>
                            </div>
                        </ThemeProvider>
                    );
                }
            }}
        />
    );
};

export default AdminLayout;
