import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  CircularProgress,
  useTheme
} from "components/Mui";
import { useForm } from "react-hook-form";
import CustomAppBar from "components/CustomAppBar/CustomAppBar";
import { useLocation } from "react-router-dom";
import {getErrorMessage, getTenantId, timeSlots} from "utility/helpers/utils";

import Template from "./Template/Template";
import WorkingDays from "./WorkingDays/WorkingDays";
import TimeSlots from "./TimeSlots/TimeSlots";

import { createWorkScheduleTemplate } from "utility/apis/SetupGuideService";
import { workingDaysOption } from "utility/constants/data";
import moment from "moment";
import { TIME_FORMAT } from "utility/helpers/metaData";
import { updateWorkScheduleTemplate } from "utility/apis/WorkScheduleTemplateService";
import {EditIcon, NotesIcon} from "components/Mui/Icons";
import {toast} from "react-toastify";
import {getIsManager} from "utility/helpers/authHelper";

import "./CreateEditTemplate.scss";

const CreateEditTemplate = (props) => {
  const {
    title,
    handleCloseButton,
    fromList,
    fetchData,
    dataFromServer,
    handleDelete,
    addWithCustomBar
  } = props;
  const tenantId = getTenantId(useLocation(), "tenantId");
  const [disableAllField, setDisableAllField] = useState(fromList);
  const [submitting, setSubmitting] = React.useState(false);
  const [slotShifted, setSlotShifted] = React.useState(fromList);
  const filteredSlots = dataFromServer?.slots?.filter(
      (item) => item.day === 2
  );
  const { register, control, handleSubmit, errors, setValue, watch } = useForm({
    defaultValues: {
      fromTimeSlot1: filteredSlots?.length > 0 && moment(
          `${filteredSlots[0]?.startSlotTimeHours}:${filteredSlots[0]?.startSlotTimeMinutes}`,
          TIME_FORMAT
      ),
      toTimeSlot1: filteredSlots?.length > 0 && moment(
          `${filteredSlots[0]?.endSlotTimeHours}:${filteredSlots[0]?.endSlotTimeHours}`,
          TIME_FORMAT
      ),
      fromTimeSlot2: filteredSlots?.length > 1 && moment(
          `${filteredSlots[1].startSlotTimeHours}:${filteredSlots[1].startSlotTimeMinutes}`,
          TIME_FORMAT
      ),
      toTimeSlot2: filteredSlots?.length > 1 && moment(
          `${filteredSlots[1].endSlotTimeHours}:${filteredSlots[1].endSlotTimeMinutes}`,
              TIME_FORMAT
      ),
    },
  });
  const [workingDaysSelected, setWorkingDaysSelected] = useState();
  const [typeSelected, setTypeSelected] = useState();
  const [timeSlotsSelected, setTimeSlotsSelected] = useState();
  const workingDaysValue = watch('workingDays');
  const typeValue = watch('templateType');
  const selectedTheme = useTheme();
  const colorTheme = selectedTheme?.palette?.primary?.main;
  const isEditView = getIsManager();

  const refreshData = () => {
    if (fetchData) {
      fetchData();
    }
  }
  const isPeriodIncluded = (from1, to1, from2, to2) => {
    if(!from2) return true
    return (from1 <= from2) && (to1 >= to2);
  };

  function convertCustomTimeSlots(data, timeSlotsSelected) {
    const results = [];
    data?.forEach((item, index) => {
      const day = index;
      const timeSlot = timeSlotsSelected.find(slot => slot.id === day);

      if(timeSlot?.value){
          const from1 = item.fromTimeSlot1? new Date(item.fromTimeSlot1): new Date();
          const to1 = item.toTimeSlot1? new Date(item.toTimeSlot1): new Date();

          results.push({
            day: timeSlot?.id,
            startSlotTimeHours: from1.getHours(),
            startSlotTimeMinutes: from1.getMinutes(),
            endSlotTimeHours: to1.getHours(),
            endSlotTimeMinutes: to1.getMinutes(),
            leaveAtHours: 0,
            leaveAtMinutes: 0
          });
          const from2 = item.fromTimeSlot2? new Date(item.fromTimeSlot2): new Date();
          const to2 = item.toTimeSlot2? new Date(item.toTimeSlot2): new Date();

          if (timeSlot?.slotShifted && item.fromTimeSlot2 && item.toTimeSlot2 && !isPeriodIncluded(from1, to1, from2, to2) &&
              ((from1.getHours() !== from2.getHours() || from1.getMinutes() !== from2.getMinutes()) ||
              (to1.getHours() !== to2.getHours() || to1.getMinutes() !== to2.getMinutes()))) {


            results.push({
              day: timeSlot?.id,
              startSlotTimeHours: from2.getHours(),
              startSlotTimeMinutes: from2.getMinutes(),
              endSlotTimeHours: to2.getHours(),
              endSlotTimeMinutes: to2.getMinutes(),
              leaveAtHours: 0,
              leaveAtMinutes: 0
            });
          }
      }
    });

    return results;
  }
  const handleSave = async (data) => {
    try {
      let slots;
      if(workingDaysSelected === "Custom"){
        slots = convertCustomTimeSlots(data?.selectedDays, timeSlotsSelected);
      }else{
        const hrFrom1 = data.fromTimeSlot1? data.fromTimeSlot1?.hours() : new Date().getHours(),
            hrTo1 = data.toTimeSlot1? data.toTimeSlot1?.hours() : new Date().getHours(),
            minFrom1 = data.fromTimeSlot1? data.fromTimeSlot1?.minutes() : new Date().getMinutes(),
            minTo1 = data.toTimeSlot1? data.toTimeSlot1?.minutes(): new Date().getMinutes(),
            hrFrom2 = data.fromTimeSlot2 ? data.fromTimeSlot2?.hours() : null,
            hrTo2 = data.toTimeSlot2 ? data.toTimeSlot2?.hours() : null,
            minFrom2 = data.fromTimeSlot2 ? data.fromTimeSlot2?.minutes() : null,
            minTo2 = data.toTimeSlot2 ? data.toTimeSlot2?.minutes() : null;
        slots = timeSlots(
            slotShifted && !isPeriodIncluded(data.fromTimeSlot1, data.toTimeSlot1, data.fromTimeSlot2, data.toTimeSlot2) && ((hrTo1 !== hrTo2 || minFrom1 !== minFrom2) || (hrFrom2 !== hrFrom1 || minTo1 !== minTo2)) ? 2 : 1,
            hrFrom1,
            hrTo1,
            minFrom1,
            minTo1,
            hrFrom2,
            hrTo2,
            minFrom2,
            minTo2
        );
      }

      setSubmitting(true);
      let message = '';
      if (fromList) {
        await updateWorkScheduleTemplate(dataFromServer.id, {
          teams: [],
          slots: slots,
          workingDays: data.workingDays,
          employeeTemplates: [],
          templateName: data.templateName,
          tenantID: tenantId,
          type: data.templateType,
        });
        message = 'Edited Successfully';
      } else {
        await createWorkScheduleTemplate({
          teams: [],
          slots: slots,
          workingDays: data.workingDays,
          templateName: data.templateName,
          tenantID: tenantId,
          type: data.templateType,
        });
        message = 'Template Created Successfully';
      }
      toast.success(message);
      refreshData();
      if (handleCloseButton) {
        handleCloseButton();
      }
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (dataFromServer) {
      if (dataFromServer.workingDays !== "Custom") {
        const filteredSlots = dataFromServer.slots.filter(
          (item) => item.day === 2
        );
        setValue(
          "fromTimeSlot1",
          moment(
            `${filteredSlots[0].startSlotTimeHours}:${filteredSlots[0].startSlotTimeMinutes}`,
            TIME_FORMAT
          )
        );
        setValue(
          "toTimeSlot1",
          moment(
            `${filteredSlots[0].endSlotTimeHours}:${filteredSlots[0].endSlotTimeMinutes}`,
            TIME_FORMAT
          )
        );

        if (filteredSlots.length > 1) {
          setValue(
            "fromTimeSlot2",
            moment(
              `${filteredSlots[1].startSlotTimeHours}:${filteredSlots[1].startSlotTimeMinutes}`,
              TIME_FORMAT
            )
          );
          setValue(
            "toTimeSlot2",
            moment(
              `${filteredSlots[1].endSlotTimeHours}:${filteredSlots[1].endSlotTimeMinutes}`,
              TIME_FORMAT
            )
          );
        } else {
          setSlotShifted(false);
        }
      }
      // handle days
      setValue(
          'workingDays',
          workingDaysOption?.find(
              (item) => dataFromServer.workingDays === item.ServerLabel
          )?.value
      );
      setValue('templateType', dataFromServer.type);
    }
  }, [dataFromServer]);

  useEffect(() => {
    setWorkingDaysSelected(workingDaysValue);
  }, [workingDaysValue]);

  useEffect(() => {
    setTypeSelected(typeValue);
  }, [typeValue]);

  return (
    <div id="edit-template" className="c-edit-template mx-3 mt-3">
      {addWithCustomBar &&
          <div className={'m-3'}>
            <div className="fw-bold fs-6 fst-italic">
              <span>
                <NotesIcon style={{color: colorTheme}}/>
              </span>
              We assumed that your working hours/days as following:{" "}
              <span style={{color: colorTheme}}>
                Mon to Fri, 08:00 to 05:00 PM
              </span>
            </div>
          </div>
      }
<div className="container-fluid">
  <form onSubmit={handleSubmit(handleSave)}>
    {addWithCustomBar && (
        <CustomAppBar
            title={title}
            showPrimaryButton
            showSecondaryButton
            textSecondary="Close"
            handleCloseButton={handleCloseButton}
              submitting={submitting}
            />
          )}
          <Grid className="create-template-text" container spacing={{ md: 1, xs: 0 }}>
            {fromList && isEditView && (
              <Grid
                item
                xs={12}
                className="d-flex justify-content-end"
                spacing={3}
              >
                <Button className={'edit-button'}
                  onClick={() => {
                    setDisableAllField(!disableAllField);
                  }}
                >
                  <EditIcon />
                </Button>
              </Grid>
            )}
            <Template
              register={register}
              disableAllField={disableAllField}
              control={control}
              errors={errors}
              dataFromServer={dataFromServer}
            />

            {/*<Grid item xs={12} spacing={3}>*/}
            {/*  <Divider className="form-divider" />*/}
            {/*</Grid>*/}
            <WorkingDays
              disableAllField={disableAllField}
              dataFromServer={dataFromServer}
              errors={errors}
              register={register}
              control={control}
              typeSelected={typeSelected}
              setValue={setValue}
            />
            {/*<Grid item xs={12} spacing={3}>*/}
            {/*  <Divider className="form-divider" />*/}
            {/*</Grid>*/}
            <TimeSlots
              dataFromServer={dataFromServer}
              disableAllField={disableAllField}
              errors={errors}
              register={register}
              control={control}
              setValue={setValue}
              slotShifted={slotShifted}
              setSlotShifted={setSlotShifted}
              workingDays={workingDaysSelected}
              setTimeSlotsSelected={setTimeSlotsSelected}
            />
            <Grid
              item
              xs={12}
              className="d-flex justify-content-end"
              spacing={3}
            >
              {!addWithCustomBar && isEditView && (
                <>
                  {dataFromServer?.id &&
                    <Button
                      className="px-4 mx-2 bg-danger"
                      color="secondary"
                      size="medium"
                      variant="outlined"
                      onClick={handleDelete}
                    >
                      Delete
                    </Button>
                  }
                  <Button
                    type="submit"
                    size="medium"
                    disabled={submitting || disableAllField}
                    className="px-4 ms-0"
                    variant="contained"
                  >
                    {submitting ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Save"
                    )}
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default CreateEditTemplate;
