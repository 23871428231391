export const FULL_DATE_FORMAT = "YYYY-MM-DD hh:mm A";
export const DATE_FORMAT = "YYYY-MM-DD";
export const MONTH_FORMAT = "MMM";
export const TIME_FORMAT = "hh:mm A";
export const TIME_24_HOUR_FORMAT = 'HH:mm';

export const VACATIONS = {
    0: 'Personal',
    1: 'Death',
    2: 'Maternity',
    3: 'Paternity',
    4: 'Sick',
    7: 'WFH',
    8: 'Wedding',
    9: 'Hajj',
    10: 'Military',
    12: 'LongVacation',
    13: 'Emergency',
};

export const FULL_DATE_ISO_FORMAT = "YYYY-MM-DDTHH:mm:ss";
export const DAY_MONTH_FORMAT = "ddd, D MMM";
export const FULL_DATE_MONTH_LETTERS_FORMAT = "DD MMM, YYYY";
export const FULL_DATE_MONTH_LETTERS_TIME_FORMAT = "DD MMM, YYYY, hh:mm:ss A";
export const DATE_TIME_FORMAT = "MM/DD/YYYY, hh:mm:ss A";
export const WEEK_IN_MS =  7 * 24 * 60 * 60 * 1000;
export const YEAR_FORMAT = 'yyyy';
