import React, {useEffect, useState} from 'react';
import {Controller, useFieldArray, useForm} from "react-hook-form";
import {updateTimeOffSubmitPolicy} from "utility/apis/SetupGuideService";
import {Button, Grid, MenuItem, TextField} from "components/Mui";
import {TimeSubmissionArr} from "utility/constants/data";
import {AddIcon, ClearIcon} from "components/Mui/Icons";
import {getErrorMessage} from "utility/helpers/utils";
import {toast} from "react-toastify";

import "./TimeOffSubmissionPolicy.scss";

const TimeOffSubmissionPolicy = (props) => {
    const {setInProgress, refreshPolicyData, timeOffSubmissionPolicy, tenantId} = props;
    const { register, control, reset, handleSubmit, errors } = useForm({
        defaultValues: {},
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "timeOffSubmissionPolicy"
    });
    const [lastRule, setLastRule] = useState();
    useEffect(() => {
        let updatedPolicy = { ...timeOffSubmissionPolicy };
        let extractedLastRule = null;

        if (updatedPolicy?.timeOffSubmissionPolicy) {
            updatedPolicy.timeOffSubmissionPolicy = updatedPolicy.timeOffSubmissionPolicy.filter(item => {
                if (item.lastRule) {
                    extractedLastRule = item;
                    return false;
                }
                return true;
            });
        }

        setLastRule(extractedLastRule);
        reset({ ...updatedPolicy, lastRule: extractedLastRule });
    }, []);
    const handleSave = async (data) => {
        setInProgress(true)
        data.lastRule["lastRule"] = true;
        data.timeOffSubmissionPolicy.push(data.lastRule);
        try {
            await updateTimeOffSubmitPolicy(tenantId, data.timeOffSubmissionPolicy);
            refreshPolicyData();
            toast.success("Updated Successfully");
        } catch (e) {
            toast.error(getErrorMessage(e));
            setInProgress(false)
        }
    };
    return (
        <div id="TimeOffSubmissionPolicy" className="c-time-off-submission-policy px-4 mt-3">
            <form id="TimeOffSubmissionPolicy" onSubmit={handleSubmit(handleSave)}>
                {fields?.map((item, index) => (
                    <Grid container spacing={{ md: 1, xs: 0 }} key={item.id}>
                        <Grid item xs={1}>
                            <p className="from-to-lbl fs-6">Time off</p>
                        </Grid>
                        <Grid item xs={1}>
                            <Controller
                                as={
                                    <TextField
                                        errors={errors}
                                        label="From"
                                        type="number"
                                        inputRef={register({
                                            required: "This field is required",
                                            validate: (value) =>
                                                value >= 0,
                                        })}
                                        inputProps={{
                                            min: 0,
                                            step: "any"
                                        }}
                                    />
                                }
                                id="fromTime"
                                control={control}
                                mode="onBlur"
                                name={`timeOffSubmissionPolicy[${index}].fromTime`}
                                defaultValue={item.fromTime}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Controller
                                as={
                                    <TextField select>
                                        {TimeSubmissionArr.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                }
                                id="fromTimeUnit"
                                control={control}
                                mode="onBlur"
                                name={`timeOffSubmissionPolicy[${index}].fromTimeUnit`}
                                defaultValue={item.fromTimeUnit}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Controller
                                as={
                                    <TextField
                                        errors={errors}
                                        label="To"
                                        type="number"
                                        inputRef={register({
                                            required: "This field is required",
                                            validate: (value) =>
                                                value >= 0,
                                        })}
                                        inputProps={{
                                            min: 0,
                                            step: "any"
                                        }}
                                    />
                                }
                                id="toTime"
                                control={control}
                                mode="onBlur"
                                name={`timeOffSubmissionPolicy[${index}].toTime`}
                                defaultValue={item.toTime}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Controller
                                as={
                                    <TextField select>
                                        {TimeSubmissionArr.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                }
                                id="toTimeUnit"
                                control={control}
                                mode="onBlur"
                                name={`timeOffSubmissionPolicy[${index}].toTimeUnit`}
                                defaultValue={item.toTimeUnit}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <p className="from-to-lbl fs-6 mt-1">Requested</p>
                        </Grid>
                        <Grid item xs={1}>
                            <Controller
                                as={
                                    <TextField
                                        errors={errors}
                                        type="number"
                                        inputRef={register({
                                            required: "This field is required",
                                            validate: (value) =>
                                                value >= 0,
                                        })}
                                        inputProps={{
                                            min: 0,
                                            step: "any"
                                        }}
                                    />
                                }
                                id="requestBefore"
                                control={control}
                                mode="onBlur"
                                name={`timeOffSubmissionPolicy[${index}].requestBefore`}
                                defaultValue={item.requestBefore}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Controller
                                as={
                                    <TextField select>
                                        {TimeSubmissionArr.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                }
                                id="requestBeforeUnit"
                                control={control}
                                mode="onBlur"
                                name={`timeOffSubmissionPolicy[${index}].requestBeforeUnit`}
                                defaultValue={item.requestBeforeUnit}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <p className="from-to-lbl fs-6 mt-1">{index === 0 ? 'same day' : 'ahead'}</p>
                        </Grid>
                        <Grid item xs={1}>
                            {index !== 0 && (
                                <Button
                                    className="delete-btn"
                                    type="button"
                                    onClick={() => {
                                        remove(index);
                                    }}
                                    variant="contained"
                                >
                                    <ClearIcon />
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                ))}
                <Grid item xs={12} className="mb-3 ml-3">
                    <Button
                        type="button"
                        onClick={() => {
                            append({
                                fromTime: 0,
                                fromTimeUnit: "WorkingDay",
                                toTime: 0,
                                toTimeUnit: "WorkingDay",
                                requestBefore: 0,
                                requestBeforeUnit: "WorkingDay",
                            });
                        }}
                        variant="contained"
                    >
                        <AddIcon />
                    </Button>
                </Grid>
                {lastRule &&
                    <Grid container spacing={{ md: 1, xs: 0 }}>
                        <Grid item xs={1}>
                            <p className="from-to-lbl fs-6">Time off</p>
                        </Grid>
                        <Grid item xs={3}>
                            <Controller
                                as={
                                    <TextField
                                        errors={errors}
                                        label="More Than"
                                        type="number"
                                        inputRef={register({
                                            required: "This field is required",
                                            validate: (value) =>
                                                value >= 0,
                                        })}
                                        inputProps={{
                                            min: 0,
                                            step: "any"
                                        }}
                                    />
                                }
                                id="fromTime"
                                control={control}
                                mode="onBlur"
                                name={`lastRule.fromTime`}
                                defaultValue={lastRule.fromTime}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Controller
                                as={
                                    <TextField select>
                                        {TimeSubmissionArr.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                }
                                id="fromTimeUnit"
                                control={control}
                                mode="onBlur"
                                name={`lastRule.fromTimeUnit`}
                                defaultValue={lastRule.fromTimeUnit}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <p className="from-to-lbl fs-6 mt-1">Requested</p>
                        </Grid>
                        <Grid item xs={1}>
                            <Controller
                                as={
                                    <TextField
                                        errors={errors}
                                        type="number"
                                        inputRef={register({
                                            required: "This field is required",
                                            validate: (value) =>
                                                value >= 0,
                                        })}
                                        inputProps={{
                                            min: 0,
                                            step: "any"
                                        }}
                                    />
                                }
                                id="requestBefore"
                                control={control}
                                mode="onBlur"
                                name={`lastRule.requestBefore`}
                                defaultValue={lastRule.requestBefore}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Controller
                                as={
                                    <TextField select>
                                        {TimeSubmissionArr.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                }
                                id="requestBeforeUnit"
                                control={control}
                                mode="onBlur"
                                name={`lastRule.requestBeforeUnit`}
                                defaultValue={lastRule.requestBeforeUnit}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <p className="from-to-lbl fs-6 mt-1">ahead</p>
                        </Grid>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Button type="submit" variant="contained" className='float-end my-3' >
                        Save
                    </Button>
                </Grid>
            </form>
        </div>
    );
};

export default TimeOffSubmissionPolicy;
