import React from 'react';
import { Autocomplete as MuiAutocomplete} from "@mui/material";
import classNames from "classnames";

import './Autocomplete.scss';

function Autocomplete(props) {
    const { className, ...rest } = props;
    return (
        <MuiAutocomplete className={classNames('text-size', className)} {...rest}/>
    );
}

export default Autocomplete;