import React from 'react';
import classNames from "classnames";
import { ReactComponent as LoadingLogo } from 'assets/images/loading.svg';

import './Loading.scss';

const Loading = (props) => {
    const {containerClassName, className} = props;
    return (
        <div className={classNames(`loading ${containerClassName ?? ''}`, className)}>
            <LoadingLogo className="loading-svg" />
        </div>
    );
};
export default Loading;
