import React from 'react';
import { Avatar, Paper } from "components/Mui";
import profilePicture from "assets/images/profilePicture.png";
import ToffyAILogo from "assets/images/toffy_ai.png";

function ReportsHeader(props) {
    const {employee} = props;
    const tenantName = employee?.tenant?.name;
    const logoURL = employee?.tenant?.logo;

    return (
        <Paper
            className="header reports-layout-header"
            sx={{ position: "fixed", top: 0, left: 0, right: 0 }}
        >
            <>
                <img src={logoURL} alt={tenantName} className="logo2 mx-3 mt-1"/>
                <div className="d-flex align-items-center">
                    <Avatar src={ employee?.profilePicture || profilePicture}/>
                    <div className="text-capitalize fw-semibold mx-3">{employee?.name} - Toffy Clock Records</div>
                </div>
                <img src={ToffyAILogo} alt="Toffy AI" className="logo2 mx-3"/>
            </>

        </Paper>
    );
}

export default ReportsHeader;