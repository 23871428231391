import React from "react";
import classNames from "classnames";
import { TimePicker as MuiTimePicker } from "@mui/x-date-pickers/TimePicker";
import {LocalizationProvider, AdapterMoment} from "components/Mui";
import { TIME_FORMAT } from "utility/helpers/metaData";
import { ErrorMessage } from "@hookform/error-message";

import './TimePicker.scss';

function TimePicker(props) {
  const { className, errors, label, closeOnSelect = false, ...rest } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MuiTimePicker
        closeOnSelect={closeOnSelect}
        format={TIME_FORMAT}
        className={classNames('time-text-size', className)}
        label={label}
        {...rest}
      />
      {!!errors && (
        <ErrorMessage
          className="error-message"
          errors={errors}
          name={label}
          as="p"
        />
      )}
    </LocalizationProvider>
  );
}

export default TimePicker;
