import React from 'react';
import CreatePolicy from "containers/AccountMenu/SetupGuide/CreatePolicy/CreatePolicy";

const ManageSetting = (props) => {
    const {title, handleCloseButton} = props;
    return (
        <div id="ManageSetting" className="c-manage-setting">
            <CreatePolicy fromManageSetting title={title} handleCloseButton={handleCloseButton} />
        </div>
    );
};

export default ManageSetting;