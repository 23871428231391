import React, { useState, useEffect } from 'react';
import {getAccruedBalance, getBalance} from "utility/apis/EmployeeService";
import Loading from "components/Loading/Loading";
import StatusCard from "components/StatusCard/StatusCard";
import { ArrowDownwardIcon, ArrowUpwardIcon } from "components/Mui/Icons";
import {getSystemConfiguration} from "utility/apis/SettingService";
import moment, {isMoment} from "moment";
import {DATE_FORMAT} from "utility/helpers/metaData";

import "./Balance.scss";


const Balance = (props) => {
    const { employeeId, date, tenantId } = props;

    const [ inProgress, setInProgress ] = useState(false);
    const [ totalData, setTotalData ] = useState(undefined);
    const [ statusCard, setStatusCard ] = useState({status: {}, label: ''});
    const [ statusCard2, setStatusCard2 ] = useState({status: {}, label: ''});
    const [ statusCard3, setStatusCard3 ] = useState({status: {}, oneCardLabel: ''});
    const [ statusCard4, setStatusCard4 ] = useState({status: {}, label: ''});

    const fetchData = async () => {
        setInProgress(true);
        try {
            let response;
            if(moment.isMoment(date) || moment.isMoment(date?.value)){
                response = await getAccruedBalance(employeeId,moment.isMoment(date)? date.format(DATE_FORMAT): moment.isMoment(date.value)? date.value.format(DATE_FORMAT) : `${date?.value}-12-31`);
            }else{
                response = await getBalance(employeeId, moment.isMoment(date)? date.format("YYYY") : moment.isMoment(date.value)? moment(date.value).format("YYYY") : date?.value);
            }
            const data = response?.data;
            console.log(data, 'data');
            const balanceIn = data?.balanceIn?.toFixed(2);
            const balanceOut = data?.balanceOut?.toFixed(2);
            const availableToDate = data?.balance_days?.toFixed(2);
            const maxToBeEarned = data?.maximumToBeEarned?.toFixed(2);
            const numberofDaysPerWeek = data?.numberofDaysPerWeek;
            setTotalData({
                balanceIn,
                balanceOut,
                availableToDate,
                maxToBeEarned,
                numberofDaysPerWeek
            });
            console.log(totalData, 'totalData');
            const systemConfig = await getSystemConfiguration(tenantId);
            let weeksSummaryEnabled;
            systemConfig?.data.forEach((row) => {
                if(row.name === "Weeks-Summary" && row.enabled) weeksSummaryEnabled = true;
            });
            if(weeksSummaryEnabled) {
                setStatusCard({
                    status: {
                        'Rolled Over': data?.left_over,
                        'Accrued To Date': data?.deserved_vacation,
                        'Compensations & Awards': data?.compensation_balance,
                        'Salary Deductions': data?.salaryDeductionsWeeks,
                    },
                    label: 'weeks'
                });
                setStatusCard2({
                    status: {
                        'Used Time Off': data?.consumed_vacation_balance,
                        'Converted From Sick': data?.convertedFromSickWeeks,
                        'Added From Sick Policy': data?.addedFromSickPolicyWeeks,
                        'Deducted From Balance': data?.balanceDeductionsWeeks || 0,
                    },
                    label: 'weeks'
                });
                setStatusCard3({
                    status: {
                        'Available To Date': data?.balance || 0,
                        [`Maximum To Be Earned By ${moment.isMoment(date) ? date?.format(DATE_FORMAT) : moment.isMoment(date?.value) ? date?.value?.format(DATE_FORMAT)  : 'Year End'}`]: data?.maximumToBeEarnedWeeks || 0
                    },
                    oneCardLabel: 'weeks'
                });
                setStatusCard4({
                    status: {
                        'Emergency leaves' : data?.emergencyBalanceInWeeks || 0
                    },
                    label: 'weeks'
                });
            } else {
                setStatusCard({
                    status: {
                        'Rolled Over': data?.left_over_days,
                        'Accrued To Date': data?.deserved_vacation_days,
                        'Compensations & Awards': data?.compensation_balance_days,
                        'Salary Deductions': data?.salaryDeductions,
                    },
                    label: 'days'
                });
                setStatusCard2({
                    status: {
                        'Used Time Off': data?.consumed_vacation_balance_days,
                        'Converted From Sick': data?.convertedFromSick,
                        'Added From Sick Policy': data?.addedFromSickPolicy,
                        'Deducted From Balance': data?.balanceDeductionsDays || 0,
                    },
                    label: 'days'
                });
                setStatusCard3({
                    status: {
                        'Available To Date': data?.balance_days || 0,
                        [`Maximum To Be Earned By ${moment.isMoment(date) ? date?.format(DATE_FORMAT) : moment.isMoment(date?.value) ? date?.value?.format(DATE_FORMAT) : 'Year End'}`]: data?.maximumToBeEarned || 0
                    },
                    oneCardLabel: 'days'
                });
                setStatusCard4({
                    status: {
                        'Emergency leaves' : data?.emergencyBalanceInDays|| 0
                    },
                    label: 'days'
                });
            }
        } catch (e) {
        }
        setInProgress(false);
    }

    useEffect(() => {
        if (date && !isMoment(date)) {
            fetchData();
        }
    }, [date]);

    return (
        <div id="balance" className="c-balance">
            {inProgress ?
                <Loading></Loading>
                :
                <div className="container-fluid">
                    {!!statusCard3 &&
                        <div className="mt-4">
                            <StatusCard className="fw-semibold" status={statusCard3.status} oneCardLabel={statusCard3.oneCardLabel} oneCard/>
                        </div>
                    }
                    <div className="row align-items-center mt-4">
                        <div className="col-auto">
                            <ArrowUpwardIcon/>
                        </div>
                        {!!statusCard &&
                            <StatusCard className="col-10" status={statusCard.status} valueLabel={statusCard.label}/>
                        }
                        <span className="col-auto fw-bold">{ statusCard.label !== 'days' ? (totalData?.balanceIn / totalData?.numberofDaysPerWeek).toFixed(2): totalData?.balanceIn} {statusCard.label}</span>
                    </div>
                    <div className="row align-items-center mt-4">
                        <div className="col-auto">
                            <ArrowDownwardIcon/>
                        </div>
                        {!!statusCard2 &&
                            <StatusCard className="col-10" status={statusCard2.status} valueLabel={statusCard2.label}/>
                        }
                        <span className="col-auto fw-bold">{ statusCard.label !== 'days' ? (totalData?.balanceOut / totalData?.numberofDaysPerWeek).toFixed(2): totalData?.balanceOut} {statusCard2.label}</span>
                    </div>
                    <div className="row align-items-center mt-4 ms-3">
                        <div className="col-auto"></div>
                        {!!statusCard4 &&
                            <StatusCard className="col-10" status={statusCard4.status} valueLabel={statusCard4.label}/>
                        }
                    </div>
                </div>
            }
        </div>
    );
};

export default Balance;
