import React, {useEffect, useState} from 'react';
import {enablePolicies, updatePolicy} from "utility/apis/SetupGuideService";
import {Controller, useForm} from "react-hook-form";
import {DISABLE_POLICY_MODAL} from "utility/modals/constants";
import withModals from "hoc/withModals/withModals";
import {Button, Dialog, DialogActions, Grid, Select, TextField, CircularProgress} from "components/Mui";
import {daysPerYearArr} from "utility/constants/data";
import {getErrorMessage} from "utility/helpers/utils";
import {toast} from "react-toastify";

const WeddingLeavePolicy = (props) => {
    const {weddingPolicy, tenantId, setInProgress, refreshPolicyData, colorTheme, modals, toggleDisablePolicyModal} = props;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { register, control, reset, handleSubmit, errors } = useForm({
        defaultValues: {},
    });
    useEffect(() => {
        reset(weddingPolicy);
    }, [weddingPolicy]);
    const handleSave = async (data) => {
        setInProgress(true);
        try {
            await updatePolicy("weddingPolicy", weddingPolicy?.id, tenantId, data);
            refreshPolicyData();
            toast.success("Updated Successfully");
        } catch (e) {
            toast.error(getErrorMessage(e));
            setInProgress(false);
        }
    };
    const handleDisable = async () => {
        if (isSubmitting) return;
        setIsSubmitting(true);
        const payload = [{
            policyType: weddingPolicy?.type,
            enable: false
        }];
        try {
            await enablePolicies(tenantId, payload);
            refreshPolicyData();
            toggleDisablePolicyModal();
            toast.success("Disabled Successfully");
        } catch (e) {
            toast.error(getErrorMessage(e));
            setIsSubmitting(false);
        }
    };
    return (
        <div id="WeddingLeavePolicy" className="c-wedding-leave-policy px-4 mt-3">
            <form id="WeddingLeavePolicy" onSubmit={handleSubmit(handleSave)}>
                <Grid container spacing={{ md: 1, xs: 0 }}>
                    <Grid item xs={4}>
                        <Controller
                            as={
                                <TextField
                                    errors={errors}
                                    label="Wedding Leave Amount"
                                    type="number"
                                    inputRef={register({
                                        required: "This field is required",
                                        validate: (value) =>
                                            value >= 0,
                                    })}
                                    inputProps={{
                                        min: 0,
                                        step: "any"
                                    }}
                                />
                            }
                            id="maxAllowPerYear"
                            control={control}
                            mode="onBlur"
                            name="maxAllowPerYear"
                            defaultValue={weddingPolicy.maxAllowPerYear}
                        />
                    </Grid>
                    <Grid item md={3} xs={4}>
                        <Controller
                            as={
                                <Select options={daysPerYearArr} />
                            }
                            id="maxAllowPerYearUnit"
                            control={control}
                            mode="onBlur"
                            name='maxAllowPerYearUnit'
                            defaultValue={weddingPolicy.maxAllowPerYearUnit}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" className='float-end my-3' >
                            Save
                        </Button>
                        <Button className="bg-danger float-end my-3 mx-3" color="secondary" variant="outlined" onClick={toggleDisablePolicyModal} >
                            Disable Policy
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Dialog
                className="details-dialog"
                open={modals[DISABLE_POLICY_MODAL]}
                toggle={toggleDisablePolicyModal}
            >
                <div>
                    <div className="m-4" style={{color: colorTheme}}>
                        Are you sure you want to disable ( Wedding Leaves ) Policy?
                    </div>
                    <div>
                        <DialogActions className="p-3 mt-3">
                            <Button
                                className="px-4 me-3"
                                variant="outlined"
                                onClick={toggleDisablePolicyModal}
                            >
                                Cancel
                            </Button>
                            <Button
                                className="px-4 bg-danger"
                                color="secondary"
                                variant="outlined"
                                onClick={handleDisable}
                            >
                                {isSubmitting ? (
                                        <CircularProgress size={24} color="inherit" />
                                    ) :
                                    "Disable"
                                }
                            </Button>
                        </DialogActions>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};
const modalsProps = {
    toggleDisablePolicyModal: DISABLE_POLICY_MODAL,
};

export default withModals(WeddingLeavePolicy, modalsProps);
