import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Divider, FormControlLabel, Grid, Select, TextField, CircularProgress} from "components/Mui";
import AccrualRules from "components/EmployeeDetailsForms/CustomizeTimeOff/TimeOffPolicyEmployeeForm/AccrualRules/AccrualRules";
import {DISABLE_POLICY_MODAL} from "utility/modals/constants";
import withModals from "hoc/withModals/withModals";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import {enablePolicies, updatePolicy} from "utility/apis/SetupGuideService";
import {Dialog, DialogActions, FormGroup} from "@mui/material";
import {AddIcon, ClearIcon} from "components/Mui/Icons";
import {daysPerYearArr} from "utility/constants/data";
import {getErrorMessage} from "utility/helpers/utils";

import './SickLeavesPolicy.scss';
import {toast} from "react-toastify";

const SickLeavesPolicy = (props) => {
    const {sickTimeOffPolicy, tenantId, setInProgress, refreshPolicyData, colorTheme, modals, toggleDisablePolicyModal} = props;
    const [convertSickToTimeOff, setConvertSickToTimeOff] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { register, control, reset, handleSubmit, errors } = useForm({
        defaultValues: {},
    });
    const { fields: increaseSetFields, append: appendIncreaseSet, remove: removeIncreaseSet } = useFieldArray({
        control,
        name: "increaseSet",
    });

    const { fields: sickConsidersFields, append: appendSickConsiders, remove: removeSickConsiders } = useFieldArray({
        control,
        name: "sickConsiders",
    });

    useEffect(() => {
        reset(sickTimeOffPolicy);
        setConvertSickToTimeOff(sickTimeOffPolicy?.converToPersonal);
    }, [sickTimeOffPolicy]);
    const handleSave = async (data) => {
        setInProgress(true);
        data.convertToPersonalAfter = parseInt(data.convertToPersonalAfter);
        data.submissionDayLimit = parseInt(data.submissionDayLimit);
        data?.sickConsiders? data?.sickConsiders?.map((item, index)=>{
            item.considerValue = parseInt(item.considerValue);
            item.considerAfter = parseInt(item.considerAfter);
        }) : [];
        data?.increaseSet?.map((item)=>{
            item.fromValue = parseInt(item.fromValue);
            item.toValue = parseInt(item.toValue);
            item.value = parseInt(item.value);
        });
        data.sickConsidersIndividual = [];
        try {
            await updatePolicy("sickPolicy", sickTimeOffPolicy?.id, tenantId, data);
            refreshPolicyData();
            toast.success("Updated Successfully");
        } catch (e) {
            toast.error(getErrorMessage(e));
            setInProgress(false);
        }
    };
    const handleDisable = async () => {
        if (isSubmitting) return;
        setIsSubmitting(true);
        const payload = [{
            policyType: sickTimeOffPolicy?.type,
            enable: false
        }];
        try {
            await enablePolicies(tenantId, payload);
            refreshPolicyData();
            toast.success("Disabled Successfully");
            toggleDisablePolicyModal();
        } catch (e) {
            toast.error(getErrorMessage(e));
            setIsSubmitting(false);
        }
    };
    return (
        <div id="SickLeavesPolicy" className="c-sick-leaves-policy px-4">
            <form id="time-off-policy" onSubmit={handleSubmit(handleSave)}>
                <Grid container spacing={{ md: 1, xs: 0 }}>
                    <AccrualRules
                        register={register}
                        fields={increaseSetFields}
                        control={control}
                        append={appendIncreaseSet}
                        remove={removeIncreaseSet}
                        errors={errors}
                    />
                </Grid>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                inputRef={register}
                                name="requiredAskForSickReport"
                                defaultChecked={sickTimeOffPolicy?.requiredAskForSickReport}
                            />
                        }
                        className="check-box"
                        label="Ask For Sick Report"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                inputRef={register}
                                name="applicableByHrOnly"
                                defaultChecked={sickTimeOffPolicy?.applicableByHrOnly}
                            />
                        }
                        className="check-box"
                        label="Applicable exclusively by the HR Manager"
                    />
                </FormGroup>
                <Grid item xs={12} spacing={3}>
                    <Divider className="form-divider"/>
                </Grid>
                <FormControlLabel
                    control={
                        <Checkbox
                            inputRef={register}
                            name="converToPersonal"
                            defaultChecked={sickTimeOffPolicy?.converToPersonal}
                            onChange={(e, value) => setConvertSickToTimeOff(value)}
                        />
                    }
                    className="check-box"
                    label="Convert “Sick” to “Time off” rules"
                />
                {convertSickToTimeOff &&
                    <>
                        <Grid container spacing={{md: 4, xs: 0 }}>
                            <Grid item xs={6}>
                                <Controller
                                    as={
                                        <TextField
                                            errors={errors}
                                            label="Convert Sick to Time off after"
                                            InputLabelProps={{ shrink: true }}
                                            type="number"
                                            inputRef={register({
                                                required: "This field is required",
                                                validate: (value) =>
                                                    value >= 0,
                                            })}
                                            inputProps={{
                                                min: 0,
                                                step: "any"
                                            }}
                                        />
                                    }
                                    name="convertToPersonalAfter"
                                    id="convertToPersonalAfter"
                                    className="w-100"
                                    defaultValue={sickTimeOffPolicy?.convertToPersonalAfter}
                                    control={control}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Controller
                                    as={
                                        <Select className="w-100" label="" options={daysPerYearArr} />
                                    }
                                    control={control}
                                    defaultValue={sickTimeOffPolicy?.convertToPersonalAfterunit}
                                    id="convertToPersonalAfterunit"
                                    name="convertToPersonalAfterunit"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Controller
                                    as={
                                        <TextField
                                            errors={errors}
                                            label="Accept sick vacations after"
                                            InputLabelProps={{ shrink: true }}
                                            type="number"
                                            inputRef={register({
                                                required: "This field is required",
                                                validate: (value) =>
                                                    value >= 0,
                                            })}
                                            inputProps={{
                                                min: 0,
                                                step: "any"
                                            }}
                                        />
                                    }
                                    name="submissionDayLimit"
                                    id="submissionDayLimit"
                                    className="w-100"
                                    defaultValue={sickTimeOffPolicy?.submissionDayLimit}
                                    control={control}
                                />
                            </Grid>
                        </Grid>
                        {sickConsidersFields?.map((item, index) => (
                            <Grid container spacing={{ md: 4, xs: 0 }} key={item.id}>
                                <Grid item xs={12}>
                                    <Grid container spacing={{ md: 1, xs: 0 }}>
                                        <Grid item xs={2}>
                                            <Controller
                                                as={
                                                    <TextField
                                                        errors={errors}
                                                        label="Consider"
                                                        type="number"
                                                        inputRef={register({
                                                            required: "This field is required",
                                                            validate: (value) =>
                                                                value >= 0,
                                                        })}
                                                        inputProps={{
                                                            min: 0,
                                                            step: "any"
                                                        }}
                                                    />
                                                }
                                                id="considerValue"
                                                control={control}
                                                mode="onBlur"
                                                name={`sickConsiders[${index}].considerValue`}
                                                defaultValue={item.considerValue}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <p className="fs-6 from-to-lbl">% as personal time off after consuming</p>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Controller
                                                as={
                                                    <TextField
                                                        errors={errors}
                                                        label="Sick days"
                                                        type="number"
                                                        inputRef={register({
                                                            required: "This field is required",
                                                            validate: (value) =>
                                                                value >= 0,
                                                        })}
                                                        inputProps={{
                                                            min: 0,
                                                            step: "any"
                                                        }}
                                                    />
                                                }
                                                id="considerAfter"
                                                control={control}
                                                mode="onBlur"
                                                name={`sickConsiders[${index}].considerAfter`}
                                                defaultValue={item.considerAfter}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            {index !== 0 && (
                                                <Button
                                                    className="delete-btn"
                                                    type="button"
                                                    onClick={() => {
                                                        removeSickConsiders(index);
                                                    }}
                                                    variant="contained"
                                                >
                                                    <ClearIcon />
                                                </Button>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                        <Grid item xs={12}>
                            <Button
                                type="button"
                                onClick={() => {
                                    appendSickConsiders({
                                        considerAfter: 0,
                                        considerValue: 0,
                                    });
                                }}
                                variant="contained"
                            >
                                <AddIcon />
                            </Button>
                        </Grid>
                    </>
                }
                <Grid item xs={12}>
                    <Button type="submit" variant="contained" className='float-end my-3' >
                        Save
                    </Button>
                    <Button className="bg-danger float-end my-3 mx-3" color="secondary" variant="outlined" onClick={toggleDisablePolicyModal} >
                        Disable Policy
                    </Button>
                </Grid>
            </form>
            <Dialog
                className="details-dialog"
                open={modals[DISABLE_POLICY_MODAL]}
                toggle={toggleDisablePolicyModal}
            >
                <div>
                    <div className="m-4" style={{color: colorTheme}}>
                        Are you sure you want to disable ( Sick Leaves ) Policy?
                    </div>
                    <div>
                        <DialogActions className="p-3 mt-3">
                            <Button
                                className="px-4 me-3"
                                variant="outlined"
                                onClick={toggleDisablePolicyModal}
                            >
                                Cancel
                            </Button>
                            <Button
                                className="px-4 bg-danger"
                                color="secondary"
                                variant="outlined"
                                onClick={handleDisable}
                            >
                                {isSubmitting ? (
                                        <CircularProgress size={24} color="inherit" />
                                    ) :
                                    "Disable"
                                }
                            </Button>
                        </DialogActions>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};
const modalsProps = {
    toggleDisablePolicyModal: DISABLE_POLICY_MODAL,
};
export default withModals(SickLeavesPolicy, modalsProps);
