import { useLocation } from "react-router-dom";
import moment from "moment";
import { forEach } from "lodash";
import * as XLSX from 'xlsx';
import {
  DATE_FORMAT,
  MONTH_FORMAT,
  FULL_DATE_FORMAT,
  TIME_FORMAT,
  VACATIONS, TIME_24_HOUR_FORMAT, DAY_MONTH_FORMAT,
} from "utility/helpers/metaData";

export const formatNumberWithCommas = (number) => {
  if (number) {
    number = number.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  }
  return number;
};

export const extractUserCode = () => {
  const user = JSON.parse(localStorage.getItem("userDetails"));
  return user?.language?.code;
};

export const lazyLoadImage = (imageName) =>
  require(`assets/images/${imageName}`);

export const lazyLoadVideo = (videoName) =>
  require(`assets/videos/${videoName}`);

export const useQuery = (queryParam) => {
  const search = new URLSearchParams(useLocation().search);
  return search.get(queryParam);
};

export const setCurrentTenant = (tenantInfo) => {
  window.localStorage.setItem('tenantInfo', JSON.stringify(tenantInfo));
}

export const getCurrentTenant = () => window.localStorage.getItem('tenantInfo');

export const setTenants = (tenants) => {
  window.localStorage.setItem('tenants', JSON.stringify(tenants));
}

export const getTenants = () => window.localStorage.getItem('tenants');


export const setTenantId = (tenantId) => {
  window.localStorage.setItem("tenantId", tenantId);
};

export const getTenantId = (location, queryParam) => {
  const search = new URLSearchParams(location.search);
  return search.get(queryParam) || window.localStorage.getItem("tenantId");
};

export const getTeamsName = (teams) => {
  let teamsName = "";
  if (teams && teams.length) {
    teamsName = teams.pop().team;
    if (teams.length) {
      teamsName += `( +${teams.length} Teams)`;
    }
  }
  return teamsName;
};

export const setCookie = (name, value, days) => {
  let expires = "";
  if (days) {
    const date = moment().add(days, "days");
    expires = moment.utc(date).format();
  }
  document.cookie = `${name}=${value || ""}; expires=${expires}; path=/`;
};

export const formatWorkingDays = (slotsPerDay, slots) => {
  const slotsLen = slots.length;
  let workingDays;
  if (slotsLen === 6 || slotsLen === 12) {
    workingDays = "Sat To Thu";
  } else if (slotsLen === 5 || slotsLen === 10) {
    if (isSunToThu(slotsPerDay)) {
      workingDays = "Sun To Thu";
    } else {
      workingDays = "Mon To Fri";
    }
  } else {
    workingDays = "Custom";
  }
  return workingDays;
};

export const formatSlots = (slots) => {
  let slotsText = "";

  slots.sort((a, b) => a.day - b.day);

  const formatTime = (hours, minutes) => {
    let amPm;
    let formattedHours = hours;
    let formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    if (hours < 12) {
      amPm = "AM";
    } else {
      amPm = "PM";
      if (hours > 12) formattedHours -= 12;
    }

    return `${formattedHours}:${formattedMinutes} ${amPm}`;
  };

  if (slots.length === 5 || slots.length === 6) {
    const firstSlot = slots[0];

    const startTime = formatTime(firstSlot.startSlotTimeHours, firstSlot.startSlotTimeMinutes);
    const endTime = formatTime(firstSlot.endSlotTimeHours, firstSlot.endSlotTimeMinutes);

    slotsText = `${startTime} - ${endTime}`;
  } else {
    const slot1 = slots[0];
    const slot2 = slots[1];

    const startTimeSlot1 = formatTime(slot1.startSlotTimeHours, slot1.startSlotTimeMinutes);
    const endTimeSlot1 = formatTime(slot1.endSlotTimeHours, slot1.endSlotTimeMinutes);

    const startTimeSlot2 = formatTime(slot2.startSlotTimeHours, slot2.startSlotTimeMinutes);
    const endTimeSlot2 = formatTime(slot2.endSlotTimeHours, slot2.endSlotTimeMinutes);

    slotsText = `${startTimeSlot1} - ${endTimeSlot1} / ${startTimeSlot2} - ${endTimeSlot2}`;
  }

  return slotsText;
};

const isSunToThu = (slotsPerDay) => {
  const sunToThuDays = [0, 1, 2, 3, 4];
  let sunToThu = true;
  forEach(slotsPerDay, ({ day, numberOfSlots }, index) => {
    if (numberOfSlots > 0) {
      if (sunToThuDays.indexOf(day) === -1) {
        sunToThu = false;
        return false;
      }
    }
  });
  return sunToThu;
};

export const formatTime = (date) => {
  return date ? moment.utc(date).format(TIME_FORMAT) : '--';
};

export const formatDate = (date) => {
  return date ? moment.utc(date).format(DATE_FORMAT) : '--';
};

export const formatMonth = (date) => {
  return moment.utc(date).format(MONTH_FORMAT);
};

export const formatFullDate = (date) => {
  return moment.utc(date).format(FULL_DATE_FORMAT);
};

export const getDurationText = (time, showDetails) => {
  const duration = moment.duration(Math.abs(time));
  let durationText = "";
  if (duration.years()) {
    durationText += ` ${duration.years()} year${
      duration.years() > 1 ? "s" : ""
    }`;
  }
  if (duration.months()) {
    durationText += ` ${duration.months()} month${
      duration.months() > 1 ? "s" : ""
    }`;
  }
  if (duration.days()) {
    durationText += ` ${duration.days()} day${duration.days() > 1 ? "s" : ""}`;
  }
  if (showDetails && duration.hours()) {
    durationText += ` ${duration.hours()} hr${duration.hours() > 1 ? "s" : ""}`;
  }
  if (showDetails && duration.minutes()) {
    let minutes = duration.minutes();
    if (duration.seconds() >= 30) {
      minutes++;
    }
    durationText += ` ${minutes} min${
        minutes > 1 ? "s" : ""
    }`;
  }
  return durationText;
};

export const formatTimeOff = (days, hours) => {
  let durationText = "";

  if (days >= 1) {
    durationText = `${days.toFixed(2)} ${days === 1 ? "day" : "days"}`;
  } else if (hours >= 1) {
    let formattedHours = parseFloat(hours.toFixed(2));
    durationText = `${formattedHours} ${
      formattedHours === 1 ? "hour" : "hours"
    }`;
  } else {
    durationText = Math.ceil(hours * 60) + " minutes";
  }
  return durationText;
};

export const getDateOptions = () => {
  let years;
  years = [
    moment().subtract(2, "y"),
    moment().subtract(1, "y"),
    moment(),
    moment().add(1, "y"),
  ].map((year) => {
    const isCurrentYear = year.isSame(moment(), "year");
    return {
      label: isCurrentYear ? `${year.format("YYYY")} (Current)` : year.format("YYYY"),
      isCurrent: isCurrentYear,
      day: year.format(DATE_FORMAT),
      fromDate: year.startOf("y").valueOf(),
      toDate: year.endOf("y").valueOf(),
      value: year.format("YYYY")
    };
  });
  years.push({label: "Custom Date", value: "customDate"});
  return years;
};

export const isObjectEmpty = (objectName) => {
  // Check if objectName is undefined or null
  if (objectName === undefined || objectName === null) {
    return true; // Return true if object is empty
  }

  // Check if objectName is an empty object
  return Object.keys(objectName).length === 0;
};

//TODO: Move these funcs to utile
export const getStartEndOfYear = (year = moment().year()) => {
  const from = `${year}-01-01`;
  const to = `${year}-12-31`;
  return { from, to };
};
export const getYears = (startYear, endYear = moment().year()) => {
  const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => {
    const year = startYear + index;
    return { year: year, isCurrent: moment().year() === year }; // Modify this line to include additional properties
  });

  return years;
};

export const getHolidayDays = (fromDate, toDate) => {
  const startDate = moment(fromDate);
  const endDate = moment(toDate);

  const diffMonths = Math.abs(endDate.diff(startDate, "months"));
  const diffDays = Math.abs(endDate.diff(startDate, "days"));

  let endDateFormat = "";

  if (diffMonths > 0) {
    endDateFormat += "MMM,";
  }
  if (diffDays > 0) {
    endDateFormat += "DD";
  }

  const endDateDisplay = endDateFormat
    ? `to ${endDate.format(endDateFormat)}`
    : "";

  // Format the date range
  const formattedDateRange = endDateDisplay? `${startDate.format("MMM,DD")} ${endDateDisplay}` : `${startDate.format(DAY_MONTH_FORMAT)}`;

  return formattedDateRange;
};


export const timeSlots = (numSlots, hrFrom1, hrTo1, minFrom1, minTo1, hrFrom2, hrTo2, minFrom2, minTo2) => {
  let slots= [];
  
  // we have only two cases
  // 1. the working days with only one slot
  // 2. the working days with two slots
  let slot1 = {};
  slot1.startSlotTimeHours = hrFrom1;
  slot1.endSlotTimeHours = hrTo1;
  slot1.startSlotTimeMinutes = minFrom1;
  slot1.endSlotTimeMinutes = minTo1;
  slots.push(slot1);
  if (numSlots === 2) {
    let slot2 = {};
    slot2.startSlotTimeHours = hrFrom2;
    slot2.endSlotTimeHours = hrTo2;
    slot2.startSlotTimeMinutes = minFrom2;
    slot2.endSlotTimeMinutes = minTo2;
    slots.push(slot2);
  }
  

  return slots;
};

export const mapVacationType = (type) => {
  return VACATIONS[type] || 'Other';
}

export const downloadExcelFile = (data, fileName, sheetName) => {
  const myWorkBook = XLSX.utils.book_new();
  const myWorkSheet = XLSX.utils.json_to_sheet(data);
  XLSX.utils.book_append_sheet(myWorkBook, myWorkSheet, sheetName);
  XLSX.writeFile(myWorkBook, fileName);
}

export const getUnitOption = (t) =>  [
  { label:t('weeks'), value:'Weeks' },
  { label:t('days'), value:'Days' },
  { label:t('hours'), value:'Hours' }
];

export const convertTimeToDecimal = (timeString)=> {
  const [hours, minutes] = moment(timeString, TIME_FORMAT).format(TIME_24_HOUR_FORMAT).split(':');
  return parseInt(hours, 10) + parseInt(minutes, 10) / 60;
}

export const convertDecimalToTime = (decimalTime) => {
  const hours = Math.floor(decimalTime);
  const minutes = (decimalTime - hours) * 60;
  return moment({ hours, minutes });
}

export const getDayOrder = (date) => {
  const jsDate = moment(date)._d;
  const yearStart = new Date(jsDate.getFullYear(), 0, 0);
  const diff = jsDate - yearStart;
  const oneDay = 1000 * 60 * 60 * 24;
  return Math.floor(diff / oneDay);
};

export const getDateFromDayOrder = (dayOrder) => {
  const year = new Date().getFullYear(); // Get the current year
  const date = new Date(year, 0); // Initialize with the first day of the year
  date.setDate(dayOrder); // Set the day of the year
  return date;
};
export const getErrorMessage = (error) => {
  return error?.response?.data?.message || error?.response?.data || error?.message || 'Something went wrong';
};

