import React, {useContext, useState} from 'react';
import CustomAppBar from "components/CustomAppBar/CustomAppBar";
import ColorPalette from "./ColorPalette/ColorPalette";
import {updateThemeColor} from "utility/apis/SettingService";
import {getCurrentTenant, getErrorMessage, setCurrentTenant} from "utility/helpers/utils";
import {colorList} from "utility/constants/data";
import { useTheme } from 'components/Mui';
import {ThemeColorContext} from "layouts/PrivateLayout/PrivateLayout";
import {HRThemeColorContext} from "layouts/HRAssistantLayout/HRAssistantLayout";
import {ManagerThemeColorContext} from "layouts/ManagerLayout/ManagerLayout";
import {toast} from "react-toastify";

const ThemeColorSetting = (props) => {
    const {title, handleCloseButton, tenantId} = props;
    const tenantInfo = JSON.parse(getCurrentTenant());
    const selectedTheme = useTheme();
    const [color, setColor] = useState({
        color : selectedTheme?.palette?.primary?.main,
        darker : selectedTheme?.palette?.primary?.darker,
        lighter : selectedTheme?.palette?.primary?.lighter
    });
    const {setSelectedTheme} = useContext(ThemeColorContext) || useContext(HRThemeColorContext) || useContext(ManagerThemeColorContext);
    const handleColorClick = (themeColor) => {
        setColor({
            color : themeColor.color,
            darker : themeColor.darker,
            lighter : themeColor.lighter
        });

    };
    const handleSaveButton = async () => {
        const themeColor = colorList.find((item)=> item.color === color.color)?.name || colorList[0].name;
        try {
            const payload = {
                name: tenantInfo?.name  || tenantInfo[0]?.name,
                phoneNumber: tenantInfo?.phoneNumber  || tenantInfo[0]?.phoneNumber,
                contactName: tenantInfo?.contactName  || tenantInfo[0]?.contactName,
                email: tenantInfo?.email  || tenantInfo[0]?.email,
                website: tenantInfo?.website  || tenantInfo[0]?.website,
                address: tenantInfo?.address  || tenantInfo[0]?.address,
                nativeLogin: tenantInfo?.nativeLogin  || tenantInfo[0]?.nativeLogin,
                domain: tenantInfo?.domain || tenantInfo[0]?.domain,
                activated: tenantInfo?.isActivated || tenantInfo[0]?.isActivated,
                confirmed: tenantInfo?.confirmed || tenantInfo[0]?.confirmed,
                themeColor: themeColor,
                logo: tenantInfo?.logo || tenantInfo[0]?.logo
            }
            const response = await updateThemeColor(tenantId, payload);
            setCurrentTenant(response.data);
            setSelectedTheme(color);
            toast.success("Updated Successfully");
        }catch (e){
            toast.error(getErrorMessage(e));
        }
    }
    return (
        <div id="ThemeColorSetting" className="c-theme-color-setting px-4 mt-4">
            <CustomAppBar
                title={title}
                showPrimaryButton
                showSecondaryButton
                handlePrimaryButton={handleSaveButton}
                handleCloseButton={handleCloseButton}
            />
            <div  className="fw-bold fs-6 fst-italic mb-3" style={{color: color.color}}>Choose your preferred theme color:</div>
            <ColorPalette selectedColor={color.color} onColorClick={handleColorClick}/>
        </div>
    );
};

export default ThemeColorSetting;
