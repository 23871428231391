import React, { useState, useEffect } from 'react';
import { getTimeOff } from "utility/apis/ConfigService";
import {Button, DataGridTable, DateTimePicker, Dialog, Tooltip, DialogActions, GridActionsCellItem} from "components/Mui";
import Loading from "components/Loading/Loading";
import StatusCard from "components/StatusCard/StatusCard";
import {TABLES_NAMES} from "utility/constants/constants";
import {map, startCase} from "lodash";
import {formatFullDate, getErrorMessage, getTenantId} from "utility/helpers/utils";
import {getPolicy} from "utility/apis/SetupGuideService";
import {useLocation} from "react-router-dom";
import {CancelIcon, DeleteIcon, EditIcon} from "components/Mui/Icons";
import moment from "moment/moment";
import {DATE_FORMAT, DAY_MONTH_FORMAT, FULL_DATE_ISO_FORMAT, TIME_FORMAT} from "utility/helpers/metaData";
import {
    cancelTimeOffRequest,
    deleteTimeOffRequest,
    updateTimeOffRequest
} from "utility/apis/EmployeeService";
import { useForm} from "react-hook-form";
import {
    CONFIRMATION_MODAL,
    TIME_OFF_CANCEL_REQUEST_MODAL,
    TIME_OFF_REQUEST_MODAL
} from "utility/modals/constants";
import WithModals from "hoc/withModals/withModals";

import "./Other.scss";
import {toast} from "react-toastify";
import {getSystemConfiguration} from "../../../../utility/apis/SettingService";

const Other = (props) => {
    const { employeeId, date, message, colorTheme, modals, toggleTimeOffEditModal, toggleConfirmationModal, toggleTimeOffCancelModal, tenantId } = props;

    const [ inProgress, setInProgress ] = useState(false);
    const [ statusCard, setStatusCard ] = useState({status: {}, label: ''});
    const [dataTable, setDataTable] = useState([]);
    const [policy, setPolicy] = useState([]);
    const [isInProgress, setIsInProgress] = useState(false);
    const [selectedTimeOffRequest, setSelectedTimeOffRequest] = useState({});
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const {  control, handleSubmit } = useForm({defaultValues: {}});
    const fetchManagePolice = async () => {
        try {
            const response = await getPolicy(tenantId);
            setPolicy(response?.data)
        } catch (e) {
        }
        setInProgress(false);
    }
    const fetchData = async () => {
        setInProgress(true);
        try {
            const response = await getTimeOff(employeeId, moment.isMoment(date)? date.format("YYYY") : moment.isMoment(date.value)? moment(date.value).format("YYYY") : date?.value, 'other', moment.isMoment(date)? date.format(DATE_FORMAT): moment.isMoment(date.value)? moment(date.value).format(DATE_FORMAT) : `${date?.value}-12-31`);
            const data = response?.data;
            const systemConfig = await getSystemConfiguration(tenantId);
            let weeksSummaryEnabled;
            systemConfig?.data.forEach((row) => {
                if(row.name === "Weeks-Summary" && row.enabled) weeksSummaryEnabled = true;
            });
            if(weeksSummaryEnabled) {
                setStatusCard({
                    status: {
                        'Client Visit': data?.usedClientVisitTimeoff / data?.workingDaysPerWeek,
                        'Team Recreation': data?.usedTeamRecreationTimeoff / data?.workingDaysPerWeek,
                        ...(!policy.bereavementAndFamilyDeathPolicy.disabled ? { 'Bereavement': data?.usedBereavementTimeOff / data?.workingDaysPerWeek } : {}),
                        ...(!policy.maternityPolicy.disabled ? { 'Maternity': data?.usedMaternityTimeOff / data?.workingDaysPerWeek } : {}),
                        ...(!policy.paternityPolicy.disabled ? { 'Paternity': data?.usedPaternityTimeOff / data?.workingDaysPerWeek } : {}),
                        ...(!policy.weddingPolicy.disabled ? { 'Wedding': data?.usedWeddingTimeOff / data?.workingDaysPerWeek } : {}),
                        ...(!policy.longVacationPolicy.disabled ? { 'Long Vacation': data?.usedLongVacationTimeOff / data?.workingDaysPerWeek } : {}),
                        ...(!policy.emergencyVacationPolicy.disabled ? { 'Emergency': data?.usedEmergencyVacationTimeOff / data?.workingDaysPerWeek } : {}),
                        ...(!policy.unPaidPolicy.disabled ? { 'UnPaid': data?.usedUnPaidTimeOff / data?.workingDaysPerWeek } : {}),
                        ...(!policy.hajPolicy.disabled ? { 'Haj': data?.usedHajTimeoff / data?.workingDaysPerWeek } : {}),
                    },
                    label: 'weeks'
                });
            } else {
                setStatusCard({
                    status: {
                        'Client Visit': data?.usedClientVisitTimeoff,
                        'Team Recreation': data?.usedTeamRecreationTimeoff,
                        ...(!policy.bereavementAndFamilyDeathPolicy.disabled ? { 'Bereavement': data?.usedBereavementTimeOff } : {}),
                        ...(!policy.maternityPolicy.disabled ? { 'Maternity': data?.usedMaternityTimeOff } : {}),
                        ...(!policy.paternityPolicy.disabled ? { 'Paternity': data?.usedPaternityTimeOff } : {}),
                        ...(!policy.weddingPolicy.disabled ? { 'Wedding': data?.usedWeddingTimeOff } : {}),
                        ...(!policy.longVacationPolicy.disabled ? { 'Long Vacation': data?.usedLongVacationTimeOff } : {}),
                        ...(!policy.emergencyVacationPolicy.disabled ? { 'Emergency': data?.usedEmergencyVacationTimeOff } : {}),
                        ...(!policy.unPaidPolicy.disabled ? { 'UnPaid': data?.usedUnPaidTimeOff } : {}),
                        ...(!policy.hajPolicy.disabled ? { 'Haj': data?.usedHajTimeoff } : {}),
                    },
                    label: 'days'
                });
            }
            const formatData = formatDataTable(data?.mergedVacations);
            setDataTable(formatData);
        } catch (e) {
        }
        setInProgress(false);
    }

    useEffect(() => {
        if (date) {
            fetchManagePolice();
        }
    }, [date]);

    useEffect(() => {
        if (policy) {
            fetchData();
        }
    }, [policy]);

    const refreshData = () => {
        fetchData() ;
    }
    const formatDataTable = (data) => {
        return map(data, ({ fromDate, toDate, hours, value, minutes, mergedVacation, vacationLengthLabel }, index) => {
            const newMergedVacations = {
                state: [],
                approver: [],
                comments: [],
            };
            map(mergedVacation, ({ vacationState, attendanceState, teamManagerApprovals, comments }) =>  {
                newMergedVacations.state.push(attendanceState || vacationState);
                newMergedVacations.comments.push(comments);
                if (teamManagerApprovals) {
                    teamManagerApprovals.forEach((teamManagerApproval) => {
                        newMergedVacations.approver.unshift(...map(teamManagerApproval?.managerApprovalSet, ({
                            state,
                            managerName,
                            comments
                        }) => ({
                            managerName,
                            state,
                            comments
                        })));
                    });
                }
            });
            return {
                id: index,
                fromDate: formatFullDate(fromDate),
                toDate: formatFullDate(toDate),
                details: vacationLengthLabel,
                state: newMergedVacations.state,
                approver: newMergedVacations.approver,
                comments: mergedVacation.comments,
                mergedVacation
            }
        });
    }

    const columnDefs = [
        {
            headerName: 'Details',
            field: 'details',
            flex: 1
        },
        {
            headerName: 'From Date',
            field: 'fromDate',
            flex: 1.8,
        },
        {
            headerName: 'To Date',
            field: 'toDate',
            flex: 1.8,
        },
        {
            headerName: 'Approver',
            field: 'approver',
            renderCell: (params) => (
                <div className="ellipsis">
                    {params.value?.map(({ state, managerName }) => (
                        <Tooltip title={`${startCase(managerName)}: ${startCase(state)}`}>
                            <div className="ellipsis">
                                <span>{startCase(managerName)}: </span>
                                <span className={state.indexOf('Rejected') > -1 ? "text-danger" : "text-info"}>{startCase(state)}</span>
                            </div>
                        </Tooltip>
                    ))}
                    {/*<span className={`ms-2 ${params.row.approver >= 0 ? "text-success" : "text-danger"}`}>*/}
                    {/*    {params.row.approver}*/}
                    {/*</span>*/}
                </div>
            ),
            flex: 2,
        },
        {
            headerName: 'Final State',
            field: 'state',
            flex: 1.5,
            renderCell: (params) => (
                <div className="ellipsis">
                    {params.value?.map((text) => (
                        <Tooltip title={startCase(text)}>
                            <div className={`ellipsis ${text.indexOf('Rejected') > -1 ? "text-danger" : "text-info"}`}>{startCase(text)}</div>
                        </Tooltip>
                    ))}
                </div>
            ),
        },
        {
            headerName: 'Comments',
            field: 'comments',
            flex: 1.5,
            renderCell: (params) => (
                <div className="ellipsis">
                    {params.value?.map((text) => (
                        <>
                            {text &&
                                <Tooltip  title={startCase(text)}>
                                    <div className="ellipsis">{startCase(text)}</div>
                                </Tooltip>
                            }
                        </>
                    ))}
                </div>
            ),
        },
        {
            headerName: 'Actions',
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params) => {
                const actions = [];
                actions.push(
                    <GridActionsCellItem showInMenu icon={<EditIcon />} onClick={(event) => handleEditClick(event, params)} label="Edit" />,
                    <GridActionsCellItem sx={Number(date?.value) !== moment().year()? {'color': '#cdcdcd', 'cursor': 'default'} : null} showInMenu icon={<CancelIcon sx={Number(date?.value) !== moment().year()? {'color': '#cdcdcd', 'cursor': 'default'} : null}/>}
                                         onClick={(event) => {
                                             if (Number(date?.value) !== moment().year()) {
                                                 event.stopPropagation();
                                                 return;
                                             }
                                             handleCancelClick(event, params);
                                         }}
                                         label="Cancel request" />,
                    <GridActionsCellItem sx={Number(date?.value) !== moment().year()? {'color': '#cdcdcd !important', 'cursor': 'default'} : null} showInMenu icon={<DeleteIcon sx={Number(date?.value) !== moment().year()? {'color': '#cdcdcd !important', 'cursor': 'default'} : null}/>}
                                         onClick={(event) => {
                                             if (Number(date?.value) !== moment().year()) {
                                                 event.stopPropagation();
                                                 return;
                                             }
                                             handleDeleteClick(event, params);
                                         }}
                                         label="Delete" className="text-danger" />
                );
                return actions;
            },
        }
    ];

    const handleEditClick = (event, params) => {
        setSelectedTimeOffRequest(params.row);
        setFromDate(moment(params.row.fromDate));
        setToDate(moment(params.row.toDate));
        toggleTimeOffEditModal();
    }

    const handleCancelClick = (event, params) => {
        setSelectedTimeOffRequest(params.row);
        toggleTimeOffCancelModal();
    }
    const handleDeleteClick = (event, params) => {
        setSelectedTimeOffRequest(params.row);
        toggleConfirmationModal();
    }
    const handleTimeOffRequestUpdate = async (event, data) => {
        setIsInProgress(true);
        event.preventDefault();
        try {
            const {  toDate, fromDate } = data;
            const formattedFormDate = fromDate.format(FULL_DATE_ISO_FORMAT) + "Z";
            const formattedToDate = toDate.format(FULL_DATE_ISO_FORMAT) + "Z";

            const payload = {
                from: formattedFormDate,
                to: formattedToDate,
                employee_id: employeeId,
                type: selectedTimeOffRequest?.mergedVacation[0]?.type
            };
            await updateTimeOffRequest(selectedTimeOffRequest?.mergedVacation[0]?.id, payload);
            refreshData();
            toggleTimeOffEditModal();
            toast.success("Updated Successfully");
        } catch (e) {
            toast.error(getErrorMessage(e));
        }finally {
            setTimeout(() => {
                setIsInProgress(false);
            }, 2000);
        }
    }
    const handleTimeOffRequestCancel = async () => {
        setIsInProgress(true);
        try {
            await cancelTimeOffRequest(selectedTimeOffRequest?.mergedVacation[0]?.id);
            refreshData();
            toggleTimeOffCancelModal();
            toast.success("Canceled Successfully");
        } catch (e) {
            toast.error(getErrorMessage(e));
        }finally {
            setTimeout(() => {
                setIsInProgress(false);
            }, 2000);
        }
    }
    const handleTimeOffRequestDelete = async () => {
        setIsInProgress(true);
        try {
            await deleteTimeOffRequest(selectedTimeOffRequest?.mergedVacation[0]?.id);
            refreshData();
            toggleConfirmationModal();
            toast.success("Deleted Successfully");
        } catch (e) {
            toast.error(getErrorMessage(e));
        }finally {
            setTimeout(() => {
                setIsInProgress(false);
            }, 2000);
        }
    }



    return (
        <div id="other" className="c-other">
            {inProgress ?
                <Loading></Loading>
                :
                <div className="container-fluid">
                    {!!statusCard &&
                        <div className="mt-4">
                            <StatusCard status={statusCard.status} valueLabel={statusCard.label}/>
                        </div>
                    }
                    <div style={{height: `calc(100vh - ${message ? 420 : 330}px)`}} className="row">
                        <DataGridTable
                            className="col-12"
                            key={TABLES_NAMES.OTHER_LIST}
                            columns={columnDefs}
                            dataTable={dataTable}
                            isExport={false}
                        />
                    </div>
                </div>
            }
            <Dialog className='time-off-request-dialog' open={modals[TIME_OFF_REQUEST_MODAL]} toggle={toggleTimeOffEditModal}>
                <form onSubmit={(event) => handleTimeOffRequestUpdate(event, { toDate, fromDate })}>
                    <div className="m-4">
                        <h6 className="mb-4" style={{color: colorTheme}}>Edit Time Off Request</h6>
                        <div className="column">
                            <DateTimePicker
                                name="fromDateTime"
                                label="From Date"
                                className="mx-1"
                                value={fromDate}
                                onChange={(newValue) => setFromDate(newValue)}
                            />
                            <DateTimePicker
                                name="toDateTime"
                                label="To Date"
                                className="mx-1"
                                value={toDate}
                                minDateTime={fromDate}
                                onChange={(newValue) => setToDate(newValue)}
                            />
                        </div>
                    </div>
                    <div>
                        <DialogActions className="p-3 mt-3">
                            <Button className="px-4 me-3" variant="outlined" onClick={toggleTimeOffEditModal}>Cancel</Button>
                            <Button className="px-4" variant="contained" type="submit" disabled={isInProgress}>Save</Button>
                        </DialogActions>
                    </div>
                </form>
            </Dialog>
            <Dialog className='confirmation-dialog' open={modals[TIME_OFF_CANCEL_REQUEST_MODAL]} toggle={toggleTimeOffCancelModal}>
                <div>
                    <div className="m-4">
                        Are you sure you want to cancel{' '}
                        <span style={{color: colorTheme}}>
                            {selectedTimeOffRequest.details} leave on {moment(selectedTimeOffRequest.fromDate).format(DAY_MONTH_FORMAT)} ({moment(selectedTimeOffRequest.fromDate).format(TIME_FORMAT)} to {moment(selectedTimeOffRequest.toDate).format(TIME_FORMAT)})
                        </span>
                        ?
                    </div>
                    <div className="m-4 fw-light">
                        Note: Cancellation requests require approval from managers
                    </div>
                    <div>
                        <DialogActions className="p-3 mt-3">
                            <Button className="px-4 me-3" variant="outlined"
                                    onClick={toggleTimeOffCancelModal}>Cancel</Button>
                            <Button className="px-4" variant="contained" disabled={isInProgress}
                                    onClick={handleTimeOffRequestCancel}>Confirm</Button>
                        </DialogActions>
                    </div>
                </div>
            </Dialog>
            <Dialog className='confirmation-dialog' open={modals[CONFIRMATION_MODAL]} toggle={toggleConfirmationModal}>
                <div>
                    <div className="m-4" style={{color: colorTheme}}>
                        Are you sure you want to delete this ?
                    </div>
                    <div>
                        <DialogActions className="p-3 mt-3">
                            <Button className="px-4 me-3" variant="outlined"
                                    onClick={toggleConfirmationModal}>Cancel</Button>
                            <Button className="px-4 bg-danger" color="secondary" variant="outlined" disabled={isInProgress}
                                    onClick={handleTimeOffRequestDelete}>Delete</Button>
                        </DialogActions>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};
const modalsProps = {
    toggleTimeOffEditModal: TIME_OFF_REQUEST_MODAL,
    toggleTimeOffCancelModal: TIME_OFF_CANCEL_REQUEST_MODAL,
    toggleConfirmationModal: CONFIRMATION_MODAL
};
export default WithModals(Other, modalsProps);
