import React, { useState, useEffect } from 'react';
import { map } from "lodash";
import {useLocation} from "react-router-dom";
import moment from "moment/moment";
import {formatFullDate, getTenantId} from "utility/helpers/utils";
import {getTimeOff, getWfhMonthlyBasis} from "utility/apis/ConfigService";
import {Divider, Accordion, AccordionDetails, AccordionSummary} from "components/Mui";
import Loading from "components/Loading/Loading";
import {ExpandMoreIcon} from "components/Mui/Icons";
import "./WfhMonthlyBasis.scss";
import {useTheme} from "../../../../components/Mui";


const WfhMonthlyBasis = (props) => {
    const { employeeId, date } = props;
    const tenantId = getTenantId(useLocation(),"tenantId");
    const [ inProgress, setInProgress ] = useState(false);
    const [ dataTable, setDataTable ] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [allRecordsDataTable, setAllRecordsDataTable] = useState([]);
    const selectedTheme = useTheme();
    const colorTheme = selectedTheme?.palette?.primary?.main;
    const darker = selectedTheme?.palette?.primary?.darker;
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? [...expanded, panel] : expanded.filter((item) => item !== panel));
    };

    useEffect(() => {
        if (date) {
            fetchAllRecordsData();
        }
    }, [date]);

    const fetchAllRecordsData = async () => {
        setInProgress(true);
        try {
            const response = await getTimeOff(employeeId, moment.isMoment(date)? moment(date).format("YYYY") : date?.value, 'wfh');
            const data = formatAllRecordsDataTable(response?.data?.mergedVacations);
            setAllRecordsDataTable(data);
            setExpanded([]);
            fetchData();
        } catch (e) {
        }
        setInProgress(false);
    }

    const fetchData = async () => {
        setInProgress(true);
        try {
            const response = await getWfhMonthlyBasis(tenantId, employeeId, moment.isMoment(date)? moment(date).format("YYYY") : date?.value);
            const data = formatDataTable(response?.data);
            setDataTable(data);
        } catch (e) {
            console.log(e)
        }
        setInProgress(false);
    }
    const formatAllRecordsDataTable = (data) => {
        return map(data, ({ fromDate, toDate, hours, value, minutes, mergedVacation, vacationLengthLabel }, index) => {
            const newMergedVacations = {
                state: [],
                approver: [],
                comments: [],
            };
            map(mergedVacation, ({ vacationState, attendanceState, teamManagerApprovals, comments }) =>  {
                newMergedVacations.state.push(attendanceState || vacationState);
                newMergedVacations.comments.push(comments);
                if (teamManagerApprovals) {
                    teamManagerApprovals.forEach((teamManagerApproval) => {
                        newMergedVacations.approver.unshift(...map(teamManagerApproval?.managerApprovalSet, ({
                            state,
                            managerName,
                            comments
                        }) => ({
                            managerName,
                            state,
                            comments
                        })));
                    });
                }
            });
            const monthName = moment(fromDate).format('MMMM');
            return {
                id: index,
                fromDate: formatFullDate(fromDate),
                toDate: formatFullDate(toDate),
                details: vacationLengthLabel,
                state: newMergedVacations.state,
                approver: newMergedVacations.approver,
                comments: newMergedVacations.comments,
                monthName: monthName,
                mergedVacation
            }
        });
    }

    const formatDataTable = (data) => {
        return map(data, (item, index) => {
            return {
                id: index,
                ...item
            }
        });
    }

    return (
        <div id="WfhMonthlyBasis" className="c-wfh-monthly-basis ps-4">
            {inProgress ?
                <Loading></Loading>
                :
                <div className="container-fluid">
                    <div className='row my-3 w-100 align-items-center' style={{paddingLeft: '15px', paddingRight: "45px"}}>
                        <div className="col-2"><b>Month</b></div>
                        <div className="col-2"><b>Granted by policy</b></div>
                        <div className="col-2"><b>Authorised Additional WFH time</b></div>
                        <div className="col-2"><b>Variation</b></div>
                        <div className="col-2"><b>Actual Consumed</b></div>
                        <div className="col-2"><b>Summary</b></div>
                    </div>
                    <Divider className="form-divider" />
                    {dataTable.map((item) => (
                        <Accordion
                            className="my-1"
                            expanded={expanded.includes(item.id)}
                            onChange={handleChange(item.id)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                className="accordion-summary-background"
                            >
                                <div className='row w-100 align-items-center'>
                                    <div className="col-2">{item?.month}</div>
                                    <div className="col-2">{item?.grantedByPolicy}</div>
                                    <div className="col-2">{item?.customized}</div>
                                    <div className="col-2" style={{ color: item?.variation > 0 ? colorTheme
                                            : item?.variation < 0 ? 'red' :  darker }}> {item?.variation > 0 ?
                                        `+${item?.variation}` : item?.variation}</div>
                                    <div className="col-2">{item?.actualConsumed}</div>
                                    <div className="col-2">{item?.summary}</div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className='row my-3 w-100 align-items-center' style={{paddingLeft: '15px'}}>
                                    <div className="col-2"><b>Details</b></div>
                                    <div className="col-2"><b>From Date</b></div>
                                    <div className="col-2"><b>To Date</b></div>
                                </div>
                                {allRecordsDataTable.map((record) => (
                                    record.monthName === item.month ?
                                        <AccordionSummary className="accordion-summary-background">
                                            <div key={record.id} className='row w-100'>
                                                <div className="col-2">{record.details}</div>
                                                <div className="col-2">{record.fromDate}</div>
                                                <div className="col-2">{record.toDate}</div>
                                            </div>
                                        </AccordionSummary>
                                        : null
                                ))}
                                {allRecordsDataTable.every((record) => record.monthName !== item.month) && (
                                    <AccordionSummary className="accordion-summary-background">
                                        <div className='row w-100'>
                                            <div className="col-2">No records for this month</div>
                                        </div>
                                    </AccordionSummary>
                                )}
                            </AccordionDetails>

                        </Accordion>
                    ))}
                </div>
            }
        </div>
    );
};

export default WfhMonthlyBasis;
