import moment from 'moment';
import {find} from "lodash";

export const setToken = (token) => window.localStorage.setItem('jwt', token);
export const setExpiryTime = (expiryTime) => window.localStorage.setItem('expiryTime', expiryTime);
export const setRefreshToken = (refreshToken) => window.localStorage.setItem('refreshToken', refreshToken);
export const setUserInfo = (userInfo) => window.localStorage.setItem('userInfo', JSON.stringify(userInfo));
export const setRoles = (roles) => window.localStorage.setItem('roles', JSON.stringify(roles));

export const getToken = () => window.localStorage.getItem('jwt');
export const getExpiryTime = () => window.localStorage.getItem('expiryTime');
export const getUserInfo = () => window.localStorage.getItem('userInfo');
export const getRefreshToken = () => window.localStorage.getItem('refreshToken');

export const setAuthData = (authData) => {
    const {token, token_expires_in, refresh_token, user_info} = authData
    setToken(token);
    setExpiryTime(token_expires_in);
    setRefreshToken(refresh_token);
    // add client_id fro slack purpose to user_info
    user_info.client_id = "https://slack.com/oauth/authorize?client_id=95087005760.197448348419&scope=channels:history,im:history,bot,incoming-webhook";
    setUserInfo(user_info);
    setRoles(user_info.roles);
}

export const getIsLoggedIn = () => {
    const token = getToken();
    return token && !isTokenExpired();
};

export const clearAuth = () => {
    localStorage.removeItem('jwt');
    localStorage.removeItem('tenantId');
    localStorage.removeItem('expiryTime');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userInfo');
    window.location.href = '/login';
};

export const isTokenExpired = () => {
    try {
      const expiryTime = getExpiryTime();
      return parseInt(expiryTime) < moment().valueOf();
    } catch (e) {
      return false;
    }
};

export const getRoles = () => {
    try {
        const roles = JSON.parse(getUserInfo()).roles;
        return roles || [];
    } catch (e) {
        return [];
    }
}

export const getIsAdmin = () => {
    const roles = getRoles();
    return find(roles, ({ roleId }) => (roleId === 1));
};

export const getIsManager = () => {
  const roles = getRoles();
  return find(roles, ({ roleId }) => (roleId === 1 || roleId === 2));
};

export const getIsHRAssistant = () => {
  const roles = getRoles();
  return find(roles, ({ roleId }) => (roleId === 1 || roleId === 2 || roleId === 3));
};
