import React from 'react';
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { BottomNavigation, BottomNavigationAction } from "components/Mui";

import './Navigation.scss';

export default function Navigation({ navigationLinks, logo, className }) {
    const history = useHistory();
    const [value, setValue] = React.useState(history.location.pathname);

    return (
        <div className={classNames("c-navigation d-flex align-items-center w-100", className)}>
           {logo && 
                <div className="logo justify-content-left">
                    {logo}
                </div>
           }
            <BottomNavigation
                className="bg-color-transparent d-none d-md-flex h-auto justify-content-end"
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
            >
                {navigationLinks.map(({id, label, href, className, onClick}) => (
                    <BottomNavigationAction 
                        className={classNames("flex-auto p-0 mx-2", className)} 
                        key={id} 
                        value={href} 
                        href={href} 
                        label={label} 
                        onClick={onClick}
                    />
                ))}
            </BottomNavigation>
        </div>
    );
}
