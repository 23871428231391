import React from "react";
import { CloseSharpIcon } from "components/Mui/Icons";
import { Dialog as MuiDialog } from '@mui/material';
import { DialogTitle, IconButton, Typography, Grid, useTheme} from 'components/Mui'

import "./Dialog.scss"

const Dialog = (props) => {
    const selectedTheme = useTheme();
    const {
      toggle,
      children,
      className,
      open,
      mainTitle,
      secondaryTitle,
      ...rest
    } = props;

    const color = selectedTheme?.palette?.primary?.main;

    return (
      <MuiDialog
        className={`CustomDialog ${className}`}
        open={open || false}
        onClose={toggle}
        {...rest}
      > 
        {(mainTitle || secondaryTitle) && 
          <DialogTitle className="dialog-header" style={{backgroundColor: color}}>
            <Grid container>
              <Grid item xs={11}>
                {mainTitle && <Typography variant="h6" className="fw-bold">{mainTitle}</Typography>}
              </Grid>
              <Grid item xs={1} >
                <IconButton className="text-white" onClick={toggle} aria-label="Close" >
                  <CloseSharpIcon fontSize="small"/>
                </IconButton>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                {secondaryTitle && <Typography variant="h6" fontSize="small">{secondaryTitle}</Typography>}
              </Grid>
            </Grid>
          </DialogTitle>
        }
        <div className="dialog-content">
          {!!children && (
            <div className="w-100 CustomDialog_body">{children}</div>
          )}
        </div>
      </MuiDialog>
    );
}

export default Dialog;
