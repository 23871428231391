import React, {useState} from "react";
import { Navigation, Paper, Dialog } from "components/Mui";
import AccountMenu from "containers/AccountMenu/AccountMenu";
// import { SettingsSharpIcon, AccountCircleSharpIcon } from "components/Mui/Icons";
import AccountCircleSharpIcon from "assets/images/profile.svg";
import SettingsSharpIcon from "assets/images/settings.svg";
import {getIsAdmin, getIsHRAssistant, getIsLoggedIn, getIsManager, getUserInfo} from "utility/helpers/authHelper";
import {getCurrentTenant, getTenantId, getTenants} from "utility/helpers/utils";
import { useLocation } from "react-router-dom";
import { ReactComponent as WhiteLogo } from "assets/images/logo-white.svg";
import ToffyAILogo from "assets/images/toffy_ai.png";
import ToffyAdminLogo from "assets/images/toffy_admin_color.png";
import { SETTINGS_MODAL } from "utility/modals/constants";
import Settings from "containers/Settings/Settings";
import withModals from "hoc/withModals/withModals";
import { useMediaQuery, Button } from "components/Mui";
import { MenuIcon } from "components/Mui/Icons";
import {Drawer, List, ListItem, ListItemText, MenuItem, Select} from "@mui/material";

import "./Header.scss";
import {filter} from "lodash";

const NAVIGATION_LINKS = [
  {
    id: "home",
    label: "home",
    href: "/home#home",
  },
  {
    id: "howItWorks",
    label: "how it works",
    href: "/home#howItWorks",
  },
  {
    id: "features",
    label: "features",
    href: "/home#features",
  },
  {
    id: "clients",
    label: "clients",
    href: "/home#clients",
  },
  {
    id: "contactUs",
    label: "contact",
    href: "/home#contactUs",
  },
  {
    id: "login",
    label: "login",
    href: "/login",
  },
  {
    id: "signUp",
    label: "sign up now",
    className: "sign-up_button",
    href: "/sign-up",
  },
];

const PRIVATE_NAVIGATION_LINKS = (tenantId, hideNav) => {
  const isManager = getIsManager();
  const isHRAssistant = getIsHRAssistant();
  const links = [
    {
      id: "employees",
      label: "employees",
      isShow: true,
      href: "/employees",
    },
    {
      id: "teams",
      label: "teams",
      isShow: isHRAssistant,
      href: `/teams`,
    },
    {
      id: "holidays",
      label: "holidays",
      isShow: isHRAssistant,
      href: "/holidays",
    },
    {
      id: "workScheduleTemplates",
      label: "Work Schedule Templates",
      isShow: isHRAssistant,
      href: `/templates`,
    },
    {
      id: "reports",
      label: "reports",
      isShow: isManager,
      href: `/reports`,
    },
    {
      id: "pending",
      label: "pending",
      isShow: isManager,
      href: "/pending",
    },
  ];
  return filter(links, el => (el.isShow && tenantId && !hideNav));
}

const getNavigationLinksRight = (toggleSettingsModal, handleClick, tenantId) => {
  let links = [];
  const isManager = getIsManager();
  const adminTenants = JSON.parse(getTenants());
  const isAdmin = getIsAdmin() && adminTenants?.length === 0;

  if (isManager && tenantId) {
    links = [
        {
          id: "settings",
          //label: <SettingsSharpIcon sx={{ fontSize: 30 }} />,
          label: <img src={SettingsSharpIcon} className="icon" alt="settings"/>,
          onClick: toggleSettingsModal,
        },
    ]
  }
  links = [
    ...links,
    {
      id: "account",
      // label: <AccountCircleSharpIcon sx={{ fontSize: 30 }} />,
      label: (
        <img src={JSON.parse(getUserInfo())?.profilePicture || AccountCircleSharpIcon} className="icon" alt="profile" />
      ),
      onClick: handleClick,
    },
    {
      id: "toffy_ai",
      label:
          <>
            <Select
                value="default"
                sx={{
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        border: 0,
                      },
                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        border: 0,
                      },
                }}
                renderValue={(selected) => (
                    <MenuItem value="default">
                      <img src={ToffyAILogo} alt="Toffy AI" className="logo2" style={{ maxWidth: '160px', maxHeight: '50px' }}/>
                    </MenuItem>
                )}
            >
              <MenuItem value="option">
                <a href={isAdmin ? "/customers" : "/tenants"}>
                  <img src={ToffyAdminLogo} alt="Toffy Admin" className="logo2" style={{ maxWidth: '160px', maxHeight: '50px' }}/>
                </a>
              </MenuItem>
            </Select>
          </>,
      href: "/"
    },
  ];
  return links;
};

const Header = (props) => {
  const { toggleSettingsModal, hideNav, hideAllNav, modals } = props;
  const isLoggedIn = getIsLoggedIn();
  const tenantId = getTenantId(useLocation(), "tenantId");
  const tenantInfo = getCurrentTenant() && JSON.parse(getCurrentTenant());
  const tenantName = Array.isArray(tenantInfo) ? tenantInfo[0]?.name : tenantInfo?.name;
  const logoURL = Array.isArray(tenantInfo) ? tenantInfo[0]?.logo : tenantInfo?.logo;


  const navigationLinks = hideAllNav ?
      []
      :
      isLoggedIn
        ? PRIVATE_NAVIGATION_LINKS(tenantId, hideNav)
        : NAVIGATION_LINKS;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const isSmallScreen = useMediaQuery('(max-width: 600px)');
  const isMediumScreen = useMediaQuery('(min-width: 601px) and (max-width: 1000px)');
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (open) => (event) => {
    setDrawerOpen(open);
  };

  const renderDrawer = () => (
      <Drawer
          anchor="right" open={drawerOpen} onClose={toggleDrawer(false)} classes={{ paper: 'custom-drawer-paper' }}
      >
        <List>
          {navigationLinks.map((link) => (
              <ListItem key={link.id} component="a" href={link.href}>
                <ListItemText primary={link.label} className="custom-list-item-text"/>
              </ListItem >
          ))}
        </List>
      </Drawer>
  );

  return (
    <Paper
      className="header"
      sx={{ position: "fixed", top: 0, left: 0, right: 0, padding: (!isLoggedIn && (isMediumScreen? "0 2rem" : (isSmallScreen? 0 : "0 8rem")))}}
    >
      {(isMediumScreen || isSmallScreen) ? (
        <div className="mobile-content">
          <div className="mobile-img">
            {isLoggedIn ? (
                <img src={logoURL} alt={tenantName}/>
            ) : (
              <WhiteLogo />
            )}
          </div>
          <Button onClick={toggleDrawer(true)} className="custom-button">
            <MenuIcon />
          </Button>
          {renderDrawer()}
        </div>
      ) : (
        <Navigation
          navigationLinks={navigationLinks}
          logo={
            isLoggedIn ? (
              <img src={logoURL} alt={tenantName} className="logo2 mx-3 mt-1" />
            ) : (
              <WhiteLogo />
            )
          }
          className={`justify-content-left`}
        />
      )}
      {isLoggedIn && (
        <>
          <Navigation
            navigationLinks={getNavigationLinksRight(
              toggleSettingsModal,
              handleClick,
              tenantId
            )}
            className="justify-content-end"
          />
          <AccountMenu handleClose={handleClose} anchorEl={anchorEl} />
          <Dialog
            className="details-dialog"
            fullScreen
            open={modals[SETTINGS_MODAL]}
            toggle={toggleSettingsModal}
          >
            <Settings handleCloseButton={toggleSettingsModal} />
          </Dialog>
        </>
      )}
    </Paper>
  );
};

const modalsProps = {
  toggleSettingsModal: SETTINGS_MODAL,
};

export default withModals(Header, modalsProps);
