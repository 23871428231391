import React from 'react';
import classNames from "classnames";
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider, AdapterMoment } from "components/Mui";
import {FULL_DATE_FORMAT} from "utility/helpers/metaData";
import {ErrorMessage} from "@hookform/error-message";

function DateTimePicker(props) {
    const { className, errors, label, ...rest } = props;
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <MuiDateTimePicker
                format={FULL_DATE_FORMAT}
                className={classNames(className)}
                label={label} {...rest}
            />
            {!!errors &&
                <ErrorMessage className="error-message" errors={errors} name={label} as="p"/>
            }
        </LocalizationProvider>
    );
}

export default DateTimePicker;