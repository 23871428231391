import React from 'react';
import { FormControlLabel as MuiFormControlLabel } from "@mui/material";
import classNames from "classnames";
import './FormControlLabel.scss';

function FormControlLabel(props) {
    const { className, ...rest } = props;
    return (
        <MuiFormControlLabel className={classNames(className)} size="small" {...rest} />
    );
}

export default FormControlLabel;