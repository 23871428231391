import { pickBy, includes } from "lodash";
import API from "./ApiBase";
import {API_URL, TENANT_TEAMS_URL, ATTENDANCE_LOCATIONS_URL} from "../constants/APIConst";

export const getEmployees = async (tenantId, pageNumber, filter, size) => {
    let params = {
        tenantId,
        sortType: 'asc',
        sortBy: 'name'
    };
    if (pageNumber) {
        params = {
            ...params,
            page: pageNumber,
            size: size
        }
    }
    else {
        params = {
            ...params,
        }
    }
    if (filter) {
        filter = pickBy(filter, (value) => {
            if (Array.isArray(value) &&  includes(value, '')) {
                value = [];
            }
            return !(value === undefined || value === '' || (Array.isArray(value) && value.length === 0));
        });
        params['filter'] =encodeURIComponent(JSON.stringify(filter));
        if (filter.name) {
            params['name'] = filter.name;
        }
    }
    const response = await API.get('/api/v2/employee', { params });
    return response;
};

export const getEmployeeDetails = async (employeeId) => {
    return await API.get(`/api/v1/employee/${employeeId}`);
};

export const editAssosiationTemplete = async (employeeId, payload) => {
    return await API.put(`/api/v1/employee/${employeeId}`, payload);
};

export const updateEmployeeDetails = async (employeeId, payload) => {
    return await API.put(`/api/v2/employee/${employeeId}`, payload);
};
export const getEmployeesName = async (tenantId, isActivated) => {
    let params = {
        tenantId,
        sortType: 'asc',
        sortBy: 'name',
        activated: isActivated
    }
    return await API.get(`/api/v2/employee/employeesNames`, {params});
};

export const getEmployeeTemplates = async (tenantId) => {
    return await API.get(`/api/v1/employee/employee-template-names/${tenantId}`);
};

export const getCompensationsTime = async (employeeId, year) => {
    return await API.get(API_URL.COMPENSATIONS_TIME_LIST(employeeId, year));
};

export const updateCompensation = async (compensationId, payload) => {
    return await API.put(`/api/v1/compensation/${compensationId}`, payload);
};

export const deleteCompensation = async (compensationId) => {
    return await API.delete(`/api/v1/compensation/${compensationId}`);
};

export const getBalanceOnly = async (employeeId, year) => {
    return await API.get(API_URL.BALANCE_ONLY_LIST(employeeId, year));
};

export const getBalance = async (employeeId, year) => {
    return await API.get(API_URL.BALANCE_LIST(employeeId, year));
};

export const getAccruedBalance = async (employeeId, year) => {
    return await API.get(API_URL.ACCRUED_BALANCE_LIST(employeeId, year));
};

export const getBalanceDeduction = async (employeeId, year) => {
    return await API.get(API_URL.BALANCE_DEDUCTION_LIST(employeeId, year));
};

export const updateBalanceDeduction = async (tenantId, compensationId, payload) => {
    return await API.put(`/api/v1/balance-deduction/${tenantId}/${compensationId}`, payload);
};

export const deleteBalanceDeduction = async (compensationId) => {
    return await API.delete(`/api/v1/balance-deduction/${compensationId}`);
};

export const getSalaryDeduction = async (employeeId, year) => {
    return await API.get(API_URL.SALARY_DEDUCTION_LIST(employeeId, year));
};

export const addSalaryDeduction = async (payload) => {
    return await API.post(`/api/v1/employee/salaryDeduction`, payload);
};

export const updateSalaryDeduction = async (payload) => {
    return await API.put(`/api/v1/employee/salaryDeduction`, payload);
};

export const updateSalaryDeductionStatus = async (salaryDeductionId, status) => {
    return await API.put(`/api/v1/employee/salaryDeduction/${salaryDeductionId}?status=${status}`);
};

export const deleteSalaryDeduction = async (compensationId) => {
    return await API.delete(`/api/v1/employee/salaryDeduction/${compensationId}`);
};


export const updateTimeOffPolicyDetails = async (employeeId, payload) => {
    return await API.put(`/api/v1/policy/timeoffpolicy/${employeeId}`, payload);
};
export const updateWfhPolicyDetails = async (employeeId, wfhPolicyId, payload) => {
    return await API.put(`/api/v1/policy/wfhPolicy/${wfhPolicyId}?empId=${employeeId}`, payload);
}

export const resetWfhPolicy = async (tenantId, employeeId) => {
    return await API.delete(`/api/v1/policy/wfhPolicyCustomization/${tenantId}/${employeeId}`);
};

export const deactivateProfile = async (employeeId) => {
    return await API.get(`/api/v1/employee/${employeeId}/disable`);
};

export const reactivateProfile = async (employeeId) => {
    return await API.get(`/api/v1/employee/${employeeId}/enable`);
};

export const hardDeleteProfile = async (employeeId) => {
    return await API.delete(`/api/v1/employee/${employeeId}`);
};

export const uploadEmployeeImage = (employeeId, payload) => {
    return API.post(`/api/v1/employee/${employeeId}/image`, payload, {headers: {'Content-Type':'multipart/form-data'}});
};

export const updateTimeOffRequest = async (compensationId, payload) => {
    return await API.put(`/api/v1/vacation/${compensationId}`, payload);
};

export const deleteTimeOffRequest = async (compensationId) => {
    return await API.delete(`/api/v1/vacation/${compensationId}`);
};
export const cancelTimeOffRequest = async (compensationId) => {
    return await API.delete(`/api/v1/vacation/delete/${compensationId}`);
};
export const convertTimeOffRequest = async (compensationId, type) => {
    return await API.put(`/api/v1/vacation/convert-vacation/${compensationId}?type=${type}`);
};
export const reportTimeOffRequest = async (compensationId, formData) => {
    return await API.post(`/api/v2/vacation/uploadFile?vId=${compensationId}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const getManagers = async (tenantId) => {
    return await API.get(`/api/v1/employee/get-managers/${tenantId}`);
};
export const addEmployeeTeam = async (tenantId, payload) => {
    return await API.post(`/api/v2/employee/add-employee-team/${tenantId}`, payload);
};
export const addObserverEmployeeTeam = async (tenantId, payload) => {
    return await API.post(`/api/v1/employee/add-employee-team/${tenantId}`, payload);
};
export const addCustomApproverTeam = async (teamId, employeeId) => {
    return await API.post(`/api/v1/employee/add-custom-approver-team/${teamId}/${employeeId}`, {});
};
export const changeEmployeeTeam = async (tenantId, payload) => {
    return await API.post(`/api/v1/employee/change-employee-team/${tenantId}`, payload);
};
export const getTenantTeams = async(tenantId)=>{
    return await API.get(TENANT_TEAMS_URL(tenantId));
}

export const getAttendanceLocation = async (tenantId) =>{
    return await API.get(ATTENDANCE_LOCATIONS_URL(tenantId));
}
export const getHoursBalance = (year, employeeId, forCurrentMonthOnly, month, token) => {
    return API.post(`/api/v2/clock/monthly-summary/${year}/${employeeId}?forCurrentMonthOnly=${forCurrentMonthOnly}&month=${month}`, token);
}

export const getAttendanceRecord = (employeeId, fromDate, toDate, payload)=>{
    return API.post(`/api/v2/employee/attendance-records/${employeeId}?fromDate=${fromDate}&toDate=${toDate}`, payload)
}
export const checkConflicts = async (recordId, payload) => {
    return await API.put(`/api/v1/clock/attendance-records/conflicts-list/${recordId}`, payload);
};
export const updateAttendanceRecord = async (recordId, payload) => {
    return await API.put(`/api/v1/clock/attendance-records/update/${recordId}`, payload);
};

export const updateJoinedTeamDate = async (employeeId, teamId, payload) => {
    return await API.put(`/api/v1/employee/change-employee-team-date/${employeeId}/${teamId}`, payload);
};

export const getEmployeeInfoByToken = (token) => {
    return API.post(`/api/v2/employee/byToken/${token}`);
}

export const getOfficeCountries = (tenant) => {
    return API.get(`/api/v1/employee/getOfficeCountries/tenant/${tenant}`);
}

export const getOfficeCities = (tenant) => {
    return API.get(`/api/v1/employee/getOfficeCities/tenant/${tenant}`);
}

export const getOfficeBuildings = (tenant) => {
    return API.get(`/api/v1/employee/getOfficeBuildings/tenant/${tenant}`);
}
export const importEmployees = (token, payload) => {
    return API.post(`/api/v1/employee/upload-employees/${token}`, payload, {headers: {'Content-Type':'multipart/form-data'}});
}
