import React, {useEffect, useState} from 'react';
import {Controller, useForm} from "react-hook-form";
import {enablePolicies, updatePolicy} from "utility/apis/SetupGuideService";
import {Button, Dialog, DialogActions, Divider, Grid, Select, TextField, CircularProgress} from "components/Mui";
import {daysPerYearArr, fromYearsArr} from "utility/constants/data";
import {DISABLE_POLICY_MODAL} from "utility/modals/constants";
import withModals from "hoc/withModals/withModals";
import {getErrorMessage} from "utility/helpers/utils";
import {toast} from "react-toastify";

const HajjLeavePolicy = (props) => {
    const {hajPolicy, tenantId, setInProgress, refreshPolicyData, colorTheme, modals, toggleDisablePolicyModal} = props;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { register, control, reset, handleSubmit, errors } = useForm({
        defaultValues: {},
    });
    useEffect(() => {
        reset(hajPolicy);
    }, [hajPolicy]);
    const handleSave = async (data) => {
        setInProgress(true)
        try {
            await updatePolicy("hajPolicy", hajPolicy?.id, tenantId, data);
            refreshPolicyData();
            toast.success("Updated Successfully");
        } catch (e) {
            toast.error(getErrorMessage(e));
            setInProgress(false)
        }
    };
    const handleDisable = async () => {
        if (isSubmitting) return;
        setIsSubmitting(true);
        const payload = [{
            policyType: hajPolicy?.type,
            enable: false
        }];
        try {
            await enablePolicies(tenantId, payload);
            refreshPolicyData();
            toast.success("Disabled Successfully");
            toggleDisablePolicyModal();
        } catch (e) {
            toast.error(getErrorMessage(e));
            setIsSubmitting(false);
        }
    };
    return (
        <div id="HajjLeavePolicy" className="c-hajj-leave-policy px-4 mt-3">
            <form id="HajjLeavePolicy" onSubmit={handleSubmit(handleSave)}>
                <Grid container spacing={{md: 1, xs: 0}}>
                    <Grid item xs={4}>
                        <Controller
                            as={
                                <TextField
                                    errors={errors}
                                    label="Hajj leave amount"
                                    type="number"
                                    inputRef={register({
                                        required: "This field is required",
                                        validate: (value) =>
                                            value >= 0,
                                    })}
                                    inputProps={{
                                        min: 0,
                                        step: "any"
                                    }}
                                />
                            }
                            id="maxAllowPerYear"
                            control={control}
                            mode="onBlur"
                            name="maxAllowPerYear"
                            defaultValue={hajPolicy?.maxAllowPerYear}
                        />
                    </Grid>
                    <Grid item md={3} xs={4}>
                        <Controller
                            as={
                                <Select options={daysPerYearArr} />
                            }
                            id="maxAllowPerYearUnit"
                            control={control}
                            mode="onBlur"
                            name='maxAllowPerYearUnit'
                            defaultValue={hajPolicy?.maxAllowPerYearUnit}
                        />
                    </Grid>
                    <Grid item xs={12} spacing={3}>
                        <Divider sx={{ bgcolor: "black", p: 0, mt: 0 }}/>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="fs-5 fw-normal mb-3">Conditions</div>
                    </Grid>
                    <Grid item xs={4}>
                        <Controller
                            as={
                                <TextField
                                    errors={errors}
                                    label="Minimun service time"
                                    type="number"
                                    inputRef={register({
                                        required: "This field is required",
                                        validate: (value) =>
                                            value >= 0,
                                    })}
                                    inputProps={{
                                        min: 0,
                                        step: "any"
                                    }}
                                />
                            }
                            id="effectiveFrom"
                            control={control}
                            mode="onBlur"
                            name="effectiveFrom"
                            defaultValue={hajPolicy?.effectiveFrom}
                        />
                    </Grid>
                    <Grid item md={3} xs={4}>
                        <Controller
                            as={
                                <Select options={fromYearsArr} />
                            }
                            id="effectiveFromUnit"
                            control={control}
                            mode="onBlur"
                            name='effectiveFromUnit'
                            defaultValue={hajPolicy?.effectiveFromUnit}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" className='float-end my-3'>
                            Save
                        </Button>
                        <Button className="bg-danger float-end my-3 mx-3" color="secondary" variant="outlined"
                                onClick={toggleDisablePolicyModal}>
                            Disable Policy
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Dialog
                className="details-dialog"
                open={modals[DISABLE_POLICY_MODAL]}
                toggle={toggleDisablePolicyModal}
            >
                <div>
                    <div className="m-4" style={{color: colorTheme}}>
                        Are you sure you want to disable ( Hajj Leaves ) Policy?
                    </div>
                    <div>
                        <DialogActions className="p-3 mt-3">
                            <Button
                                className="px-4 me-3"
                                variant="outlined"
                                onClick={toggleDisablePolicyModal}
                            >
                                Cancel
                            </Button>
                            <Button
                                className="px-4 bg-danger"
                                color="secondary"
                                variant="outlined"
                                onClick={handleDisable}
                            >
                                {isSubmitting ? (
                                        <CircularProgress size={24} color="inherit" />
                                    ) :
                                    "Disable"
                                }
                            </Button>
                        </DialogActions>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};
const modalsProps = {
    toggleDisablePolicyModal: DISABLE_POLICY_MODAL,
};

export default withModals(HajjLeavePolicy, modalsProps);
