import React from "react";
import {Controller} from "react-hook-form";
import {Checkbox, FormControlLabel, Grid, Select, TextField} from "components/Mui";
import {
    daysPerYearArr,
    timeUnitOptions
} from "utility/constants/data";

const WFHPolicyCustomization = (props) => {
    const {errors, control, register, wfhPolicyDataFromServer} = props;

    return (
        <>
            <Grid container md={12} className='my-3'>
                <h6>WFH Policy Customization</h6>
            </Grid>
            <Grid container spacing={{md: 1, xs: 1}}>
                <Grid item xs={12} md={12}>
                    <Grid container spacing={{md: 0, xs: 1}}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        inputRef={register}
                                        name="daysOnly"
                                        defaultChecked={wfhPolicyDataFromServer?.daysOnly}
                                    />
                                }
                                style={{paddingBottom: 12, paddingLeft: 0}}
                                label="In days only"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        inputRef={register}
                                        name="timesOnly"
                                        defaultChecked={wfhPolicyDataFromServer?.timesOnly}
                                    />
                                }
                                style={{paddingBottom: 12, paddingLeft: 0}}
                                label="In times only (regardless hours or days)"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        inputRef={register}
                                        name="refuseBridgeVacations"
                                        defaultChecked={wfhPolicyDataFromServer?.refuseBridgeVacations}
                                    />
                                }
                                style={{paddingBottom: 12, paddingLeft: 0}}
                                label="Deny bridge WFH"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        inputRef={register}
                                        name="applicableByManagers"
                                        defaultChecked={wfhPolicyDataFromServer?.applicableByManagers}
                                    />
                                }
                                style={{paddingBottom: 12, paddingLeft: 0}}
                                label="Applicable by managers"
                            />
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <p>Limit when submitting on the same day</p>
                        </Grid>
                        <Grid item xs={1} md={1}>
                            <TextField
                                hiddenLabel
                                style={{width: "80px"}}
                                inputRef={register({
                                    required: "required",
                                })}
                                errors={errors}
                                control={control}
                                type="number"
                                name={`limitInHours`}
                                defaultValue={wfhPolicyDataFromServer?.limitInHours}
                            />
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <p className="left-aligned-lbl">hours after first work time slot</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={{md: 1, xs: 1}}>
                <Grid item xs={12} md={12}>
                    <Grid container spacing={{md: 0, xs: 1}}>
                        <Grid item xs={3} md={3}>
                            <p>Max days per week</p>
                        </Grid>
                        <Grid item xs={1} md={1}>
                            <TextField
                                hiddenLabel
                                style={{width: "80px"}}
                                errors={errors}
                                inputRef={register({
                                    required: "required",
                                })}
                                control={control}
                                type="number"
                                name={`maxDaysInWeek`}
                                defaultValue={wfhPolicyDataFromServer?.maxDaysInWeek}
                            />
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <p className="left-aligned-lbl">day/s</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={{md: 1, xs: 1}}>
                <Grid item xs={12} md={12}>
                    <Grid container spacing={{md: 0, xs: 1}}>
                        <Grid item xs={3} md={3}>
                            <p>Requests should be submitted at least</p>
                        </Grid>
                        <Grid item xs={1} md={1}>
                            <TextField
                                hiddenLabel
                                style={{width: "80px"}}
                                errors={errors}
                                inputRef={register({
                                    required: "required",
                                })}
                                control={control}
                                type="number"
                                name={`minDaysBeforeRequest`}
                                defaultValue={wfhPolicyDataFromServer?.minDaysBeforeRequest}
                            />
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <p className="left-aligned-lbl">day/s ahead (zero if no limit)</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={{md: 1, xs: 1}}>
                <Grid item xs={12} md={12}>
                    <Grid container spacing={{md: 0, xs: 1}}>
                        <Grid item xs={3} md={3}>
                            <p>WFH amount (Without clarification)</p>
                        </Grid>
                        <Grid item xs={1} md={1}>
                            <TextField
                                hiddenLabel
                                style={{width: "80px"}}
                                errors={errors}
                                inputRef={register({
                                    required: "required",
                                })}
                                control={control}
                                type="number"
                                name={`maxAllow`}
                                defaultValue={wfhPolicyDataFromServer?.maxAllow}
                            />
                        </Grid>
                        <Grid item xs={2} md={2}>
                            <Controller
                                as={
                                    <Select hiddenLabel options={daysPerYearArr}/>
                                }
                                sx={{ minWidth: "142px" ,paddingLeft:"8px"}} // Set fixed width
                                id="fromUnit"
                                control={control}
                                name={`maxAllowUnit`}
                                defaultValue={wfhPolicyDataFromServer?.maxAllowUnit}
                            />
                        </Grid>
                        <Grid item xs={1} md={1}>
                            <p className="from-to-lbl">per</p>
                        </Grid>
                        <Grid item xs={2} md={2}>
                            <Controller
                                as={
                                    <Select options={timeUnitOptions}/>
                                }
                                sx={{minWidth:"86px"}}
                                id="resetPer"
                                control={control}
                                name={`resetPer`}
                                defaultValue={wfhPolicyDataFromServer?.resetPer}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={{md: 1, xs: 1}}>
                <Grid item xs={12} md={12}>
                    <Grid container spacing={{md: 0, xs: 1}}>
                        <Grid item xs={3} md={3}>
                            <p>WFH amount (With clarification)</p>
                        </Grid>
                        <Grid item xs={1} md={1}>
                            <TextField
                                hiddenLabel
                                style={{width: "80px"}}
                                errors={errors}
                                inputRef={register({
                                    required: "required",
                                })}
                                control={control}
                                type="number"
                                name={`maxAllowWithClarification`}
                                defaultValue={wfhPolicyDataFromServer?.maxAllowWithClarification}
                            />
                        </Grid>
                        <Grid item xs={2} md={2}>
                            <Controller
                                as={
                                    <Select hiddenLabel options={daysPerYearArr}/>
                                }
                                sx={{ minWidth: "142px" ,paddingLeft:"8px"}} // Set fixed width
                                id="fromUnit"
                                control={control}
                                name={`maxAllowWithClarificationUnit`}
                                defaultValue={wfhPolicyDataFromServer?.maxAllowWithClarificationUnit}
                            />
                        </Grid>
                        <Grid item xs={1} md={1}>
                            <p className="from-to-lbl">per</p>
                        </Grid>
                        <Grid item xs={2} md={2}>
                            <Controller
                                as={
                                    <Select options={timeUnitOptions}/>
                                }
                                sx={{minWidth:"86px"}}
                                id="resetWithClarificationPer"
                                control={control}
                                name={`resetWithClarificationPer`}
                                defaultValue={wfhPolicyDataFromServer?.resetWithClarificationPer}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default WFHPolicyCustomization;
