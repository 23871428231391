import React from 'react';
import { Button as MuiButton} from "@mui/material";
import classNames from "classnames";

import './Button.scss';

function Button(props) {
    const { children, className, size='small', ...rest } = props;
    return (
        <MuiButton size={size} className={classNames('text-style', className)} {...rest}>
            {children}
        </MuiButton>
    );
}

export default Button;