import API from './ApiBase';
import { getRefreshToken } from "../helpers/authHelper";

export const getEmployeeTenants = (payload) => {
    return API.post('/api/v1/employee/getEmployeeTenants', payload);
};

export const loginToTenant = (payload) => {
    return API.post('/api/v1/tenant/login', payload);
};


export const login = async (payload) => {
    return await API.post('/api/v1/employee/login-username-password', payload);
};

export const logout =  async () => {
    return await API.post('/api/v1/employee/logout/', { refresh_token: getRefreshToken()});
};

export const signUp = async (payload) => {
    return API.post('/api/v2/tenant/signup', payload);
};

export const uploadLoge = async (id, payload) => {
    return API.post(`/api/v1/tenant/${id}/logo`, payload); // multipart/form-data
};

export const resetPassword = async (payload) => {
    return await API.post('', payload);
};
