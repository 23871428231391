import React, {useState, useEffect, useContext} from 'react';
import {Controller, useForm} from "react-hook-form";
import {filter, map, find, includes, get, startCase, lowerCase} from "lodash";
import {
    getOfficeBuildings,
    getOfficeCities,
    getOfficeCountries,
    updateEmployeeDetails
} from "utility/apis/EmployeeService";
import {useTheme, FormControlLabel, Checkbox, Select, Autocomplete, TextField} from "components/Mui";
import CheckboxesTags from "components/CheckboxesTags/CheckboxesTags";
import { getRoles } from "utility/apis/ConfigService";
import CustomAppBar from "components/CustomAppBar/CustomAppBar";
import {getErrorMessage, getTenantId} from "utility/helpers/utils";
import {useLocation} from "react-router-dom";
import {getFunctionalRole} from "utility/apis/SettingService";
import {ATTENDANCE_WHITE_LISTED_OPTIONS} from "utility/constants/constants";
import Loading from "components/Loading/Loading";

import "./Roles.scss";
import {toast} from "react-toastify";
import { getIsManager } from "utility/helpers/authHelper";
import {ProfileContext} from "containers/ProfileDetails/ProfileDetails";

const Roles = (props) => {
    const { employeeId, message, title, handleCloseButton, roles, employee, profilePersonalInfo, setProfilePersonalInfo, setEmployeeUpdated, setProfileRoles, setCurrentTabIndex, currentTabIndex } = props;
    const tenantId = getTenantId(useLocation(),"tenantId");
    const { register, control, handleSubmit, getValues, errors } = useForm({ mode: 'onSubmit' });
    const [functionalRolesOptions, setFunctionalRolesOptions] = useState([]);
    const [rolesOptions, setRolesOptions] = useState([]);
    const [rolesValue, setRolesValue] = useState(get(employee ? employee : profilePersonalInfo, 'roles', [4]));
    const [functionalRolesValue, setFunctionalRolesValue] = useState(get(employee ? employee : profilePersonalInfo, 'functionalRole', undefined));
    const [defaultRoles, setDefaultRoles] = useState([]);
    const [ inProgress, setInProgress ] = useState(false);
    const [officeCountries, setOfficeCountries] = useState([]);
    const [officeCities, setOfficeCities] = useState([]);
    const [officeBuildings, setOfficeBuildings] = useState([]);
    const selectedTheme = useTheme();
    const colorTheme = selectedTheme?.palette?.primary?.main;
    const isManager = getIsManager();
    const [isInProgress, setIsInProgress] = useState(false);

    const context = useContext(ProfileContext);

    const saveProfile = props.saveProfile ?? context?.saveProfile ?? false;
    const setSaveProfile = props.setSaveProfile ?? context?.setSaveProfile ?? (() => {});
    const fetchData = async () => {
        setInProgress(true);
        try {
            const response = await getRoles();
            const responseRoles = formatDataTable(response.data);
            const response2 = await getFunctionalRole(tenantId)
            setRolesOptions(responseRoles);
            setFunctionalRolesOptions(response2.data);
            const activeRoles = employee ?
                filter(responseRoles, (el) => find(roles, { id: el.id } ))
                :
                filter(responseRoles, (el) => includes(roles, el.id));
            setDefaultRoles(activeRoles);
            setRolesValue(map(activeRoles, 'id'));
        } catch (e) {
        }
        setInProgress(false);
    }

    useEffect(() => {
        fetchData();
        handleOfficeLocation();
    }, []);

    const handleProfileRolesStep = (event, step) => {
        event.preventDefault();
        setProfilePersonalInfo({
            ...profilePersonalInfo,
            ...getValues(),
            roles: rolesValue,
            functionalRole: functionalRolesValue
        });

        if (!isManager) {
            setSaveProfile(true);
        } else {
            setCurrentTabIndex(currentTabIndex +  (step === "next"? 1 : -1))
        }
    };


    const formatDataTable = (data) => {
        return map(data, ({ name, id, fixed, details }) => {
            return {
                id,
                name: startCase(lowerCase(name)),
                details,
                fixed,
                disabled: lowerCase(name) === "employee"
            }
        });
    }

    const handleRolesChange = (event, selectedRoles) => {
        const employeeRole = find(rolesOptions, { name: 'Employee' });

        if (employeeRole && !find(selectedRoles, { id: employeeRole.id })) {
            selectedRoles.push(employeeRole);
        }
        setRolesValue(map(selectedRoles, 'id'));
        if (!employeeId) {
            setProfileRoles(map(selectedRoles, 'id'));
        }
    };
    const handleFunctionalRolesChange = (event, value) => {
        setFunctionalRolesValue(value);
    };

    const handleOfficeLocation = async () => {
        const response = await getOfficeCountries(tenantId);
        setOfficeCountries(response?.data?.map(item => ({
            name: item,
        })));

        const response2 = await getOfficeCities(tenantId);
        setOfficeCities(response2?.data?.map(item => ({
            name: item,
        })));

        const response3 = await getOfficeBuildings(tenantId);
        setOfficeBuildings(response3?.data?.map(item => ({
            name: item,
        })));
    }

    const handleSaveDetailsButton = async (data) => {
        setIsInProgress(true);
        try {
            await updateEmployeeDetails(employeeId, {
                personalInfo: {
                    adUsername: employee?.adUsername,
                    city: employee?.city,
                    country: employee?.country,
                    dob: employee?.dob,
                    email: employee.email,
                    fullName: employee.fullName,
                    gender: employee.gender,
                    hireDate: employee.hireDate,
                    homePhoneNumber: employee.homePhoneNumber,
                    idNumber: employee.idNumber,
                    mobileNumber: employee.mobileNumber,
                    name: employee.name,
                    residence: employee.residence,

                    functionalRole: functionalRolesValue,
                    attendanceWhiteListed: data.attendanceWhiteListed,
                    eligibleForEmergencyWFH: data?.eligibleForEmergencyWFH,
                    officeCountry: data?.officeCountry,
                    officeCity: data?.officeCity,
                    officeBuilding: data?.officeBuilding,
                },
                updatedTab: "PERSONALINFO"
            });
            await updateEmployeeDetails(employeeId, {
                roles: rolesValue,
                updatedTab: "ROLES"
            });
            setEmployeeUpdated(true);
            toast.success("Updated Successfully");
        } catch (e) {
            toast.error(getErrorMessage(e));
        }finally {
            setTimeout(() => {
                setIsInProgress(false);
            }, 2000);
        }
    };

    return (
        <div id="roles" className="c-roles px-4">
            {inProgress ?
                <Loading></Loading>
                :
                <div className="container-fluid">
                    <form onSubmit={ employeeId ? handleSubmit(handleSaveDetailsButton): (event)=> handleProfileRolesStep(event, "next")}>
                        <CustomAppBar title={title} showBackButton={!employeeId} handleBackButton={(event)=> handleProfileRolesStep(event, "back")} showPrimaryButton textPrimary={isManager ? (!employeeId && "Next") : (!employeeId && "Save & Create Profile")} showSecondaryButton={employeeId} textSecondary={!employeeId && "Cancel"} handleCloseButton={handleCloseButton} message={message} submitting={isInProgress}/>
                        <div className='fs-4  my-4'>
                            <div className="fw-bold fs-6 fst-italic mb-1" style={{color: colorTheme}}>To change employee work information, please update the following field: </div>
                        </div>
                        <div className="row">
                            {isManager &&
                                <>
                                    <div className="col-12">
                                        {!!rolesOptions?.length &&
                                            <CheckboxesTags
                                                disableCloseOnSelect={true}
                                                label="Active Employee Roles"
                                                name="roles" className="fw-bold fs-6 fst-italic mb-1"
                                                onChange={handleRolesChange}
                                                labelField='name'
                                                fullWidth={true}
                                                options={rolesOptions}
                                                limitTags={2}
                                                defaultValue={!find(defaultRoles, { id: 4 })? [...defaultRoles, find(rolesOptions, { id: 4 })] : [...defaultRoles]}
                                                id="roles"
                                                multiple={true}
                                                inputRef={register}
                                                errors={errors}
                                            />
                                        }
                                    </div>
                                    <div className="col-12">
                                        {!!functionalRolesOptions.length &&
                                            <CheckboxesTags
                                                label="Functional Roles"
                                                name="functionalRoles"
                                                onChange={handleFunctionalRolesChange}
                                                labelField='value'
                                                fullWidth={true}
                                                options={functionalRolesOptions}
                                                limitTags={2}
                                                defaultValue={functionalRolesValue}
                                                id="roles"
                                                inputRef={register}
                                                errors={errors}
                                            />
                                        }
                                    </div>
                                    <div className="col-12">
                                        <Controller
                                            as={<Select label="White Listed" options={ATTENDANCE_WHITE_LISTED_OPTIONS}/>}
                                            id="attendanceWhiteListed"
                                            name="attendanceWhiteListed"
                                            defaultValue={get(employee ? employee : profilePersonalInfo, 'attendanceWhiteListed', ATTENDANCE_WHITE_LISTED_OPTIONS[0].value)}
                                            control={control}
                                        />
                                    </div>
                                </>
                            }
                            <div className="col-12">
                                <Autocomplete
                                    freeSolo
                                    id="officeCountry"
                                    disableClearable
                                    defaultValue={{name: get(employee ? employee : profilePersonalInfo, 'officeCountry', '') || ''}}
                                    options={officeCountries}
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, option) => (
                                        <li {...props}>
                                            {option.name}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params}
                                                   className='withAuto'
                                                   id="officeCountry"
                                                   name="officeCountry"
                                                   label="Office Country"
                                                   type="text"
                                                   inputRef={register}
                                                   errors={errors}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-12">
                                <Autocomplete
                                    freeSolo
                                    id="officeCity"
                                    disableClearable
                                    defaultValue={{name: get(employee ? employee : profilePersonalInfo, 'officeCity', '') || ''}}
                                    options={officeCities}
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, option) => (
                                        <li {...props}>
                                            {option.name}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            className='withAuto'
                                            id="officeCity"
                                            name="officeCity"
                                            label="Office City"
                                            type="text"
                                            inputRef={register}
                                            error={!!errors.officeCity}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-12">
                                <Autocomplete
                                    freeSolo
                                    id="officeBuilding"
                                    disableClearable
                                    defaultValue={{name: get(employee ? employee : profilePersonalInfo, 'officeBuilding', '') || ''}}
                                    options={officeBuildings}
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, option) => (
                                        <li {...props}>
                                            {option.name}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            className='withAuto'
                                            id="officeBuilding"
                                            name="officeBuilding"
                                            label="Office Building"
                                            type="text"
                                            inputRef={register}
                                            error={!!errors.officeBuilding}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-12">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            inputRef={register}
                                            name="eligibleForEmergencyWFH"
                                            defaultChecked={get(employee ? employee : profilePersonalInfo, 'eligibleForEmergencyWFH', '')}
                                        />
                                    }
                                    style={{paddingBottom: 2, marginLeft: -9}}
                                    label="Eligible For Emergency WFH"
                                />
                            </div>
                        </div>
                    </form>
                </div>
            }
        </div>
    );
};

export default Roles;
