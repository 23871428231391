import React from "react";
import {Controller} from "react-hook-form";
import {Grid, Select, TextField} from "components/Mui";
import {fromYearsArr} from "utility/constants/data";

const Conditions = (props) => {
    const {errors, control, wfhPolicyDataFromServer} = props;
    return (
        <>
            <Grid container xs={12} className='my-3'>
                <h6>Conditions</h6>
            </Grid>
            <Grid container spacing={{md: 1, xs: 1}}>
                <Grid item xs={12} md={12}>
                    <Grid container spacing={{md: 0, xs: 1}}>
                        <Grid item xs={3} md={3}>
                            <p>Minimum service time</p>
                        </Grid>
                        <Grid item xs={1} md={1}>
                            <Controller
                                as={
                                    <TextField
                                        errors={errors}
                                        InputLabelProps={{shrink: true}}
                                    />
                                }
                                style={{ width: 80 }}
                                id="effectiveFrom"
                                type="number"
                                control={control}
                                name={`effectiveFrom`}
                                defaultValue={wfhPolicyDataFromServer?.effectiveFrom}
                            />
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <Controller
                                as={
                                    <Select options={fromYearsArr}/>
                                }
                                sx={{minWidth:"101px",paddingLeft: "8px"}}
                                id="effectiveFromUnit"
                                control={control}
                                name={`effectiveFromUnit`}
                                defaultValue={wfhPolicyDataFromServer?.effectiveFromUnit}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default Conditions;
