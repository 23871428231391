import React from 'react';
import { TextField as MuiTextField, MenuItem } from "components/Mui";
import classNames from "classnames";
import './Select.scss';

function Select(props) {
    const { className, options,optionComponent,  ...rest } = props;
    return (
        <>
            <MuiTextField
                select
                size="small"
                variant="outlined"
                className={classNames('select-size',className)}
                {...rest}
            >
                {options.map((option) => (
                    <MenuItem disabled={option.disabled} key={option.value} value={option.value}>
                        {optionComponent? (
                            optionComponent(option)
                        ):(
                            option.label
                        )}
                        
                    </MenuItem>
                ))}
            </MuiTextField>
        </>
    );
}

export default Select;
