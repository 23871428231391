import React, {useEffect, useState} from 'react';
import {useFieldArray, useForm} from "react-hook-form";
import moment from "moment/moment";
import WithModals from "hoc/withModals/withModals";
import { AddIcon, ClearIcon } from "components/Mui/Icons";
import {TextField, DatePicker, Divider, Button, TimePicker, Dialog, Grid, DialogActions} from "components/Mui";
import { benefitActionOptions, benefitBeneficiaryOptions, benefitReportOptions, benefitRulesOptions, benefitTypeOptions, benefitUnitOptions, dayOptions } from "utility/constants/data";
import {convertDecimalToTime, convertTimeToDecimal, getErrorMessage} from "utility/helpers/utils";
import {addBenefit, deleteBenefit, updateBenefit} from "utility/apis/SettingService";
import {DATE_FORMAT, TIME_FORMAT} from "utility/helpers/metaData";
import {CONFIRMATION_MODAL} from "utility/modals/constants";
import CheckboxesTags from "components/CheckboxesTags/CheckboxesTags";

import "./BenefitForm.scss"
import {toast} from "react-toastify";
const BenefitForm = (props) => {
    const { employees, teams, benefit, tenantId, refreshData, date, toggleBenefitFormModal, modals, toggleConfirmationModal, colorTheme} = props;
    const [beneficiary, setBeneficiary] = useState(benefit?.beneficiary);
    const [policyName, setPolicyName] = useState([]);
    const [teamName, setTeamName] = useState(teams[0]);
    const [employeeName, setEmployeeName] = useState(null);
    const [fromDate, setFromDate] = useState(benefit?.fromDate? moment(benefit?.fromDate) : null);
    const [toDate, setToDate] = useState(benefit?.toDate? moment(benefit?.toDate) : null);
    const [fromTime, setFromTime] = useState(null);
    const [selectedBenefitType, setSelectedBenefitType] = useState(benefit?.type || benefitTypeOptions[0]?.value);
    const [modifiedBenefitRulesOptions, setModifiedBenefitRulesOptions] = useState();
    const [isInProgress, setIsInProgress] = useState(false);
    const [benefitTracking, setBenefitTracking] = useState(benefit?.benefitTrackingTime || benefitActionOptions[0]?.value);

    useEffect(() => {
        if(selectedBenefitType === "ATTENDANCE"){
            setPolicyName(['FREE_TIME_OFF']);
            setModifiedBenefitRulesOptions([
                {
                    label : "Free Time Off",
                    value : "FREE_TIME_OFF",
                }
            ])
        }else{
            setModifiedBenefitRulesOptions(benefitRulesOptions)
        }
    }, [selectedBenefitType]);

    const { register, control, setError, handleSubmit, errors, reset } = useForm({
        mode: 'onSubmit'
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "policies",
    });

    useEffect(() => {
        if (employees?.length && benefit) {
            if (benefit.beneficiary === "TEAM") {
                const team = teams?.find(item => item.id === benefit.beneficiaryId);
                if (team) {
                    setTeamName({ value: team.value });
                }
            } else if (benefit.beneficiary === "EMPLOYEE") {
                const employee = employees.find(item => item.id === benefit.beneficiaryId);
                if (employee) {
                    setEmployeeName({ value: employee.value });
                }
            }
            if (benefit.policies?.length) {
                setPolicyName(benefit.policies.map(policy => policy.name));
            }
            reset({ policies: benefit.policies });
        }
    }, [employees, teams, benefit]);


    const updatePolicies = (policies) => {
        policies?.forEach((item, index) => {
            if (item.unit) {
                item.unit = benefitUnitOptions?.find(option => option.label === item.unit)?.value;
            }
            if (item.per) {
                item.per = benefitReportOptions?.find(option => option.label === item.per)?.value;
            }
            if (item.name) {
                item.name = modifiedBenefitRulesOptions?.find(option => option.label === item.name)?.value;
            }
            if (item.day) {
                item.day = dayOptions.find(option => option.label === item.day)?.value || item.day;
            }
            if(item.periodTo || item.periodFrom){
                item.periodFrom = convertTimeToDecimal(item.periodFrom);
                item.periodTo = convertTimeToDecimal(item.periodTo);
            }
        });
    }
    const handleSave = async (payload)=> {
        if (isInProgress) {
            return;
        }
        setIsInProgress(true);
        payload['id'] = benefit?.id;
        payload['priority'] = benefit?.priority;
        payload['tenant'] = benefit?.tenant || {idString: tenantId};
        payload['benefitTrackingTime'] = benefitTracking;
        payload['type'] = selectedBenefitType;
        updatePolicies(payload?.policies);

        const selectedItemArray = (payload.beneficiary === "TEAM" || payload.beneficiary === "EMPLOYEE") ? (payload.beneficiary === "TEAM" ? teams : employees) : null;
        if(selectedItemArray){
            payload.beneficiaryId = selectedItemArray.find(item => item?.value === payload?.beneficiaryId).id;
        }
        try {
            benefit? await updateBenefit(payload) : await addBenefit(payload);
            toast.success(benefit? "Updated Successfully" : 'Saved Successfully');
            if(toggleBenefitFormModal){
                toggleBenefitFormModal();
            }
            refreshData();
        } catch (e) {
            const message = getErrorMessage(e);
            // setError("name", { message });
            toast.error(message);
        }
        setIsInProgress(false);
    };
    const handleDelete = async (event)=> {
        event.preventDefault();
        if (isInProgress) {
            return;
        }
        setIsInProgress(true);
        try {
            await deleteBenefit(benefit?.id);
            toast.success("Deleted Successfully");
            toggleConfirmationModal();
            refreshData();
        } catch (e) {
            toast.error(getErrorMessage(e));
        }
        setIsInProgress(false);
    };
    return (
        <div id="BenefitForm" className="c-benefit-form">
            <form onSubmit={handleSubmit(handleSave)} className="m-3">
                <Grid container spacing={{md: 1, xs: 0}}>
                    <Grid item xs={12} md={5}>
                        <TextField
                            errors={errors}
                            label="Name"
                            inputRef={register({
                                required: "this field is required",
                            })}
                            InputLabelProps={{shrink: true}}
                            name="name"
                            id="name"
                            className="w-100"
                            defaultValue={benefit?.name || ""}
                        />
                    </Grid>
                    <Grid item md={5}>
                        <CheckboxesTags
                            id="type"
                            label="Type"
                            name="type"
                            labelField='label'
                            fullWidth={true}
                            options={benefitTypeOptions}
                            defaultValue={benefit?.type ? {label: benefitTypeOptions?.find((option) => option.value === benefit?.type)?.label} : benefitTypeOptions[0]}
                            inputRef={register({
                                required: "This field is required"
                            })}
                            errors={errors}
                            onChange={(e, value) => setSelectedBenefitType(value?.value ? value?.value : null)}
                        />
                    </Grid>
                    <Grid item md={5}>
                        <CheckboxesTags
                            label="Action"
                            name="benefitTrackingTime"
                            labelField='label'
                            fullWidth={true}
                            options={benefitActionOptions}
                            onChange={(e, value) => setBenefitTracking(value?.value)}
                            defaultValue={benefit?.benefitTrackingTime? {label: benefitActionOptions?.find((option) => option.value === benefit?.benefitTrackingTime)?.label } : benefitActionOptions[0]}
                            id="benefitTrackingTime"
                            inputRef={register({
                                required: "This field is required"
                            })}
                            errors={errors}
                        />

                    </Grid>
                </Grid>
                <Grid container spacing={{md: 1, xs: 0}}>
                    <Grid item md={5}>
                        <DatePicker
                            name="fromDate"
                            label="From Date"
                            inputRef={register({
                                required: "This field is required"
                            })}
                            onChange={(event)=> setFromDate(event && moment(event))}
                            format={DATE_FORMAT}
                            defaultValue={benefit?.fromDate? moment(benefit?.fromDate): null}
                        />
                    </Grid>
                    <Grid item md={5}>
                        <DatePicker
                            name="toDate"
                            label="To Date"
                            inputRef={register({
                                required: "This field is required"
                            })}
                            onChange={(event)=> setToDate(event && moment(event))}
                            minDate={fromDate}
                            format={DATE_FORMAT}
                            defaultValue={benefit?.toDate? moment(benefit?.toDate) : null}
                        />
                    </Grid>
                    <Grid item md={5}>
                        <CheckboxesTags
                            label="Beneficiary"
                            name="beneficiary"
                            onChange={(e, value) => setBeneficiary(value?.value)}
                            labelField='value'
                            fullWidth={true}
                            options={benefitBeneficiaryOptions}
                            defaultValue={benefit?.beneficiary? {value: benefit?.beneficiary} : benefitBeneficiaryOptions[0]}
                            id="beneficiary"
                            inputRef={register({
                                required: "This field is required"
                            })}
                            errors={errors}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={{md: 1, xs: 0}}>
                    {beneficiary === "EMPLOYEE" &&
                        <Grid item md={5}>
                            <CheckboxesTags
                                label="Employee"
                                name="beneficiaryId"
                                labelField='value'
                                fullWidth={true}
                                options={employees}
                                onChange={(e, value) => setEmployeeName(value)}
                                value={employeeName}
                                id="employee"
                                inputRef={register({
                                    required: "This field is required"
                                })}
                                errors={errors}
                                showLeftImage
                            />
                        </Grid>
                    }
                    {beneficiary === "TEAM" &&
                        <Grid item md={5}>
                            <CheckboxesTags
                                label="Team"
                                name="beneficiaryId"
                                fullWidth={true}
                                labelField='value'
                                options={teams}
                                onChange={(e, value) => setTeamName(value)}
                                value={teamName}
                                id="team"
                                inputRef={register({
                                    required: "This field is required"
                                })}
                                errors={errors}
                            />
                        </Grid>
                    }
                    <Grid item md={12}>
                        <Divider
                            className="form-divider"
                        />
                    </Grid>
                    <Grid item md={12} className="my-2">
                        <span className="m-1 fs-4">Rules</span>
                    </Grid>
                    {fields?.map((item, index) => (
                        <Grid container spacing={{md: 1, xs: 0}} key={item?.id} className="m-1">
                            <Grid item md={5}>
                                <CheckboxesTags
                                    label="Name"
                                    name={`policies[${index}].name`}
                                    labelField='label'
                                    fullWidth={true}
                                    onChange={(e, value) =>
                                        setPolicyName(prevPolicyName => {
                                            const updatedArray = [...prevPolicyName];
                                            updatedArray[index] = value?.value;
                                            return updatedArray;
                                        })
                                    }
                                    options={modifiedBenefitRulesOptions}
                                    value={
                                        policyName[index]
                                            ? {
                                                label: modifiedBenefitRulesOptions?.find(
                                                    (option) => option.value === policyName[index]
                                                )?.label,
                                            }
                                            : null
                                    }
                                    id="PoliciesName"
                                    inputRef={register({
                                        required: "This field is required"
                                    })}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid container spacing={{md: 1, xs: 0}} className="m-1">
                                {policyName[index] === "FREE_TIME_OFF" && (
                                    <>
                                        <Grid item md={3}>
                                            <TextField
                                                inputRef={register({
                                                    required: "required",
                                                })}
                                                errors={errors}
                                                type="number"
                                                label="Value"
                                                name={`policies[${index}].maxUnits`}
                                                defaultValue={item?.maxUnits || 0}
                                                id="maxUnits"
                                                className="w-100"
                                            />
                                        </Grid>
                                        <Grid item md={3}>
                                            <CheckboxesTags
                                                label="Unit"
                                                name={`policies[${index}].unit`}
                                                labelField='label'
                                                fullWidth={true}
                                                options={benefitUnitOptions}
                                                defaultValue={item.unit? {label: benefitUnitOptions?.find((option) => option.value === item.unit)?.label} : benefitUnitOptions[0]}
                                                id="unit"
                                                inputRef={register({
                                                    required: "This field is required"
                                                })}
                                                errors={errors}
                                            />
                                        </Grid>
                                        <Grid item md={3}>
                                            <CheckboxesTags
                                                label="Report Per"
                                                name={`policies[${index}].per`}
                                                labelField='label'
                                                fullWidth={true}
                                                options={benefitReportOptions}
                                                defaultValue={item.per? {label: benefitReportOptions?.find((option) => option.value === item.per)?.label} : benefitReportOptions[0]}
                                                id="per"
                                                inputRef={register({
                                                    required: "This field is required"
                                                })}
                                                errors={errors}
                                            />
                                        </Grid>
                                    </>
                                )}
                                {policyName[index] === "FREE_DAY_OFF" && (
                                    <Grid item xs={5}>
                                        <CheckboxesTags
                                            label="Day"
                                            name={`policies[${index}].day`}
                                            labelField='label'
                                            fullWidth={true}
                                            options={dayOptions}
                                            defaultValue={item.day ? {label: dayOptions.find(day => day.value === item.day).label} : dayOptions[0]}
                                            id="day"
                                            inputRef={register({
                                                required: "This field is required"
                                            })}
                                            errors={errors}
                                        />
                                    </Grid>
                                )}
                                {policyName[index] === "FREE_DEFINED_PERIOD_TIME_OFF" && (
                                    <>
                                        <Grid item md={5}>
                                            <TimePicker
                                                name={`policies[${index}].periodFrom`}
                                                label="From Date"
                                                inputRef={register({
                                                    required: "This field is required"
                                                })}
                                                onChange={(event)=> setFromTime(event && moment(event))}
                                                format={TIME_FORMAT}
                                                defaultValue={item?.periodFrom? convertDecimalToTime(item?.periodFrom) : undefined}
                                            />
                                        </Grid>
                                        <Grid item md={5}>
                                            <TimePicker
                                                name={`policies[${index}].periodTo`}
                                                label="To Date"
                                                inputRef={register({
                                                    required: "This field is required"
                                                })}
                                                minTime={fromTime}
                                                format={TIME_FORMAT}
                                                defaultValue={item?.periodTo? convertDecimalToTime(item?.periodTo) : undefined}
                                            />
                                        </Grid>
                                    </>
                                )}
                                {policyName[index] === "OTHER" && (
                                    <>
                                        <Grid item md={3}>
                                            <TextField
                                                name={`policies[${index}].maxUnits`}
                                                inputRef={register({
                                                    required: "required",
                                                })}
                                                errors={errors}
                                                type="number"
                                                label="Value"
                                                id="OtherMaxUnits"
                                                defaultValue={item?.maxUnits || 0}
                                                className="w-100"
                                            />
                                        </Grid>
                                        <Grid item md={3}>
                                            <CheckboxesTags
                                                label="Unit"
                                                name={`policies[${index}].unit`}
                                                labelField='label'
                                                fullWidth={true}
                                                options={benefitUnitOptions}
                                                defaultValue={item.unit? {label: benefitUnitOptions?.find((option) => option.value === item.unit)?.label} : benefitUnitOptions[0]}
                                                id="otherUnit"
                                                inputRef={register({
                                                    required: "This field is required"
                                                })}
                                                errors={errors}
                                            />
                                        </Grid>
                                        <Grid item md={3}>
                                            <CheckboxesTags
                                                label="Report Per"
                                                name={`policies[${index}].per`}
                                                labelField='label'
                                                fullWidth={true}
                                                options={benefitReportOptions}
                                                defaultValue={item.per? {label: benefitReportOptions?.find((option) => option.value === item.per)?.label} : benefitReportOptions[0]}
                                                id="OtherPer"
                                                inputRef={register({
                                                    required: "This field is required"
                                                })}
                                                errors={errors}
                                            />
                                        </Grid>
                                    </>
                                )}
                                <Grid item xs={2}>
                                    <Button
                                        className="delete-btn"
                                        type="button"
                                        onClick={() => {
                                            remove(index);
                                            policyName.splice(index, 1);
                                        }}
                                        variant="contained"
                                    >
                                        <ClearIcon/>
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item md={12}>
                                <Divider
                                    className="form-divider"
                                />
                            </Grid>
                        </Grid>
                    ))}
                    <Grid item xs={2} className="mb-3">
                        <Button
                            type="button"
                            onClick={() => {
                                append({
                                    id: `policies_${fields.length}`,
                                });
                                setPolicyName(prevPolicyName => [...prevPolicyName, "FREE_TIME_OFF"]);
                            }}
                            variant="contained"
                        >
                            <AddIcon/>
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12} className="my-3">
                    <Button type="submit" variant="contained" className='float-end my-3' disabled={isInProgress}>
                        Save
                    </Button>
                    {toggleBenefitFormModal ?
                        <Button
                            className="float-end m-3"
                            variant="outlined"
                            onClick={toggleBenefitFormModal}
                        >
                            Cancel
                        </Button>
                        :
                        <Button
                            className="float-end m-3 bg-danger"
                            color="secondary"
                            variant="outlined"
                            onClick={() => {
                                toggleConfirmationModal();
                            }}
                        >
                            Delete
                        </Button>
                    }
                </Grid>
            </form>
            <Dialog className='confirmation-dialog' open={modals[CONFIRMATION_MODAL]} toggle={toggleConfirmationModal}>
                <>
                    <div className="m-4" style={{color: colorTheme}}>
                        Are you sure you want to delete this ?
                    </div>
                    <div>
                        <DialogActions className="p-3 mt-3">
                            <Button className="px-4 me-3" variant="outlined"
                                    onClick={toggleConfirmationModal}>Cancel</Button>
                            <Button className="px-4 bg-danger" color="secondary" variant="outlined"
                                    onClick={(event)=> handleDelete(event)} disabled={isInProgress}>Delete</Button>
                        </DialogActions>
                    </div>
                </>
            </Dialog>
        </div>
    );
};
const modalsProps = {
    toggleConfirmationModal: CONFIRMATION_MODAL
}
export default WithModals(BenefitForm, modalsProps);
