import React, { useState, useEffect } from 'react';
import { Tab as MuiTab, Tabs as MuiTabs } from '@mui/material';

import "./Tabs.scss";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`custom-tabpanel-${index}`}
          aria-labelledby={`custom-tab-${index}`}
          {...other}
      >
        {value === index && <>{children}</>}
      </div>
  );
}

const a11yProps = (index) => {
  return {
    id: `custom-tab-${index}`,
    className: 'custom-tab',
    'aria-controls': `custom-tabpanel-${index}`,
  };
}

function Tabs({ items, currentTabIndex, handleTabOnChange }) {
  const [tabValue, setTabValue] = useState(currentTabIndex || 0);

  useEffect(() => {
    setTabValue(currentTabIndex);
  }, [currentTabIndex]);

  const handleTabChange = (newValue) => {
    setTabValue(newValue);
    if (handleTabOnChange) {
      handleTabOnChange(newValue);
    }
  };

  return (
    <div className="custom-tabs">
      <MuiTabs
        value={tabValue}
        onChange={(e, newValue) => handleTabChange(newValue)}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        {items.map((item, index) => (
          <MuiTab
            key={index}
            label={item.label}
            {...a11yProps(index)}
          />
        ))}
      </MuiTabs>

      {items.map(({component}, index) => (
        <TabPanel
          key={index}
          value={tabValue}
          index={index}
          className="tab-panel w-100 animated fadeIn"
        >
          {component}
        </TabPanel>
      ))}
    </div>
  );
}

export default Tabs;