import React, { useEffect, useState } from "react";
import { Save } from "@mui/icons-material";
import {Button, DataGridTable, Dialog, Tooltip, TextField} from "components/Mui";
import { AddCircleIcon, DeleteIcon } from "components/Mui/Icons";
import { DialogActions, GridActionsCellItem } from "components/Mui";
import CustomAppBar from "components/CustomAppBar/CustomAppBar";
import { v4 as uuidv4 } from 'uuid';
import WithModals from "hoc/withModals/withModals";
import { getSystemConfiguration } from "utility/apis/SettingService";
import API from "utility/apis/ApiBase";
import { API_URL } from "utility/constants/APIConst";
import { NEW_SYSTEM_CONFIGURATION_MODAL } from "utility/modals/constants";
import "./SystemConfiguration.scss";
import {useForm} from "react-hook-form";
import {getErrorMessage} from "utility/helpers/utils";
import {toast} from "react-toastify";

function SystemConfiguration(props) {
  const {
    toggleNewSystemConfigurationModal,
    modals,
    handleCloseButton,
    title,
    tenantId
  } = props;
  const [dataTable, setDataTable] = useState([]);
  const systemConfigurationListUrl = API_URL.SYSTEM_CONFIGURATION_LIST;
  const [checkboxState, setCheckboxState] = useState({});
  const [newSystemConfigName, setNewSystemConfigName] = useState("");
  const [newSystemConfigEnabled, setNewSystemConfigEnabled] = useState(true);
  const { register, errors, setError } = useForm({ mode: 'all' });
  const [isInProgress, setIsInProgress] = useState(false);

  const fetchData = async () => {
    try {
      const response = await getSystemConfiguration(tenantId);
      const initialState = {};
      response?.data.forEach((row) => {
        initialState[row.name] = row.enabled;
      });
      setCheckboxState(initialState);
      setDataTable(response?.data);
    } catch (e) {
      console.error("Error fetching data:", e);
    }
  };

  const updateSystemConfigList = async () => {
    const updatedSystemConfigData = await getSystemConfiguration(tenantId);
    setDataTable(updatedSystemConfigData.data);
    const initialState = {};
    updatedSystemConfigData?.data.forEach((row) => {
      initialState[row.name] = row.enabled;
    });
    setCheckboxState(initialState);
  };

  const handleDelete = async (rowData) => {
    try {
      const apiUrl = `${API_URL.DELETE_SYSTEM_CONFIGURATION(tenantId)}`;
      await API.delete(apiUrl, { data: { name: rowData.name } });
      await updateSystemConfigList();
      toast.success("Deleted Successfully");
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  };

  const handleCheckboxChange = (event, rowData) => {
    const { checked } = event.target;
    if (rowData && rowData.name) {
      const { name } = rowData;
      setCheckboxState((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } else if (rowData === "new") {
      setNewSystemConfigEnabled(event.target.checked);
    } else {
      console.log("Checkbox state handling error", rowData);
    }
  };

  const handleSave = async (event, rowData) => {
    try {
      let dataToSend = {};
      if (checkboxState[rowData.name] !== rowData.enabled) {
        dataToSend = {
          name: rowData.name,
          enabled: checkboxState[rowData.name],
          tenantId: tenantId,
        };
        await API.put(API_URL.SYSTEM_CONFIGURATION_LIST(tenantId), dataToSend);
        await updateSystemConfigList();
        toast.success("Updated Successfully");
      }
    } catch (e) {
      const message = getErrorMessage(e);
      // setError("name", { message });
      toast.error(message);
    }
  };

  const handleAddClick = () => {
    toggleNewSystemConfigurationModal();
  };

  const addNewSystemConfig = async (event) => {
    setIsInProgress(true);
    try {
      event.preventDefault();
      let payload = {
        name: newSystemConfigName,
        enabled: newSystemConfigEnabled,
        tenantId: tenantId,
      };
      await API.post(API_URL.ADD_NEW_SYSTEM_CONFIGURATION_LIST(), payload);
      await updateSystemConfigList();
      toast.success("Added Successfully");
      toggleNewSystemConfigurationModal();
    } catch (e) {
      const message = getErrorMessage(e);
      // setError("name", { message });
      toast.error(message);
    }finally {
      setTimeout(() => {
        setIsInProgress(false);
      }, 2000);
    }
  };

  useEffect(() => {
    fetchData();
  }, [tenantId]);

  const columnDefs = [
    {
      headerName: "Name",
      field: "name",
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <div>{params.value}</div>
        </Tooltip>
      ),
      flex: 1,
    },
    {
      headerName: "Enable/Disable",
      field: "enabled",
      renderCell: (params) => (
        <div>
          <input
            type="checkbox"
            checked={checkboxState[params.row?.name]}
            onChange={(event) => handleCheckboxChange(event, params.row)}
          />
        </div>
      ),
      flex: 1,
    },
    {
      headerName: "Actions",
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
           title="Save"  // Tooltip on hover
          aria-label="Save" // Screen reader support
          icon={<Save />}
          label="Save"
          onClick={(event) => handleSave(event, params.row)}
        />,
        <GridActionsCellItem
          title="Delete"  // Tooltip on hover
          aria-label="Delete" // Screen reader support
          icon={<DeleteIcon />}
          label="Delete"
          className="text-danger"
          onClick={(event) => handleDelete(params.row)}
        />,
      ],
    },
  ];

  return (
    <div
      className="row ps-4 mt-4 SystemConfiguration"
      style={{ height: "calc(100vh - 135px)", width: `100%` }}
    >
      <CustomAppBar
        title={title}
        showSecondaryButton
        textSecondary="Close"
        handleCloseButton={handleCloseButton}
      />
      <div className="d-flex">
        <div className="message-frame">Create a new system configuration</div>
        <Button
          className="add-system-configuration-button"
          onClick={handleAddClick}
        >
          <AddCircleIcon />
        </Button>
      </div>
      <DataGridTable
        className="col-12"
        URL={systemConfigurationListUrl}
        columns={columnDefs}
        dataTable={dataTable}
        getRowId={(row) => row.name + uuidv4()}
        isExport={false}
      />

      <Dialog
        className="new-system-config-dialog"
        open={modals[NEW_SYSTEM_CONFIGURATION_MODAL]}
        toggle={toggleNewSystemConfigurationModal}
      >
        <form onSubmit={addNewSystemConfig}>
          <div className="m-4">
            <h6 className="mb-4 u-color-primary">
              Create a new System Configuration
            </h6>
            <div className="row">
              <TextField
                required
                className="col-12"
                id="name"
                name="name"
                label="Name"
                type="text"
                inputRef={register({
                  required: "This field is required"
                })}
                errors={errors}
                onChange={(event) => setNewSystemConfigName(event.target.value)}
              />
              <div style={{ display: "flex", gap: "5px", padding: 0 }}>
                <input
                  type="checkbox"
                  id="new_config_checkbox"
                  checked={newSystemConfigEnabled}
                  onChange={(event) => handleCheckboxChange(event, "new")}
                />
                <label htmlFor="new_config_checkbox">Enabled</label>
              </div>
            </div>
          </div>
          <div>
            <DialogActions className="p-3 mt-3">
              <Button
                className="px-4"
                variant="outlined"
                onClick={toggleNewSystemConfigurationModal}
              >
                Cancel
              </Button>
              <Button className="px-4" variant="contained" type="submit" disabled={isInProgress}>
                Save
              </Button>
            </DialogActions>
          </div>
        </form>
      </Dialog>
    </div>
  );
}

const modalsProps = {
  toggleNewSystemConfigurationModal: NEW_SYSTEM_CONFIGURATION_MODAL,
};

export default WithModals(SystemConfiguration, modalsProps);
