import API from "./ApiBase";

export const getHolidays = async (tenantId, range) => {
  const { from, to } = range;
  if (!from) {
    return;
  }
  let params = {
    from,
    to,
  };

  const response = await API.get(`/api/v1/holidays/range/${tenantId}`, {
    params,
  });
  return response;
};

export const updateHolidays = async (holidayId, payload) => {
  const response = await API.put(`/api/v1/holidays/${holidayId}`, payload);
  return response;
};

export const deleteHoliday = async (holidayId) => {
  // I know this is wired but backend implemented to 
  // accept the request only if the request contains payload
  const response = await API.delete(`/api/v1/holidays/${holidayId}`,
  { data: {
  }});
  return response;
};

export const createHoliday = async (tenantId, payload) => {
  const response = await API.post(`/api/v1/holidays/${tenantId}`, payload);
  return response;
};
