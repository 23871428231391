import React, {useEffect, useState} from 'react';
import CustomAppBar from "components/CustomAppBar/CustomAppBar";
import { getFunctionalRole } from "utility/apis/SettingService";
import {Button, Dialog} from "components/Mui";
import Loading from "components/Loading/Loading";
import {AddCircleIcon, ExpandMoreIcon} from "components/Mui/Icons";
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "components/Mui";
import WithModals from "hoc/withModals/withModals";
import {FUNCTIONAL_ROLE_MODAL} from "utility/modals/constants";
import FunctionalRoleForm from "./FunctionalRole/FunctionalRoleForm";

import "./FunctionalRoleSetting.scss"
const FunctionalRoleSetting = (props) => {
    const {title, handleCloseButton, tenantId, colorTheme, modals, toggleFunctionalRoleModal, toggleConfirmationModal} = props;
    const [functionalRoles, setFunctionalRoles] = useState();
    const [inProgress, setInProgress] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const fetchData = async () => {
        try {
            setInProgress(true);
            let response = await getFunctionalRole(tenantId);
            setFunctionalRoles(response.data);
            setExpanded(false);
        } catch (e) {
            setFunctionalRoles([
                {
                    "id": 1,
                    "value": "test1",
                    "description": "ttt1",
                    "tenantId": "36772a23-c90c-4f76-a53b-511b6001a510"
                },
                {
                    "id": 2,
                    "value": "test2",
                    "description": "ttt2",
                    "tenantId": "36772a23-c90c-4f76-a53b-511b6001a510"
                }
            ]);
            console.log(e);
        }
        setInProgress(false);
    }
    useEffect( () => {
        fetchData();
    }, []);


    return (
        <div id="FunctionalRoleSetting" className="c-functional-role-setting px-4 mt-4">
            <CustomAppBar
                title={title}
                showSecondaryButton
                textSecondary="Close"
                handleCloseButton={handleCloseButton}
            />
            {inProgress ?
                <Loading></Loading>
            :
                <>
                    <div className="d-flex">
                        <div className="message-frame">
                            Create a new functional role
                        </div>
                        <Button className="add-functional-role-button" onClick={toggleFunctionalRoleModal}>
                            <AddCircleIcon/>
                        </Button>
                    </div>
                    {functionalRoles?.map((item) => (
                        <Accordion
                            className="my-3"
                            expanded={expanded === item.id}
                            onChange={handleChange(item.id)}
                            key={item.id}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                className="accordion-summary-bg"
                            >
                                <Typography className="functional-role-title">{item.value}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <FunctionalRoleForm role={item} tenantId={tenantId} fetchData={fetchData} toggleConfirmationModal={toggleConfirmationModal} colorTheme={colorTheme}></FunctionalRoleForm>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </>
            }
            <Dialog
                className="details-dialog"
                open={modals[FUNCTIONAL_ROLE_MODAL]}
                toggle={toggleFunctionalRoleModal}
                mainTitle={'Create a New Functional Role'}
            >
                <div>
                    <FunctionalRoleForm tenantId={tenantId} fetchData={fetchData} toggleFunctionalRoleModal={toggleFunctionalRoleModal} colorTheme={colorTheme}></FunctionalRoleForm>
                </div>
            </Dialog>
        </div>
    );
};

const modalsProps = {
    toggleFunctionalRoleModal: FUNCTIONAL_ROLE_MODAL,
};

export default WithModals(FunctionalRoleSetting, modalsProps);
