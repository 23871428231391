import React, {useState} from "react";
import HolidayAddEditForm from "components/Holidays/HolidayAddEditForm/HolidayAddEditForm";
import {createHoliday} from "utility/apis/HolidayService";
import {getErrorMessage, getTenantId} from "utility/helpers/utils";
import {useLocation} from "react-router-dom";
import { AdapterMoment, LocalizationProvider, Container } from 'components/Mui';
import {toast} from "react-toastify";

const AddHoliday = (props) => {
    const { title, handleCloseButton } = props;
    const [submitting, setSubmitting] = useState(false);
    const tenantId = getTenantId(useLocation(), "tenantId");
    const handleCreate = async (data) => {
        try {
            setSubmitting(true);
            await createHoliday(tenantId, {
                ...data,
                isExcluded: data.isExcluded ? 1 : 0,
                toDate: data.toDate ? data.toDate : data.fromDate,
                special: parseInt(data.special),
            });
            toast.success("Added Holiday Successfully");
            handleCloseButton();
        } catch (e) {
            toast.error(getErrorMessage(e));
        }
        setSubmitting(false);
    };
    return (
        <div id="add-holiday" className="c-add-holiday">
            <div className="container-fluid">
                <div className='fs-4'>
                    <Container className="bg-white float-start" maxWidth="lg">
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <HolidayAddEditForm
                                holidayAddWithCustomBar
                                title = {title}
                                handleCloseButton = {handleCloseButton}
                                handleSave = {handleCreate}
                                submitting={submitting}
                            />
                        </LocalizationProvider>
                    </Container>
                </div>
            </div>
        </div>
    );
};
export default AddHoliday;
