const INIT_ENV_IP = "http://172.25.2.80:9091";

const MODE = {
  PRODUCTION: {
    IS_DEV_MODE: false,
    ENV_IP: window.location.protocol + "//" + window.location.host,
  },
  DEVELOPMENT: {
    IS_DEV_MODE: true,
    ENV_IP: INIT_ENV_IP,
  },
  MOCK: {
    IS_DEV_MODE: true,
    ENV_IP: "http://localhost:3100",
    PROXY_HOST_IP: INIT_ENV_IP,
  },
};

const { IS_DEV_MODE, ENV_IP, PROXY_HOST_IP } = MODE.PRODUCTION; // Make sure this var is PRODUCTION before push your change

const ENVIRONMENT = {
  IS_DEV_MODE,
  ENV_IP,
  PROXY_HOST_IP,
};

module.exports = {
  ENVIRONMENT,
};
