import React, {useState} from 'react';
import {Button, DatePicker, Dialog, DialogActions, Divider, Grid, MenuItem, TextField, CircularProgress} from "components/Mui";
import {Controller, useForm} from "react-hook-form";
import {daysPerYearArr} from "utility/constants/data";
import {DISABLE_POLICY_MODAL} from "utility/modals/constants";
import {enablePolicies, updatePolicy} from "utility/apis/SetupGuideService";
import moment from "moment/moment";
import withModals from "hoc/withModals/withModals";
import {getErrorMessage} from "utility/helpers/utils";
import {toast} from "react-toastify";

const LongVacationLeavePolicy = (props) => {
    const {longVacationPolicy, tenantId, setInProgress, refreshPolicyData, modals, colorTheme, toggleDisablePolicyModal} = props;
    const [fromDate, setFromDate] = useState(longVacationPolicy?.fromDate? moment(longVacationPolicy?.fromDate) : null);
    const [toDate, setToDate] = useState(longVacationPolicy?.toDate? moment(longVacationPolicy?.toDate) : null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { register, control, handleSubmit, errors } = useForm({
        defaultValues: {},
    });
    const handleSave = async (data) => {
        setInProgress(true)
        try {
            await updatePolicy("longVacationPolicy", longVacationPolicy?.id, tenantId, data)
            refreshPolicyData();
            toast.success("Updated Successfully");
        } catch (e) {
            toast.error(getErrorMessage(e));
            setInProgress(true)
        }
    };
    const handleDisable = async () => {
        if (isSubmitting) return;
        setIsSubmitting(true);
        const payload = [{
            policyType: longVacationPolicy?.type,
            enable: false
        }];
        try {
            await enablePolicies(tenantId, payload);
            refreshPolicyData();
            toggleDisablePolicyModal();
            toast.success("Disabled Successfully");
        } catch (e) {
            toast.error(getErrorMessage(e));
            setIsSubmitting(false);
        }
    };
    return (
        <div id="LongVacationLeavePolicy" className="c-long-vacation-leave-policy px-4 mt-3">
            <form id="LongVacationLeavePolicy" onSubmit={handleSubmit(handleSave)}>
                <Grid container spacing={{md: 1, xs: 0}}>
                    <Grid item xs={4}>
                        <Controller
                            as={
                                <TextField
                                    errors={errors}
                                    label="Long Vacation Leave Amount"
                                    type="number"
                                    inputRef={register({
                                        required: "This field is required",
                                        validate: (value) =>
                                            value >= 0,
                                    })}
                                    inputProps={{
                                        min: 0,
                                        step: "any"
                                    }}
                                />
                            }
                            id="maxAllowPerYear"
                            control={control}
                            mode="onBlur"
                            name="maxAllowPerYear"
                            defaultValue={longVacationPolicy.maxAllowPerYear}
                        />
                    </Grid>
                    <Grid item md={3} xs={4}>
                        <Controller
                            as={
                                <TextField select>
                                    {daysPerYearArr.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            }
                            id="maxAllowPerYearUnit"
                            control={control}
                            mode="onBlur"
                            name='maxAllowPerYearUnit'
                            defaultValue={longVacationPolicy.maxAllowPerYearUnit}
                        />
                    </Grid>
                    <Grid item xs={12} spacing={3}>
                        <Divider sx={{ bgcolor: "black", p: 0, mt: 0 }}/>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="fs-5 fw-normal mb-3">Schedule</div>
                    </Grid>
                    <Grid item xs={4}>
                        <DatePicker
                            name="fromDate"
                            label="From Date"
                            className="col-12"
                            defaultValue={moment(longVacationPolicy?.fromDate)}
                            onChange={(event)=> setFromDate(event && moment(event))}
                            inputRef={register}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <DatePicker
                            name="toDate"
                            label="To Date"
                            className="col-12"
                            defaultValue={moment(longVacationPolicy?.toDate)}
                            onChange={(event)=> setToDate(event && moment(event))}
                            minDate={fromDate}
                            inputRef={register}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" className='float-end my-3'>
                            Save
                        </Button>
                        <Button className="bg-danger float-end my-3 mx-3" color="secondary" variant="outlined"
                                onClick={toggleDisablePolicyModal}>
                            Disable Policy
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Dialog
                className="details-dialog"
                open={modals[DISABLE_POLICY_MODAL]}
                toggle={toggleDisablePolicyModal}
            >
                <div>
                    <div className="m-4" style={{color: colorTheme}}>
                        Are you sure you want to disable ( Long Vacation Leaves ) Policy?
                    </div>
                    <div>
                        <DialogActions className="p-3 mt-3">
                            <Button
                                className="px-4 me-3"
                                variant="outlined"
                                onClick={toggleDisablePolicyModal}
                            >
                                Cancel
                            </Button>
                            <Button
                                className="px-4 bg-danger"
                                color="secondary"
                                variant="outlined"
                                onClick={handleDisable}
                            >
                                {isSubmitting ? (
                                        <CircularProgress size={24} color="inherit" />
                                    ) :
                                    "Disable"
                                }
                            </Button>
                        </DialogActions>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};
const modalsProps = {
    toggleDisablePolicyModal: DISABLE_POLICY_MODAL,
};

export default withModals(LongVacationLeavePolicy, modalsProps);
