import React, {useEffect, useState} from 'react';
import { TABLES_NAMES } from "utility/constants/constants";
import {DataGridTable} from "components/Mui";
import {map} from "lodash";
import {API_URL} from "utility/constants/APIConst";

import "./AttendanceReportMonthlyBasis.scss";

const AttendanceReportMonthlyBasis = (props) => {
    const { employeeId, date, message} = props;
    const [ attendanceReportListUrl, setAttendanceReportListUrl ] = useState(undefined);

    useEffect(() => {
         if (date) {
            setAttendanceReportListUrl(API_URL.ATTENDANCE_REPORT_MONTHLY_SUMMARY(employeeId, (date?.value || date)));
        }
    }, [date]);

    const columnDefs = [
        {
            headerName: 'Month',
            field: 'month',
            flex: 1,
            headerAlign: 'center',
            align: 'center'
        },
        {
            headerName: 'Working Hours',
            field: 'workingHours',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            valueFormatter: (params) => Number(params.value).toFixed(2)
        },
        {
            headerName: 'Hours to compensate',
            field: 'hoursToCompensate',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            valueFormatter: (params) => Number(params.value).toFixed(2)
        },
        {
            headerName: 'Days to compensate',
            field: 'daysToCompensate',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            valueFormatter: (params) => Number(params.value).toFixed(2)
        },
        {
            headerName: 'Daily Deducted Hours',
            field: 'deductionDailyValue',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            valueFormatter: (params) => Number(params.value).toFixed(2)
        },
        {
            headerName: 'Monthly Deducted Hours',
            field: 'deductionMonthlyValue',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            valueFormatter: (params) => Number(params.value).toFixed(2)
        }
    ];

    const formatDataTable = (data) => {
        return map(data,({month, numOfWorkingHours, hoursToCompensate, daysToCompensate, deductionDailyValue, deductionMonthlyValue}, index) => {
            return {
                id: index,
                month: month,
                workingHours: numOfWorkingHours,
                hoursToCompensate: hoursToCompensate,
                daysToCompensate: daysToCompensate,
                deductionDailyValue: deductionDailyValue,
                deductionMonthlyValue: deductionMonthlyValue
            }
        });
    }

    return (
        <div id="monthly-basis" className="c-attendance-report-monthly-basis ps-4">
            <div className="container-fluid">
                <div style={{height: `calc(100vh - ${message ? 155 : 120}px)`}} className="row">
                    <DataGridTable
                        className="col-12"
                        key={TABLES_NAMES.ATTENDANCE_REPORT_MONTHLY_BASIS}
                        formatDataTable={formatDataTable}
                        columns={columnDefs}
                        URL={attendanceReportListUrl}
                    />
                </div>
            </div>
        </div>
    );
};

export default AttendanceReportMonthlyBasis;