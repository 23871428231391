import React, {useEffect, useState} from "react";
import {DatePicker, Grid} from "components/Mui";
import {FormControl, FormControlLabel, FormGroup, FormLabel} from "@mui/material";
import {Switch} from "@mui/material";
import {DATE_FORMAT} from "utility/helpers/metaData";
import {getDaylightTimeSaving, updateDaylightTimeSaving} from "utility/apis/SettingService";
import CustomAppBar from "components/CustomAppBar/CustomAppBar";
import {getDateFromDayOrder, getDayOrder, getErrorMessage} from "utility/helpers/utils";
import Loading from "components/Loading/Loading";
import {toast} from "react-toastify";

const DaylightTimeSaving = (props) => {
    const {title, tenantId, handleCloseButton} = props;
    let [summerTime, setSummerTime] = useState(null);
    let [winterTime, setWinterTime] = useState(null);
    let [inProgress, setInProgress] = useState(true);
    const [showGrid, setShowGrid] = useState(undefined);
    const moment = require('moment');

    useEffect(() => {
        getDaylightSettings();
    }, []);

    const getDaylightSettings = async () => {
        try {
            setInProgress(true);
            let response = await getDaylightTimeSaving(tenantId);
            const { summerTimeStart, summerTimeEnd, dst} = response.data;
            setShowGrid(dst);
            setSummerTime(moment(getDateFromDayOrder(summerTimeStart)));
            setWinterTime(moment(getDateFromDayOrder(summerTimeEnd)));
        } catch (e) {
            console.error('Error fetching daylight settings:', e);
        }
        setInProgress(false);
    };

    const handleSaveButton = async () => {
        try {
            const summerDayOrder = getDayOrder(summerTime);
            const winterDayOrder = getDayOrder(winterTime);
            const response = await updateDaylightTimeSaving(
                tenantId,
                summerDayOrder,
                winterDayOrder,
                showGrid
            );
            toast.success("Updated Successfully");
            await getDaylightTimeSaving(tenantId);
        } catch (e) {
            toast.error(getErrorMessage(e));
        }
    };

    const handleSwitchChange = (event) => {
        setShowGrid(event.target.checked);
    };

    return (
        <div id="DaylightTimeSaving" className="ps-4 mt-4">
            <CustomAppBar
                title={title}
                showPrimaryButton
                showSecondaryButton
                handlePrimaryButton={handleSaveButton}
                handleCloseButton={handleCloseButton}
                textSecondary="Close"
            />
            {inProgress ?
                <Loading></Loading>
            :
                <>
                    <FormControl
                        component="fieldset"
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "end",
                            gap: "5px",
                        }}
                    >
                        <FormLabel component="legend" style={{width: "fit-content"}}>
                            Enable daylight time saving
                        </FormLabel>
                        <FormGroup aria-label="position" row>
                            <FormControlLabel
                                control={
                                    <Switch
                                        color="primary"
                                        defaultChecked={showGrid}
                                        onChange={handleSwitchChange}
                                    />
                                }
                            />
                        </FormGroup>
                    </FormControl>
                    {showGrid && (
                        <Grid container spacing={{md: 1, xs: 0}}>
                            <Grid item md={5}>
                                <p>Summer time starts on:</p>
                                <DatePicker
                                    name="summerTime"
                                    label="Summer time starts on"
                                    format={DATE_FORMAT}
                                    value={summerTime}
                                    onChange={(date) => setSummerTime(date)}
                                />
                            </Grid>
                            <Grid item md={5}>
                                <p>Winter time starts on:</p>
                                <DatePicker
                                    name="winterTime"
                                    label="Winter time starts on"
                                    format={DATE_FORMAT}
                                    value={winterTime}
                                    onChange={(date) => setWinterTime(date)}
                                />
                            </Grid>
                        </Grid>
                    )}
                </>
            }

        </div>
    );
};

export default DaylightTimeSaving;
