import React from 'react';
import classNames from "classnames";
import {Route, Redirect, useLocation} from 'react-router-dom';
import { getIsLoggedIn } from "utility/helpers/authHelper";

import Header from 'containers/Header/Header';
import Footer from 'containers/Footer/Footer';
import { ThemeProvider } from "components/Mui";
import {defaultTheme} from "theme";
import {getTenantId} from "../../utility/helpers/utils";

const PublicLayout = ({ component: Component, hideAllNav, className='not-logged-in', ...rest }) => {
  const isLoggedIn = getIsLoggedIn();
  const tenantId = getTenantId(useLocation(), "tenantId");

  return(
  <Route
    {...rest}
    render={(matchProps) => (
      <>
      {isLoggedIn ?
        <>
            {tenantId ?
                <Redirect to={{ pathname: '/employees'}} />
                :
                <Redirect to={{ pathname: '/customers'}} />
            }
        </>
        :
        <ThemeProvider theme={defaultTheme}>
          <div className={classNames("c-layout", className)}>
            <Header hideAllNav={hideAllNav} />
            <main className="c-public-layout c-layout_main background-style">
              <Component {...rest} {...matchProps} />
            </main>
            <Footer />
          </div>
        </ThemeProvider>
      }
      </>
    )}
  />
);
}

export default PublicLayout;
