import React from "react";
import { Grid,  Select, TextField } from "components/Mui";
import { Controller } from "react-hook-form";
import { TimeArr } from "utility/constants/data";

const Leaves = (props) => {
  const { errors, register, control, dataFromServer } = props;
  return (
    <Grid container spacing={{ md: 1, xs: 1 }}>
      <Grid item xs={12}>
        <div className="fs-5 fw-semibold my-3">Leaves (per day)</div>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          spacing={{ md: 1, xs: 0 }}
        >
          <Grid item xs={6} md={2}>
            <Controller
              as={
                <TextField
                  errors={errors}
                  label="Min"
                  inputRef={register({
                    required: "required",
                  })}
                  InputLabelProps={{ shrink: true }}
                />
              }
              id="minLeaveTime"
              type="number"
              inputRef={register({
                  required: "This field is required",
                  validate: (value) =>
                      value >= 0,
              })}
              inputProps={{
                  min: 0,
                  step: "any"
              }}
              control={control}
              mode="onBlur"
              name={`minLeaveTime`}
              defaultValue={dataFromServer?.minLeaveTime}
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <Controller
              as={
                <Select options={TimeArr} />
              }
              sx={{ minWidth: "96px" }} // Set fixed width
              id="minLeaveTimeUnit"
              control={control}
              mode="onBlur"
              name={`minLeaveTimeUnit`}
              defaultValue={dataFromServer?.minLeaveTimeUnit}
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <Controller
              as={
                <TextField
                  label="Max"
                  errors={errors}
                  inputRef={register({
                    required: "required",
                  })}
                  InputLabelProps={{ shrink: true }}
                />
              }
              id="maxLeaveTime"
              type="number"
              inputRef={register({
                  required: "This field is required",
                  validate: (value) =>
                      value >= 0,
              })}
              inputProps={{
                  min: 0,
                  step: "any"
              }}
              control={control}
              mode="onBlur"
              name={`maxLeaveTime`}
              defaultValue={dataFromServer?.maxLeaveTime}
            />
          </Grid>

          <Grid item xs={6} md={2}>
            <Controller
              as={
                <Select options={TimeArr} />
              }
              sx={{ minWidth: "96px" }} // Set fixed width
              id="maxLeaveTimeUnit"
              control={control}
              mode="onBlur"
              name={`maxLeaveTimeUnit`}
              defaultValue={dataFromServer?.maxLeaveTimeUnit}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Leaves;
