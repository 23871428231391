import React, {useState} from 'react';
import {Controller, useForm} from "react-hook-form";
import {enablePolicies, updatePolicy} from "utility/apis/SetupGuideService";
import {Button, Checkbox, Dialog, DialogActions, FormControlLabel, Select, TextField, CircularProgress} from "components/Mui";
import {DISABLE_POLICY_MODAL} from "utility/modals/constants";
import withModals from "hoc/withModals/withModals";
import {daysPerYearArr, fromYearsArr, timeUnitOptions} from "utility/constants/data";
import {getErrorMessage} from "utility/helpers/utils";
import {toast} from "react-toastify";

const WorkFromHomePolicy = (props) => {
    const {workFromHomePolicy, tenantId, setInProgress, refreshPolicyData, colorTheme, modals, toggleDisablePolicyModal} = props;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { register, control, handleSubmit, errors } = useForm({
        defaultValues: {},
    });
    const handleSave = async (data) => {
        setInProgress(true);
        try {
            await updatePolicy( "wfhPolicy", workFromHomePolicy?.id, tenantId, data);
            refreshPolicyData();
            toast.success("Updated Successfully");
        } catch (e) {
            toast.error(getErrorMessage(e));
            setInProgress(false);
        }
    };
    const handleDisable = async () => {
        if (isSubmitting) return;
        setIsSubmitting(true);
        const payload = [{
            policyType: workFromHomePolicy?.type,
            enable: false
        }];
        try {
            await enablePolicies(tenantId, payload);
            refreshPolicyData();
            toast.success("Disabled Successfully");
            toggleDisablePolicyModal();
        } catch (e) {
            toast.error(getErrorMessage(e));
            setIsSubmitting(false);
        }
    };
    return (
        <div id="WorkFromHomePolicy" className="c-work-from-home-policy px-4 mt-3">
            <form id="WorkFromHomePolicy" className="row d-flex flex-column" onSubmit={handleSubmit(handleSave)}>
                <FormControlLabel
                    control={
                        <Checkbox
                            inputRef={register}
                            name="daysOnly"
                            defaultChecked={workFromHomePolicy?.daysOnly}
                        />
                    }
                    className="check-box"
                    label="In Days Only"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            inputRef={register}
                            name="timesOnly"
                            defaultChecked={workFromHomePolicy?.timesOnly}
                        />
                    }
                    className="check-box"
                    label="In Times Only (regardless hours or days)"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            inputRef={register}
                            name="refuseBridgeVacations"
                            defaultChecked={workFromHomePolicy?.refuseBridgeVacations}
                        />
                    }
                    className="check-box"
                    label="Deny bridge WFH"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            inputRef={register}
                            name="applicableByManagers"
                            defaultChecked={workFromHomePolicy?.applicableByManagers}
                        />
                    }
                    className="check-box"
                    label="Applicable By Managers"
                />
                <div className="col-12 d-flex mt-3">
                    <Controller
                        as={
                            <TextField
                                errors={errors}
                                label="Limit when submitting on the same day"
                                InputLabelProps={{shrink: true}}
                                type="number"
                                inputRef={register({
                                    required: "This field is required",
                                    validate: (value) =>
                                        value >= 0,
                                })}
                                inputProps={{
                                    min: 0
                                }}
                            />
                        }
                        name="limitInHours"
                        id="limitInHours"
                        defaultValue={workFromHomePolicy?.limitInHours}
                        control={control}
                    />
                    <span className="col-5 mx-lg-3 fs-6">hours after first work time slot</span>
                </div>
                <div className="col-12 d-flex">
                    <Controller
                        as={
                            <TextField
                                errors={errors}
                                label="Max days per week"
                                InputLabelProps={{shrink: true}}
                                type="number"
                                inputRef={register({
                                    required: "This field is required",
                                    validate: (value) =>
                                        value >= 0,
                                })}
                                inputProps={{
                                    min: 0,
                                    step: "any"
                                }}
                            />
                        }
                        name="maxDaysInWeek"
                        id="maxDaysInWeek"
                        defaultValue={workFromHomePolicy?.maxDaysInWeek}
                        control={control}
                    />
                    <span className="col-5 mx-lg-3 fs-6">day/s</span>
                </div>
                <div className="col-12 d-flex">
                    <Controller
                        as={
                            <TextField
                                errors={errors}
                                label="Requests should to be submitted at least"
                                InputLabelProps={{shrink: true}}
                                type="number"
                                inputRef={register({
                                    required: "This field is required",
                                    validate: (value) =>
                                        value >= 0,
                                })}
                                inputProps={{
                                    min: 0,
                                    step: "any"
                                }}
                            />
                        }
                        name="minDaysBeforeRequest"
                        id="minDaysBeforeRequest"
                        defaultValue={workFromHomePolicy?.minDaysBeforeRequest}
                        control={control}
                    />
                    <span className="col-5 mx-lg-3 fs-6">day/s ahead (zero if no limit)</span>
                </div>
                <div className="col-12 d-flex">
                    <Controller
                        as={
                            <TextField
                                errors={errors}
                                label="WFH amount (Without clarification)"
                                InputLabelProps={{shrink: true}}
                                type="number"
                                inputRef={register({
                                    required: "This field is required",
                                    validate: (value) =>
                                        value >= 0,
                                })}
                                inputProps={{
                                    min: 0,
                                    step: "any"
                                }}
                            />
                        }
                        name="maxAllow"
                        id="maxAllow"
                        defaultValue={workFromHomePolicy?.maxAllow}
                        control={control}
                        className="me-2"
                    />
                    <Controller
                        as={
                            <Select options={daysPerYearArr}/>
                        }
                        id="maxAllowUnit"
                        control={control}
                        mode="onBlur"
                        name='maxAllowUnit'
                        defaultValue={workFromHomePolicy.maxAllowUnit}
                        className="me-2"
                    />
                    <span className="mx-lg-3 fs-6 me-2">Per</span>
                    <Controller
                        as={
                            <Select options={timeUnitOptions}/>
                        }
                        id="resetPer"
                        control={control}
                        mode="onBlur"
                        name='resetPer'
                        defaultValue={workFromHomePolicy.resetPer}
                        className="me-2"
                    />
                </div>
                <div className="col-12 d-flex">
                    <Controller
                        as={
                            <TextField
                                errors={errors}
                                label="WFH amount (With clarification)"
                                InputLabelProps={{shrink: true}}
                                type="number"
                                inputRef={register({
                                    required: "This field is required",
                                    validate: (value) =>
                                        value >= 0,
                                })}
                                inputProps={{
                                    min: 0,
                                    step: "any"
                                }}
                            />
                        }
                        name="maxAllowWithClarification"
                        id="maxAllowWithClarification"
                        defaultValue={workFromHomePolicy?.maxAllowWithClarification}
                        control={control}
                        className="me-2"
                    />
                    <Controller
                        as={
                            <Select options={daysPerYearArr}/>
                        }
                        id="maxAllowWithClarificationUnit"
                        control={control}
                        mode="onBlur"
                        name='maxAllowWithClarificationUnit'
                        defaultValue={workFromHomePolicy.maxAllowWithClarificationUnit}
                        className="me-2"
                    />
                    <span className="mx-lg-3 fs-6 me-2">Per</span>
                    <Controller
                        as={
                            <Select options={timeUnitOptions}/>
                        }
                        id="maxAllowPerYearUnit"
                        control={control}
                        mode="onBlur"
                        name='resetWithClarificationPer'
                        defaultValue={workFromHomePolicy.resetWithClarificationPer}
                        className="me-2"
                    />
                </div>
                <hr/>
                <span className="col-12">Conditions</span>
                <div className="col-12 d-flex mt-3">
                    <Controller
                        as={
                            <TextField
                                errors={errors}
                                label="Minimun service time"
                                InputLabelProps={{shrink: true}}
                                type="number"
                                inputRef={register({
                                    required: "This field is required",
                                    validate: (value) =>
                                        value >= 0,
                                })}
                                inputProps={{
                                    min: 0,
                                    step: "any"
                                }}
                            />
                        }
                        name="effectiveFrom"
                        id="effectiveFrom"
                        defaultValue={workFromHomePolicy?.effectiveFrom}
                        control={control}
                        className="me-2"
                    />
                    <Controller
                        as={
                            <Select options={fromYearsArr}/>
                        }
                        id="effectiveFromUnit"
                        control={control}
                        mode="onBlur"
                        name='effectiveFromUnit'
                        defaultValue={workFromHomePolicy.effectiveFromUnit}
                        className="me-2"
                    />
                </div>
                <div className="col-12">
                    <Button type="submit" variant="contained" className='float-end my-3'>
                        Save
                    </Button>
                    <Button className="bg-danger float-end my-3 mx-3" color="secondary" variant="outlined"
                            onClick={toggleDisablePolicyModal}>
                        Disable Policy
                    </Button>
                </div>
            </form>
            <Dialog
                className="details-dialog"
                open={modals[DISABLE_POLICY_MODAL]}
                toggle={toggleDisablePolicyModal}
            >
                <div>
                    <div className="m-4" style={{color: colorTheme}}>
                        Are you sure you want to disable ( Work From Home ) Policy?
                    </div>
                    <div>
                        <DialogActions className="p-3 mt-3">
                            <Button
                                className="px-4 me-3"
                                variant="outlined"
                                onClick={toggleDisablePolicyModal}
                            >
                                Cancel
                            </Button>
                            <Button
                                className="px-4 bg-danger"
                                color="secondary"
                                variant="outlined"
                                onClick={handleDisable}
                            >
                                {isSubmitting ? (
                                        <CircularProgress size={24} color="inherit" />
                                    ) :
                                    "Disable"
                                }
                            </Button>
                        </DialogActions>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};
const modalsProps = {
    toggleDisablePolicyModal: DISABLE_POLICY_MODAL,
};
export default withModals(WorkFromHomePolicy, modalsProps);
