import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import moment from "moment";
import {find, map} from "lodash";
import {editAssosiationTemplete, updateEmployeeDetails} from "utility/apis/EmployeeService";
import {getTenantId, formatWorkingDays, formatSlots, getErrorMessage} from "utility/helpers/utils";
import {TABLES_NAMES} from "utility/constants/constants";
import {API_URL} from "utility/constants/APIConst";
import {getSpecialHolidaysForEmployee, getTemplates} from "utility/apis/ConfigService";
import {Button, Collapse, DataGridTable, DatePicker, Dialog, Tooltip, DialogActions, GridActionsCellItem} from "components/Mui";
import Loading from "components/Loading/Loading";
import CheckboxesTags from "components/CheckboxesTags/CheckboxesTags";
import {EditIcon, KeyboardArrowDownIcon, KeyboardArrowUpIcon} from "components/Mui/Icons";
import CustomAppBar from "components/CustomAppBar/CustomAppBar";
import {toast} from "react-toastify";
import {TIME_OFF_REQUEST_MODAL} from "utility/modals/constants";
import WithModals from "hoc/withModals/withModals";
import {DATE_FORMAT} from "utility/helpers/metaData";

import "./WorkScheduleTemplates.scss";

const WorkScheduleTemplates = (props) => {
    const tenantId = getTenantId(useLocation(),"tenantId");
    const { employeeId, employee, message, defaultHolidays, handleCloseButton, title, colorTheme, toggleTimeOffEditModal, modals } = props;
    const templatesListUrl = API_URL.TEMPLATES_LIST(tenantId, employeeId);
    const [selectedRow, setSelectedRow] = useState(null);
    const [templatesAssociationsList, setTemplatesAssociationsList] = useState([]);
    const [isInProgress, setIsInProgress] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const { register, handleSubmit, errors } = useForm({ mode:'onSubmit' });
    const [inProgress, setInProgress] = useState(true);
    const [templateOptions, setTemplateOptions] = useState([]);
    const [holidayOptions, setHolidayOptions] = useState([]);
    const [open, setOpen] = useState(true);
    const [holidaysValue, setHolidaysValue] = useState([]);
    const [templateValue, setTemplateValue] = useState([]);
    const [defaultTemplate, setDefaultTemplate] = useState(undefined);
    const [reload , setReload] = useState(false);
    const fetchData = async () => {
        setInProgress(true);
        try {
            const response = await getTemplates(tenantId, moment().year());
            setTemplateOptions(response.data);
            const response2 = await getSpecialHolidaysForEmployee(employeeId, tenantId);
            setHolidayOptions(response2.data);
        } catch (e) {
        }
        setInProgress(false);
    }

    const formatDataTable = (data) => {
        setTemplatesAssociationsList(map(data, ({ workingHours: { templateName, id }, associations }) => {
            const sortedAssociations = associations?.sort((a, b) => a.from - b.from);
            return {
                associations: sortedAssociations,
                templateId: id,
                templateName: templateName
            }
        }))
        return map(data, ({ workingHours: { templateName, id }, associations, active , workingHours }, index) => {
            return {
                id,
                name: templateName,
                associations: associations?.map(({from, to}) => moment(from).isAfter(moment())? `Starting on ${from}` :`${from} - ${to || 'Present'}`),
                active: active ? 'ACTIVE' : 'INACTIVE',
                workingDays: formatWorkingDays(workingHours?.slotsPerDay, workingHours?.slots),
                timeSlots: formatSlots(workingHours?.slots)
            }
        });
    }

    const handleHolidaysChange = (event, value) => {
        setHolidaysValue(map(value, 'id'));
    };
 
    const handleTemplatesChange = (event, value) => {
        setTemplateValue([{ templateId: value?.workingHours?.id}]);
    };

    const onDataTableChanged = (data) => {
        if (data) {
            const activeTemplateId = find(data, { active: true })?.workingHours?.id
            const activeTemplate = find(templateOptions, (el) => el?.workingHours?.id === activeTemplateId );
            setDefaultTemplate(activeTemplate || {});
            handleTemplatesChange(undefined, activeTemplate);
        }
        setHolidaysValue(defaultHolidays);
    }

    const handleSaveDetailsButton = async () => {
        setSubmitting(true);
        try {
            await updateEmployeeDetails(employeeId, {
                templates: templateValue,
                updatedTab: "TEMPLATES"
            });
            await updateEmployeeDetails(employeeId, {
                holidays: holidaysValue,
                updatedTab: "HOLIDAYS"
            });
            toast.success("Updated Successfully");
            setReload(!reload);
        } catch (e) {
            toast.error(getErrorMessage(e));
        } finally {
            setTimeout(() => {
                setSubmitting(false);
            }, 2000);
        }
    };

    const columnDefs = [
        {
            headerName: 'Name',
            field: 'name',
            flex: 1
        },
        {
            headerName: 'Association',
            field: 'associations',
            flex: 1.5,
            renderCell: (params) => (
                <div className="ellipsis">
                    {params?.value?.map((text) => (
                        <Tooltip title={text}>
                            <div className="ellipsis">{text}</div>
                        </Tooltip>
                    ))}
                </div>
            ),
        },
        {
            headerName: 'Status',
            field: 'active',
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <div className={`ellipsis ${params.value === 'ACTIVE' ? "text-success" : "text-danger"}`}>
                        {params.value}
                    </div>
                </Tooltip>
            ),
            flex: 1.2,
        },
        {
            headerName: 'Working Days',
            field: 'workingDays',
            flex: 1,
        },
        {
            headerName: 'Time Slots',
            field: 'timeSlots',
            flex: 1.2,
            renderCell: (params) => (
                <div className="ellipsis">
                    {params.value?.split("/")?.map((text) => (
                        <Tooltip title={text}>
                            <div className="ellipsis">{text}</div>
                        </Tooltip>
                    ))}
                </div>
            ),
        },
        {
            headerName: 'Actions',
            field: 'actions',
            type: 'actions',
            flex: 0.5,
            getActions: (params) => [
                <GridActionsCellItem showInMenu icon={<EditIcon />} onClick={(event) => handleEditClick(event, params)} label="Edit" />,
            ]
        }
    ];

    useEffect(() => {
        fetchData();
    }, []);

    const handleEditClick = (event, params) => {
        const item = templatesAssociationsList?.find((item) => item?.templateId === params?.row?.id);
        setSelectedRow(item);
        toggleTimeOffEditModal();
    }
    const handleAssociationChange = (index, field, newValue) => {
        setSelectedRow((prevRow) => {
            const updatedAssociations = [...prevRow.associations];
            const dates = updatedAssociations[index];
            field === "from" ? dates.from = moment(newValue).format("YYYY-MM-DD") : null;
            field === "to" ? dates.to = moment(newValue).format("YYYY-MM-DD") : null;
            updatedAssociations[index] = dates;
            return { ...prevRow, associations: updatedAssociations };
        });
    };

    const handleDeleteAssociation = (index) => {
        setSelectedRow((prevRow) => {
            const updatedAssociations = prevRow.associations.map((item, i) => {
                return item?.associationId === index ? { ...item, delete: true } : {...item};
            });

            return { ...prevRow, associations: updatedAssociations };
        });
    };

    // const validateTemplatesDates = (payload) => {
    //     const templates = payload.templates;
    //     const containsPresentTemplate = templates.some((template) =>
    //         template.associations.some(
    //             (assoc) => assoc.delete === false && !assoc.to
    //         )
    //     );
    //     if (!containsPresentTemplate) {
    //         toast.error("Employee should have at least one template");
    //         return false;
    //     }
    //     if (
    //         templates.filter((template) =>
    //             template.associations.some(
    //                 (assoc) => assoc.delete === false && !assoc.to
    //             )
    //         ).length > 1
    //     ) {
    //         toast.error("There shouldn't be two templates present at the same time");
    //         return false;
    //     }
    //     const templateDates = [];
    //     templates.forEach((template) => {
    //         template.associations.forEach((assoc) => {
    //             if (assoc.delete === false) {
    //                 templateDates.push({
    //                     from: moment(assoc.from, DATE_FORMAT),
    //                     to: assoc.to ? moment(assoc.to, DATE_FORMAT) : null,
    //                 });
    //             }
    //         });
    //     });
    //     templateDates.sort((a, b) => a.from - b.from);
    //     const validTemplateDates = templateDates.filter((el) => el != null);
    //     const hireDate = moment(employee?.hireDate, DATE_FORMAT);
    //     if (!hireDate?.isSame(validTemplateDates[0]?.from)) {
    //         toast.error(`First template date should equal the hire Date`);
    //         return false;
    //     }
    //     let prevTo = validTemplateDates[0]?.to;
    //     for (let j = 1; j < validTemplateDates.length; j++) {
    //         const template = validTemplateDates[j];
    //         if (!template) continue;
    //         if ((template.to? false: !template.from) && j < validTemplateDates.length - 1) {
    //             toast.error("Template without end date should be the last template");
    //             return false;
    //         }
    //         const templateFrom = template.from;
    //         if (!prevTo || !templateFrom.isSame(prevTo, 'day')) {
    //             toast.error("There shouldn't be any gaps or overlap between template dates");
    //             return false;
    //         }
    //         prevTo = template.to;
    //     }
    //     return true;
    // };


    const handleSave = async () => {
        setIsInProgress(true);
        const payload = {
            templates: templatesAssociationsList?.map((item) => {
                if (item.templateId === selectedRow.templateId) {
                    return {
                        templateId: selectedRow.templateId,
                        associations: selectedRow?.associations,
                    };
                } else {
                    return {
                        templateId: item.templateId,
                        associations: item.associations
                    };
                }
            }),
            updatedTab: "TEMPLATES",
        }

        // const validation = validateTemplatesDates(payload);
        // if (!validation) {
        //     return;
        // }  the validations would be implemented from the BE side

        try {
            await editAssosiationTemplete(employeeId, payload);
            toast.success("Updated Successfully");
            toggleTimeOffEditModal();
            setReload(!reload);
        } catch (e) {
            toast.error(getErrorMessage(e));
        }finally {
            setTimeout(() => {
                setIsInProgress(false);
            }, 2000);
        }
    };


    return (
        <div id="work-schedule-templates" className="c-work-schedule-templates px-4 mt-5">
            {inProgress ?
                <Loading></Loading>
                :
                <div className="container-fluid">
                    <form onSubmit={handleSubmit(handleSaveDetailsButton)}>
                        <CustomAppBar title={title} showPrimaryButton showSecondaryButton handleCloseButton={handleCloseButton}  message={message} submitting={submitting}/>

                        <div className="row">
                            <div className="col-12 col-md-3">
                                {defaultTemplate &&
                                    <CheckboxesTags
                                        label="Active Employee Template"
                                        name="activeTemplate"
                                        onChange={handleTemplatesChange}
                                        labelField='workingHours.templateName'
                                        fullWidth={true}
                                        options={templateOptions}
                                        defaultValue={defaultTemplate}
                                        id="activeTemplate"
                                        inputRef={register({
                                            required: "This field is required"
                                        })}
                                        errors={errors}
                                    />
                                }
                            </div>
                            <div className="col-12 col-md-3">
                                <CheckboxesTags
                                    disableCloseOnSelect
                                    label="Special Holidays"
                                    name="holidays"
                                    onChange={handleHolidaysChange}
                                    labelField='name'
                                    fullWidth={true}
                                    options={holidayOptions}
                                    limitTags={2}
                                    defaultValue={holidayOptions.filter((el) => el.active === true)}
                                    id="holidays"
                                    multiple={true}
                                    inputRef={register}
                                />
                            </div>
                        </div>
                        <div>
                            <Button onClick={() => setOpen(!open)}>
                                <span>Templates History</span>
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </Button>
                        </div>
                        <Collapse in={open} timeout="auto">
                            <div style={{ height: "calc(100vh - 230px)" }} className="row">
                                <DataGridTable
                                    className="col-12"
                                    key={TABLES_NAMES.TEMPLATES_LIST}
                                    columns={columnDefs}
                                    URL={templatesListUrl}
                                    formatDataTable={formatDataTable}
                                    onDataTableChanged={onDataTableChanged}
                                    reload = {reload}
                                />
                            </div>
                        </Collapse>
                    </form>
                </div>
            }
            <Dialog
                className="time-off-request-dialog"
                open={modals[TIME_OFF_REQUEST_MODAL]}
                toggle={toggleTimeOffEditModal}
            >
                <div className="m-4">
                    <h6 className="mb-4" style={{ color: colorTheme }}>
                        Association - {selectedRow?.templateName}
                    </h6>
                    <div className="association-list">
                        {selectedRow?.associations?.map((association, index) => (
                            (!association?.delete &&
                                <div className="association-item d-flex mt-1 align-items-start" key={index}>
                                <DatePicker
                                    name={`from-${index}`}
                                    label="From"
                                    className="mx-1"
                                    value={moment(association?.from)}
                                    onChange={(newValue) => {
                                        handleAssociationChange(index, "from", newValue)
                                    }}
                                />
                                <DatePicker
                                    name={`to-${index}`}
                                    label="To"
                                    className="mx-1"
                                    value={
                                        association?.to
                                            ? moment(association?.to)
                                            :  moment()
                                    }
                                    onChange={(newValue) => {
                                        handleAssociationChange(index, "to", newValue)
                                    }}
                                />
                                <Button
                                    className="ms-1"
                                    variant="outlined"
                                    color="error"
                                    onClick={() => handleDeleteAssociation(association?.associationId)}
                                >
                                    Delete
                                </Button>
                            </div>
                        )))}
                </div>
        </div>
    <DialogActions className="p-3">
        <Button
            className="px-4 me-3"
            variant="outlined"
            onClick={toggleTimeOffEditModal}
                    >
                        Cancel
                    </Button>
                    <Button className="px-4" variant="contained" onClick={handleSave}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
const modalsProps = {
    toggleTimeOffEditModal: TIME_OFF_REQUEST_MODAL
};
export default WithModals(WorkScheduleTemplates, modalsProps);