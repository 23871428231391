export const EMPLOYEE_DETAILS_MODAL = "EMPLOYEE_DETAILS_MODAL";
export const COMPENSATION_TIME_MODAL = "COMPENSATION_TIME_MODAL";
export const BALANCE_DEDUCTION_MODAL = "BALANCE_DEDUCTION_MODAL";
export const SALARY_DEDUCTION_MODAL = "SALARY_DEDUCTION_MODAL";
export const CONFIRMATION_MODAL = "CONFIRMATION_MODAL";
export const SETTINGS_MODAL = "SETTINGS_MODAL";
export const CREATE_HOLIDAY_MODAL = "CREATE_HOLIDAY_MODAL";
export const SETUP_GUIDE_MODAL = "SETUP_GUIDE_MODAL";
export const TIME_OFF_REQUEST_MODAL = "TIME_OFF_REQUEST_MODAL";
export const TEAMS_INFO_MODAL = "TEAMS_INFO_MODAL";
export const TIME_OFF_CONVERT_REQUEST_MODAL = "TIME_OFF_CONVERT_REQUEST_MODAL";
export const TIME_OFF_CANCEL_REQUEST_MODAL = "TIME_OFF_CANCEL_REQUEST_MODAL";
export const CUSTOM_DATE_MODAL = "CUSTOM_DATE_MODAL";
export const PENDING_ACTION_DETAILS_MODAL = "PENDING_ACTION_DETAILS_MODAL";
export const ATTENDANCE_CANCELLATION_MODAL = "ATTENDANCE_CANCELLATION_MODAL";
export const CREATE_TEMPLATE_MODAL = "CREATE_TEMPLATE_MODAL";
export const PROFILE_DETAILS_MODAL = 'PROFILE_DETAILS_MODAL';
export const DISABLE_POLICY_MODAL = "DISABLE_POLICY_MODAL";
export const CREATE_CUSTOMER_MODAL = "CREATE_CUSTOMER_MODAL";
export const CUSTOMER_INFORMATION_MODAL = "CUSTOMER_INFORMATION_MODAL";
export const NEW_SYSTEM_CONFIGURATION_MODAL = "NEW_SYSTEM_CONFIGURATION_MODAL";
export const BALANCE_ACTION_MODAL = "BALANCE_ACTION_MODAL";
export const MANAGE_POLICIES_ENABLE = "MANAGE_POLICIES_ENABLE";
export const BENEFIT_FORM_MODAL = "BENEFIT_FORM_MODAL";
export const SCHEDULE_FORM_MODAL = "SCHEDULE_FORM_MODAL";
export const DETACHED_TEAMS_MODAL = "DETACHED_TEAMS_MODAL";
export const MOVE_TEMPLATE_MODAL = "MOVE_TEMPLATE_MODAL";
export const CREATE_EDIT_TEAM_MODAL = "CREATE_EDIT_TEAM_MODAL";
export const ADD_TEAM_MEMBERS_MODAL = "ADD_TEAM_MEMBERS_MODAL";
export const FUNCTIONAL_ROLE_MODAL = "FUNCTIONAL_ROLE_MODAL";
export const REACTIVE_CONFIRMATION_MODAL = "REACTIVE_CONFIRMATION_MODAL";
export const SICK_ADD_REPORT = "SICK_ADD_REPORT";
export const CUSTOM_TIME_MODAL = "CUSTOM_TIME_MODAL";
export const IMPORT_EMPLOYEES_MODAL = "IMPORT_EMPLOYEES_MODAL";
export const CONFLICTS_EDIT_RECORD_MODAL = "IMPORT_EMPLOYEES_MODAL";
export const SUCCESSFULLY_REGISTERED_MODAL = "SUCCESSFULLY_REGISTERED_MODAL";
