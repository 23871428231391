import React, { useState, useEffect } from 'react';
import {useForm} from "react-hook-form";
import moment from "moment";
import {
    EditIcon,
    DeleteIcon,
    CheckIcon,
    CheckBoxOutlineBlankIcon,
    AddCircleIcon,
} from "components/Mui/Icons";
import {formatMonth, getErrorMessage} from "utility/helpers/utils";
import {
    getSalaryDeduction,
    getBalanceOnly,
    updateSalaryDeduction,
    deleteSalaryDeduction,
    updateSalaryDeductionStatus, 
    addSalaryDeduction
} from "utility/apis/EmployeeService";
import { SALARY_DEDUCTION_MODAL, CONFIRMATION_MODAL } from "utility/modals/constants";
import WithModals from "hoc/withModals/withModals";
import { TABLES_NAMES } from "utility/constants/constants";
import {
    Button,
    DataGridTable,
    DialogActions,
    GridActionsCellItem,
    Dialog,
    TextField,
    DatePicker,
    Tooltip
} from "components/Mui";
import Loading from "components/Loading/Loading";

import "./SalaryDeduction.scss";
import {toast} from "react-toastify";
import {YEAR_FORMAT} from "../../../../utility/helpers/metaData";

const SalaryDeduction = (props) => {
    const { employeeId, date, colorTheme, modals, toggleSalaryDeductionModal, toggleConfirmationModal, message } = props;

    const [ inProgress, setInProgress ] = useState(false);
    const [selectedSalaryDeduction, setSelectedSalaryDeduction] = useState({});
    const { handleSubmit, register, errors } = useForm({ mode: 'onChange' });
    const [dataTable, setDataTable] = useState([]);
    const [balance, setBalance] = useState(0);
    const [isInProgress, setIsInProgress] = useState(false);

    const fetchData = async () => {
        setInProgress(true);
        try {
            const balanceResponse = await getBalanceOnly(employeeId, moment.isMoment(date)? date.format("YYYY") : moment.isMoment(date.value)? moment(date.value).format("YYYY") : date?.value);
            setBalance(balanceResponse?.data?.expectedBalance);
            const response = await getSalaryDeduction(employeeId, moment.isMoment(date)? date.format("YYYY") : moment.isMoment(date.value)? moment(date.value).format("YYYY") : date?.value);
            const data = response?.data?.salaryDeductionList;
            setDataTable(data);
        } catch (e) {
        }
        setInProgress(false);
    }

    const refreshData = () => {
        fetchData() ;
    }

    useEffect(() => {
        if (date) {
            fetchData();
        }
    }, [date]);

    const handleEditStatusClick = async (event, params) => {
        try {
            const newStatus = !params.row.done;
            await updateSalaryDeductionStatus(params.id, newStatus);
            refreshData();
            toast.success("Updated Successfully");
        } catch (e) {
            toast.error(getErrorMessage(e));
        }
    }

    const handleEditClick = (event, params) => {
        setSelectedSalaryDeduction(params.row);
        toggleSalaryDeductionModal();
    }

    const handleAddClick = () => {
        setSelectedSalaryDeduction({});
        toggleSalaryDeductionModal();
    }

    const handleDeleteClick = (event, params) => {
        setSelectedSalaryDeduction(params.row);
        toggleConfirmationModal();
    }

    const handleSalaryDeductionUpdate = async (data) => {
        setIsInProgress(true);
        try {
            const { numOfDays, comments, date } = data;
            const payload = {
                done: false,
                ...selectedSalaryDeduction,
                comments,
                numOfDays,
                employee: employeeId,
                balance,
                date: moment(date, 'YYYY-MM-DD').valueOf()
            };
            let messageStatus = ''
            if (payload.id === undefined) {
                await addSalaryDeduction(payload);
                messageStatus = 'Saved Successfully';
            } else {
                await updateSalaryDeduction(payload);
                messageStatus = 'Updated Successfully';
            }
            refreshData();
            toggleSalaryDeductionModal();
            toast.success(messageStatus);
        } catch (e) {
            toast.error(getErrorMessage(e));
        }finally {
            setTimeout(() => {
                setIsInProgress(false);
            }, 2000);
        }
    }

    const handleSalaryDeductionDelete = async () => {
        setIsInProgress(true);
        try {
            await deleteSalaryDeduction(selectedSalaryDeduction.id);
            refreshData();
            toggleConfirmationModal();
            toast.success("Deleted Successfully");
        } catch (e) {
            toast.error(getErrorMessage(e));
        }finally {
            setTimeout(() => {
                setIsInProgress(false);
            }, 2000);
        }
    }

    const columnDefs = [
        {
            headerName: 'Month',
            field: 'date',
            renderCell: (params) => (
                <Tooltip title={formatMonth(params.value)}>
                    <div className="ellipsis">
                        {formatMonth(params.value)}
                    </div>
                </Tooltip>
            ),
            flex: 1
        },
        {
            headerName: 'Number of Days',
            field: 'numOfDays',
            renderCell: (params) => (
                <Tooltip title={`${params.value} ${params.value > 1 ? 'days' : 'day'}`}>
                    <div className="ellipsis">
                        {params.value} {params.value > 1 ? 'days' : 'day'}
                    </div>
                </Tooltip>
            ),
            flex: 1.5
        },
        {
            headerName: 'Comments',
            field: 'comments',
            flex: 2
        },
        {
            headerName: 'Actions',
            field: 'actions',
            type: 'actions',
            width: 150,
            getActions: (params) => {
                return [
                    <GridActionsCellItem icon={params?.row?.done ?
                        <CheckIcon color="primary" />
                        :
                        <CheckBoxOutlineBlankIcon />
                    } onClick={(event) => handleEditStatusClick(event, params)} label="Edit" />,
                    <GridActionsCellItem icon={<EditIcon />} onClick={(event) => handleEditClick(event, params)} label="Edit" />,
                    <GridActionsCellItem icon={<DeleteIcon />} onClick={(event) => handleDeleteClick(event, params)} label="Delete" className="text-danger" />,
                ];
            }
        }
    ];

    return (
        <div id="salary-deduction" className="c-salary-deduction">
            {inProgress ?
                <Loading></Loading>
                :
                <div className="container-fluid">
                <div style={{height: `calc(100vh - ${message ? 240 : 205}px)`}} className="row">
                        <div className="d-flex flex-center message-flex">
                            <div className="message-frame">
                                {balance < 0 ?
                                    `The maximum allowed number of days to be deducted is ${Math.abs(balance).toFixed(2)}`
                                    :
                                    "Adding salary deductions on an employee with a positive balance is forbidden"
                                }
                            </div>
                            {balance < 0 ?
                                <Button className="add-salary-deduction-button" onClick={handleAddClick}>
                                    <AddCircleIcon />
                                </Button>
                            :
                                null
                            }
                        </div>
                        <DataGridTable
                            className="col-12"
                            key={TABLES_NAMES.SALARY_DEDUCTION_LIST}
                            columns={columnDefs}
                            dataTable={dataTable}
                            isExport={false}
                        />
                    </div>
                </div>
            }
            <Dialog className='salary-deduction-dialog' open={modals[SALARY_DEDUCTION_MODAL]} toggle={toggleSalaryDeductionModal}>
                <form onSubmit={handleSubmit(handleSalaryDeductionUpdate)}>
                    <div className="m-4">
                        <h6 className="mb-4" style={{color: colorTheme}}>Edit salary deduction</h6>
                        <div className="row">
                            <TextField defaultValue={selectedSalaryDeduction?.numOfDays}
                                       inputRef={register({
                                           required: "This field is required",
                                           pattern: {
                                               value: /^(0|[1-9]\d*)(\.\d+)?$/,
                                               message: "Enter a valid Number",
                                           }
                                       })}
                                       errors={errors}
                                       className="col-12" id="numOfDays" name="numOfDays" label="Number of Days"
                            />
                            <DatePicker
                                name="date"
                                label="Month"
                                className="col-12"
                                defaultValue={moment(selectedSalaryDeduction?.date)}
                                openTo="month"
                                views={['month']}
                                inputRef={register({
                                    required: "Date is required",
                                    validate: (value) =>
                                        moment(value)?.format(YEAR_FORMAT) === (moment.isMoment(date) ? date.format("YYYY") : moment.isMoment(date.value) ? moment(date.value).format("YYYY") : date?.value) ||
                                        "Invalid date",
                                })}
                                errors={errors}
                            />
                            <TextField defaultValue={selectedSalaryDeduction?.comments}
                                       inputRef={register}
                                       className="col-12" id="comments" name="comments"
                                       label="Comments" type="text"/>
                        </div>
                    </div>
                    <div>
                        <DialogActions className="p-3 mt-3">
                            <Button className="px-4 me-3" variant="outlined" onClick={toggleSalaryDeductionModal}>Cancel</Button>
                            {selectedSalaryDeduction?.id === undefined ?
                                <Button className="px-4" variant="contained" type="submit" disabled={isInProgress}>Deduct days</Button>
                                :
                                <Button className="px-4" variant="contained" type="submit" disabled={isInProgress}>Edit Deduction Days</Button>
                            }
                        </DialogActions>
                    </div>
                </form>
            </Dialog>
            <Dialog className='confirmation-dialog' open={modals[CONFIRMATION_MODAL]} toggle={toggleConfirmationModal}>
                <div>
                    <div className="m-4" style={{color: colorTheme}}>
                        Are you sure you want to delete this ?
                    </div>
                    <div>
                    <DialogActions className="p-3 mt-3">
                        <Button className="px-4 me-3" variant="outlined" onClick={toggleConfirmationModal}>Cancel</Button>
                        <Button className="px-4 bg-danger" color="secondary" variant="outlined" disabled={isInProgress} onClick={handleSalaryDeductionDelete}>Delete</Button>
                    </DialogActions>
                </div>
                </div>
            </Dialog>
        </div>
    );
}
const modalsProps = {
    toggleSalaryDeductionModal: SALARY_DEDUCTION_MODAL,
    toggleConfirmationModal: CONFIRMATION_MODAL
};

export default WithModals(SalaryDeduction, modalsProps)
