import React, {useState} from "react";
import { useHistory  } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { logout } from 'utility/apis/UserService';
import { Navigation, Paper, Menu, MenuItem } from "components/Mui";
import Loading from "components/Loading/Loading";
import { clearAuth, getUserInfo } from "utility/helpers/authHelper";
import Confirm from "components/Confirm/Confirm";
import adminLogo from 'assets/images/toffy_admin_color.png';
import {PowerSettingsNewSharpIcon} from 'components/Mui/Icons';
import AccountCircleSharpIcon from "assets/images/profile.svg";

import "./Header.scss";

const getNavigationLinks = (handleClick) => {
  return [
    {
      id: "customers",
      label: "customers",
      href: "/customers",
    },
    {
      id: "system_log",
      label: "system log",
      href: `/system-logs`,
    },
  ];
};
const getNavigationLinksRight = (handleClick) => {
  return [
    {
      id: "account",
      label: (
          <img src={JSON.parse(getUserInfo()).profilePicture || AccountCircleSharpIcon} className="icon" alt="profile" />
      ),
      onClick: handleClick,
    }
  ];
};

const AdminHeader = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const confirmLogout = (event) => {
    setOpen(true);
  };

  const handleLogoutClick = async () => {
    try {
      setOpen(false);
      setLoading(true);
      await logout();
      clearAuth(history);
      setLoading(false);
    } catch (e) {}
  }

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  }
  return (
    <Paper className="header admin-header w-100" >
      <div className="logo justify-content-left">
        <img src={adminLogo} className="logo2 mx-3"/>
      </div>
      <div className="d-flex justify-content-between w-100">
        {JSON.parse(getUserInfo())?.showBotLogs &&
            <Navigation
                navigationLinks={getNavigationLinks(openMenu)}
            />
        }
        <Navigation
            navigationLinks={getNavigationLinksRight(openMenu)}
            className="justify-content-end"
        />
      </div>
      <Menu
        disableScrollLock
        anchorEl={anchorEl}
        id="account-menu"
        open={Boolean(anchorEl)}
        onClose={()=>setAnchorEl(null)}
        PaperProps={{
          elevation: 0,
          className: 'account_menu',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={confirmLogout} className={"text-danger"}>
          <PowerSettingsNewSharpIcon fontSize="small" />
          <span className='mx-2'>{t('logout')}</span>
        </MenuItem>
      </Menu>
      {loading &&
        <Loading containerClassName='logout-loader'></Loading>
      }
      <Confirm 
        open={open} 
        confirmMessage={t('logout-confirmation')} 
        actionLabel={t('logout')} 
        onActionClick={handleLogoutClick} 
        onCancel={()=>setOpen(false)}
      />
    </Paper>
  );
};

export default AdminHeader;
