import React from 'react';
import { Divider, Dialog, DialogActions,  Button, Menu, MenuItem, List, ListItemText, Collapse, ListItemButton , useTheme} from 'components/Mui';
import Loading from "components/Loading/Loading";
import { logout } from 'utility/apis/UserService';
import { LiveHelpSharpIcon, PersonSharpIcon, PowerSettingsNewSharpIcon, BusinessIcon, ExpandLessIcon, ExpandMoreIcon } from 'components/Mui/Icons';
import WithModals from "hoc/withModals/withModals";
import { CONFIRMATION_MODAL, EMPLOYEE_DETAILS_MODAL, SETUP_GUIDE_MODAL } from "utility/modals/constants";
import {clearAuth, getIsManager, getUserInfo} from "utility/helpers/authHelper";
import {getTenantId, getTenants, setCurrentTenant, setTenantId} from "utility/helpers/utils";
import {useHistory, useLocation} from "react-router-dom";
import EmployeeDetails from "containers/EmployeeDetails/EmployeeDetails";
import SetupGuide from "./SetupGuide/SetupGuide";
import { useTranslation } from 'react-i18next';
import classNames from "classnames";

import './AccountMenu.scss'
import {filter} from "lodash";

function AccountMenu(props) {
    const tenantId = getTenantId(useLocation(), "tenantId");
    const isManager = getIsManager();
    const { anchorEl, handleClose, modals, toggleConfirmationModal, toggleProfileDetailsModal, toggleSetupGuideModal } = props;
    const [profileId, setProfileId] = React.useState(null);
    const [openSwitch, setOpenSwitch] = React.useState(false);
    const open = Boolean(anchorEl);
    const history = useHistory();
    const profileTabs = ["personal_info", "work_schedule_templates", "teams_and_work_schedule_templates", "benefits", "teams", "roles", "time_off", "wfh", "customize_time_off_policy", "attendance_report"];
    const { t } = useTranslation();
    const [inProgress, setInProgress] = React.useState(false);
    const selectedTheme = useTheme();
    const colorTheme = selectedTheme?.palette?.primary?.main;
    const tenants = JSON.parse(getTenants());

    const handleCloseMenu = () => {
        try {
            handleClose();
            setOpenSwitch(false)
        } catch (e) {       
        }
    };


    const handleTenantClick = (tenantId) => {
        try {
            setTenantId(tenantId);
            setCurrentTenant(tenants.filter((item) => {
                return item.id === tenantId;
            }))
            window.location.href = `/employees?tenantId=${tenantId}`;
        } catch (e) {       
        }
    }

    const handleLogout = async () => {
        try {
            setInProgress(true)
            handleLogoutClick()
            await logout();
            clearAuth(history);
            toggleConfirmationModal();
            setInProgress(false)
        } catch (e) {       
        }
    };

    const handleProfileClick = () => {
        try {
            const user_info = getUserInfo();
            setProfileId(JSON.parse(user_info).id);
            handleClose();
            toggleProfileDetailsModal();
        } catch (e) {       
        }
    }

    const handleSetupGuideClick = () => {
        handleClose();
        toggleSetupGuideModal();
    }

    const handleLogoutClick = () => {
        handleClose();
        toggleConfirmationModal()
    }

    const menuItems = () => {
        
        let menu = [
            {   
                id: "my_profile",
                icon: <PersonSharpIcon fontSize="small" />,
                label: "My profile",
                isShow: true,
                clickHandler: handleProfileClick
            },
            {
                id: "setup_guide",
                icon: <LiveHelpSharpIcon fontSize="small"/>,
                label: "Setup Guide",
                isShow: tenantId,
                clickHandler: handleSetupGuideClick
            },
            {
                id: "logout",
                icon: <PowerSettingsNewSharpIcon fontSize="small" />,
                label: "Logout",
                isShow: true,
                clickHandler: handleLogoutClick,
                className: "text-danger",
            }
        ];
        if( tenants.length > 1){
            let employeeTenants = [];
            for(let i=0; i < tenants.length; i++){
                employeeTenants.push({name: tenants[i].name, id: tenants[i].id});
            }
            menu.splice(1, 0, {
                icon: <BusinessIcon fontSize="small" />,
                label: "Switch Tenant",
                isShow: true,
                items: employeeTenants
            })
        }

        return filter(menu, el => el.isShow);;
    }
    const menuItemList = menuItems();
    return (
        <>
            <div className={classNames("centered-loading", inProgress ? "d-block" : "d-none")}>
                {inProgress && <Loading></Loading>}
            </div>
            <Menu
                disableScrollLock
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleCloseMenu}
                PaperProps={{
                    elevation: 0,
                    className: 'account_menu',
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {menuItemList.map(({ id, icon, label, className, clickHandler, items}, index) => (
                        <div key={index}>
                            <div className='position-relative'>
                                <MenuItem onClick={tenants?.length > 1 && index === 1 ? () => setOpenSwitch(!openSwitch): clickHandler} className={className}>
                                    {icon}<span className='mx-2'>{label}</span> {(tenants?.length > 1 && index === 1) ? openSwitch ? <ExpandLessIcon  className="rotate_90"/> : <ExpandMoreIcon className="rotate_90"/> : null}
                                </MenuItem>
                                {index !== menuItems().length-1 && <Divider className='bg-dark'/>}
                            </div>
                            {
                                index === 1 &&
                                <Collapse in={openSwitch} timeout="auto" unmountOnExit  className="position-absolute tenants_menu">
                                    <List component="div" disablePadding>
                                        {
                                            items?.map(({name, id}, index2) => (
                                                <div key={id}>
                                                    <ListItemButton className="list-item" onClick={() => handleTenantClick(id)}>
                                                        <ListItemText primary={name} />
                                                    </ListItemButton>
                                                    {index2 !== items.length-1 && <Divider className='bg-dark'/>}
                                                </div>
                                            ))
                                        }
                                    </List>
                                </Collapse>
                            }
                        </div>
                    ))
                }
            </Menu>
            <Dialog className='confirmation-dialog' open={modals[CONFIRMATION_MODAL]} toggle={toggleConfirmationModal}>
                <div>
                    <div className="m-4" style={{color: colorTheme}}>
                        {t('logout-confirmation')}
                    </div>
                    <div>
                    <DialogActions className="p-3 mt-3">
                        <Button className="px-4 me-3" variant="outlined" onClick={toggleConfirmationModal}>Cancel</Button>
                        <Button className="px-4 bg-danger" color="secondary" variant="outlined" onClick={handleLogout}>Logout</Button>
                    </DialogActions>
                </div>
                </div>
            </Dialog>
            <Dialog className='profile-details-dialog' fullScreen open={modals[EMPLOYEE_DETAILS_MODAL]} toggle={toggleProfileDetailsModal}>
                <EmployeeDetails handleCloseButton={toggleProfileDetailsModal} employeeId={profileId} profileTabs={profileTabs}></EmployeeDetails>
            </Dialog>
            <Dialog className='setup-guide-dialog' fullScreen open={modals[SETUP_GUIDE_MODAL]} toggle={toggleSetupGuideModal}>
                <SetupGuide handleCloseButton={toggleSetupGuideModal}></SetupGuide>
            </Dialog>
        </>
    );
}

const modalsProps = {
    toggleConfirmationModal: CONFIRMATION_MODAL,
    toggleProfileDetailsModal: EMPLOYEE_DETAILS_MODAL,
    toggleSetupGuideModal: SETUP_GUIDE_MODAL
};

export default WithModals(AccountMenu, modalsProps)
