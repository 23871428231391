import React, {useState} from 'react';
import {Button, Grid} from 'components/Mui';
import './ColorPalette.scss'
import {CheckIcon} from "components/Mui/Icons";
import {colorList} from "utility/constants/data";
const ColorPalette = ({ selectedColor, onColorClick }) => {

    const [clicked, setClicked] = useState(selectedColor);
    return (
        <Grid container spacing={{md: 1, xs: 1}}>
            {colorList.map((item, index) => (
                <Grid item md={1}>
                    <Button
                        key={index}
                        className='color-button'
                        style={{ backgroundColor: item.color }}
                        onClick={() => {
                            onColorClick(item);
                            setClicked(item.color);
                        }}
                    >
                        {clicked === item.color && (
                            <span className="check-mark"><CheckIcon/></span>
                        )}
                    </Button>
                </Grid>
            ))}
        </Grid>
    );
};

export default ColorPalette;
