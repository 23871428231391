import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import CustomAppBar from "components/CustomAppBar/CustomAppBar";
import { Accordion, AccordionDetails, AccordionSummary, Grid, DialogActions, useTheme, Button, Checkbox, Dialog, FormControlLabel, Typography } from "components/Mui";
import {ExpandMoreIcon, NotesIcon} from "components/Mui/Icons";
import {enablePolicies, getPolicy} from "utility/apis/SetupGuideService";
import {getErrorMessage, getTenantId} from "utility/helpers/utils";
import TimeOffPolicy from "./TimeOffPolicy/TimeOffPolicy";
import SickLeavesPolicy from "./SickLeavesPolicy/SickLeavesPolicy";
import AttendancePolicy from "./AttendancePolicy/AttendancePolicy";
import BereavementLeavePolicy from "./BereavementLeavePolicy/BereavementLeavePolicy";
import MaternityLeavePolicy from "./MaternityLeavePolicy/MaternityLeavePolicy";
import WeddingLeavePolicy from "./WeddingLeavePolicy/WeddingLeavePolicy";
import LongVacationLeavePolicy from "./LongVacationLeavePolicy/LongVacationLeavePolicy";
import EmergencyLeavePolicy from "./EmergencyLeavePolicy/EmergencyLeavePolicy";
import UnPaidLeavePolicy from "./UnPaidLeavePolicy/UnPaidLeavePolicy";
import WorkFromHomePolicy from "./WorkFromHomePolicy/WorkFromHomePolicy";
import HajjLeavePolicy from "./HajjLeavePolicy/HajjLeavePolicy";
import TimeOffSubmissionPolicy from "./TimeOffSubmissionPolicy/TimeOffSubmissionPolicy";
import PaternityLeavePolicy from "./PaternityLeavePolicy/PaternityLeavePolicy";
import {MANAGE_POLICIES_ENABLE} from "utility/modals/constants";
import WithModals from "hoc/withModals/withModals";
import Loading from "components/Loading/Loading";

import './CreatePolicy.scss';
import {toast} from "react-toastify";

const CreatePolicy = (props) => {
  const { fromManageSetting, title, handleCloseButton, toggleManagePoliciesEnable, modals } = props;
  const tenantId = getTenantId(useLocation(),"tenantId");
  const [policyData, setPolicyData] = useState(undefined);
  const [inProgress, setInProgress] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [policyCheckboxes, setPolicyCheckboxes] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const selectedTheme = useTheme();
  const colorTheme = selectedTheme?.palette?.primary?.main;
  const createPolicyFields = (policyData, tenantId) =>
  {
      return [
        {
            id : "timeOffPolicy",
            title : "Time Off Policy",
            select : !policyData?.timeOffPolicy?.disabled,
            component: (
                <TimeOffPolicy setInProgress={setInProgress} refreshPolicyData={refreshPolicyData} timeOffPolicyData={policyData?.timeOffPolicy} tenantId={tenantId} />
            ),
        },
        {
            id : "sickLeavesPolicy",
            title : "Sick Leaves Policy",
            select : !policyData?.sickTimeOffPolicy?.disabled,
            component: (
                <SickLeavesPolicy sickTimeOffPolicy={policyData?.sickTimeOffPolicy} tenantId={tenantId} setInProgress={setInProgress} refreshPolicyData={refreshPolicyData} colorTheme={colorTheme}/>
            ),
        },
        {
            id : "attendancePolicy",
            title : "Attendance Policy",
            select : policyData?.attendancePolicy?.enabled,
            component: (
                <AttendancePolicy attendancePolicy={policyData?.attendancePolicy} tenantId={tenantId} setInProgress={setInProgress} refreshPolicyData={refreshPolicyData} colorTheme={colorTheme}/>
            ),
        },
        {
            id : "bereavementLeavePolicy",
            title : "Bereavement Leave Policy",
            select : !policyData?.bereavementAndFamilyDeathPolicy?.disabled,
            component: (
                <BereavementLeavePolicy bereavementAndFamilyDeathPolicy={policyData?.bereavementAndFamilyDeathPolicy} tenantId={tenantId} setInProgress={setInProgress} refreshPolicyData={refreshPolicyData} colorTheme={colorTheme}/>
            ),
        },
        {
            id : "maternityLeavePolicy",
            title : "Maternity Leave Policy",
            select : !policyData?.maternityPolicy?.disabled,
            component: (
                <MaternityLeavePolicy maternityPolicy={policyData?.maternityPolicy} tenantId={tenantId} setInProgress={setInProgress} refreshPolicyData={refreshPolicyData} colorTheme={colorTheme}/>
            ),
        },
        {
            id : "weddingLeavePolicy",
            title : "Wedding Leave Policy",
            select : !policyData?.weddingPolicy?.disabled,
            component: (
                <WeddingLeavePolicy weddingPolicy={policyData?.weddingPolicy} tenantId={tenantId} setInProgress={setInProgress} refreshPolicyData={refreshPolicyData} colorTheme={colorTheme}/>
            ),
        },
        {
            id : "paternityLeavePolicy",
            title : "Paternity Leave Policy",
            select : !policyData?.paternityPolicy?.disabled,
            component: (
                <PaternityLeavePolicy paternityPolicy={policyData?.paternityPolicy} tenantId={tenantId} setInProgress={setInProgress} refreshPolicyData={refreshPolicyData} colorTheme={colorTheme}/>
            ),
        },
        {
            id : "longVacationLeavePolicy",
            title : "Long Vacation Leave Policy",
            select : !policyData?.longVacationPolicy?.disabled,
            component: (
                <LongVacationLeavePolicy longVacationPolicy={policyData?.longVacationPolicy} tenantId={tenantId} setInProgress={setInProgress} refreshPolicyData={refreshPolicyData} colorTheme={colorTheme}/>
            ),
        },
        {
            id : "emergencyLeavePolicy",
            title : "Emergency Leave Policy",
            select : !policyData?.emergencyVacationPolicy?.disabled,
            component: (
                <EmergencyLeavePolicy emergencyVacationPolicy={policyData?.emergencyVacationPolicy} tenantId={tenantId} setInProgress={setInProgress} refreshPolicyData={refreshPolicyData} colorTheme={colorTheme}/>
            ),
        },
        {
            id : "unPaidLeavePolicy",
            title : "UnPaid Leave Policy",
            select : !policyData?.unPaidPolicy?.disabled,
            component: (
                <UnPaidLeavePolicy unPaidPolicy={policyData?.unPaidPolicy} tenantId={tenantId} setInProgress={setInProgress} refreshPolicyData={refreshPolicyData} colorTheme={colorTheme}/>
            ),
        },
        {
            id : "workFromHomePolicy",
            title : "Work From Home Policy",
            select : !policyData?.workFromHomePolicy?.disabled,
            component: (
                <WorkFromHomePolicy workFromHomePolicy={policyData?.workFromHomePolicy} tenantId={tenantId} setInProgress={setInProgress} refreshPolicyData={refreshPolicyData} colorTheme={colorTheme}/>
            ),
        },
        {
            id : "hajjLeavePolicy",
            title : "Hajj Leave Policy",
            select : !policyData?.hajPolicy?.disabled,
            component: (
                <HajjLeavePolicy hajPolicy={policyData?.hajPolicy} tenantId={tenantId} setInProgress={setInProgress} refreshPolicyData={refreshPolicyData} colorTheme={colorTheme}/>
            ),
        },
        {
            id : "timeOffSubmissionPolicy",
            title : "Time Off Submission Policy",
            select : !policyData?.timeOffSubmissionPolicy?.disabled,
            component: (
                <TimeOffSubmissionPolicy timeOffSubmissionPolicy={policyData?.timeOffSubmissionPolicy} tenantId={tenantId} setInProgress={setInProgress} refreshPolicyData={refreshPolicyData}/>
            ),
        }
    ]
  };
  const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
  };
  const getPolicyData = async (tenantId) => {
      try {
          setInProgress(true);
          const response = await getPolicy(tenantId);
          setPolicyData(response.data);
          setExpanded(false);
      } catch (e) {
          console.log(e);
      }
      setInProgress(false);
  }
  const refreshPolicyData = ()=>{
      getPolicyData(tenantId);
  }

    useEffect(()=>{
        getPolicyData(tenantId);
    }, [])

    useEffect(()=>{
        if(policyData){
            setPolicyCheckboxes([
                {name: "Sick leave", type: "SickTimeOffPolicy", select: !policyData["sickTimeOffPolicy"]?.disabled},
                {name: "Bereavement Leave", type: "BereavementAndFamilyDeathPolicy", select: !policyData["bereavementAndFamilyDeathPolicy"]?.disabled},
                {name: "Maternity Leave", type: "MaternityPolicy", select: !policyData["maternityPolicy"]?.disabled},
                {name: "Wedding Leave", type: "WeddingPolicy", select: !policyData["weddingPolicy"]?.disabled},
                {name: "Paternity Leave", type: "PaternityPolicy", select: !policyData["paternityPolicy"]?.disabled},
                {name: "Emergency Leave", type: "EmergencyPolicy", select: !policyData["emergencyVacationPolicy"]?.disabled},
                {name: "UnPaid Leave", type: "UnPaidPolicy", select: !policyData["unPaidPolicy"]?.disabled},
                {name: "Long Vacation Leave", type: "LongVacationPolicy", select: !policyData["longVacationPolicy"]?.disabled},
                {name: "Work from home (WFH)", type: "WorkFromHomePolicy", select: !policyData["workFromHomePolicy"]?.disabled},
                {name: "Hajj Leave", type: "HajPolicy", select: !policyData["hajPolicy"]?.disabled},
                {name: "Attendance Policy", type: "AttendancePolicy", select: policyData["attendancePolicy"]?.enabled},
            ]);
        }
    }, [policyData])
  const handleCheckboxChange = (policyType) => {
      setPolicyCheckboxes((prevCheckboxesArray) =>
          prevCheckboxesArray.map((item) =>
              item.type === policyType ? { ...item, select: !item.select } : item
          )
      );
  };
  const handleSubmit = async (event) => {
     setIsSubmitting(true);
     event.preventDefault();
     const payload = policyCheckboxes.map((item) => ({
        policyType : item.type,
        enable: item.select,
    }));
     try {
        await enablePolicies(tenantId, payload);
        refreshPolicyData();
        toggleManagePoliciesEnable();
         toast.success("Saved Successfully");
     } catch (e) {
         toast.error(getErrorMessage(e));
     }finally {
         setTimeout(() => {
             setIsSubmitting(false);
         }, 2000);
     }
  };
  return (
    <div id="create-policy" className="c-create-policy mx-3 mt-4">
     <div className="container-fluid">
          <CustomAppBar title={title} showSecondaryButton textSecondary="Close" handleCloseButton={handleCloseButton} />
            <div className='fs-4'>
                {fromManageSetting ?
                    <div className='d-flex justify-content-end'>
                        <Button onClick={toggleManagePoliciesEnable} variant="contained">Manage Policies</Button>
                    </div>
                    :
                    <div>
                        <div className="fw-bold fs-6 fst-italic">
                            <span><NotesIcon style={{color: colorTheme}} /></span> Go to Settings > Manage Policies tab if you want to do this step later
                        </div>
                    </div>
                }
                {(inProgress || !policyData) ? (
                    <Loading></Loading>
                ) : (
                    createPolicyFields(policyData, tenantId).map((item) =>
                            item?.select && (
                                <Accordion
                                    className="my-3"
                                    expanded={expanded === item.id}
                                    onChange={handleChange(item.id)}
                                    key={item.id} // Add a unique key prop
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        className="accordion-summary-bg"
                                    >
                                        <Typography className="holiday-title">{item.title}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {item.component}
                                    </AccordionDetails>
                                </Accordion>
                            )
                    )
                )}
            </div>
        </div>
        <Dialog className='confirmation-dialog' open={modals[MANAGE_POLICIES_ENABLE]} toggle={toggleManagePoliciesEnable}>
            <div className="d-flex flex-column mx-3">
                <div className="fs-5 m-3" style={{color: colorTheme}}>
                    Manage policies
                </div>
                {policyCheckboxes &&
                    <div className='m-3'>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={{ md: 1, xs: 0 }}>
                                {policyCheckboxes?.map((item) => (
                                    <Grid item md={6} >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="checked"
                                                    defaultChecked={item.select}
                                                    onChange={() => handleCheckboxChange(item.type)}
                                                />
                                            }
                                            className="check-box-padding"
                                            label={item.name}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                            <DialogActions className="p-3 mt-3">
                                <Button className="px-4 me-3" variant="outlined"
                                        onClick={toggleManagePoliciesEnable}>Cancel</Button>
                                <Button type="submit" className="px-4" variant="contained" disabled={isSubmitting}>Save</Button>
                            </DialogActions>
                        </form>
                    </div>
                }
            </div>
        </Dialog>
    </div>
  );
};
const modalsProps = {
    toggleManagePoliciesEnable: MANAGE_POLICIES_ENABLE,
};

export default WithModals(CreatePolicy, modalsProps);
