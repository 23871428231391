import { createTheme } from "components/Mui";

const customTheme = (tenantTheme) => {
    return createTheme({
        palette: {
            type: "light",
            primary: {
                light: "#f2f8f8",
                main: tenantTheme?.color,
                dark: tenantTheme?.darker,
                contrastText: "#fff",
            },
            secondary: {
                light: "#fff",
                main: "#fff",
                dark: "#fff",
                contrastText: tenantTheme?.lighter,
            },
        },
    });
};


const defaultTheme = createTheme({
    palette: {
        type: "light",
        primary: {
            light: "#f2f8f8",
            main: "#32c1c1",
            dark: "#2ca4a4",
            contrastText: "#fff",
        },
        secondary: {
            light: "#fff",
            main: "#fff",
            dark: "#fff",
            contrastText: "#32c1c1",
        },
    },
});

export { defaultTheme, customTheme };
