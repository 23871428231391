import React, { useEffect, useState } from "react";
import {LeftTabsDrawer, useTheme} from "components/Mui";
import BirthdaySetting from "./BirthdaySetting/BirthdaySetting";
import ManageSetting from "./ManageSetting/ManageSetting";
import FunctionalRole from "./FunctionalRoleSetting/FunctionalRoleSetting";
import SlackSetting from "./SlackSetting/SlackSetting";
import BenefitSetting from "./BenefitSetting/BenefitSetting";
import ScheduleSetting from "./ScheduleSetting/ScheduleSetting";
import {  CakeIcon, TagIcon, SaveAsIcon, UpdateIcon, BrushIcon, RedeemIcon, TuneIcon, SettingsSharpIcon , CalendarMonthIcon, ManageAccountsOutlinedIcon } from "components/Mui/Icons";
import {getTenantId} from "utility/helpers/utils";
import {useLocation} from "react-router-dom";
import ThemeColorSetting from "./ThemeColorSetting/ThemeColorSetting";
import DaylightTimeSaving from "./DaylightTimeSaving/DaylightTimeSaving";
import SystemConfiguration from "./SystemConfiguration/SystemConfiguration";
import "./Settings.scss";

const getSettingsDrawerList = (employeeId, handleCloseButton, tenantId, colorTheme) => {
  const tabs = [
    {
      title: "Birthday Wishes",
      component: <BirthdaySetting handleCloseButton={ handleCloseButton }/>,
      icon: <CakeIcon />,
      disabled: true
    },
    {
      title: "Slack Account",
      component: <SlackSetting title="Slack Account" handleCloseButton={ handleCloseButton } tenantId={tenantId}/>,
      icon: <TagIcon />,
    },
    {
      title: "Manage Polices",
      component: <ManageSetting title="Manage Polices" handleCloseButton={ handleCloseButton }/>,
      icon: <SaveAsIcon />,
    },
    {
      title: "Daylight time saving",
      component: <DaylightTimeSaving title="Daylight time saving" handleCloseButton={ handleCloseButton } tenantId={tenantId} colorTheme={colorTheme} />,
      icon: <UpdateIcon />,
    },
    {
      title: "Theme Color",
      component: <ThemeColorSetting title="Theme Color" handleCloseButton={ handleCloseButton } tenantId={tenantId} />,
      icon: <BrushIcon />,
    },
    {
      title: "Benefits",
      component: <BenefitSetting title="Benefits" handleCloseButton={ handleCloseButton } tenantId={tenantId} colorTheme={colorTheme}/>,
      icon: <RedeemIcon />,
    },
    {
      title: "Functional Role",
      component: <FunctionalRole title="Functional Role" handleCloseButton={ handleCloseButton } tenantId={tenantId} colorTheme={colorTheme}/>,
      icon: <ManageAccountsOutlinedIcon />,
    },
    {
      title: "Schedule",
      component: <ScheduleSetting title="Schedule" handleCloseButton={ handleCloseButton } tenantId={tenantId} colorTheme={colorTheme}/>,
      icon: <CalendarMonthIcon />,
    },
    {
      title: "System Configuration",
      component: <SystemConfiguration title="System configuration" handleCloseButton={ handleCloseButton } tenantId={tenantId} colorTheme={colorTheme}/>,
      icon: <TuneIcon />,
    },
  ];

  return tabs;
};

function Settings(props) {
  const { employeeId, handleCloseButton } = props;
  const tenantId = getTenantId(useLocation(), "tenantId");
  const [settingsDrawerList, setSettingsDrawerList] = useState([]);
  const selectedTheme = useTheme();
  const colorTheme = selectedTheme?.palette?.primary?.main;
  useEffect(() => {
    setSettingsDrawerList(getSettingsDrawerList(employeeId, handleCloseButton, tenantId, colorTheme));
  }, []);

  return (
    <div className="c-settings">
      {!!settingsDrawerList.length && (
          <LeftTabsDrawer
              drawerList={settingsDrawerList}
              currentTabIndex={1}
              orientation="vertical"
              className='left-drawer-setting-style'
          >
            <div className="drawer-header ">
              <div className="employee-image">
                <SettingsSharpIcon className="image" style={{color: colorTheme}}/>
                <h3 style={{color: colorTheme}} className='d-label-none px-2'>Settings</h3>
              </div>
            </div>
          </LeftTabsDrawer>
      )}
    </div>
  );
}

export default Settings;
