import React from 'react';
import {Tooltip as MuiTooltip, Zoom} from "@mui/material";
function Tooltip(props) {
    const { title, children, className, size='small', ...rest } = props;
    return (
        <MuiTooltip title={title} className={className} slots={{transition: Zoom}} {...rest}>
            {children}
        </MuiTooltip>
    );
}

export default Tooltip;