import React, {useState} from 'react';
import {rankOrdinalNumbers} from "utility/constants/data";
import {EMPLOYEE_DETAILS_MODAL} from "utility/modals/constants";
import EmployeeDetails from "containers/EmployeeDetails/EmployeeDetails";
import WithModals from "hoc/withModals/withModals";
import {Dialog, Tooltip, useTheme} from "components/Mui";
import Loading from "components/Loading/Loading";
import profile from 'assets/images/profile.svg';

import './EmployeeCircularImage.scss';
import classNames from "classnames";

const EmployeeCircularImage = ({ rank, isObserver, customApprover, imageUrl, employee, name, id, color, handelRefreshEmployeeList, toggleEmployeeProfileDetails, modals, handlePopoverOpen, size, loading, className }) => {
    const [selectedEmployeeId, setSelectedEmployeeId] = useState();
    const profileTabs = ["personal_info", "work_schedule_templates", "benefits", "teams", "roles", "time_off", "wfh", "customize_time_off_policy", "attendance_report"];
    const selectedTheme = useTheme();
    const [dialogOpen, setDialogOpen] = useState(null);
    const colorTheme = selectedTheme?.palette?.primary?.main;
    const openProfile = ()=> {
        setDialogOpen(true)
        let employeeId = id;
        if(!Number.isInteger(employeeId)){
            employeeId = id.employee;
        }
        setSelectedEmployeeId(employeeId);
        toggleEmployeeProfileDetails();
    }
    return (
        <>
            <Tooltip title={employee?.name || name} disableFocusListener disableHoverListener={dialogOpen}>
                <div className={classNames(className, "employee-circular-image-container")} onClick={(event) => {
                    handlePopoverOpen ? handlePopoverOpen(event, employee) : openProfile()
                }}>
                    {(rank && rank !== 0 && (!customApprover && !isObserver)) ?

                        <div className={`circular-number ${ size === "small" ? "small-circular" : "normal-circular"}`} style={{backgroundColor: color || colorTheme}}>
                            <span>{rankOrdinalNumbers[rank]?.label}</span>
                        </div>
                        :
                        null
                    }
                    {loading && <Loading className="absolute-loader"></Loading>}
                    <div className={`employee-container ${ size === "small" ? "small-employee-container" : "normal-employee-container"}`}>
                        <div className={`second-border ${ size === "small" ? "small-second-border" : "normal-second-border"}`}>
                            <div className="image-container">
                                <img src={imageUrl} alt={name} className="image" onError={(e) => e.target.src = profile}/>
                            </div>
                        </div>
                    </div>
                    {(customApprover || isObserver) && <div className={`custom-approver-badge ${ size === "small" ? "small-custom-approver-badge" : "normal-custom-approver-badge"}`}>{customApprover? "Custom" : "Observer"}</div>}
                    <div className="name-container">{name}</div>
                </div>
            </Tooltip>

            <Dialog className='details-dialog' fullScreen open={modals[EMPLOYEE_DETAILS_MODAL]}
                    toggle={toggleEmployeeProfileDetails}>
                <EmployeeDetails handleCloseButton={toggleEmployeeProfileDetails} employeeId={selectedEmployeeId}
                                 handelRefreshEmployeeList={handelRefreshEmployeeList} profileTabs={profileTabs}></EmployeeDetails>
            </Dialog>
        </>
    );
};
const modalsProps = {
    toggleEmployeeProfileDetails: EMPLOYEE_DETAILS_MODAL
};

export default WithModals(EmployeeCircularImage, modalsProps)
