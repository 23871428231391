import API from "./ApiBase";

export const getTeams = async (tenantId) => {
  const response = await API.get(`/api/v1/team/tenantTeams/${tenantId}`);
  return response;
};

export const updateTeam = async (teamId, payload) => {
  const response = await API.put(`/api/v1/team/${teamId}`, payload);
  return response;
};

export const createTeam = async (tenantId, payload) => {
  const response = await API.post(`/api/v2/team/${tenantId}`, payload);
  return response;
};

export const deleteTeam = async (teamId) => {
  return await API.put(`/api/v1/team/delete-team/${teamId}`, {});
};


export const getEmployeeApprovers = async (employeeId) => {
  return await API.get(`/api/v1/employee/getEmployeeApprovers/${employeeId}`);
};

export const getRootOfHierarchy = async (tenantId) => {
  return await API.get(`/api/v1/employee/getRootOfHierarchy/${tenantId}`);
};

export const directDescendants = async (employeeId) => {
  return await API.get(`/api/v1/employee/direct-descendants?employeeId=${employeeId}`);
};

export const getEmployeeTeamsName = async (tenantId) => {
  return await API.get(`/api/v1/employee/teams-names/${tenantId}`);
};

export const getFilteredTeams = async (tenantId, filterBy, filterValue) => {
  const params = {
    filterBy,
  };

  if (filterBy === 'member') {
    params.member = filterValue;
  } else if (filterBy === 'teamName') {
    params.teamName = filterValue;
  } else {
    params.member = filterValue;
    params.teamName = filterValue;
  }

  return await API.get(`/api/v1/team/filteredTeams/${tenantId}`, { params });
};
