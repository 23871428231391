import React, {useEffect} from "react";
import { Controller } from "react-hook-form";
import { Grid, TimePicker, Button, Tooltip, Checkbox, FormControlLabel } from "components/Mui";
import { AddIcon, RemoveIcon } from "components/Mui/Icons";
import {dayOptionsStartingFromSat} from "utility/constants/data";
import {TIME_FORMAT} from "utility/helpers/metaData";
import moment from "moment";
import {getIsManager} from "utility/helpers/authHelper";
import {toast} from "react-toastify";

import "./TimeSlots.scss";

const TimeSlots = (props) => {
  const { dataFromServer, slotShifted, setSlotShifted, control, setValue, disableAllField, workingDays, setTimeSlotsSelected } = props;
  const date = moment();
    const isEditView = getIsManager();

  const filteredSlots = dataFromServer?.slots?.filter(
        (item) => item.day === 2
  );
  const [fromTimeSlot1, setFromTimeSlot1] = React.useState(filteredSlots?.length? moment( `${filteredSlots[0]?.startSlotTimeHours}:${filteredSlots[0]?.startSlotTimeMinutes}`, TIME_FORMAT ) : date);
  const [toTimeSlot1, setToTimeSlot1] = React.useState(filteredSlots?.length? moment( `${filteredSlots[0]?.endSlotTimeHours}:${filteredSlots[0]?.endSlotTimeMinutes}`, TIME_FORMAT ) : date);
  const [fromTimeSlot2, setFromTimeSlot2] = React.useState(filteredSlots?.length > 1? moment( `${filteredSlots[1]?.startSlotTimeHours}:${filteredSlots[1]?.startSlotTimeMinutes}`, TIME_FORMAT ) : date);
  const [toTimeSlot2, setToTimeSlot2] = React.useState(filteredSlots?.length > 1? moment( `${filteredSlots[1]?.endSlotTimeHours}:${filteredSlots[1]?.endSlotTimeMinutes}`, TIME_FORMAT ) : date);


  const [selectedDays, setSelectedDays] = React.useState(() =>
     dayOptionsStartingFromSat.map((day) => ({
        id: day.value,
        label: day.label,
        value: false,
        fromTimeSlot1: date,
        toTimeSlot1: date,
        fromTimeSlot2: date,
        toTimeSlot2: date,
        slotShifted: false,
     }))
  );

  useEffect(() => {
     if (dataFromServer?.type === 'Custom') {
        setSelectedDays(updateSelectedDays(dataFromServer))
     }
  }, []);
    useEffect(() => {
        setTimeSlotsSelected(selectedDays);
    }, [selectedDays]);

  const updateSelectedDays = (dataFromServer) => {
     return dayOptionsStartingFromSat.map((day) => {
        const matchingDayEntries = dataFromServer.slots.filter((entry) => entry.day === day.value);
        if(matchingDayEntries.length === 0){
            return {
                id: day.value,
                label: day.label,
                value: false,
                fromTimeSlot1: date,
                toTimeSlot1: date,
                fromTimeSlot2: date,
                toTimeSlot2: date,
                slotShifted: false,
            };
        }
        let updatedDay = {
            id: day.value,
            label: day.label,
            value: true,
            fromTimeSlot1: moment(
                `${matchingDayEntries[0].startSlotTimeHours}:${matchingDayEntries[0].startSlotTimeMinutes}`,
                TIME_FORMAT
            ),
            toTimeSlot1: moment(
                `${matchingDayEntries[0].endSlotTimeHours}:${matchingDayEntries[0].endSlotTimeMinutes}`,
                TIME_FORMAT
            ),
            fromTimeSlot2: date,
            toTimeSlot2: date,
            slotShifted: false,
        };
        if (matchingDayEntries.length === 2) {
            updatedDay.slotShifted = true;
            updatedDay.fromTimeSlot2 = moment(
                `${matchingDayEntries[1].startSlotTimeHours}:${matchingDayEntries[1].startSlotTimeMinutes}`,
                TIME_FORMAT
            );
            updatedDay.toTimeSlot2 = moment(
                `${matchingDayEntries[1].endSlotTimeHours}:${matchingDayEntries[1].endSlotTimeMinutes}`,
                TIME_FORMAT
            );
        }
        return updatedDay;
     });
  };

  const handleDayChange = (id) => {
     setSelectedDays((prev) => {
        const updatedDays = prev.map((day) =>
            day.id === id ? { ...day, value: !day.value } : day
        );
        return updatedDays;
     });
  };
  const handleAddShift = (id) => {
     setSelectedDays((prev) => {
        const updatedDays = prev.map((day) =>
            day.id === id ? { ...day, slotShifted: true } : day
        );
        return updatedDays;
     });
  };

  const handleDeleteShift = (id) => {
     setSelectedDays((prev) => {
        const updatedDays = prev.map((day) =>
            day.id === id ? { ...day, slotShifted: false } : day
        );
        return updatedDays;
     });
  };

  return (
    <>
      {(workingDays === 'Custom')?
          <Grid container spacing={2} className="mx-1">
              {selectedDays?.map(({ label, value , id,fromTimeSlot1,toTimeSlot1,fromTimeSlot2, toTimeSlot2, slotShifted})  => (
                  <React.Fragment key={id}>
                      <Grid item md={12}>
                          <FormControlLabel
                              control={
                                  <Checkbox
                                      checked={value}
                                      onChange={() => handleDayChange(id)}
                                      disabled={disableAllField}
                                  />
                              }
                              label={label}
                          />
                      </Grid>
                      {value && (
                          <Grid className="m-2" container spacing={{ md: 1, xs: 0 }}>
                              <Grid item xs={12} md={2}>
                                  <Controller
                                      name={`selectedDays[${id}].fromTimeSlot1`}
                                      control={control}
                                      render={({ field }) => (
                                          <TimePicker
                                              {...field}
                                              label="From"
                                              value={field?.value || moment(fromTimeSlot1)}
                                              defaultValue={field?.value || moment(fromTimeSlot1)}
                                              onChange={(newValue) => {
                                                  field?.onChange(newValue);
                                                  setValue(`selectedDays[${id}].fromTimeSlot1`, newValue);
                                                  setSelectedDays((prev) => {
                                                      const updatedDays = [...prev];
                                                      const dayIndex = updatedDays.findIndex(day => day.id === id);
                                                      if (dayIndex !== -1) {
                                                          updatedDays[dayIndex].fromTimeSlot1 = newValue;
                                                      }
                                                      return updatedDays;
                                                  });
                                              }}
                                              disabled={disableAllField}
                                          />
                                      )}
                                  />
                              </Grid>
                              <Grid item xs={12} md={2}>
                                  <Controller
                                      name={`selectedDays[${id}].toTimeSlot1`}
                                      control={control}
                                      render={({ field }) => (
                                          <TimePicker
                                              {...field}
                                              label="To"
                                              value={field?.value || moment(toTimeSlot1)}
                                              defaultValue={field?.value || moment(toTimeSlot1)}
                                              onChange={(newValue) => {
                                                  field?.onChange(newValue);
                                                  setValue(`selectedDays[${id}].toTimeSlot1`, newValue);
                                                  setSelectedDays((prev) => {
                                                      const updatedDays = [...prev];
                                                      const dayIndex = updatedDays.findIndex(day => day.id === id);
                                                      if (dayIndex !== -1) {
                                                          updatedDays[dayIndex].toTimeSlot1 = newValue;
                                                      }
                                                      return updatedDays;
                                                  });
                                              }}
                                              minTime={moment(fromTimeSlot1)}
                                              disabled={disableAllField}
                                          />
                                      )}
                                  />
                              </Grid>
                              {slotShifted ? (
                                  <>
                                      <Grid item xs={12} md={2}>
                                          <Controller
                                              name={`selectedDays[${id}].fromTimeSlot2`}
                                              control={control}
                                              render={({ field }) => (
                                                  <TimePicker
                                                      {...field}
                                                      label="From"
                                                      value={field?.value || moment(fromTimeSlot2)}
                                                      defaultValue={field?.value || moment(fromTimeSlot2)}
                                                      onChange={(newValue) => {
                                                          field?.onChange(newValue);
                                                          setValue(`selectedDays[${id}].fromTimeSlot2`, newValue);
                                                          setSelectedDays((prev) => {
                                                              const updatedDays = [...prev];
                                                              const dayIndex = updatedDays.findIndex(day => day.id === id);
                                                              if (dayIndex !== -1) {
                                                                  updatedDays[dayIndex].fromTimeSlot2 = newValue;
                                                              }
                                                              return updatedDays;
                                                          });
                                                      }}
                                                      minTime={moment(toTimeSlot1)}
                                                      disabled={disableAllField}
                                                  />

                                              )}
                                          />
                                      </Grid>
                                      <Grid item xs={8} md={2}>
                                          <Controller
                                              name={`selectedDays[${id}].toTimeSlot2`}
                                              control={control}
                                              render={({ field }) => (
                                                  <TimePicker
                                                      {...field}
                                                      label="To"
                                                      value={field?.value || moment(toTimeSlot2)}
                                                      defaultValue={field?.value || moment(toTimeSlot2)}
                                                      onChange={(newValue) => {
                                                          field?.onChange(newValue);
                                                          setValue(`selectedDays[${id}].toTimeSlot2`, newValue);
                                                          setSelectedDays((prev) => {
                                                              const updatedDays = [...prev];
                                                              const dayIndex = updatedDays.findIndex(day => day.id === id);
                                                              if (dayIndex !== -1) {
                                                                  updatedDays[dayIndex].toTimeSlot2 = newValue;
                                                              }
                                                              return updatedDays;
                                                          });
                                                      }}
                                                      minTime={moment(fromTimeSlot2)}
                                                      disabled={disableAllField}
                                                  />
                                              )}
                                          />
                                      </Grid>
                                  </>
                              ) : null}
                              {slotShifted ? (
                                  <Grid item xs={2} md={2}>
                                      <Tooltip title="Delete shift">
                                          <Button
                                              disabled={disableAllField}
                                              className="delete-btn"
                                              type="button"
                                              onClick={() => handleDeleteShift(id)}
                                              variant="contained"
                                          >
                                              <RemoveIcon />
                                          </Button>
                                      </Tooltip>
                                  </Grid>
                              ) : (
                                  <Grid item xs={2} md={2}>
                                      <Tooltip title="Add shift">
                                          <Button
                                              disabled={disableAllField}
                                              className="add-btn"
                                              type="button"
                                              onClick={() => handleAddShift(id)}
                                              variant="contained"
                                          >
                                              <AddIcon />
                                          </Button>
                                      </Tooltip>
                                  </Grid>
                              )}
                          </Grid>

                      )}
                  </React.Fragment>
              ))}
          </Grid>
      :
          <Grid className="mx-1" container spacing={{ md: 1, xs: 0 }}>
              <Grid item xs={12} md={2}>
                  <Controller
                      name="fromTimeSlot1"
                      control={control}
                      render={({ field }) => (
                          <TimePicker
                              {...field}
                              label="From"
                              value={field?.value || moment(fromTimeSlot1)}
                              onChange={(newValue) => {
                                  field?.onChange(newValue);
                                  setValue('fromTimeSlot1', newValue);
                                  setFromTimeSlot1(newValue);
                              }}
                              disabled={disableAllField}
                          />
                      )}
                  />
              </Grid>
              <Grid item xs={12} md={2}>
                  <Controller
                      name="toTimeSlot1"
                      control={control}
                      render={({ field }) => (
                          <TimePicker
                              {...field}
                              label="To"
                              value={field?.value || moment(toTimeSlot1)}
                              onChange={(newValue) => {
                                  field?.onChange(newValue);
                                  setValue('toTimeSlot1', newValue);
                                  setToTimeSlot1(newValue);
                              }}
                              minTime={moment(fromTimeSlot1)}
                              disabled={disableAllField}
                          />
                      )}
                  />
              </Grid>
              {slotShifted ? (
                  <>
                      <Grid item xs={12} md={2}>
                          <Controller
                              name="fromTimeSlot2"
                              control={control}
                              render={({ field }) => (
                                  <TimePicker
                                      {...field}
                                      label="From"
                                      value={field?.value || moment(fromTimeSlot2)}
                                      onChange={(newValue) => {
                                          field?.onChange(newValue);
                                          setValue('fromTimeSlot2', newValue);
                                          setFromTimeSlot2(newValue);
                                      }}
                                      minTime={moment(toTimeSlot1)}
                                      disabled={disableAllField}
                                  />
                              )}
                          />
                      </Grid>
                      <Grid item xs={8} md={2}>
                          <Controller
                              name="toTimeSlot2"
                              control={control}
                              render={({ field }) => (
                                  <TimePicker
                                      {...field}
                                      label="To"
                                      value={field?.value || moment(toTimeSlot2)}
                                      onChange={(newValue) => {
                                          field?.onChange(newValue);
                                          setValue('toTimeSlot2', newValue);
                                          setToTimeSlot2(newValue)
                                      }}
                                      minTime={moment(fromTimeSlot2)}
                                      disabled={disableAllField}
                                  />
                              )}
                          />
                      </Grid>
                  </>
              ) : null}
              <>
                  {isEditView &&
                      <>
                          {slotShifted ? (
                              <Grid item xs={2} md={2}>
                                  <Tooltip title="Delete shift">
                                      <Button
                                          disabled={disableAllField}
                                          className="delete-btn"
                                          type="button"
                                          onClick={() => {
                                              setSlotShifted(false);
                                          }}
                                          variant="contained"
                                      >
                                          <RemoveIcon />
                                      </Button>
                                  </Tooltip>
                              </Grid>
                          ) : (
                              <Grid item xs={2} md={2}>
                                  <Tooltip title="Add shift">
                                      <Button
                                          disabled={disableAllField}
                                          className="add-btn"
                                          type="button"
                                          onClick={() => {
                                              setSlotShifted(true);
                                          }}
                                          variant="contained"
                                      >
                                          <AddIcon />
                                      </Button>
                                  </Tooltip>
                              </Grid>
                          )}
                      </>
                  }
              </>
          </Grid>

      }
    </>
  );
};

export default TimeSlots;
