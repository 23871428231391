import React, { useState, useEffect } from 'react';
import classNames from "classnames";
import {Tab as MuiTab, Tabs as MuiTabs} from "@mui/material";
import {LeftDrawer} from "components/Mui";
import UploadEmployeeImage from "../../uploadEmployeeImage/uploadEmployeeImage";

import "./LeftTabsDrawer.scss";

const TabPanel = (props) => {
    const { children, ...other } = props;

    return (
        <div
            role="tabpanel"
            aria-labelledby={`custom-tab`}
            {...other}
        >
            {children}
        </div>
    );
}

const a11yProps = (index) => {
    return {
        id: `custom-tab-${index}`,
        'aria-controls': `custom-tabpanel-${index}`,
    };
}

function LeftTabsDrawer({ drawerList, children, profilePicture, employeeId, orientation, currentTabIndex, handelRefreshEmployeeList, handleTabOnChange, wizardForm, keepOpen, UploadNewProfileImage, className=''}) {
    const [open, setOpen] = useState(true);
    const [selectedIndex, setSelectedIndex] = useState(currentTabIndex??0);

    useEffect(() => {
        currentTabIndex !== undefined && setSelectedIndex(currentTabIndex)
    }, [currentTabIndex]);

    const handleTabChange = (newTabIndex) => {
        setSelectedIndex(newTabIndex);
        if(handleTabOnChange){
            handleTabOnChange(newTabIndex);
        }
    };

    const handleDrawerChange = (value) => {
        setOpen(value);
    };

    return (
        <div className={classNames("c-left-tabs-drawer", open ? "drawer-open" : "drawer-close")}>
            <LeftDrawer onDrawerChange={handleDrawerChange} keepOpen={keepOpen} className={className}>
                {
                    profilePicture || UploadNewProfileImage ?
                         <UploadEmployeeImage UploadNewProfileImage={UploadNewProfileImage} employeeId={employeeId} profilePicture={profilePicture} handelRefreshEmployeeList={handelRefreshEmployeeList}/>
                    :
                        (children ? children : <div className='report-empty-header'></div>)
                }
                <div className="drawer-list">
                    <MuiTabs
                        orientation={orientation}
                        value={selectedIndex}
                        onChange={(e, newValue) => handleTabChange(newValue)}
                        variant="fullWidth"
                    >
                        {drawerList.map(({title, icon, className, disabled}, index) => (
                            <MuiTab
                                key={index}
                                label={<div className="d-label-none">{title}</div>}
                                icon={icon}
                                iconPosition="start"
                                className={classNames(className, ((wizardForm && (selectedIndex !== index)) || disabled) && "disabledTab")}
                                {...a11yProps(index)}
                                disabled={(wizardForm && (currentTabIndex !== index)) || disabled}
                            />
                        ))}
                    </MuiTabs>
                </div>
            </LeftDrawer>

            <div>
                <TabPanel className="tab-panel w-100 animated fadeIn" >
                    {drawerList[selectedIndex]?.component}
                </TabPanel>
            </div>
        </div>
    );
}

export default LeftTabsDrawer;
