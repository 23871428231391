import React, {useEffect, useState} from 'react';
import classNames from "classnames";
import {Route, useLocation} from 'react-router-dom';

import Footer from 'containers/Footer/Footer';
import {ThemeProvider} from "components/Mui";
import {defaultTheme} from "theme";
import ReportsHeader from "containers/Header/ReportsHeader";
import {getEmployeeInfoByToken} from "utility/apis/EmployeeService";
import {getErrorMessage} from "utility/helpers/utils";
import {toast} from "react-toastify";

const ReportsLayout = ({ component: Component, className='logged-in', ...rest }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const [employee, setEmployee] = useState(undefined);
    const getToken =  async () => {
        try {
            const response = await getEmployeeInfoByToken(token);
            setEmployee(response?.data);
        } catch (e){
            toast.error(getErrorMessage(e));
        }
    }
    useEffect(()=>{
        getToken();
    }, []);

    return(
        <Route
            {...rest}
            render={(matchProps) => (
                <>
                        <ThemeProvider theme={defaultTheme}>
                            <div className={classNames("c-layout", className)}>
                                <ReportsHeader employee={employee}/>
                                <main className="c-public-layout c-layout_main container-fluid background-style">
                                    {employee && <Component employee={employee} {...rest} {...matchProps} />}
                                </main>
                                <Footer />
                            </div>
                        </ThemeProvider>
                </>
            )}
        />
    );
}

export default ReportsLayout;
