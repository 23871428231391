import API from "./ApiBase";

export const getWorkScheduleTemplates = async (tenantId) => {
  const response = await API.get(`/api/v2/w-hours/tenant/${tenantId}`);
  return response;
};

export const updateWorkScheduleTemplate = async (tempId, payload) => {
  const response = await API.put(`/api/v2/w-hours/${tempId}`, payload);
  return response;
};

export const deleteWorkScheduleTemplate = async (tempId) => {
  const response = await API.delete(`/api/v1/w-hours/${tempId}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
};
