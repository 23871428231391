import API from './ApiBase';

export const createWorkScheduleTemplate = (payload) => {
    return API.post('/api/v2/w-hours/', payload);
};
export const createProfile = (tenantId, payload) => {
    return API.post(`/api/v2/employee/${tenantId}`, payload);
};
export const getPolicy = (tenantId) => {
    return API.get(`/api/v1/policy?tId=${tenantId}`);
};
export const getTimeOffPolicy = (tenantId) => {
    return API.get(`/api/v1/policy/timeoffpolicy/${tenantId}`);
};
export const enablePolicies = (tenantId, payload) => {
    return API.post(`/api/v1/policy/enablePolicies/${tenantId}`, payload);
};
export const updateAttendancePolicy = (tenantId, payload) => {
    return API.put(`/api/v1/policy/attendancePolicy/${tenantId}`, payload);
};
export const updateTimeOffSubmitPolicy = (tenantId, payload) => {
    return API.put(`/api/v1/policy/timeOffSubmitPolicy?tId=${tenantId}`, payload);
};
export const updatePolicy = (type, policyId, tenantId, payload) => {
    return API.put(`/api/v1/policy/${type}/${policyId}?tId=${tenantId}`, payload);
};