/* eslint-disable no-use-before-define */

import React, {useState} from 'react';
import {get, debounce} from "lodash";
import classNames from "classnames";
import {ErrorMessage} from "@hookform/error-message";
import { TextField, Checkbox, Chip, Autocomplete } from "components/Mui";
import { CheckBoxIcon, CheckBoxOutlineBlankIcon } from "components/Mui/Icons";
import profilePicture from "assets/images/profilePicture.png";

import './CheckboxesTags.scss';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CheckboxesTags(props) {
    const {labelField, name, value, label, placeholder, showLabel, showLeftImage, hideSelectedItems, fullWidth, inputRef, multiple, loading, errors, sx, defaultValue, ...rest} = props;

    const [inputValue, setInputValue] = useState('');
    const [visibleOptions, setVisibleOptions] = useState(25);

    const handleInputChange = debounce((event, newValue) => {
        setInputValue(newValue);
        setVisibleOptions(25);
    }, 400);
    const handleScroll = (event) => {
        const listboxNode = event.currentTarget;
        if (listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight) {
            setVisibleOptions(prev => prev + 25);
        }
    };
    return (
        <div>
            {showLabel && label && <label className="pl-1 font-weight-bold label-tag u-color-darkish-gray mr-5">{label}</label>}
            <Autocomplete
                value={value}
                defaultValue={defaultValue}
                inputRef={inputRef}
                loading={loading}
                name={name}
                multiple={multiple}
                className={"checkboxes-tags"}
                sx={sx}
                {...rest}
                onInputChange={handleInputChange}
                filterOptions={(options) =>
                    options
                        .filter(option =>
                            get(option, labelField).toLowerCase().includes(inputValue.toLowerCase())
                        )
                        .slice(0, visibleOptions)
                }
                ListboxProps={{
                    onScroll: handleScroll
                }}
                getOptionLabel={(option) => get(option ,labelField)}
                renderOption={(props, option, status ) => (
                    <li {...props} className={classNames('d-flex option my-1', option.fixed && 'disabled', status.selected && 'selected')}>
                        <Checkbox
                            className={classNames('', !multiple && 'visibility-hidden')}
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{marginRight: 8}}
                            checked={multiple ? status.selected : (status.inputValue === option[labelField] || status.inputValue === option["value"])}
                        />
                        {showLeftImage  && (option.profilePicture ?
                            (<img className='profile-pic-left' alt="img" src={option.profilePicture}/>)
                        :
                            <img className='profile-pic-left' alt="defaultImg" src={profilePicture}/>)
                        }
                        <div className='col mx-3 align-content-center' >
                            <div className='row'>{get(option, labelField)}</div>
                            {option?.scondaryText && <div className='row secondaryText'>{option?.scondaryText}</div>}
                            {option?.membersAmount && <div className='row secondaryText'>{option?.membersAmount}</div>}
                        </div>
                    </li>
                )}
                renderTags={(tagValues, getTagProps) => {
                    if(hideSelectedItems && tagValues && tagValues.length > 1) {
                        return <div className="text-nowrap" style={{paddingTop: "9.5px"}}>{`${tagValues.length} ${label} selected`}</div>
                    } else {
                        return tagValues.map((option, index) => (
                            <Chip key={index} {...getTagProps({ index })} disabled={get(option ,'fixed')} label={get(option ,labelField)} />
                        ));
                    }
                }}
                renderInput={(params) => (
                        <TextField {...params} name={name} inputRef={inputRef} fullWidth={fullWidth} label={label} placeholder={placeholder} />
                    )
                }
            />
            {!!errors && !(defaultValue?.length > 0 || value?.length > 0) &&
                <ErrorMessage className="error-message" errors={errors} name={name} as="p"/>
            }
        </div>
    );
}
